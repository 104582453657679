import React, { useState, useEffect, useRef } from "react";
import warnCircle from "../../assets/images/icons/warnCircle.svg";

type selectDropdownInfo = {
    style: string;
    data: Array<string>;
    title: string;
    titleStyle: string;
    placeholder: string;
    // placeholderColor: string;
    isTitle: boolean;
    isShowRequired: boolean;
    onSelect: any;
    onChange: any;
    name: string;
    value: string;
    revertColor?: boolean;
    selectedLabel?: boolean;
};
function SelectOutline({
                           style,
                           data = [],
                           title,
                           titleStyle,
                           placeholder,
                           contact,
                           setContact,
                           name,
                           onChange,
                           value,
                           selectedLabel = false,

                           // placeholderColor,
                           isTitle = false,
                           isShowRequired = false,
                           onSelect = () => null,
                           revertColor = false,
                       }: selectDropdownInfo) {
    const [isShow, setShow] = useState(false);
    const [selected, setSelected] = useState('');
    // const [dropdownSelected, setDropdownSelected] = useState(false);
    useEffect(() => {
        value && setSelected(value);
    }, [value]);
    useEffect(() => {
        if (isTitle) setSelected(data[0]);
    }, [data]);
    useEffect(() => {
        onSelect(selected);
        setShow(false);
    }, [selected]);
    const target = useRef<any>(null);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isShow && target.current && !target.current?.contains(e.target)) {
                setShow(false);
            }
        };

        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isShow]);

  return (
    <div ref={target}>
      {!isTitle ? (
        <div className="relative ">
          <span
            className={`absolute h-[18px]  block text-[12px] font-abcmonument !text-[14px] text-gray font-light leading-[160%] text-normal font-normal  transition-all duration-300 ${selected ? " text-white" : "top-[calc(100%-10px)] -translate-y-full left-0 text-gray"
              }`}
          >
            {placeholder}
          </span>
          <div
            className={`
          flex items-end   bg-transparent border-b cursor-pointer w-full focus:ring-1 pb-[10px] ${style} ${selected
                ? "border-b-brand-clime text-brand-clime justify-between "
                : "border-b-gray  text-[#FFFFFF] justify-end"
              } `}
            onClick={() => setShow(!isShow)}
          >
            {isShowRequired && !selected ? (
              <div className="gotham  absolute mr-[40px] right-[20px bottom-3 flex items-center font-normal text-xs text-[#FF6900]">
                This Field is required
                <img className="w-4 ml-[12px]" src={warnCircle} />
              </div>
            ) : (
              ""
            )}
            <input
            disabled
              onChange={onChange}
              name={name}
              type="text"
              className="outline-none w-full bg-transparent h-[18px] mt-[3px]"
              onInput={(e) => setSelected(e.target.value)}
              value={selected}
            />
            <div
              className={`w-[10px] h-[10px]  mr-4 border-l-[2px] border-b-[2px] mb-[7px] transition-all duration-150 -rotate-45 ${selected ? "border-brand-clime" : "border-brand-clime"
                } ${isShow ? "border-brand-clime rotate-[135deg]" : ""}`}
            ></div>
          </div>
          <div
            id='scrollVert'
            className={`w-full overflow-y-auto  bg-black border-slate-400 text-white border-2 absolute z-40 ${isShow ? "" : "hidden"
              }`}
          >
            {data.map((item: any, index: number) => (
              <div
                key={index}
                className="cursor-pointer text-start px-6 py-4 hover:bg-slate-400"
                onClick={() => {
                  setSelected(item);
                  setContact && setContact({ ...contact, [name]: item });
                  // setDropdownSelected(true);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="relative ">
          <span
            className={` after:ml-0.5 after:text-white block  font-medium graphik-regular text-white ${titleStyle}`}
          >
            {title}
          </span>

          <div
            className={`
      flex items-center mt-1 px-6 py-4 bg-black border cursor-pointer w-full focus:ring-1 justify-between  ${style} ${selected
                ? "border-brand-clime text-white "
                : "border-[#FFFFFF] text-gray "
              } `}
            onClick={() => setShow(!isShow)}
          >
            {selected ? selected : placeholder}
            <div
              className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 text-brand-clime ${isShow ? "rotate-[135deg]" : ""
                }`}
            ></div>
          </div>
          <div
            id='scrollVert'
            className={`w-full overflow-y-auto max-h-[224px] bg-black border-slate-400 text-white border-2 absolute z-40 ${isShow ? "" : "hidden"
              }`}
          >
            {data.map((item: any) => (
              <div
                className="cursor-pointer px-6 py-4 hover:bg-slate-400"
                onClick={() => {
                  setSelected(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectOutline;
