import React from "react";
import LifeStyle2 from "../../assets/images/homeClubLime/lifestyle2.png";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { createMarkup } from "../../common/functions/setInnerHtml";

export default function OneLine() {
  const { data, loading } = useFetch({
    params: "life-style-page",
    populate: "bottomBanner,bottomBanner.images",
  });
  return (
    <>
      {data != null && !loading && (
        <div className=" mx-[14px] lg:mx-[44px] mb-[50px] lg:my-[58px] md:flex-row flex-col-reverse flex">
          <div className="w-full md:w-1/2">
            <div className="text-brand-clime relative w-full h-full">
              <img
                src={
                  data.data.attributes.bottomBanner.images.data[0].attributes
                    .url ?? LifeStyle2
                }
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 pb-[50px] md:py-[50px] md:pl-[90px] md:pr-[100px]">
            <p
              className="Gravity text-[45px] lg:text-[65px] leading-[90%] text-white"
              dangerouslySetInnerHTML={createMarkup(
                data.data.attributes.bottomBanner.title
              )}
            >
            </p>
            <p className="text-base Mono my-[30px] md:my-[50px] text-white">
              {data.data.attributes.bottomBanner.description ??
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit​. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim veniam,.`}
            </p>
            
          </div>
        </div>
      )}
    </>
  );
}
