import React, { useEffect, useState } from "react";
import ExploreOtherVivaBrands from "../../components/home/ExploreOtherVivaBrands";
import Layout from "../../components/layout/Layout";
import StudioList from "../../components/studios/StudioList";
import StudioSearch from "../../components/studios/StudioSearch";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import { useLazyQuery } from "@apollo/client";
import { getBrandByName, getListLocation, getLocationByBrandId } from "../../apis/mutation";
import { Amplify, Storage } from "aws-amplify";
import DistanceCalculator from "../../utils/DistanceCalc";

function Studio() {

  const [isMap, setIsMap] = useState<boolean>(false);
  const [studios, setStudios] = useState();
  const [studiosDummy, setStudiosDummy] = useState();

  const [param, setParam] = useState<string>("");
  // const [allowed, setAllowed] = useState<boolean>(false);

  const [getBrandId] = useLazyQuery(getBrandByName)
  const [listStudioApi] = useLazyQuery(getListLocation)
  const [getLocationByBrand] = useLazyQuery(getLocationByBrandId)

  const { data: nearYour, loading } = useFetch({
    params: param,
    populate: "image,access,address.country",
  });
  const { data: allStudios } = useFetch({
    params: "studios",
    populate: "image,access,address.country",
  });
  const [graphLocations, setGraphLocations] = useState([]);

  // Studios near you
  useEffect(() => {
    const getPost = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(
          `studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`
        );
      });
    };
    getPost();
    getBrandId().then(res => {
      const brandId = res.data.getBrandByName.items[0].id
      getLocationByBrand({ variables: { brandId } }).then(location => {
        setGraphLocations(location.data.getLocationByBrandId.items);
        // setLocation(location.data.getLocationByBrandId.items[2]);
        navigator?.geolocation?.getCurrentPosition((locationCoor) => { handleGraphData(location.data.getLocationByBrandId.items, locationCoor) },
          () => { handleGraphData(location.data.getLocationByBrandId.items, null) }
        );
      })
    })
  }, []);
  async function handleGraphData(data: any, location: any) {
    data = data.map(async (i: any) => {
      let la = location?.coords?.latitude || 0
      let lo = location?.coords?.longitude || 0
      const distance = await DistanceCalculator({ origin: { lat: la, lng: lo }, destination: { lat: i?.geoLocation?.latitude, lng: i?.geoLocation?.longitude } })
      const imgUrl = await Storage.get(i.clubImage).catch(() => '')
      const result = {
        id: i.id,
        image: imgUrl,
        title: i.name.toLowerCase(),
        distance: location ? distance?.text : '', // need calculate
        access: ["24/7", "swimming pool", "health club"], //missing field data
        address: i?.address?.address ?? 'Unit 75, 76/30 Lonsdale St, Braddon ACT 2612',
        price: i?.attributes?.price ?? '16.9',
        phoneNumber: i.phoneNumber,
        isShow: false,
        regionState: i?.state?.toLowerCase(),
        regionCity: i?.suburb?.toLowerCase(),
      }
      if (!location) {
        return result;
      }
      if (distance && distance.value < 10000) return result;
      return undefined
    });
    data = await Promise.all(data)
    data = data.filter(i => i)
    setStudios(data)
    return data;
  }
  // Tracking allowing get the location
  // useEffect(() => {
  //   if (nearYour || allStudios)
  //     if (nearYour == null) {
  //       console.log('location turned off')
  //       // // setAllowed(false);
  //       // setStudios(allStudios);
  //       // const clubList = handleData(allStudios);
  //       // setStudios(clubList);
  //       // setStudiosDummy(clubList);
  //       getBrandId().then(res => {
  //         const brandId = res.data.getBrandByName.items[0].id
  //         getLocationByBrand({ variables: { brandId } }).then(location => {
  //           setGraphLocations(location.data.getLocationByBrandId.items);
  //           // setLocation(location.data.getLocationByBrandId.items[2]);
  //           navigator?.geolocation?.getCurrentPosition((locationCoor) => { handleGraphData(location.data.getLocationByBrandId.items, locationCoor) },
  //             () => { handleGraphData(location.data.getLocationByBrandId.items, null) },
  //           );
  //         })
  //       })
  //     } else {
  //       console.log('location turned on')
  //       getBrandId().then(res => {
  //         const brandId = res.data.getBrandByName.items[0].id
  //         getLocationByBrand({ variables: { brandId } }).then(location => {
  //           setGraphLocations(location.data.getLocationByBrandId.items);
  //           // setLocation(location.data.getLocationByBrandId.items[2]);
  //           navigator?.geolocation?.getCurrentPosition((locationCoor) => { handleGraphData(location.data.getLocationByBrandId.items, locationCoor) },
  //             () => { handleGraphData(location.data.getLocationByBrandId.items, null) },
  //           );
  //         })
  //       })
  //       // // setAllowed(true);
  //       // setStudios(nearYour);
  //       // const clubList = handleData(nearYour);
  //       // setStudios(clubList);
  //       // setStudiosDummy(clubList);
  //     }
  // }, [nearYour, allStudios]);

  function handleData(data: any) {
    data = data.data.map((i: any) => {
      return {
        id: i.id,
        image: i.attributes?.image?.data
          ? i.attributes?.image?.data?.attributes?.url
          : i.attributes?.image?.url,
        title: i.attributes?.title.toLowerCase(),
        distance: 0.3, // need calculate
        access: ["24/7", "swimming pool", "health club"], //missing field data
        address: i?.attributes?.fullAddress,
        price: i.attributes?.price,
        phoneNumber: i.attributes?.phoneNumber,
        isShow: false,
        regionState: i?.attributes?.address?.data
          ? i?.attributes?.address.data.attributes.Name.toLowerCase()
          : i?.attributes?.address?.Name?.toLowerCase(),
        regionCity: i?.attributes?.address?.data
          ? i?.attributes?.address?.data?.attributes?.country?.data?.attributes?.Name?.toLowerCase()
          : i?.attributes?.address?.country?.Name.toLowerCase(),
      };
    });
    return data;
  }

  const handleSearch = ({ key = "", target = "" }) => {
    if (key === "Enter") {
      if (target.value === "") setStudios(studiosDummy);
      else
        setStudios(
          studiosDummy.filter((i) =>
            i.address.toLowerCase().includes(target.value.toLowerCase())
          )
        );
    }
  };
  const setView = (value) => {
    setStudios(value);
  };

  const setFilterLocation = (value) => {
    // setFilter(value);
    const filter = value.map((e) => e.text);
    let data = [...studiosDummy];
    data = data.filter(
      (e) =>
        filter.some((v) =>
          e.regionState.toLowerCase().includes(v.toLowerCase())
        ) ||
        filter.some((v) => e.regionCity.toLowerCase().includes(v.toLowerCase()))
    );
    setStudios(data);
    // const res = data.filter(item => !arr2.includes(item));
  };
  const setSortData = (value) => {
    let data = [...studios];
    if (value.text == "a-z")
      data.sort((a, b) => a.title.localeCompare(b.title));
    if (value.text == "z-a")
      data.sort((a, b) => a.title.localeCompare(b.title)).reverse();
    else if (value.text == "closest to me") data = [...studiosDummy];
    setStudios(data);
    // setFilter(value);
  };
  return (
    <Layout>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <StudioSearch
            isMap={isMap}
            setIsMap={setIsMap}
            set={(e: any) => handleSearch(e)}
            data={studios}
            filterLocation={setFilterLocation}
            sortData={setSortData}
          ></StudioSearch>
          <StudioList
            isMap={isMap}
            data={studios}
            setList={(v) => setView(v)}
          ></StudioList>
          <ExploreOtherVivaBrands></ExploreOtherVivaBrands>
        </>
      )}
    </Layout>
  );
}

export default Studio;
