import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import loadingData from "../../assets/images/loading.mp4";
import { Link } from "gatsby";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import CheckedMarkClime from "../../assets/images/icons/CheckedMarkClime.svg";
import { useLazyQuery } from "@apollo/client";
import { getBrandByName, getListLocation, getLocationByBrandId } from "../../apis/mutation";
import { Amplify, Storage } from "aws-amplify";
import { useLoadScript } from "@react-google-maps/api";
import DistanceCalculator from "../../utils/DistanceCalc";
interface content {
  text: string;
  isReverse: boolean;
}

// Get Distance from current position to Studios
function Distance(pos: number) {
  const distance = pos?.km;
  return <span className="gotham">{distance?.toFixed(1)} km</span>;
}
function StudiosNearYou({
  // text = "find a studio",
  isReverse = false,
}: content) {
  const [param, setParam] = useState<string>("");
  const [studios, setStudios] = useState<[]>([]);
  const [allowed, setAllowed] = useState<boolean>(false);

  const { data: nearYour, loading } = useFetch({
    params: param,
    populate: "image,access,address.state",
  });
  const { data: allStudios } = useFetch({
    params: "studios",
    populate: "image,access,address.state",
  });
  const { data: homeText } = useFetch({
    params: "home-page",
    populate: "location, location.title,location.button",
  });
  // Studios near you
  const [graphLocations, setGraphLocations] = useState([]);
  const [getBrandId] = useLazyQuery(getBrandByName)
  const [listStudioApi] = useLazyQuery(getListLocation)
  const [getLocationByBrand] = useLazyQuery(getLocationByBrandId)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.GOOGLE_API_KEY,
    libraries: ["geometry"],
  });
  const [locationIsOn, setLocationIsOn] = useState()
  useEffect(() => {
    const getPost = () => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        setParam(
          `studios/inRange?location[]=${position?.coords?.latitude}&location[]=${position?.coords?.longitude}&distance=10000000`
        );
      });
    };
    getPost();
    getBrandId().then(res => {
      const brandId = res.data.getBrandByName.items[0].id
      getLocationByBrand({ variables: { brandId } }).then(location => {
        setGraphLocations(location.data.getLocationByBrandId.items);
        // setLocation(location.data.getLocationByBrandId.items[2]);
        navigator?.geolocation?.getCurrentPosition((locationCoor) => { handleGraphData(location.data.getLocationByBrandId.items, locationCoor); setLocationIsOn(true) },
          () => { handleGraphData(location.data.getLocationByBrandId.items, null); setLocationIsOn(false) },
        );
      })
    })
  }, []);

  async function handleGraphData(data: any, location: any) {
    data = data.map(async (i: any) => {
      let la = location?.coords?.latitude || 0
      let lo = location?.coords?.longitude || 0
      const distance = await DistanceCalculator({ origin: { lat: la, lng: lo }, destination: { lat: i?.geoLocation?.latitude, lng: i?.geoLocation?.longitude } })
      const imgUrl = await Storage.get(i.clubImage).catch(() => '')
      const result = {
        id: i.id,
        image: imgUrl,
        title: i.name.toLowerCase(),
        distance: location ? distance?.text : '', // need calculate
        access: ["24/7", "swimming pool", "health club"], //missing field data
        address: i?.address?.address ?? 'Unit 75, 76/30 Lonsdale St, Braddon ACT 2612',
        price: i?.attributes?.price ?? '16.9',
        phoneNumber: i.phoneNumber,
        isShow: false,
        regionState: i?.state?.toLowerCase(),
        regionCity: i?.suburb?.toLowerCase(),
      }
      if (!location) {
        return result;
      }
      if (distance && distance.value < 10000) return result;
      return undefined
    });
    data = await Promise.all(data)
    data = data.filter(i => i)
    setStudios(data)
    return data;
  }
  // const [spherical, setSpherical] = useState()
  // useEffect(() => { loadGGMap() }, []);
  // const loadGGMap = async () => {
  //   // const { spherical } = await google.maps.importLibrary("geometry")
  //   if (window.google && window.google.maps) {
  //     // calculateDistance();
  //   } else {
  //     // Load the Google Maps API script dynamically
  //     const script = document.createElement('script');
  //     script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=geometry`;
  //     script.async = true;
  //     script.defer = true;
  //     // script.onload = calculateDistance;
  //     document.head.appendChild(script);
  //   }
  //   // setSpherical(spherical);
  //   const geoLocation = { "latitude": -35.395964, "longitude": 149.071076, "__typename": "GeoLocation" }
  //   // spherical.computeDistanceBetween(,)

  // }
  // Tracking allowing get the location
  useEffect(() => {
    // if (nearYour == null) {
    //   setAllowed(false);
    //   setStudios(allStudios);
    // } else {
    //   setAllowed(true);
    //   setStudios(nearYour);
    // }
    if (isLoaded) { }
  }, [nearYour, allStudios, isLoaded]);

  return (
    <>
      {/* <DistanceCalculator origin={{ lat: 10.7792353, lng: 106.6979297 }} destination={{ lat:10.0353205, lng: 105.7143907 }} /> */}
      {loading && !isLoaded ? (
        <video
          style={{ width: "100%", objectFit: "fill", height: "513px" }}
          autoPlay
        >
          <source src={loadingData} type="video/mp4"></source>
        </video>
      ) : (
        <div className="md:px-[70px] px-[25px] justify-between studios-nearby flex md:flex-row md:mt-[160px] mt-[60px] mb-[130px]">
          <div className="md:w-[30%] w-full justify-between">
            <p className="text-brand-clime gotham text-[18px] tracking-[10%] ">
              {homeText?.data?.attributes?.location?.title}
            </p>
            <p className="md:text-[65px] text-[#d1ff00] uppercase bebas mt-[30px] mb-[30px] md:mb-0 md:mt-[71px] text-[40px] leading-[45px] md:leading-[90%] md:w-[382px] w-full md:h-[188px]   font-bold ">
              we have{" "}
              <span className=" text-effect-clime-2 Gravity text-transparent ">
                {studios?.length}
              </span>{" "}
              <br className="hidden md:block" />
              clubs
              <br className="block md:hidden" /> {locationIsOn ? 'near you' : ''}
            </p>
            {homeText?.data?.attributes?.location?.button.button && (
              <Link
                to={`${homeText?.data?.attributes?.location?.button.link ?? "/studio"
                  }`}
              >
                <div
                  className={`md:mt-[51px] mt-0 border-b-[1px] w-[178px] md:w-auto focus:border-b-brand-orange focus:border-b-[5px] font-bold h-[51px] text-[18px] border-t border-l bg-[black] border-r `}
                >
                  <ButtonNavigate
                    text={homeText?.data?.attributes?.location?.button.label}
                    isArrowRight={true}
                  ></ButtonNavigate>
                </div>
              </Link>
            )}
          </div>
          <div
            className="md:ml-[40px] ml-0 mt-[32px] md:mt-0 overflow-x-scroll flex-1 flex md:gap-x-[40px] gap-[33px]"
            id="scroll"
          >
            {studios.length && studios.filter(i => i)?.map((item: object, index: number) => {
              return (
                <div
                  className="md:min-w-[324px] min-w-[284px] block cursor-pointer md:pb-[63px] pb-8 "
                  key={index}
                >
                  <Link key={index} to={`/studio/${item.id}`}>
                    {item?.imageUrl?.slice(-3) ==
                      "mp4" ||
                      item?.attributes?.image?.url?.slice(-3) == "mp4" ? (
                      <video
                        controls
                        loop
                        muted
                        className="w-[324px] h-[312px] object-cover"
                        src={
                          item?.attributes?.image?.data?.attributes?.url ||
                          item?.attributes?.image?.url
                        }
                      />
                    ) : (
                      <img
                        src={
                          allowed
                            ? item?.attributes?.image?.url
                            : item?.attributes?.image?.data?.attributes?.url
                        }
                        className="w-[324px] h-[312px] object-cover"
                        alt="studio"
                      />
                    )}

                    <div className="mt-[28px] flex items-center justify-between w-full">
                      <div className="flex justify-between items-center w-full">
                        <h3
                          className={`uppercase text-2xl font-bold gotham ${isReverse ? "text-white" : "text-brand-clime"
                            }`}
                        >
                          {item?.title}
                        </h3>
                        <span className="text-white gotham">
                          {item?.distance}
                        </span>
                      </div>

                    </div>
                    {/* <div className="w-full h-[1.5px] mt-[11px] border-b border-b-gray"></div> */}
                    <div className="mt-[17px] text-white  flex text-base font-normal flex-col gap-2">
                      {allowed ? (
                        <div className="">
                          {item?.access
                            ?.filter((i, index) => i && index < 3)
                            ?.map((item, idx) => (
                              <div
                                className="flex items-center gap-[17px] mb-[21px]"
                                key={idx}
                              >
                                <div className="w-[21px] h-[21px] flex justify-center items-center border-[1px] border-brand-clime rounded-full">
                                  <img src={CheckedMarkClime} />
                                </div>
                                <span className="gotham uppercase">
                                  {item}
                                </span>
                              </div>
                            ))}
                        </div>
                      ) : (
                        <div className="">
                          {item?.access?.filter((i, index) => i && index < 3)
                            .map((item, idx) => (
                              <div
                                className="flex items-center gap-[17px] mb-[21px]"
                                key={idx}
                              >
                                <div className="w-[21px] h-[21px] flex justify-center items-center border-[1px] border-brand-clime rounded-full">
                                  <img src={CheckedMarkClime} />
                                </div>
                                <span className="gotham ">
                                  {item}
                                </span>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      )}
    </>
  );
}

export default StudiosNearYou;
