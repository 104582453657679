// import { url } from "inspector";
import React from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { navigate } from 'gatsby'
interface props {
  data: any;
}
export default function GroupFitness({ data }: props) {
  const [active, setActive] = React.useState(0);

  return (
    <div
      className={`text-brand-clime grid md:grid-cols-2 grid-cols-1 bg-cover custom-bg-none  bg-no-repeat  grid-flow-row md:pl-[25px] md:pl-[70px] `}
      style={{
        backgroundImage: `url(${data?.avaliableClass[active]?.background?.data?.attributes?.url})`,
      }}
    // style={{'var(--image-url)': groupFitnessPhoto }}
    >
      <div className="">
        <p className="text-[98.92px] Gravity leading-[89.03px] md:pt-[152px] md:pb-[152px] px-[14px] md:px-0">
          Group
          <br /> Fitness
        </p>
        <div className="pb-[32px] px-[14px] md:px-0">
          <ButtonNavigate text="ALL CLASSES" isArrowRight={true} onClick={() => navigate('/group-fitness')} />
        </div>
      </div>
      <div className="flex md:items-end flex-col justify-center py-[33px] ">
        {data?.avaliableClass.map((item, index) => (
          <div
            key={index}
            className={`pt-[20px] pl-[30px] custom-bg-none-opposite  pb-[25px] text-white border-1 border-t relative bg-cover bg-no-repeat ${active === index
                ? "w-full md:border-brand-clime "
                : "w-1/3 border-[rgba(255,255,255,0.4)]"
              } ${index === data?.avaliableClass - 1 && active !== index
                ? "border-b"
                : ""
              }`}
            style={{
              backgroundImage:
                active === index
                  ? `url(${data?.avaliableClass[active]?.background?.data?.attributes?.url})`
                  : "",
            }}
            onClick={() => {
              setActive(index);
            }}
          >
            <p
              className={`uppercase ${active === index
                  ? " relative md:absolute top-0 left-0 md:-translate-y-1/2 md:leading-[90%]  bebas text-effect-clime text-transparent text-[55px] md:text-[70px]"
                  : ""
                }`}
            >
              {item.title}
              {active === index && (
                <span className="block md:hidden h-[1px] w-full bg-brand-clime absolute top-[37.5px] -translate-y-1/2 "></span>
              )}
            </p>

            <div
              className={`text-sm Mono  md:mt-[45px]  ${active === index ? "" : "hidden"
                }`}
            >
              {item.description}
              <div className="uppercase flex gap-[50px] gotham mt-[28px]">
                <div className="flex gap-[20px] items-center">
                  <p className="border gotham border-brand-clime rounded-full w-[39px] h-[39px] flex items-center justify-center">
                    {item?.customMetadata?.time ?? 45}
                  </p>
                  <p className="gotham ">Minutes</p>
                </div>
                <div className="flex gap-[20px] items-center">
                  <p className="border gotham border-brand-clime rounded-full w-[39px] h-[39px] flex items-center justify-center">
                    {item?.customMetadata?.classes ?? 5}
                  </p>
                  <p className="gotham ">per class</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
