import ArrowButton from '../../common/buttons/ArrowButton';
import React from 'react';
import appstore from '../../assets/images/appstore.png';
import googlePlay from '../../assets/images/chplay.png';
import moment from 'moment';
import { navigate } from 'gatsby';
import ArrowButtonSecondary from '../../common/buttons/ArrowButtonSecondary';
function Congrats(props) {
  return (
      <div
          id="scroll-hidden"
          className=" h-[900px] w-full overflow-y-scroll md:overflow-y-hidden sm:pb-20"
      >
        <h2
            onClick={() => {
              props.moveSlide(0);
            }}
            className="leading-[100%] text-brand-clime border-b mt-0 md:mt-[74px] border-b-brand-clime !tracking-[0.3px] pb-2 text-[25px] font-black text-left flex md:hidden uppercase"
        >
          success, you've just <br /> joined club lime
        </h2>
        <div
            className={`mt-[28px] sm:mt-[23px] flex flex-col md:flex-row items-start md:items-center w-full ${
                moment(new Date()).diff(
                    moment(props.info.memberDetails.dob),
                    'years'
                ) >= 18
                    ? 'justify-between '
                    : 'sm:gap-[62px] gap-6'
            }`}
        >
          <div className="flex flex-col justify-start items-start">
            <h2
                style={{ fontSize: '40px', textAlign: 'start' }}
                className="font-black uppercase !tracking-[0.65px] text-[65px] text-white text-start mb-3 md:mb-0 font-abcgravity "
            >
              {moment(new Date()).diff(
                  moment(props.info.memberDetails.dob),
                  'years'
              ) >= 18
                  ? 'welcome, ' + props.info.memberDetails.surname + '!'
                  : 'MEMBERSHIP PENDING'}
            </h2>
            <div
                className={`text-white text-start font-normal  mt-[31px] text-[14px] font-abcmonument  max-w-[535px]   ${
                    moment(new Date()).diff(
                        moment(props.info.memberDetails.dob),
                        'years'
                    ) >= 18
                        ? 'mt-4 '
                        : 'mt-[5px]'
                }`}
                onClick={() =>
                    moment(new Date()).diff(
                        moment(props.info.memberDetails.dob),
                        'years'
                    ) >= 18 && navigate('/login')
                }
            >
              {moment(new Date()).diff(
                  moment(props.info.memberDetails.dob),
                  'years'
              ) >= 18 ? (
                  <ArrowButton text="LOG IN" isRight={true} />
              ) : (
                  "Thank you for joining Club Lime. We're excited to have you onboard. What happens now? An SMS has been sent to your nominated parent/guardian, which requires approval to finalise your membership. If you or your parent/guardian have any issues, please contact your local Club Lime directly."
                )}
            </div>
          </div>
          <div
              className={` gap-x-12 gap-y-[18px] items-start sm:flex-row flex-col justify-between self-start ${
                  moment(new Date()).diff(
                      moment(props.info.memberDetails.dob),
                      'years'
                  ) >= 18
                      ? 'flex flex-row'
                      : 'grid grid-row-reverse '
              }`}
          >
            <div className='order-2'>
              {/* <h2 className='font-abcmonument text-sm text-white text-left'>Your membership number</h2>
                        <p className='font-abcmonument text-textSecondary text-left tracking-[1px] text-[12px] mt-1'>123 456 789</p> */}
              <h2 className="font-abcmonument text-sm text-white text-left">Username</h2>
              <p className="font-abcmonument text-textSecondary text-left tracking-[1.4px] text-[14px] mt-1">
                {props.info.memberDetails.email}
              </p>
            </div>
            <div className='order-1'>
              <h2 className="font-abcmonument text-sm text-white text-left">Start date</h2>
              <p className="font-abcmonument text-textSecondary text-left tracking-[1.4px] text-[14px] mt-1">
                {moment(new Date()).diff(
                    moment(props.info.memberDetails.dob),
                    'years'
                ) >= 18
                    ? `Today, ${moment().format('DD/MM/YYYY')}`
                    : 'Pending'}
              </p>
            </div>
            {moment(new Date()).diff(
                moment(props.info.memberDetails.dob),
                'years'
            ) < 18 ? (
                <div className='order-3 w-max'>
                  <ArrowButton text="LOG IN" isRight={true} />
                </div>
            ) : (
                ''
            )}
          </div>
        </div>
        <div className="mt-[57px]">
          <h2 className="uppercase text-left pb-[8px] sm:pb-[8px] border-b border-b-brand-clime text-brand-clime text-[25px] !tracking-[0.3px] font-bold">
            next steps
          </h2>
        </div>
        <div className="mt-[21px]">
          <div className="flex flex-col md:flex-row items-start pb-[12px] border-b border-b-borderLine md:items-center justify-between">
            <div className="flex items-center gap-4">
              <h1 className="font-black sm:text-brand-clime text-white font-abcgravity text-[40px] !tracking-[0.4px]  ">
                01
              </h1>
              <p
                  style={{ fontSize: '18px' }}
                  className="uppercase font-bold sm:text-white text-brand-clime  font-abcmonument !tracking-[0.6px] "
              >
                Check your email
              </p>
            </div>
            <p className="text-white text-left md:text-right text-[14px] mt-2 md:mt-0 pl-[42px] sm:pl-0 leading-[160%]">
              We've sent your membership number, and payment receipt to your
              nominated <br /> email address to your email address.
            </p>
          </div>
          <div className="flex pt-4 pb-3 flex-col md:flex-row items-start border-b border-b-borderLine md:items-center justify-between">
            <div className="flex items-center gap-4">
              <h1 className="font-black sm:text-brand-clime text-white font-abcgravity text-[40px] !tracking-[0.4px] ">
                02
              </h1>
              <p
                  style={{ fontSize: '18px' }}
                  className="uppercase font-bold sm:text-white text-brand-clime font-abcmonument !tracking-[0.6px] "
              >
                Check the app
              </p>
            </div>
            <div>
              <p className="text-white text-left md:text-right text-[14px] mt-2 md:mt-0 pl-[42px] sm:pl-0 leading-[160%]">
                Download the Club Lime app, get it setup on your phone <br />{' '}
              </p>
              <div className="mt-2 flex justify-start md:justify-end items-center gap-3 sm:pl-0 pl-[42px]">
                <img className="h-[34px] w-auto" src={appstore} alt="appstore" />
                <img className="h-[53px] w-auto" src={googlePlay} alt="ggplay" />
              </div>
            </div>
          </div>
          <div className="flex pt-6 pb-3 flex-col md:flex-row items-start md:items-center justify-between">
            <div className="flex items-center gap-4">
              <h1 className="font-black sm:text-brand-clime text-white font-abcgravity text-[40px] !tracking-[0.4px] ">
                03
              </h1>
              <p
                  style={{ fontSize: '18px' }}
                  className="uppercase text-left font-bold sm:text-white text-brand-clime  font-abcmonument !tracking-[0.6px]"
              >
                Come to your club and <br />
                start training
              </p>
            </div>
            <p className="text-white text-left text-[14px] md:text-right mt-2 md:mt-0 pl-[42px] sm:pl-0 leading-[160%]">
              Congratulations on taking the first step of your fitness journey at
              Club Lime. <br /> Simply use the mobile member app to access your
              gym and get your sweat on!
            </p>
          </div>

          <div className="mt-4  md:absolute md:hidden block    md:right-[110px] customArrowCongrats md:items-end  md:w-[251px] w-full">
            <ArrowButtonSecondary
                isRight
                text="NEED A PERSONAL TRAINER ?"
                onclick={() => console.log('Personal trainer')}
                noArr={false}
            />
          </div>
        </div>
      </div>
  );
}

export default Congrats;