import React from "react";
import LoadingScreen from "../../common/loading/LoadingScreen";
import useFetch from "../../hooks/useFetch";

function ExploreOtherVivaBrands() {
  const { loading, data } = useFetch({
    params: "club",
    populate: "brands.logo,brands.image,brands.features",
  });
  const orderFeatures = [
    "24/7 ACCESS",
    "NO LOCK-IN CONTRACTS",
    "100 GYMS AUSTRALIA WIDE",
    "STATE OF THE ART EQUIPMENT",
  ];
  return (
    <div className="md:px-[70px] px-[25px] md:mb-[72px] mb-[25px]">
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          <h2 className="text-white font-bold py-[16px] text-2xl border-b border-b-[#FFFFFF] uppercase">
            explore other viva brands
          </h2>
          <div
            id="scroll"
            className="mt-[14px] flex w-full sm:overflow-x-hidden md:overflow-x-auto overflow-x-scroll md:gap-[60px] gap-[18px]"
          >
            {data?.data?.attributes?.brands?.map((item: any, index: number) => (
              <div className="block" key={index}>
                <img
                  src={item?.image?.data?.attributes?.url}
                  className="md:min-w-[398px] min-w-[235px] object-cover h-[165px] md:h-[272px]"
                />
                <img
                  src={item?.logo?.data?.attributes?.url}
                  className="mt-[22px] w-[200px] object-cover"
                />
                <div className="md:mt-[40px] uppercase mt-[18px] md:mb-[54px] mb-[20px] md:text-base text-sm font-normal text-white">
                  {item?.features?.data
                    ?.sort((a, b) => {
                      const indexA = orderFeatures.indexOf(a.attributes.title);
                      const indexB = orderFeatures.indexOf(b.attributes.title);
                      if (
                        (indexA !== -1 && indexB !== -1) ||
                        (indexA === -1 && indexB === -1)
                      ) {
                        return indexA - indexB;
                      }
                      if (indexA === -1) {
                        return 1;
                      }
                      if (indexB === -1) {
                        return -1;
                      }
                    })
                    .map((brand) => (
                      <li style={{ color: item?.color }}>
                        <span className="graphik-regular text-white">
                          {brand?.attributes?.title}
                        </span>
                      </li>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default ExploreOtherVivaBrands;
