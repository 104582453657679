import React from "react";
import LoadingScreen from "../../common/loading/LoadingScreen";
import member1 from "../../assets/images/homeClubLime/member1.png";
import member2 from "../../assets/images/homeClubLime/member2.png";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { navigate } from "gatsby";
import { createMarkup } from "../../common/functions/setInnerHtml";

function Memberships() {
  // const [toggle, setToggle] = useState(false);
  // const [selected, setSelected] = useState(0);
  const { loading, data } = useFetch({
    params: "home-page",
    populate: "bottomBanner ,bottomBanner.img1, bottomBanner.img2",
  });
  const membershipsData = data?.data?.attributes?.bottomBanner;
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && membershipsData && (
        <>
          <div className=" grid-cols-5 grid-rows-5 mb-[100px] md:grid hidden relative">
            <div className="col-span-2 row-span-3 flex justify-end items-center">
              <img
                className="opacity-75 z-10"
                src={membershipsData?.img1?.data?.attributes?.url ?? member1}
              />
            </div>
            <div className="text-white"></div>
            <div className="text-white"></div>
            <div className="text-white"></div>
            <div className="text-white col-span-3 relative flex flex-col">
              <div className="h-1/2 w-[100vw] max-w-[1377px] absolute top-6 right-0  text-center mr-[48px] menu-text">
                <div className="bebas relative leading-[90%] z-10 text-[106.364px] text-right  mt-[-25px] italic" dangerouslySetInnerHTML={createMarkup(membershipsData?.title)} >
                  {/* {?? "IT’S A LIFESTYle"} */}
                  {/* <span className="bebas text-[106.364px] text-effect-clime-2 text-transparent leading-[57%] italic"> your</span>
                  <span className="bebas text-[106.364px] leading-[57%] italic text-[#d1ff00] "> gym </span>
                  <span className="bebas text-[106.364px] text-effect-clime-2 text-transparent leading-[57%] italic"> your </span>
                  <span className="bebas text-[106.364px] leading-[57%] italic text-[#d1ff00] "> lifestyle</span> */}
                </div>
              </div>
              <div className="h-1/2"></div>
            </div>
            <div className="text-white"></div>
            <div className="text-white col-span-3 row-span-3 flex flex-col pt-[38px]">
              <div className="md:ml-[84px] flex flex-col gap-[21px]">
                <p className="uppercase text-brand-clime  text-md Bossa">
                  {membershipsData?.altTitle ?? "life at lime"}
                </p>
                <p className="Mono text-base">
                  {membershipsData?.description ??
                    "A healthy lifestyle looks different to everyone. At Club Lime, we offer more than just rooms filled with exercise equipment. We give you fitness on your terms, granting you the freedom, motivation and support to live your ideal lifestyle."}
                </p>
                <ButtonNavigate
                  onClick={() => navigate("/studio")}
                  text="EXPLORE"
                  isArrowRight={true}
                ></ButtonNavigate>
              </div>
            </div>

            <div className="col-span-2 col-start-4 col-end-6 row-start-3 row-end-6 flex justify-end items-end">
              <img
                src={membershipsData?.img2?.data?.attributes?.url ?? member2}
              />
            </div>
          </div>
          <div className="relative md:hidden block">
            <div className="relative">
              <img
                src={membershipsData?.img1?.data?.attributes?.url ?? member1}
              />
              <div className="bebas absolute bottom-[10%]  w-full md:text-[106.364px] text-[60.364px] text-center  border-brand-clime leading-[90%] text-effect-clime-2 italic text-transparent mt-[-25px] menu-text mr-[14px]" dangerouslySetInnerHTML={createMarkup(membershipsData?.title)}>
                {/* {membershipsData?.title ?? "IT’S A LIFESTYLE"} */}
              </div>
            </div>

            <div className="md:ml-[158px] flex flex-col gap-[21px] px-[4.9%] py-[26px]">
              <p className="uppercase text-brand-clime Bossa text-sm">
                {membershipsData?.altTitle ?? "life at lime"}
              </p>
              <p className="Mono text-base text-white">
                {membershipsData?.description ??
                  "A healthy lifestyle looks different to everyone. At Club Lime, we offer more than just rooms filled with exercise equipment. We give you fitness on your terms, granting you the freedom, motivation and support to live your ideal lifestyle."}
              </p>
              <div className="mb-[30px] md:mb-0">
                <ButtonNavigate
                  onClick={() => navigate("/studio")}
                  text="EXPLORE"
                  isArrowRight={true}
                ></ButtonNavigate>
              </div>
              <img
                src={membershipsData?.img2?.data?.attributes?.url ?? member2}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Memberships;
