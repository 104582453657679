import React, { useState, useRef } from 'react'
function VideoItem(url: string) {
    const videoRef = useRef<any>();
    const [isPlay, setIsPlay] = useState(false);
    return (
        <div className='relative'>
            <svg onClick={() => {
                setIsPlay(true);
                videoRef.current.play()
            }} className={`absolute left-1/2 top-1/2 z-20 transform -translate-y-1/2 -translate-x-1/2 ${isPlay ? 'opacity-0' : 'opacity-100'}`}
                xmlns="http://www.w3.org/2000/svg" width="74" height="78" viewBox="0 0 74 78" fill="none">
                <path d="M2.35892 4.13952C2.3446 2.62145 3.96041 1.64154 5.29996 2.35592L70.8866 37.3333C72.2985 38.0862 72.2985 40.1098 70.8867 40.8627L5.29676 75.8426C3.95796 76.5566 2.34283 75.5781 2.35569 74.0609L2.66935 37.0451L2.35892 4.13952Z" stroke="#82FA00" strokeWidth="3" />
            </svg>
            <video
                controls={isPlay}
                onEnded={() => setIsPlay(false)}
                ref={videoRef}
                onLoadedData={() => videoRef.current.pause()}
                className='md:w-[626px] w-[243px] md:h-[513px] h-[232px] object-cover' src={url?.url} />
        </div>
    )
}

export default VideoItem