import React, { useState, useRef } from "react";
import Layout from "../components/layout/Layout";
import ButtonActive from "../common/buttons/Active";
import plus from "../assets/images/memberships/plusActive.png";
import minus from "../assets/images/memberships/minusActive.svg";
import textEffect from "../assets/images/work/textEffect2.png";
import useFetch from "../hooks/useFetch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import playTriangle from "../assets/images/icons/playTriangle.svg";
// import { motion } from "framer-motion";
import LoadingScreen from "../common/loading/LoadingScreen";
// import { Input } from "postcss";
import InputBase from "../common/inputs/InputBase";
import SelectOption from "../common/dropdowns/SelectOption";

// Slide Config
const settings = {
  dots: false,
  infinite: 1 > 2,
  speed: 500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export const Franchise: React.FC = () => {
  const filter = useFetch({
    params: "form",
    populate:
      "franchiseRequestFromData, franchiseRequestFromData.state , franchiseRequestFromData.timeFrameToOpen.*, franchiseRequestFromData.investmentCapacity.*,franchiseRequestFromData.heardUsFrom.*",
  });
  // const { data: allStudios } = useFetch({
  //   params: "studios",
  //   populate: "image,access,address.country",
  // });
  const state = useFetch({ params: "states", populate: "" });
  const [toggle, setToggle] = useState(false);
  const [selected, setSelected] = useState(0);
  // const [currentClass, setCurrentClass] = useState<string>("");

  const videoRef = useRef<any>();
  const [isPlay, setIsPlay] = useState(true);

  const play = () => {
    setIsPlay(false);
    videoRef.current.play();
  };

  const { data, loading } = useFetch({
    params: "franchise-page",
    populate:
      "topBanner.background,form,midBanner.background,benefits,benefits.image,endCarousels.background,videoCarousels.background,videoCarousels.images",
  });

  // useEffect(() => {
    // const lastEl = data?.data?.attributes?.endCarousels?.slice(-1);
    // setCurrentClass(lastEl?.[0]?.title);
  // }, [data]);

  // Handle Check Total Rate
  // const totalRate = (total: number) => {
  //   let rate = [];
  //   let unRate = [];
  //   for (let i = 1; i <= total; i++) {
  //     rate.push(i);
  //   }
  //   for (let i = 1; i <= 5 - total; i++) {
  //     unRate.push(i);
  //   }

  //   const [form, setForm] = useState({
  //     fullName: "",
  //     email: "",
  //     phoneNumber: "",
  //     location: "",
  //     message: "",
  //   });
  //   const [formControlled, setFormControlled] = useState({
  //     fullName: false,
  //     email: false,
  //     phoneNumber: false,
  //     location: false,
  //     message: false,
  //   });
  //   const [errText, setErrText] = useState({ email: "" });

  //   const submit = () => {
  //     let control = { ...formControlled };
  //     Object.keys(form).map((e) => {
  //       if (form[e] == "" || e === "email") control = { ...control, [e]: true };
  //       else if (e === "email") {
  //         if (form[e] === "") {
  //           control = { ...control, [e]: true };
  //           setErrText({ ...errText, email: "" });
  //         } else if (
  //           !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
  //             form[e]
  //           )
  //         ) {
  //           setErrText({ ...errText, email: "invalid email address" });
  //           control = { ...control, [e]: true };
  //         } else control = { ...control, [e]: false };
  //       } else control = { ...control, [e]: false };
  //       setFormControlled(control);
  //     });
  //   };
  //   return (
  //     <div className="flex gap-2">
  //       {rate.map((index: number) => (
  //         <svg
  //           key={index}
  //           width="9"
  //           height="14"
  //           viewBox="0 0 9 14"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M0.435547 8.38662C0.435547 6.32523 3.24352 3.40494 4.47903 1C5.60221 3.1759 8.5225 6.00458 8.5225 8.38662C8.5225 10.7687 7.39932 12.6812 4.47903 12.6812C1.55874 12.6812 0.435547 10.448 0.435547 8.38662Z"
  //             fill="#FF6900"
  //             stroke="#FF6900"
  //             strokeWidth="0.64878"
  //             strokeLinejoin="round"
  //           />
  //         </svg>
  //       ))}
  //       {unRate.map((index: number) => (
  //         <svg
  //           key={index}
  //           width="10"
  //           height="14"
  //           viewBox="0 0 10 14"
  //           fill="none"
  //           xmlns="http://www.w3.org/2000/svg"
  //         >
  //           <path
  //             d="M0.912109 8.38662C0.912109 6.32523 3.72008 3.40494 4.95559 1C6.07878 3.1759 8.99907 6.00458 8.99907 8.38662C8.99907 10.7687 7.87588 12.6812 4.95559 12.6812C2.0353 12.6812 0.912109 10.448 0.912109 8.38662Z"
  //             stroke="#FF6900"
  //             strokeLinejoin="round"
  //           />
  //         </svg>
  //       ))}
  //     </div>
  //   );
  // };

  // const filteredStudio = ["belconnen", "anu", "braddon"];

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    location: "",
    timeFrame: "",
    investment: "",
    state: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    location: false,
    timeFrame: false,
    investment: false,
    state: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" || e === "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };

  return (
    <Layout>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          {/* Banner */}
          <div className="w-full lg:h-[650px] h-[552px] relative">
            <img
              src={
                data?.data?.attributes?.topBanner?.background?.data?.attributes
                  ?.url
              }
              className="w-full h-full object-cover"
              alt="hiit intro"
            />
            <div className="w-full absolute top-[50%] transform  translate-y-[-50%]">
              <h6 className="text-[#FF6900] lg:text-2xl text-[20px] lowercase font-bold text-center">
                {data?.data?.attributes?.topBanner?.title}
              </h6>
              <p className="lg:mt-[38px] mt-[30px] max-w-[585px] mx-auto outline-text-md lg:text-[80px] text-[30px] uppercase leading-[90%] font-bold  text-center text-white tracking-wider">
                {data?.data?.attributes?.topBanner?.description}
              </p>
              <div className="mt-[95px] w-full flex justify-center">
                <ButtonActive text="play video" height={56} />
              </div>
            </div>
          </div>
          {/* Expression of interest */}
          <div className="w-full border-y pt-[54px] lg:px-[70px] px-[25px] pb-[62px] flex expression-form justify-between">
            <div>
              <h2 className="font-bold text-white tracking-tight lowercase lg:text-[60px] text-[40px] max-w-[337px] leading-[110%]">
                {data?.data?.attributes?.form?.title}
              </h2>
              <p className="lg:mt-[105px] mt-[30px] graphik-regular font-normal text-sm leading-[160%] text-white max-w-[372px]">
                {data?.data?.attributes?.form?.description}
              </p>
            </div>
            <div className="flex-col">
              <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-[40px] graphik-regular gap-y-[4px] text-brand-orange">
                <InputBase
                  width={"100%"}
                  type="text"
                  className=" px-6 bg-black border h-[56px] border-[#FFFFFF] placeholder-gray focus:placeholder:text-[#FF7900] focus:outline-none focus:border-brand-orange focus:text-brand-orange focus:ring-brand-orange block w-[391px] sm:text-sm focus:ring-1"
                  holder={"First name*"}
                  isRequired={true}
                  isShowRequired={formControlled.firstName}
                  onchange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                />
                <InputBase
                  width={"100%"}
                  type="text"
                  className=" px-6 bg-black border h-[56px] border-[#FFFFFF] placeholder-gray focus:placeholder:text-[#FF7900] focus:outline-none focus:border-brand-orange focus:text-brand-orange focus:ring-brand-orange block w-[391px] sm:text-sm focus:ring-1"
                  holder={"Last name*"}
                  isRequired={true}
                  isShowRequired={formControlled.lastName}
                  onchange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />

                <InputBase
                  width={"100%"}
                  type="text"
                  className=" px-6 bg-black border h-[56px] border-[#FFFFFF] placeholder-gray focus:placeholder:text-[#FF7900] focus:outline-none focus:border-brand-orange focus:text-brand-orange focus:ring-brand-orange block w-[391px] sm:text-sm focus:ring-1"
                  holder={"Email address*"}
                  isRequired={true}
                  isShowRequired={formControlled.email}
                  onchange={(e) => setForm({ ...form, email: e.target.value })}
                />

                <InputBase
                  type="text"
                  width={"100%"}
                  className=" px-6 bg-black border h-[56px] border-[#FFFFFF] placeholder-gray focus:placeholder:text-[#FF7900] focus:outline-none focus:border-brand-orange focus:text-brand-orange focus:ring-brand-orange block w-[391px] sm:text-sm focus:ring-1"
                  holder={"Phone number*"}
                  isRequired={true}
                  isShowRequired={formControlled.phoneNumber}
                  onchange={(e) =>
                    setForm({ ...form, phoneNumber: e.target.value })
                  }
                />

                <SelectOption
                  titleStyle="text-[12px]"
                  style=" h-[54px] w-full graphik-regular text-[14px]"
                  placeholder="State*"
                  isShowRequired={formControlled.state}
                  placeholderColor="text-slate-400"
                  onSelect={(v) => setForm({ ...form, state: v })}
                  data={state.data.data.map((e) => e.attributes.Name)}
                ></SelectOption>
                <SelectOption
                  titleStyle="text-[12px]"
                  style=" h-[54px] w-full graphik-regular text-[14px]"
                  placeholder="Investment capacity*"
                  isShowRequired={formControlled.investment}
                  placeholderColor="text-slate-400"
                  onSelect={(v) => setForm({ ...form, investment: v })}
                  data={filter.data?.data.attributes.franchiseRequestFromData.investmentCapacity.map(
                    (e) => e.value
                  )}
                ></SelectOption>
                <SelectOption
                  titleStyle="text-[12px]"
                  style=" h-[54px] lg:w-[391px] w-full graphik-regular text-[14px]"
                  placeholder="Timeframe to open*"
                  isShowRequired={formControlled.timeFrame}
                  placeholderColor="text-slate-400"
                  onSelect={(v) => setForm({ ...form, timeFrame: v })}
                  data={filter.data?.data.attributes.franchiseRequestFromData.timeFrameToOpen.map(
                    (e) => e.from + "-" + e.to + " months"
                  )}
                ></SelectOption>
                <InputBase
                  width="100%"
                  holder="Location*"
                  isRequired={true}
                  isShowRequired={formControlled.location}
                  onchange={(e) =>
                    setForm({ ...form, location: e.target.value })
                  }
                ></InputBase>
              </div>
              <div className="float-right mt-[36px]" onClick={() => submit()}>
                <ButtonActive height={56} text="send now" />
              </div>
            </div>
          </div>
          {/* About us */}
          <div className="mt-[30px] w-full h-[625px] relative hidden lg:block">
            <img
              src={textEffect}
              className="absolute left-0 top-[-45px] w-[36x] z-10 h-full"
            />
            <img
              src={textEffect}
              className="absolute right-0 top-[-45px] w-[36x] h-full"
            />
            <div className="absolute left-0 top-0 w-[953px] h-[554px]">
              <img
                src={
                  data?.data?.attributes?.midBanner?.background?.data
                    ?.attributes?.url
                }
                className="w-full h-full object-cover"
              />
              <div className="absolute top-[151px] right-[-380px]">
                <h2 className="font-bold lg:text-[60px] text-[40px] leading-[110%] tracking-tight lowercase opacity-90 outline-text-1">
                  {data?.data?.attributes?.midBanner?.title}
                </h2>
                <p className="font-normal leading-[160%] text-sm text-white graphik-regular mt-[31px] float-right max-w-[433px]">
                  {data?.data?.attributes?.midBanner?.description}
                </p>
              </div>
            </div>
          </div>
          {/* Mobile */}
          <div className="mt-[30px] w-full h-[625px] overflow-hidden block lg:hidden">

            <div className="px-[25px] w-full flex flex-col">
              <h2 className="font-bold  text-[40px] leading-[110%] tracking-tight lowercase opacity-90 outline-text-1">
                {data?.data?.attributes?.midBanner?.title}
              </h2>
              <p className="font-normal leading-[160%] text-sm text-white graphik-regular mt-[31px]">
                {data?.data?.attributes?.midBanner?.description}
              </p>
            </div>
            <div className="ml-[80px]">
              <img
                src={
                  data?.data?.attributes?.midBanner?.background?.data
                    ?.attributes?.url
                }
                className="w-full h-[312px] object-cover transform translate-y-[-30px] absolute -z-10"
              />
            </div>
          </div>
          {/* Benefits */}
          <div className="text-white w-full mt-[57px] hidden lg:block">
            {data?.data?.attributes?.benefits.map(
              (item: object, index: number) => (
                <div
                  onClick={() => {
                    setToggle(!toggle);
                    setSelected(index);
                  }}
                  key={index}
                >
                  <div
                    style={{
                      borderTop: "1px solid white",
                      borderBottom:
                        index === 4 && !toggle ? "1px solid #C6C6C6" : "",
                    }}
                    className="flex cursor-pointer items-center py-5 pr-[65px] pl-[74px] justify-between"
                  >
                    <p
                      style={{
                        color:
                          selected === index && toggle ? "#FF6900" : "white",
                      }}
                      className="text-2xl font-bold"
                    >
                      {toggle && selected === index
                        ? item?.customMetadata?.expandedText
                        : item.title}
                    </p>
                    <span
                      onClick={() => {
                        setToggle(!toggle);
                        setSelected(index);
                      }}
                    >
                      <img
                        src={toggle && selected === index ? minus : plus}
                        alt="plus icon"
                      />
                    </span>
                  </div>
                  <ul
                    id={selected === index && toggle ? "showlist" : "hideList"}
                    style={{
                      paddingBottom: selected == index && toggle ? "25px" : "",
                    }}
                    className="list-mems"
                  >
                    <div className="flex mt-[49px] items-center justify-between pl-[74px] pr-[65px]">
                      <div>
                        <p className="leading-[160%] text-base font-normal max-w-[384px] graphik-regular text-white">
                          {item?.subtitle}
                        </p>
                        <div className="mt-[40px]">
                          <ButtonActive
                            height={56}
                            text="register my interest"
                          />
                        </div>
                      </div>
                      <div className="w-[195px] h-[195px] rounded-[50%] overflow-hidden  border border-brand-orange flex items-center justify-center text-2xl font-bold text-brand-orange">
                        <img
                          className="w-full h-full object-cover"
                          src={item?.image?.data?.attributes?.url}
                        />
                      </div>
                    </div>
                  </ul>
                </div>
              )
            )}
          </div>
          {/* Mobile */}
          <div className="text-white w-full mt-[27px] block lg:hidden">
            {data?.data?.attributes?.benefits.map(
              (item: object, index: number) => (
                <div
                  onClick={() => {
                    setToggle(!toggle);
                    setSelected(index);
                  }}
                  key={index}
                >
                  <div
                    style={{
                      borderTop: "1px solid white",
                      borderBottom:
                        index === 4 && !toggle ? "1px solid #C6C6C6" : "",
                    }}
                    className="flex cursor-pointer items-center py-5 mt-5 px-[25px]   justify-between"
                  >
                    <p
                      style={{
                        color:
                          selected === index && toggle ? "#FF6900" : "white",
                      }}
                      className="text-base font-bold"
                    >
                      {toggle && selected === index
                        ? item?.customMetadata?.expandedText
                        : item.title}
                    </p>
                    <span
                      onClick={() => {
                        setToggle(!toggle);
                        setSelected(index);
                      }}
                    >
                      <img
                        src={toggle && selected === index ? minus : plus}
                        alt="plus icon"
                      />
                    </span>
                  </div>
                  {selected === index && toggle && (
                    <div className="w-[162px] h-[162px] rounded-[50%]  ml-[25px] overflow-hidden  border border-brand-orange flex items-center justify-center text-2xl font-bold text-brand-orange">
                      <img
                        className="w-full h-full object-cover "
                        src={item?.image?.data?.attributes?.url}
                      />
                    </div>
                  )}
                  <ul
                    id={selected === index && toggle ? "showlist" : "hideList"}
                    style={{
                      paddingBottom: selected == index && toggle ? "25px" : "",
                    }}
                    className="list-mems"
                  >
                    <div className="flex mt-[49px] items-center justify-between px-[25px]">
                      <div>
                        <p className="leading-[160%] text-base font-normal max-w-[384px] graphik-regular text-white">
                          {item?.subtitle}
                        </p>
                        <div className="mt-[40px]">
                          <ButtonActive
                            height={56}
                            text="register my interest"
                          />
                        </div>
                      </div>

                    </div>
                  </ul>
                </div>
              )
            )}
          </div>
          {/* Carousel  */}
          <div className="mt-[50px] hidden lg:block">
            <Slider {...settings}>
              {data?.data?.attributes?.videoCarousels?.map((item) => (
                <div className="relative w-full py-[20px]">
                  <div className="text-white  flex justify-center items-center h-[757px]">
                    <div>
                      <img
                        src={item?.images?.data?.[0]?.attributes?.url}
                        className="max-h-[391px] max-w-[650px] object-cover"
                      />
                    </div>
                    <div className="relative border-[1px]  w-[702px] h-[442px] border-brand-orange p-6 mx-[86px]">
                      <video
                        onEnded={() => setIsPlay(true)}
                        ref={videoRef}
                        onLoadedData={() => videoRef.current.pause()}
                        style={{
                          objectFit: "fill",
                          height: "391px",
                          width: "650px",
                        }}
                      >
                        <source
                          src={item?.background?.data?.attributes?.url}
                          type="video/mp4"
                        ></source>
                      </video>
                      <div className="w-full flex justify-center mt-[70px]">
                        <ButtonActive text="register my interest" height={56} />
                      </div>
                      <img
                        onClick={() => {
                          play();
                          setIsPlay(false);
                        }}
                        className={`absolute top-1/2 left-1/2 cursor-pointer -translate-x-1/2 -translate-y-1/2 ${!isPlay ? "opacity-0" : ""
                          }`}
                        src={playTriangle}
                      />
                      <p className="absolute uppercase leading-[81.17px] tracking-[0.03em] text-[90.19px] top-1/2 left-[0%] -translate-y-1/2 stroke-gray origin-bottom-left -rotate-90">
                        {item?.title}
                      </p>
                      <p className="absolute uppercase leading-[81.17px] tracking-[0.03em] text-[90.19px] top-1/2 right-[0%] -translate-y-1/2 stroke-gray origin-top-right -rotate-90 ">
                        {item?.description}
                      </p>
                    </div>
                    <div>
                      <img
                        src={item?.images?.data?.[1]?.attributes?.url}
                        className="max-h-[391px] max-w-[650px] object-cover"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
          {/* Carousel mobile */}
          <div className="mt-[50px] block lg:hidden">
            <Slider {...settings}>
              {data?.data?.attributes?.videoCarousels?.map((item) => (
                <div className="relative w-full py-[20px]">
                  <div className="text-white  flex justify-center w-full items-center h-[757px]">
                    <div>
                      <img
                        src={item?.images?.data?.[0]?.attributes?.url}
                        className="max-h-[391px] max-w-[650px] object-cover"
                      />
                    </div>
                    <div className="border-[1px] w-[288px] h-[229px] border-brand-orange p-3 mx-[23px] ">
                      <div className="h-[263px] w-[189px] overflow-hidden">
                        <video
                          onEnded={() => setIsPlay(true)}
                          ref={videoRef}
                          onLoadedData={() => videoRef.current.pause()}
                          style={{
                            objectFit: "contain",
                            height: "100%",
                            width: "100%",
                            overflow: 'hidden'
                          }}
                        >
                          <source
                            src={item?.background?.data?.attributes?.url}
                            type="video/mp4"
                          ></source>
                        </video>
                      </div>
                      <div className="w-full flex justify-center mt-[70px]">
                        <ButtonActive text="register my interest" height={56} />
                      </div>
                      <img
                        onClick={() => {
                          play();
                          setIsPlay(false);
                        }}
                        className={`absolute top-1/2 left-1/2 cursor-pointer -translate-x-1/2 -translate-y-1/2 ${!isPlay ? "opacity-0" : ""
                          }`}
                        src={playTriangle}
                      />
                      <p className="absolute hidden uppercase leading-[81.17px] tracking-[0.03em] text-[90.19px] top-1/2 left-[0%] -translate-y-1/2 stroke-gray origin-bottom-left -rotate-90">
                        {item?.title}
                      </p>
                      <p className="absolute uppercase hidden leading-[81.17px] tracking-[0.03em] text-[90.19px] top-1/2 right-[0%] -translate-y-1/2 stroke-gray origin-top-right -rotate-90 ">
                        {item?.description}
                      </p>
                    </div>
                    <div>
                      <img
                        src={item?.images?.data?.[1]?.attributes?.url}
                        className="max-h-[391px] max-w-[650px] object-cover"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </>
      )}
    </Layout>
  );
};
