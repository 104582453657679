import React, { useEffect, useState } from "react";
import HomeSlider from "../../components/home/HomeSlider";
import Layout from "../../components/layout/Layout";
import Intro from "../../components/studios/Intro";
import Gallery from "../../components/studios/Gallery";
import PricesForBraddon from "../../components/memberships/PricesForBraddon";
import ClubFeatures from "../../components/studios/ClubFeatures";
import Equipment from "../../components/studios/Equipment";
import GroupFitness from "../../components/studios/GroupFitness";
import PersonalTrainer from "../../components/studios/PersonalTrainer";
import ClassesToday from "../../components/studios/ClassesToday";
import useFetch from "../../hooks/useFetch";
import GetYourFreePass from "../../components/home/GetYourFreePass";
import TwoWeekFree from "../../components/studios/TwoWeekFree";
import { useLazyQuery } from "@apollo/client";
import { getBrandByName, getLocationByBrandId } from "../../apis/mutation";

function studioId(props: any) {
  //   const test = useLocation();

  const { data: studioPromotion } = useFetch({ params: 'global', populate: 'studioPromotion' })
  const { data } = useFetch({
    params: "studios",
    populate:
      "image, access, description, gallery, coachers, coachers.image, coachers.customMetadata, coachers.background,coachers.images, background, avaliableClass, avaliableClass.background, avalibleClass.customMetadata, midCarrousels, midCarrousels.background, midCarrousels.images, openTime, supportTime",
  });
  const [currentClub, setCurrentCLub] = useState();
  useEffect(() => {
    if (data) {
      const club = data.data.filter((i) => i.id == 4)[0]?.attributes;
      setCurrentCLub(club);
    }
  }, [data]);
  const [show, setShow] = useState(false);

  const [clubGraph, setClubGraph] = useState()
  const [getBrandId] = useLazyQuery(getBrandByName)
  const [getLocationByBrand] = useLazyQuery(getLocationByBrandId)
  useEffect(() => {
    getBrandId().then(res => {
      const brandId = res.data.getBrandByName.items[0].id
      getLocationByBrand({ variables: { brandId } }).then(location => {
        const currentStudio = location.data.getLocationByBrandId.items?.filter((i) => i.id == props.id)[0]
        setClubGraph(currentStudio)
      })
    })

    const timeOut = setTimeout(showModal, 5000);

    function showModal() {
      setShow(true);
      stopTimeOut();
    }

    function stopTimeOut() {
      clearTimeout(timeOut);
    }
  }, []);

  return (
    <Layout>
      {/* <StudioDetail id={props}></StudioDetail> */}
      {data != null && (
        <>
          {studioPromotion.data.attributes.studioPromotion && <><div className="sticky z-40 top-[30%] left-full bg-[red] w-full h-[0px] text-red-500">
            <button
              className="absolute right-0 uppercase rounded-tl-[5px] rounded-tr-[5px] h-[38px] w-[211px] origin-bottom-right -rotate-90 bg-brand-clime border-[1px] border-brand-clime text-black font-abcmonument leading-[0.1em] font-medium"
              onClick={() => setShow(true)}
            >
              Two weeks free
            </button>
          </div>
            <div
              className={`sticky z-50 top-[10%]  bg-[red] w-full text-red-500  h-full transition-all duration-500 ${show ? "show left-full" : "w-0 left-full"
                }`}
            >
              <div className="absolute  h-[49px]  right-0 bg-black border-[1px] border-brand-clime text-brand-clime ">
                <div className="relative">
                  <div className="sticky z-40 top-[30%] right-full bg-[red] w-full h-[0px] text-red-500">
                    <button
                      className="absolute uppercase rounded-tl-[5px] rounded-tr-[5px] right-full h-[38px] w-[211px] origin-bottom-right -rotate-90 bg-brand-clime border-[1px]  border-brand-clime text-black font-abcmonument leading-[0.1em] font-medium"
                      onClick={() => setShow(!show)}
                    >
                      Two weeks free{" "}
                      <svg
                        className="md:hidden inline"
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.7578 16.4351L5.67036 5.34761"
                          stroke="currentColor"
                          strokeWidth="1.11532"
                        />
                        <path
                          d="M5.54297 16.5601L16.5608 5.54224"
                          stroke="currentColor"
                          strokeWidth="1.11532"
                        />
                      </svg>
                    </button>
                  </div>
                  {show && <TwoWeekFree setShow={setShow} />}
                </div>
              </div>
            </div></>}
          <HomeSlider />
          <Intro data={currentClub} graphData={clubGraph} />
          <Gallery data={currentClub} />
          <div id="Membership">
            <PricesForBraddon color='#1B1B1B' data={{ ...clubGraph }} />
          </div>
          <div id="Club-features">
            <ClubFeatures data={currentClub} />
          </div>
          <Equipment data={currentClub} />
          <div id="Classes">
            <GroupFitness data={currentClub} />
          </div>
          <ClassesToday data={{ ...clubGraph }} />

          <PersonalTrainer data={currentClub} />

          <div id="Free-trial">
            <GetYourFreePass />
          </div>
        </>
      )}
    </Layout>
  );
}

export default studioId;
