import React, { useState } from 'react';
interface IProps {
  text: string;
  isRight: boolean;
  onclick: () => void;
  noArr: boolean;
}
function ArrowButtonSecondary(props: IProps) {
  const { isRight, text, onclick, noArr } = props;
  const [effected, setEffected] = useState<boolean>(false);
  return (
    <>
      <div
        onMouseEnter={() => setEffected(true)}
        onMouseLeave={() => setEffected(false)}
        className={` ${
          !isRight ? 'flex-row-reverse' : 'flex-row'
        } border border-white hover:border-white bg-transparent hover:bg-transparent hover:text-black transition-all flex items-center justify-between rounded`}
        onClick={() => {
          onclick && onclick();
        }}
      >
        <button
          className={`!text-[11px] !tracking-[0.56px] md:!tracking-[0.6px] !font-bold font-abcmonument  uppercase px-10 md:px-0 h-[40px] ${
            effected ? 'text-brand-clime' : 'text-white'
          }`}
        >
          {text}
        </button>
        {!noArr && (
          <span
            className={`${
              isRight
                ? `border-l ${
                    !effected ? 'border-l-white' : 'border-l-white'
                  }`
                : `border-r ${
                    !effected ? 'border-r-white' : 'border-r-white'
                  }`
            } px-3 flex  h-[40px] items-center justify-center`}
          >
            {!isRight ? (
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.49609 9L1.00408 4.82884L5.49609 0.65768"
                  stroke={effected ? 'black' : 'black'}
                  strokeWidth="1.2"
                />
                <path
                  d="M1.23682 5.02734L19.4023 5.02734"
                  stroke={effected ? 'black' : 'black'}
                  strokeWidth="1.2"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1055 1L18.5975 5.17116L14.1055 9.34232"
                  stroke={effected ? '#82FA00' : 'white'}
                  strokeWidth="1.2"
                />
                <path
                  d="M18.3647 4.97266H0.199219"
                  stroke={effected ? '#82FA00' : 'white'}
                  strokeWidth="1.2"
                />
              </svg>
            )}
          </span>
        )}
      </div>
    </>
  );
}

export default ArrowButtonSecondary;
