import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import Intro from "../components/lifestyle/Intro";
import Layout from "../components/layout/Layout";
import GetYourFreePass from "../components/home/GetYourFreePass";
import MemberStories from "../components/about/MemberStories";
import TwoLine from "../components/lifestyle/TwoLine";
import OneLine from "../components/lifestyle/OneLine";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import { navigate } from "gatsby";

export default function LifeStyle() {
  const { data } = useFetch({
    params: "life-style-page",
    populate: "videosCarousels ,videosCarousels.images",
  });
  const [dataVideo, setDataVideo] = useState([]);
  useEffect(() => {
    if (data != null) {
      const videoArray = data.data.attributes.videosCarousels.images.data.map(
        (i: any) => i.attributes.url
      );
      setDataVideo(videoArray);
    }
  }, [data]);
  return (
    <Layout>
      <div>
        <Intro />
        <div>
          {data != null && (
            <MemberStories
              videoData={dataVideo}
              topTitle={data.data.attributes.videoCarouselsTitle}
              titleLeft={data.data.attributes.videosCarousels.title}
              titleRight={data.data.attributes.videosCarousels.description}
            />
          )}
          <div className="lg:hidden flex  justify-center h-6 bg-[#212121]">
            <ButtonNavigate
            onClick={()=>navigate('/register')}
              text="BECOME A MEMBER"
              isArrowRight={true}
            ></ButtonNavigate>
          </div>
        </div>
        <TwoLine />
        <OneLine />
        <GetYourFreePass />
      </div>
    </Layout>
  );
}
