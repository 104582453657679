import React, { useContext } from 'react';
import { Link } from 'gatsby';
import InputBase from '../../../common/inputs/InputBase';
import Switch from '../../../../common/buttons/Switch';
import SlideSwitch from '../../../../common/SlideSwitch';
function Notification() {
  const [actives, setActives] = React.useState([
    true,
    true,
    true,
    true,
    true,
  ]);

  return (
      <div className="sm:mt-[30px] mt-[32px] sm:pb-6 border-[1.5px] border-[rgba(255,255,255,0.4)] rounded-md !sm:my-6 sm:hover:bg-[#1F2528] sm:border border-0 hover:bg-transparent margin-0 pb-20">
        {/* <Link to="/dashboard">
        <div className="font-abcmonument uppercase text-sm leading-[19px] text-white tracking-widest font-medium">
          <div className="w-[41px] h-[48px] p-[7px] pl-0">
            <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
              <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
            </div>
          </div>
          <span className="font-abcmonument uppercase text-sm leading-[19px] text-white tracking-widest">
            dashboard
          </span>
        </div>
      </Link> */}
        {/* Layout */}
        <div className="border-b border-white   px-6  py-[20px]  h-[57px]   border-b-white text-white font-bold  flex justify-between items-center uppercase">
          notification settings
        </div>

        <div className="mt-[32px] px-6 ">
          {/* <h6 className="text-white uppercase tracking-widest font-abcmonument text-sm font-medium">
          notification settings
        </h6> */}
          <div className="mt-[37px] !gap-[44px] flex flex-col sm:gap-y-0 gap-y-6">
            <div className="flex items-center sm:gap-[25px] gap-3">
              <div className="hidden sm:block">
                <Switch noDivider initial="on" />
              </div>
              <div className="block sm:hidden">
                <SlideSwitch
                    name="active_number_0"
                    active={actives[0]}
                    onActive={() => {
                      const newActives = [...actives];
                      newActives[0] = !actives[0];
                      setActives([...newActives]);
                    }}
                />
              </div>
              <span className="text-white font-abcmonument font-normal text-sm leading-[160%]">
              Show notifications for milestones
            </span>
            </div>
            <div className="flex items-center sm:gap-[25px] gap-3">
              <div className="hidden sm:block">
                <Switch noDivider initial="on" />
              </div>
              <div className="block sm:hidden">
                <SlideSwitch
                    name="active_number_1"
                    active={actives[1]}
                    onActive={() => {
                      const newActives = [...actives];
                      newActives[1] = !actives[1];
                      setActives([...newActives]);
                    }}
                />
              </div>
              <span className="text-white font-abcmonument font-normal text-sm leading-[160%]">
              Show notifications for promotional offers
            </span>
            </div>
            <div className="flex items-center sm:gap-[25px] gap-3">
              <div className="hidden sm:block">
                <Switch noDivider initial="on" />
              </div>
              <div className="block sm:hidden">
                <SlideSwitch
                    name="active_number_2"
                    active={actives[2]}
                    onActive={() => {
                      const newActives = [...actives];
                      newActives[2] = !actives[2];
                      setActives([...newActives]);
                    }}
                />
              </div>
              <span className="text-white font-abcmonument font-normal text-sm leading-[160%]">
              Show notifications for upcoming bookings
            </span>
            </div>
            <div className="flex items-center sm:gap-[25px] gap-3">
              <div className="hidden sm:block">
                <Switch noDivider initial="on" />
              </div>
              <div className="block sm:hidden">
                <SlideSwitch
                    name="active_number_3"
                    active={actives[3]}
                    onActive={() => {
                      const newActives = [...actives];
                      newActives[3] = !actives[3];
                      setActives([...newActives]);
                    }}
                />
              </div>
              <span className="text-white font-abcmonument font-normal text-sm leading-[160%]">
              Show notifications for club alerts
            </span>
            </div>
            <div className="flex items-center sm:gap-[25px] gap-3">
              <div className="hidden sm:block">
                <Switch noDivider initial="on" />
              </div>
              <div className="block sm:hidden">
                <SlideSwitch
                    name="active_number_4"
                    active={actives[4]}
                    onActive={() => {
                      const newActives = [...actives];
                      newActives[4] = !actives[4];
                      setActives([...newActives]);
                    }}
                />
              </div>
              <span className="text-white font-abcmonument font-normal text-sm leading-[160%]">
              Show notifications for class buddies bookings
            </span>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Notification;
