import React, { useEffect, useState } from 'react'
import Navigation from "./Navigation";
export const HambergerMenu = () => {
    const [toggle, setToggle] = useState<boolean>(false)
    useEffect(() => {
        toggle
            ? document.body.classList.add('hide-scroll')
            : document.body.classList.remove('hide-scroll')
    }, [toggle])
    return (
        <div className='w-full h-[70px] flex items-center' >
            <div onClick={() => setToggle(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="19" viewBox="0 0 32 19" fill="none">
                    <line x1="5.67067e-08" y1="0.702914" x2="32" y2="0.702917" stroke="white" strokeWidth="1.2973" />
                    <line x1="5.67067e-08" y1="9.35135" x2="32" y2="9.35135" stroke="white" strokeWidth="1.2973" />
                    <line x1="5.67067e-08" y1="9.35135" x2="32" y2="9.35135" stroke="white" strokeWidth="1.2973" />
                    <line x1="5.67067e-08" y1="18" x2="32" y2="18" stroke="white" strokeWidth="1.2973" />
                </svg>
            </div>
            <Navigation toggle={toggle} setToggle={setToggle} />
        </div>
    )
}
