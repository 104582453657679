import allClassPic1 from "../src/assets/images/classes/allClassPic1.jpg";
import allClassPic2 from "../src/assets/images/classes/allClassPic2.jpg";
import allClassPic3 from "../src/assets/images/classes/allClassPic3.jpg";
import allClassPic4 from "../src/assets/images/classes/allClassPic4.jpg";
import moment from "moment";

export const allClassTitle = "are you ready?";

export const allClassSlider = [
  {
    sweatRating: 1,
    img: allClassPic1,
    title: "republic Throw down",
    aboutClass:
      "The Throw Down is the ideal class for a calorie burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.",
  },
  {
    sweatRating: 1,
    img: allClassPic2,
    title: "republic amrap",
    aboutClass:
      "The AMRAP is the ideal class for a calorie burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.",
  },
  {
    sweatRating: 1,
    img: allClassPic3,
    title: "republic shred",
    aboutClass:
      "The SHRED is the ideal class for a calorie burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.",
  },
  {
    sweatRating: 1,
    img: allClassPic4,
    title: "republic peak",
    aboutClass:
      "The PEAK is the ideal class for a calorie burn. Sweat, challenge yourself and learn how to incorporate weights into your training routine.",
  },
];

export const dropdown = {
  location: {
    label: "location",
    value: [{ state: ["act", "nsw", "qld", "vic"] }],
  },
  class: {
    label: "Class",
    value: [
      {
        Classes: [
          "amrap",
          "heat",
          "hiit",
          "peak",
          "shred",
          "strength",
          "throwdown",
          "yoga",
        ],
      },
    ],
  },
  mood: {
    label: "Mood",
    value: [{ "all mood": ["any", "hard core", "soft"] }],
  },
  findStudio: {
    label: "Club",
    value: [
      { State: ["act", "nsw", "qld", "vic"] },
      {
        Studio: [
          "anu",
          " belconnen",
          "braddon",
          "campbell",
          "conder",
          "curtin",
        ],
      },
    ],
  },
  date: {
    label: `date`,
    value: [
      {
        Date: [
          `Today ${moment().format("DD/MM")}`,
          moment().add(1, "days").format("ddd DD/MM"),
          moment().add(2, "days").format("ddd DD/MM"),
          moment().add(3, "days").format("ddd DD/MM"),
          moment().add(4, "days").format("ddd DD/MM"),
          moment().add(5, "days").format("ddd DD/MM"),
          moment().add(6, "days").format("ddd DD/MM"),
        ],
      },
    ],
  },
};
