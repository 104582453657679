import { gql } from '@apollo/client';

export const getMember = gql`
  query getMember($memberId: ID!) {
    getMember(memberId: $memberId) {
      memberId
      aliasMemberId
      email
      secondaryEmail
      title
      givenName
      middleName
      surname
      gender
      dob
      mobileNumber
      workPhone
      homePhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactNumber
      emergencyContactRelation
      emergencyContactName2
      emergencyContactEmail2
      emergencyContactNumber2
      emergencyContactRelation2
      imageUrl
      address
      suburb
      state
      postCode
      country
      joinDate
      getLinksSms
      accepted
      brandId
      type
      role
      staffKind
      isBlocked
      homeLocationId
      brandDetails {
        id
        name
        __typename
      }
      homeLocationDetails {
        id
        name
        __typename
      }
      contracts {
        items {
          id
          description
          membershipName
          startDateTime
          isActive
          locationId
          membershipId
          costPriceFromMembership
          membershipLocationId
          paymentFrequency {
            type
            amount
            __typename
          }
          # joinedLocationDetail {
          #   name
          #   shortName
          #   __typename
          # }
          accessGroup {
            items {
              name
              accessGroupAccessPoints {
                items {
                  locationId
                }
              }
            }
          }
          billings {
            items {
              isProcessed
              debitDate
              debitAmount
              currency
              id
              membershipAmount
              description
              locationId
              memberContractId
              memberId
              membershipId
              membershipLocationId
              payLocationId
              paymentType
              # directDebitLog {
              #   items {
              #     id
              #     billingId
              #     updatedBy
              #     description
              #     debitAmountNew
              #     debitAmountOriginal
              #     reason
              #     createdAt
              #     updaterDetails {
              #       memberId
              #       createdAt
              #       givenName
              #       surname
              #       __typename
              #     }
              #     __typename
              #   }
              #   __typename
              # }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      # paymentDetails {
      #   items {
      #     accountNumber
      #     accountName
      #     bsb
      #     cardCvv
      #     cardExpiryDate
      #     cardHolderName
      #     cardNumber
      #     cardType
      #     id
      #     paymentType
      #     __typename
      #   }
      #   __typename
      # }
      __typename
    }
  }
`;

export const listClassSchedules = gql`
  query listClassSchedules {
    listClassSchedules {
      items {
        id
        brandId
        bookingMessage
        classScheduleDateTime
        classScheduleEndDateTime
        instructorDetails {
          instructorDisplayName
          givenName
        }
        isActive
        locationId
        maxBooking
        maxPerTeam
        note
        studioDetails {
          studioName
        }
        classTemplateDetails {
          className
          hasVirtualClass
          # isBookable
          classDescription

          isActive
        }
      }
    }
    # listClassSchedules {
    #   items {
    #     classScheduleDateTime
    #     classScheduleEndDateTime
    #     brandId
    #     instructorDetails {
    #       givenName
    #       surname
    #       instructorDisplayName
    #     }
    #     maxBooking
    #     studioDetails {
    #       studioName
    #       locationId
    #     }
    #         isActive
    #     locationId
    #     maxBooking
    #     maxPerTeam
    #     studioId
    #     locationId
    #     classTemplateDetails {
    #       className
    #       hasVirtualClass
    #       isBookable
    #       classDescription

    #       isActive
    #     }
    #   }
    # }
  }
`;

export const getClassScheduleByLocationId = gql`
  query getClassScheduleByLocationId(
    $locationId: ID!
    $classScheduleDateTime: String
  ) {
    getClassScheduleByLocationId(
      locationId: $locationId
      classScheduleDateTime: { le: $classScheduleDateTime }
    ) {
      items {
        brandId
        classScheduleDateTime
        maxBooking
        id
        locationId
        bookingMessage
        classScheduleEndDateTime
        classTemplateId
        isActive
        isBookable
        classTemplateDetails {
          id
          className
          brandId
          classDuration
          classDescription
        }
        bookingCount
        canWaitlist
        waitlistCount
        instructorDetails {
          id
          instructorFullName
          instructorDisplayName
        }
        studioDetails {
          studioName
        }
      }
    }
  }
`;

export const listMemberships = gql`
  query {
    listMemberships {
      items {
        id
        brandId
        membershipName
        description
        costPrice
        isActive
        description
        joiningFee
        joiningFee2
        joiningFee3
        isDeleted
        joiningFee
        paymentFrequency {
          type
          amount
        }
        activationDates {
          items {
            createdAt
            endDateTime
            id
            isActive
            membershipId
            startDateTime
            updatedAt
          }
        }
        membershipLocations {
          items {
            id
            locationId
            membershipId
            createdAt
          }
        }
        brandDetails {
          id
          locations {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const listMembershipId = gql`
  query getMembershipByBrandId {
    getMembershipByBrandId(brandId: "0abcb677-156c-4a67-b650-62c2a0ae3bd0") {
      items {
        id
        brandId
        membershipName
        description
        costPrice
        isActive
        description
        joiningFee
        joiningFee2
        joiningFee3
        isDeleted
        joiningFee
        paymentFrequency {
          type
          amount
        }
        activationDates {
          items {
            id
            isActive
            membershipId
          }
        }
        membershipLocations {
          items {
            id
            locationId
            membershipId
            createdAt
          }
        }
        brandDetails {
          id
          locations {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const getMembershipById = gql`
  query getMembership($id: ID!) {
    getMembership(id: $id) {
      id
      brandId
      membershipName
      description
      costPrice
      isActive
      description
      joiningFee
      joiningFee2
      joiningFee3
      isDeleted
      joiningFee
      paymentFrequency {
        type
        amount
      }
      activationDates {
        items {
          createdAt
          endDateTime
          id
          isActive 
          membershipId
          startDateTime
          updatedAt
        }
      }
      membershipLocations {
        items {
          id
          locationId
          membershipId
          createdAt
        }
      }
      brandDetails {
        id
        locations {
          items {
            id
            abn
          }
        }
      }
    }
  }
`;
// query {
//   listMemberships {
//     items {
//       brandDetails {
//         id
//         locations {
//           items {
//             id
//           }
//         }
//       }
//       id
//       brandId
//       membershipName
//       costPrice
//       isActive
//       description
//       joiningFee
//       joiningFee2
//       joiningFee3
//       isDeleted
//       joiningFee
//       paymentFrequency {
//         type
//       }
//     }
//   }
// }

export const getClassBookingByMemberId = gql`
  query getClassBookingsByMemberId($memberId: ID!) {
    getClassBookingsByMemberId(memberId: $memberId) {
      items {
        id
        bookedDateTime
        cancelledDateTime
        hasAttended
        waitlistClassScheduleId
        waitlistClassScheduleDetails {
          id
          classScheduleDateTime
          classScheduleEndDateTime
          instructorDetails {
            instructorDisplayName
            givenName
          }
          classTemplateDetails {
            id
            classDescription
            className
          }
          locationId
        }
        classScheduleDetails {
          id
          classScheduleDateTime
          classScheduleEndDateTime

          instructorDetails {
            instructorDisplayName
            givenName
          }
          bookings {
            items {
              classScheduleId
              confirmedDateTime
              hasAttended
              memberClassBookingList {
                items {
                  waitlistClassScheduleId
                }
              }
            }
          }
          classTemplateDetails {
            id
            classDescription
            className
          }
          locationId
        }
      }
    }
  }
`;

export const getListLocation = gql`
  query {
    listLocations {
      items {
        id
        clubUrl
        clubImage
        email
        name
        phoneNumber
        is24Hour
        address {
          address
          city
          suburb
          state
          postCode
        }
        brandId
        clubPageUrl
      }
    }
  }
`;

export const getBrandByName = gql`
  query {
    getBrandByName(name: "Club Lime") {
      nextToken
      items {
        id
      }
    }
  }
`;

export const getLocationByBrandId = gql`
  query getLocationByBrandId($brandId: ID!) {
    getLocationByBrandId(brandId: $brandId) {
      items {
        city
        clubImage
        clubPageUrl
        clubUrl
        country
        email
        geoLocation {
          latitude
          longitude
        }
        address {
          address
          city
          suburb
          state
          postCode
        }
        hasCreche
        id
        is24Hour
        locationAccessTimes {
          items {
            closeTime
            openTime
            day
            type
            phDate
          }
        }
        locationFeatures {
          items {
            featureId
            featureDetails {
              description
              imageUrl
              name
            }
          }
        }
        brandId
        name
        phoneNumber
        state
        status
        suburb
        summary
        walk3DURL
      }
    }
  }
`;

export const listLocationEmail = gql`
  query {
    listLocations {
      items {
        id
        email
        clubUrl
        clubImage
        name
        phoneNumber
        is24Hour
        address {
          address
          city
          suburb
          state
          postCode
        }
        brandId
        clubPageUrl
      }
    }
  }
`;

export const getMemberContractByMemberId = gql`
  query getMemberContractByMemberId($memberId: ID!) {
    getMemberContractByMemberId(memberId: $memberId) {
      items {
        id
        description
        membershipName
        startDateTime
        endDateTime
        isActive
        locationId
        membershipId
        costPriceFromMembership
        membershipLocationId
        expiryDateTime
        expiryDate
        paymentFrequency {
          type
          amount
        }
        autoRenew
        joinedLocationDetail {
          name
          shortName
          memberships {
            items {
              costPrice
              locationDetails {
                address {
                  address
                }
                name
              }
            }
          }
        }
        billings {
          items {
            isProcessed
            debitDate
            debitAmount
            currency
            id
            membershipAmount
            description
            locationId
            memberContractId
            memberId
            membershipId
            membershipLocationId
            payLocationId
            paymentType
          }
        }
        joiningFee3
        joiningFee2
        joiningFee
        accessGroup {
          items {
            name
            accessGroupAccessPoints {
              items {
                locationId
              }
            }
          }
        }
        suspensions {
          items {
            id
            cancelledDateTime
            suspensionEndDateTime
            suspensionStartDateTime
            suspensionType
            locationId
            memberContractId
          }
        }
      }
    }
  }
`;

export const fullMemberData = gql`
  query getMember($memberId: ID!) {
    getMember(memberId: $memberId) {
      memberId
      aliasMemberId
      email
      secondaryEmail
      title
      givenName
      middleName
      surname
      gender
      dob
      mobileNumber
      workPhone
      homePhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactNumber
      emergencyContactRelation
      emergencyContactName2
      emergencyContactEmail2
      emergencyContactNumber2
      emergencyContactRelation2
      imageUrl
      address
      suburb
      state
      postCode
      country
      joinDate
      getLinksSms
      accepted
      brandId
      type
      role
      staffKind
      isBlocked
      homeLocationId
      brandDetails {
        id
        name
        __typename
      }
      homeLocationDetails {
        id
        name
        __typename
      }
      contracts {
        items {
          id
          description
          membershipName
          startDateTime
          isActive
          locationId
          membershipId
          costPriceFromMembership
          membershipLocationId
          paymentFrequency {
            type
            amount
            __typename
          }
          # joinedLocationDetail {
          #   name
          #   shortName
          #   __typename
          # }
          billings {
            items {
              isProcessed
              debitDate
              debitAmount
              currency
              id
              membershipAmount
              description
              locationId
              memberContractId
              memberId
              membershipId
              membershipLocationId
              payLocationId
              paymentType
              # directDebitLog {
              #   items {
              #     id
              #     billingId
              #     updatedBy
              #     description
              #     debitAmountNew
              #     debitAmountOriginal
              #     reason
              #     createdAt
              #     updaterDetails {
              #       memberId
              #       createdAt
              #       givenName
              #       surname
              #       __typename
              #     }
              #     __typename
              #   }
              #   __typename
              # }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      # paymentDetails {
      #   items {
      #     accountNumber
      #     accountName
      #     bsb
      #     cardCvv
      #     cardExpiryDate
      #     cardHolderName
      #     cardNumber
      #     cardType
      #     id
      #     paymentType
      #     __typename
      #   }
      #   __typename
      # }
      __typename
    }
  }
`;

export const getClassBookingsByClassScheduleId = gql`
  query getClassBookingsByClassScheduleId($classScheduleId: ID!) {
    getClassBookingsByClassScheduleId(classScheduleId: $classScheduleId) {
      items {
        hasAttended
        brandId
        confirmedDateTime
        classTemplateId
        locationId
        memberId
      }
    }
  }
`;

export const updateMemberInfo = gql`
  mutation updateMemberInfo($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      dob
      gender
      givenName
      homeLocationId
      imageUrl
      joinLocationId
      memberId
      postCode
      state
      suburb
      surname
      title
      workPhone
      secondaryEmail
      middleName
      mobileNumber
      emergencyContactRelation2
      emergencyContactRelation
      emergencyContactNumber2
      emergencyContactNumber
      emergencyContactName2
      emergencyContactName
      emergencyContactEmail2
      emergencyContactEmail
      country
      address
    }
  }
`;

export const registerMutaion = gql`
  mutation SignupMember($input: SignupMemberViaWebInput!) {
    signupMemberViaWeb(input: $input) {
      memberId
      message
      error
    }
  }
`;

export const suspendMemberShip = gql`
  mutation suspendMembership(
    $memberContractId: ID!
    $suspendFrom: AWSDate!
    $suspendTo: AWSDate!
    $suspensionAmount: Float!
    $suspensionReason: String
    $imageUrl: String
    $suspensionType: SUSPENSION_TYPE!
  ) {
    suspendMembership(
      operation: CREATE_SUSPENSION
      memberContractId: $memberContractId
      suspendFrom: $suspendFrom
      suspendTo: $suspendTo
      suspensionAmount: $suspensionAmount
      suspensionReason: $suspensionReason
      imageUrl: $imageUrl
      suspensionType: $suspensionType
    ) {
      statusCode
    }
  }
`;

export const cancelMemberShip = gql`
  mutation cancelMemberShip(
    $memberId: ID!
    $memberContractId: ID!
    $endDate: AWSDate!
    $updatedBy: ID
  ) {
    cancelMembership(
      memberId: $memberId
      memberContractId: $memberContractId
      endDate: $endDate
      updatedBy: $updatedBy
    )
  }
`;

export const listBrands = gql`
  query listBrands {
    listBrands {
      items {
        id
        description
        name
      }
    }
  }
`;

export const bookAClass = gql`
  mutation bookClass($classScheduleId: ID!, $memberId: ID!) {
    bookClass(classSchedule: $classScheduleId, memberId: $memberId) {
      bookingId
      message
    }
  }
`;

export const cancelBooking = gql`
  mutation MyMutation($bookingId: ID!) {
    cancelBooking(bookingId: $bookingId)
  }
`;
export const revokeMembershipCancellation = gql`
  mutation revokeMembershipCancellation(
    $memberContractId: ID!
    $memberId: ID!
  ) {
    revokeMembershipCancellation(
      memberContractId: $memberContractId
      memberId: $memberId
    )
  }
`;

export const changeMembership = gql`
  mutation changeMembership(
    $memberId: ID!
    $membershipLocationId: ID!
    $newMembershipId: ID!
    $oldMemberContractId: ID!
  ) {
    changeMembership(
      memberId: $memberId
      membershipLocationId: $membershipLocationId
      newMembershipId: $newMembershipId
      oldMemberContractId: $oldMemberContractId
    ) {
      message
      newMemberContractId
    }
  }
`;
// export const getWaitlistClassBookingsByClassScheduleId = gql`
// query getWaitlistClassBookingsByClassScheduleId(waitlistClassScheduleId: "e32d8526-070b-4d12-97dc-89285dbf45a4") {
//   items {
//     cancelledDateTime
//     memberId
//   }
// }`;
export const revokeMembershipSuspension = gql`
  mutation revokeMembershipSuspension(
    $reason: String
    $suspensionEndDate: AWSDate!
    $suspensionId: ID!
  ) {
    revokeMembershipSuspension(
      reason: $reason
      suspensionEndDate: $suspensionEndDate
      suspensionId: $suspensionId
    ) {
      statusCode
      message
    }
  }
`;
export const changeSuspensionsDate = gql`
  mutation suspendMembership(
    $suspensionEndDate: AWSDate!
    $suspensionId: ID!
  ) {
    suspendMembership(
      suspensionEndDate: $suspensionEndDate
      operation: EDIT_SUSPENSION
      suspensionId: $suspensionId
      suspensionReason: "userSuspend"
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;
export const cancelSuspension = gql`
  mutation suspendMembership($suspensionId: ID!) {
    suspendMembership(
      suspensionId: $suspensionId
      operation: CANCEL_SUSPENSION
    ) {
      statusCode
    }
  }
`;

export const healthQuestionsByBrandId = gql`
  query getHealthQuestionnairesByBrandId {
    getHealthQuestionnairesByBrandId(brandId: "0abcb677-156c-4a67-b650-62c2a0ae3bd0", filter: {isDeleted: {eq: false}, isActive: {eq: true}}) {
    items {
      answerType
      brandId
      createdAt
      expectedAnswer
      id
      isActive
      isDeleted
      mandatory
      question
      sectionId
      sectionLabel
    }
  }
  }
`;

export const registerMutation = gql`
  mutation SignupMember($input: SignupMemberViaWebInput!) {
    signupMemberViaWeb(input: $input) {
      memberId
      message
      error
    }
  }
`;