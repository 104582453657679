import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Auth, Storage } from 'aws-amplify';
import { navigate } from 'gatsby';
import { AuthContext, AuthProvider } from '../apis/auth';
import { useApolloClient } from '@apollo/client';
import { getMember } from '../apis/mutation';


const PrivateRoute = ({ component: Component, location, ...rest }) => {
  useEffect(() => {
    if (!window.localStorage.getItem('userGatsby') && location.pathname !== '/access') {
      navigate('/access');
    }
    // if (!window.localStorage.getItem('userInfo') && location.pathname !== '/login') {
    //   navigate('/login');
    // }
  }, []);
  const setUserInfo = useContext(AuthContext).setUserInfo;
  useEffect(() => {
    // if (!getCookie(accessKey) && location.pathname !== accessRoute) {
    //   navigate(accessRoute);
    // }
    signIn();
  }, []);
  const getToken = async () => {
    try {
      Auth.currentAuthenticatedUser()
          .then((user) => {
            return Auth.changePassword(user, 'oldPassword', 'newPassword');
          })
          .then((data) => {})
          .catch((err) => console.log(err));
      const jwtToken =
          '(await Auth?.currentSession())?.getIdToken().getJwtToken()';
      return jwtToken;
    } catch (e) {
      return false;
    }
  };
  const client = useApolloClient();

  const signIn = async () => {
    const token = await getToken();
    if (!token) {
      navigate('/login');
    } else {
      Auth.currentAuthenticatedUser()
          .then((user) => {
            client
                .query({
                  query: getMember,
                  variables: {
                    memberId: user.attributes['custom:memberId'],
                  },
                })
                .then(async (res) => {
                  const data = res.data.getMember;
                  const imgUrl = data?.imageUrl
                      ? await Storage.get(data.imageUrl)
                      : '/icons/Dashboard/profile.svg';
                  setUserInfo({ ...data, imageUrl: imgUrl });
                  localStorage.setItem(
                      'currentUserDetail',
                      JSON.stringify(res.data.getMember)
                  );
                });
          })
          .catch((e) => {});
    }
  };
  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
