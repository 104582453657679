import React, { useContext, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fullRateIcon from "../../assets/images/icons/rating.svg";
import noneRatingIcon from "../../assets/images/icons/noneRatingIcon.svg";
import ButtonNavigate from "../../common/buttons/ButtonNavigate.tsx";
import InputBase from "../../common/inputs/InputBase";
import { navigate } from "gatsby";
import useFetch from "../../hooks/useFetch";
import { RegisterContext } from "../../context/RegisterContext";

function AllClassesIntro() {
  const { data } = useFetch({
    params: "classes-page",
    populate: "items, items.images , items.customMetadata",
  });

  const [animation, setAnimated] = useState(false);
  const [titleWidth, setTitleWidth] = useState(0);

  useEffect(() => {
    const divWidth = (document.getElementById("titleContainer") as any)
      .offsetWidth;
    const layoutWidth = (document.getElementById("Layout") as any).offsetWidth;
    const translateX = layoutWidth - divWidth;
    setTitleWidth(translateX);
  }, [animation]);

  const handleStyle = () => {
    return (
      <div
        style={{
          transform:
            animation && titleWidth
              ? `translate(${titleWidth - 6}px, 0px)`
              : "",
        }}
        id="titleContainer"
        className={`outline-text h-min -tracking-[0.03em] leading-none text-[300px] uppercase whitespace-nowrap transition-all duration-[1500ms] `}
        onMouseEnter={() => {
          setAnimated(true);
        }}
        onMouseLeave={() => setAnimated(false)}
      >
        {data?.data?.attributes?.title}
      </div>
    );
  };
  function calculateRating(value: number) {
    const sweat: any = [];
    const number = Math.floor(value);
    for (let i = 0; i < number; i++) {
      const data = <img className="mr-2" src={fullRateIcon} />;
      sweat.push(data);
    }
    if (sweat.length < 5) {
      for (let i = 0; i < 5 - number; i++) {
        const data = <img className="mr-2" src={noneRatingIcon} />;
        sweat.push(data);
      }
    }

    return sweat;
  }
  function classIntroSlider() {
    const [slider, setSlider] = useState<any>();

    const [state, setState] = useState({
      slideIndex: 0,
      updateCount: 0,
    });

    useEffect(() => {
      if (slider) {
        let slickListDiv = document.getElementsByClassName("slick-list")[0];
        slickListDiv.addEventListener("wheel", (event) => {
          event.preventDefault();
          event.deltaY > 0 ? slider.slickNext() : slider.slickPrev();
        });
      }
    }, [slider]);
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      slidesToShow: 1,
      arrows: false,
      vertical: true,
      fade: true,
      beforeChange: (current, next) => setState({ slideIndex: next }),
    };

    return (
      <div className="h-[582px] flex">
        {data?.data?.attributes?.items && (
          <Slider
            {...settings}
            ref={(c: any) => setSlider(c)}
            className="h-[582px] sliderClass"
          >
            {data?.data?.attributes?.items.map((i: any) => (
              <div className="h-full ">
                <div className="flex w-full h-full">
                  <div className="w-1/3 flex justify-center items-center h-[582px]">
                    <span
                      className="text-white w-[370px] text-6xl tracking-[0.03em] outline-text-1"
                      onClick={() => slider.slickNext()}
                    >
                      {i.title}
                    </span>
                  </div>
                  <div
                    className="w-1/3 h-[582px] px-[34px] py-[28px] flex justify-center items-center border-brand-orange border-2"
                    onClick={() => slider.slickNext()}
                  >
                    <img
                      src={
                        i.images?.data ? i.images?.data[0].attributes?.url : ""
                      }
                      className="object-cover h-full w-full"
                    />
                  </div>
                  <div className="w-1/3 h-[582px] flex justify-center items-center">
                    <div className="w-[288px] ">
                      <p
                        className="text-brand-orange mb-6 text-xl"
                        onClick={() => slider.slickNext()}
                      >
                        about the class
                      </p>
                      <p
                        className="text-white graphik-regular mb-6"
                        onClick={() => slider.slickNext()}
                      >
                        {i.description}
                      </p>
                      <div
                        className="text-white flex"
                        onClick={() => slider.slickNext()}
                      >
                        {calculateRating(i?.customMetadata?.rate * 1 ?? 0)}
                        <div className="ml-[6px]">sweat rating</div>
                      </div>

                      <ButtonNavigate
                        style=" h-[56px] w-[161px] mt-[53px] border-[#777777] text-[#777777] border-b"
                        text="book a class"
                        onClick={() => navigate("/book-a-class")}
                      ></ButtonNavigate>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}

        <div className="h-full">
          {Array(data?.data?.attributes?.items.length)
            .fill("")
            .map((idx, index) => (
              <div
                className={`w-[5px] ${state.slideIndex} ${
                  state.slideIndex == index
                    ? "bg-brand-orange"
                    : "bg-blackSecondary"
                }`}
                style={{
                  height: `${100 / data?.data?.attributes?.items.length}%`,
                }}
              ></div>
            ))}
        </div>
      </div>
    );
  }
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });

  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
    if (Object.values(control).every((i) => !i)) {
      navigate("/studio/2#keepme");
    }
  };
  const setOpen = useContext(RegisterContext).setOpen;
  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <>
      <div className="grouptest w-full group flex overflow-x-hidden overflow-y-hidden">
        {handleStyle()}
      </div>
      {classIntroSlider()}
      <div className="flex items-center mt-[50px] justify-between mx-[70px]">
        <p className="max-w-[584px] text-white font-normal leading-[160%] graphik-regular mr-[70px]">
          {data?.data?.attributes?.content}
        </p>
        <ButtonNavigate
          style="text-[#777777] border-[#777777] border-b h-[56px] w-[161px] mt-[53px]"
          text="full timetable"
          onClick={() => navigate("/book-a-class")}
        ></ButtonNavigate>
      </div>
      <div className="text-white px-[70px] flex justify-between mt-[75px] mb-[100px]">
        <div
          className="flex-col text-[50px] leading-[110%] w-[40%]  -tracking-[0.03em]"
          dangerouslySetInnerHTML={createMarkup(
            data?.data?.attributes?.inviteTitle
          )}
        >
          {/* <p className="text-white font-bold ">not a member?</p>
          <p className="text-white font-bold ">
            get your
            <span className="text-brand-orange font-bold"> free pass.</span>
          </p> */}
        </div>
        <div className="flex-1">
          <h3 className="text-[24px] font-bold mb-[30px]">
            <span className="text-white">i want to visit</span>
            <span className="text-brand-orange mx-2">braddon, act </span>
            <span
              className="text-gray underline graphik-regular text-base"
              onClick={() => {
                setOpen(!open);
              }}
            >
              change
            </span>
          </h3>
          <div className="grid grid-rows-2 grid-flow-col gap-x-[41px] gap-y-[12px]">
            <InputBase
              width="373px"
              type="string"
              holder="First name*"
              isRequired={true}
              isShowRequired={formControlled.firstName}
              onchange={(e) => setForm({ ...form, firstName: e.target.value })}
            />
            <InputBase
              type="string"
              holder="Last name*"
              isRequired={true}
              isShowRequired={formControlled.lastName}
              onchange={(e) => setForm({ ...form, lastName: e.target.value })}
            />
            <InputBase
              type="email"
              holder="Email address*"
              errorText={errText.email}
              isRequired={true}
              isShowRequired={formControlled.email}
              onchange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <InputBase
              type="string"
              holder="Phone number*"
              isRequired={true}
              isShowRequired={formControlled.phoneNumber}
              onchange={(e) =>
                setForm({ ...form, phoneNumber: e.target.value })
              }
            />
          </div>
          <div className="flex mt-[28px] justify-between items-center">
            <p className="text-gray text-sm graphik-regular">Required*</p>
            <ButtonNavigate
              text="lets go"
              onClick={() => {
                submit();
              }}
              style={
                "h-[53px] w-[131px] border-b border-[#777777] text-[#777777]"
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllClassesIntro;
