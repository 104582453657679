import React, { ComponentPropsWithoutRef } from 'react';

interface IProps extends ComponentPropsWithoutRef<'button'> {
  children: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
}

const PrimaryOutlinedButton = (props: IProps) => {
  const { children, className = '', variant = 'primary', ...rest } = props;

  let myClass = '';
  if (variant === 'primary') {
    myClass = 'hover:bg-brand-clime hover:border-primary ';
  } else if (variant === 'secondary') {
    myClass = 'hover:bg-white hover:!text-primary ';
  }

  return (
      <button
          className={`bg-transparent hover:text-black py-2 px-8 min-h-[40px] text-white border border-solid border-white text-sm transition-all duration-300 ${myClass} ${className}`}
          {...rest}
      >
        {children}
      </button>
  );
};

export default PrimaryOutlinedButton;
