import React, { useEffect, useState } from "react";
import plus from "../../assets/images/memberships/plus.png";
function Checkbox(props: any) {
  const {
    onChecked,
    isPlus,
    style,
    isBorderBottomColor,
    isChecked,
    controlled,
  } = props;
  const [isAccepted, setIsAccepted] = useState(false);
  useEffect(() => {
    if (typeof onChecked == "function") {
      onChecked(isAccepted);
    }
  }, [isAccepted]);

  return (
    <button
      onClick={() => setIsAccepted(!isAccepted)}
      style={
        isChecked
          ? {
              borderBottom: `1px solid ${
                isBorderBottomColor ? isBorderBottomColor : "#82FA00"
              }`,
            }
          : !controlled && isAccepted
          ? {
              borderBottom: `1px solid ${
                isBorderBottomColor ? isBorderBottomColor : "#82FA00"
              }`,
            }
          : {
              borderBottom: `1px solid ${
                isBorderBottomColor
                  ? isPlus
                    ? isBorderBottomColor
                    : "#FF6900"
                  : "white"
              }`,
            }
      }
      className={
        `text-white font-bold ${
          isChecked ? "border-brand-clime" : isAccepted ? 'border-brand-clime' : "bg-transparent border-white"
        } h-[24px] w-[24px] border-t border-l bg-none border-r  rounded-md flex justify-center items-center ` +
        style
      }
    >
      {isChecked ? (
        <svg
          width="14"
          height="10"
          viewBox="0 0 14 10"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 4.69231L5.2 9L13 1"
            stroke={"#82FA00"}
            strokeWidth="1.02"
            fill="none"
          />
        </svg>
      ) : !controlled && isAccepted ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="11"
          viewBox="0 0 14 11"
          fill="none"
        >
          <path
            d="M1 4.69231L5.2 9L13 1"
            stroke="#82FA00"
            strokeWidth="1.5"
            fill="none"
          />
        </svg>
      ) : isPlus ? (
        <img src={plus} />
      ) : (
        ""
      )}
    </button>
  );
}

export default Checkbox;
