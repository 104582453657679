import React from 'react'
import Checkbox from '../../common/buttons/Checkbox'

const fakeLocations = [
  'Belconnen',
  'Braddon',
  'COndor',
  'Curtin',
  'Fyshwick',
  'Gordon',
  'Hughes'
]

const fakeClassTypes = [
  'barre',
  'core',
  'hiit',
  'body pump',
  'combat',
  'cycle',
  'rpm'
]

interface IFilterProps {
  open: boolean
  onClose: () => void
  selectedFilter: any
  onSelectFilter: (property: string, value: any) => void
}

const Filter = (props: IFilterProps) => {
  const { open, onClose, selectedFilter, onSelectFilter } = props

  const [subFilter, setSubFilter] = React.useState<{
    type: any
    open: boolean
    title: string
    list: Array<any>
  }>({
    type: '',
    open: false,
    title: '',
    list: []
  })

  const onChooseSubFilter = (type: string, title: string, list: Array<any>) => {
    setSubFilter({
      type,
      open: true,
      title,
      list
    })
  }

  const onBack = () => {
    setSubFilter({
      ...subFilter,
      open: false,
      list: []
    })
  }

  return (
    <React.Fragment>
      {/* Overlay */}
      {open && (
        <div
          onClick={() => {
            if (subFilter.open) {
              onBack()
            }
            onClose()
          }}
          className="fixed left-0 top-0 w-screen h-screen bg-graySecondary opacity-80 z-[90]"
        ></div>
      )}
      {/* MAIN FILTER */}
      <div
        className={`fixed  ${
          open ? ' bottom-0 ' : ' -bottom-full pointer-events-none '
        } ${
          subFilter.open ? ' -left-full pointer-events-none ' : ' left-0 '
        } left-0 z-[100] transition-all duration-300`}
      >
        <div className="bg-graySecondary w-screen h-[80vh] relative">
          <div className="flex items-center justify-between px-7 py-3 border-b border-brand-clime">
            <p className="text-white text-sm font-normal leading-7 uppercase">
              FILTER
            </p>
            <div onClick={onClose} className="w-6 h-6 block">
              <img
                className="w-full object-contain"
                src="/icons/close-brand-clime.png"
                alt="close"
              />
            </div>
          </div>
          <div className="overflow-y-auto h-[calc(80vh-106px-53px)]">
            <div className="flex items-center justify-between px-7 py-4 border-b border-white border-opacity-40">
              <p className="text-white text-sm">Location</p>
              <div
                onClick={() =>
                  onChooseSubFilter('locations', 'LOCATION', fakeLocations)
                }
                className="text-brand-clime text-sm font-medium opacity-80 flex items-center gap-x-3 uppercase"
              >
                {selectedFilter['locations']?.[0] || ''}{' '}
                {selectedFilter['locations'].length > 1 && '+'}
                <div className="w-[10.25px] h-4">
                  <img
                    className="w-full object-contain"
                    src="/icons/arrow-right-brand-clime.png"
                    alt="arrow-right"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between px-7 py-4 border-b border-white border-opacity-40">
              <p className="text-white text-sm">Class type</p>
              <div
                onClick={() =>
                  onChooseSubFilter('classTypes', 'CLASS TYPE', fakeClassTypes)
                }
                className="text-brand-clime text-sm font-medium opacity-80 flex items-center gap-x-3 uppercase"
              >
                {selectedFilter['classTypes']?.[0] || ''}{' '}
                {selectedFilter['classTypes'].length > 1 && '+'}
                <div className="w-[10.25px] h-4">
                  <img
                    className="w-full object-contain"
                    src="/icons/arrow-right-brand-clime.png"
                    alt="arrow-right"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between px-7 py-4 border-b border-white border-opacity-40">
              <p className="text-white text-sm">Time of day</p>
              <div className="text-sm text-white font-medium opacity-80 flex items-center gap-x-2">
                {['ALL', 'AM', 'PM'].map((e: string, index: number) => {
                  return (
                    <React.Fragment key={e}>
                      <span
                        onClick={() => onSelectFilter('timeOfDay', e)}
                        className={`${
                          e === selectedFilter.timeOfDay
                            ? ' text-brand-clime underline '
                            : ' text-white '
                        }  uppercase`}
                      >
                        {e}
                      </span>
                      {index !== ['ALL', 'AM', 'PM'].length - 1 ? '/' : ''}
                    </React.Fragment>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="bg-graySecondary px-7 pt-5 pb-10 absolute bottom-0 left-0 w-full">
            <div
              onClick={onClose}
              className="border border-white rounded-md p-2 text-white text-sm text-center leading-7 font-medium uppercase"
            >
              APPLY
            </div>
          </div>
        </div>
      </div>

      {/* SUB FILTER */}
      <div
        className={`fixed bottom-0 ${
          subFilter.open ? ' left-0 ' : ' left-full pointer-events-none '
        } left-0 z-[100] transition-all duration-300`}
      >
        <div className="bg-graySecondary w-screen h-[80vh] relative">
          <div className="flex items-center justify-between px-7 py-3 border-b border-brand-clime">
            <div onClick={onBack} className="w-6 h-6 block">
              <img
                className="w-full object-contain"
                src="/icons/arrow-back-brand-clime.png"
                alt="back"
              />
            </div>
            <p className="text-white text-sm font-normal leading-7 uppercase">
              {subFilter.title}
            </p>
            <div className="blank w-6 h-6 block"></div>
          </div>
          <div className="overflow-y-auto h-[calc(80vh-106px-53px)]">
            {subFilter.list.map((item, index: number) => {
              return (
                <div
                  key={index}
                  className="flex items-center justify-between px-7 py-4 border-b border-white border-opacity-40"
                >
                  <p className="text-white text-sm uppercase">{item}</p>
                  <Checkbox
                    isChecked={selectedFilter[subFilter.type].includes(item)}
                    onChecked={() => {
                      if (selectedFilter[subFilter.type].includes(item)) {
                        onSelectFilter(subFilter.type, [
                          ...selectedFilter[subFilter.type].filter(
                            (e: any) => e !== item
                          )
                        ])
                      } else {
                        onSelectFilter(subFilter.type, [
                          ...selectedFilter[subFilter.type],
                          item
                        ])
                      }
                    }}
                  />
                </div>
              )
            })}
          </div>
          <div className="bg-graySecondary px-7 pt-5 pb-10 absolute bottom-0 left-0 w-full">
            <div
              onClick={onBack}
              className="border border-white rounded-md p-2 text-white text-sm text-center leading-7 font-medium uppercase"
            >
              DONE
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Filter
