import React from 'react';
import './styles.css';

interface ISlideSwitch {
  active: boolean;
  onActive: () => void;
  name: string;
}

const SlideSwitch = (props: ISlideSwitch) => {
  const { active, onActive, name } = props;

  return (
    <div className="mySlideSwitch">
      <input
        onChange={onActive}
        checked={active}
        id={`mySlideSwitch__${name}`}
        type="checkbox"
      />
      <label htmlFor={`mySlideSwitch__${name}`}></label>
    </div>
  );
};

export default SlideSwitch;
