import React, { useEffect, useRef, useState } from "react";
import triangleClime from "../../assets/images/icons/triangleClime.svg";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { createMarkup } from "../../common/functions/setInnerHtml";

export default function Intro() {
  const { data: lifeStyleData, loading } = useFetch({
    params: "life-style-page",
    populate: "topBanner,topBanner.background,topBanner.mobileImage",
  });
  const videoRef = useRef();
  const [play, setIsPlay] = useState(false);
  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (play) videoRef.current.play();
      else videoRef.current.pause();
    }
  }, [play]);
  return (
    <>
      {!loading && lifeStyleData != null && (
        <>
          <div className="mb-[158px]">
            <div className="text-brand-clime flex justify-center mt-[73px] sm:mt-[133px] mx-[14px] md:mx-[43px] relative ">
              {lifeStyleData?.data?.attributes?.topBanner.background?.data?.attributes?.url.slice(
                -3
              ) == "mp4" ? (
                <>
                  <video
                    onEnded={() => setIsPlay(true)}
                    ref={videoRef}
                    onLoadedData={() => videoRef.current.pause()}
                    className="sm:h-[608px] w-full h-[292px] object-cover sm:block hidden"
                    onClick={() => {
                      setIsPlay(!play);
                    }}
                  >
                    <source
                      src={
                        lifeStyleData?.data?.attributes?.topBanner.background
                          ?.data?.attributes?.url ?? "It’s a lifestyle"
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                  {!play && (
                    <img
                      onClick={() => {
                        setIsPlay(!play);
                      }}
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 sm:block hidden"
                      src={triangleClime}
                    />
                  )}
                </>
              ) : (
                <img
                  className="sm:h-[608px] w-full h-[292px] object-cover sm:block hidden"
                  src={
                    lifeStyleData?.data?.attributes?.topBanner.background?.data
                      ?.attributes?.url ?? "It’s a lifestyle"
                  }
                />
              )}

              {lifeStyleData?.data?.attributes?.topBanner.mobileImage?.data?.attributes?.url.slice(
                -3
              ) == "mp4" ? (
                <>
                  <video
                    loop
                    muted
                    autoPlay
                    onEnded={() => setIsPlay(true)}
                    ref={videoRef}
                    onLoadedData={() => videoRef.current.pause()}
                    className="sm:h-[608px] w-full h-[292px] object-cover sm:hidden"
                    onClick={() => {
                      setIsPlay(!play);
                    }}
                  >
                    <source
                      src={
                        lifeStyleData?.data?.attributes?.topBanner.mobileImage
                          ?.data?.attributes?.url ?? "It’s a lifestyle"
                      }
                      type="video/mp4"
                    ></source>
                  </video>
                  {/* {!play && (
                    <img
                      onClick={() => {
                        setIsPlay(!play);
                      }}
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      src={triangleClime}
                    />
                  )} */}
                </>
              ) : (
                <img
                  className="sm:h-[608px] object-cover w-full h-[292px] sm:hidden"
                  src={
                    lifeStyleData?.data?.attributes?.topBanner.mobileImage?.data
                      ?.attributes?.url ?? "It’s a lifestyle"
                  }
                />
              )}

              {/* <video
                onEnded={() => setIsPlay(true)}
                ref={videoRef}
                onLoadedData={() => videoRef.current.pause()}
                className="sm:h-[608px] w-full h-[292px] object-cover"
                onClick={() => {
                  setIsPlay(!play);
                }}
              >
                <source
                  src={
                    lifeStyleData?.data?.attributes?.topBanner.background?.data
                      ?.attributes?.url ?? "It’s a lifestyle"
                  }
                  type="video/mp4"
                ></source>
              </video>
              {!play && (
                <img
                  onClick={() => {
                    setIsPlay(!play);
                  }}
                  className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                  src={triangleClime}
                />
              )} */}
              <p className="Gravity-border uppercase w-full text-[60px] text-center md:text-[106px] absolute bottom-full leading-[95px] translate-y-1/2 left-1/2 -translate-x-1/2 text-effect-clime text-transparent">
                {lifeStyleData?.data?.attributes?.topTitle ??
                  "It’s a lifestyle"}
              </p>

              <p className="absolute  top-full  -translate-y-1/2 Gravity w-full text-center text-[45px] lg:text-[100px] text-white leading-[90px]  left-1/2 -translate-x-1/2 " dangerouslySetInnerHTML={createMarkup(lifeStyleData?.data?.attributes?.topBanner?.title?.split(
                "<br/>"
              )[0] ?? "")}>
                {/* {} */}
              </p></div>
            <p className="Gravity text-[45px] lg:text-[100px] mt-[15px] md:mt-[46px] text-white leading-[90%] text-center" dangerouslySetInnerHTML={createMarkup(lifeStyleData?.data?.attributes?.topBanner?.title?.split(
                "<br/>"
              )[1] ?? "")}>
              {/* {} */}
            </p>
            <div className=" flex items-center flex-col mb-[70px]">
              <p className="md:w-[780px] w-full  text-white Mono text-ba text-center my-[34px] sm:px-0 px-[14px]">
                {lifeStyleData?.data?.attributes?.topBanner?.description ??
                  `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequat, vel
                illum dolore eu feugiat nulla facilisis at vero eros et accumsan
                et iusto odio dignissim qui blandit praesent luptatum zzril
                delenit augue duis dolore te feugait nulla facilisi.`}
              </p>{" "}
              {/* <ButtonNavigate
                text="KEEP READING"
                isArrowRight={true}
              ></ButtonNavigate> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
