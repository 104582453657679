import React, { useEffect, useState, useLayoutEffect } from "react";
import CheckedMark from "../../assets/images/icons/CheckedMark.svg";
import arrowRight from "../../assets/images/icons/arrowRight.svg";
import { Link, navigate } from "gatsby";
import { Map } from "../map/Map";
import DropDownIcon from "../../assets/images/icons/DropDownIcon.svg";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";

function listOfStudio(props) {
  const { isMap } = props;
  const viewClub = (index: number) => {
    !isMap &&
      props.setList(
        props.data.map((i, idx: number) =>
          isMap
            ? { ...i }
            : idx === index
              ? { ...i, isShow: true }
              : { ...i, isShow: false }
        )
      );
  };

  return (
    <>

      <div className="w-full  md:flex hidden">
        <div
          className={`${isMap
            ? "lg:w-[651px] w-full transition-transform h-[570px] overflow-y-scroll scrollbar-map"
            : "w-full transition-all"
            }`}
        >
          {props.data && props.data.length ? (
            props.data.map((i: any, index: number) => {
              return (
                <div
                  className={`${isMap && "hover:bg-[#1C1C1C] transition-all"
                    } 'text-white h-[280px] border-b-[1px] border-[rgba(255,255,255,0.4)]'+ ${i?.isShow ? "bg-[#1C1C1C]" : ""
                    }`}
                  key={index}
                  onMouseEnter={() => viewClub(index)}
                >
                  <div className="p-[22px] flex">
                    {i?.image?.slice(-3) == "mp4" ? (
                      <video
                        autoPlay
                        muted
                        src={i.image}
                        className="min-w-[311px] max-w-[311px] h-[235px] object-cover"
                      />
                    ) : (
                      <img
                        src={i.image}
                        className="min-w-[311px] max-w-[311px] h-[235px] object-cover"
                      />
                    )}

                    <div
                      className={`px-[26px] text-brand-clime flex flex-col ${isMap ? "min-w-full" : "w-1/4"
                        } `}
                    >
                      <div
                        className={`flex h-1/2 gap-[4px] ${isMap ? "justify-between w-full" : "flex-col"
                          }`}
                      >
                        <span className="text-brand-clime font-bold gotham uppercase">
                          {i.title}
                        </span>
                        <span className="text-white text-sm font-normal gotham">
                          {i.distance}
                        </span>
                      </div>
                      <div className="flex flex-col h-1/2 gap-[11px] justify-between">
                        <span className="text-white max-w-[218px] text-sm font-normal Mono">
                          {i.address}
                        </span>
                        <p className="text-brand-clime max-w-[218px] text-sm font-normal gotham">
                          {i.phoneNumber}
                        </p>
                      </div>
                    </div>
                    {!isMap && (
                      <div className="w-1/4 px-[26px] flex flex-col justify-end mb-[17px] gap-[23px]">
                        {i.access.map((i: any, index: number) => (
                          <div
                            key={index}
                            className="flex gap-[17px] items-center"
                          >
                            <div className="w-[30px] h-[30px] flex flex-none justify-center items-center border-[1px] border-brand-clime rounded-full">
                              <img className="m-auto" src={CheckedMark} />
                            </div>
                            <p className="text-white gotham text-[11.39px] tracking-widest">
                              {i}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="w-1/4 flex justify-between items-end flex-col">
                      {!isMap && (
                        <div className="flex flex-col justify-end">
                          <div className="text-end text-white gotham">
                            Memberships from
                          </div>
                          <span className="text-brand-clime font-medium text-base text-end gotham">
                            ${i.price}/week
                          </span>
                        </div>
                      )}

                      {!isMap && (
                        <>
                          {i.isShow ? (
                            <Link to={"/studio/" + i.id} className="">
                              <ButtonNavigate
                                text="VIEW CLUB"
                                isArrowRight={true}
                              ></ButtonNavigate>
                            </Link>
                          ) : (
                            <div className="w-[60px] h-[60px] flex justify-center items-center border-[1px] rounded-md border-white hover:border-b-[5px] hover:border-b-brand-clime cursor-pointer">
                              <div className="flex  justify-center items-center ">
                                <img className="" src={arrowRight} />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
        {isMap && (
          <div className="flex-1 lg:block hidden">
            <Map />
          </div>
        )}
      </div>
    </>
  );
}

const StudioList = (props) => {
  const { isMap } = props;
  return (
    <>
      <div className="mt-[42px] mx-[69px] md:block hidden">
        <div
          className={`h-[38px] border-b-brand-clime border-b-[1.5px] text-brand-clime font-bold text-xl ${isMap ? "max-w-[650px]" : "w-full"
            }  flex justify-between`}
        >
          <div
            className={`text-brand-clime uppercase font-bold text-xl ${isMap && "hidden"
              }`}
          >
            act
          </div>
          <div className="text-brand-clime font-bold text-xl">
            {props?.data?.length} {props?.data?.length > 1 ? 'Clubs' : 'Club'} {!isMap ? "" : " nearby"}
          </div>
        </div>
        <div className="flex flex-col">{listOfStudio(props)}</div>
      </div>
      <div className=" w-full md:hidden block">{listOfStudioMobile(props)}</div>
    </>
  );
};



function listOfStudioMobile(props) {
  const { isMap } = props;
  const [expand, setExpand] = useState<boolean>(false)
  const viewClub = (index: number) => {
    !isMap &&
      props.setList(
        props.data.map((i, idx: number) =>
          isMap
            ? { ...i }
            : idx === index
              ? { ...i, isShow: true }
              : { ...i, isShow: false }
        )
      );
  };
  const unViewClub = (index: number) => {
    !isMap &&
      props.setList(
        props.data.map((i, idx: number) =>
          isMap
            ? { ...i }
            : idx === index
              ? { ...i, isShow: false }
              : { ...i, isShow: false }
        )
      );
  };



  return (
    <div className=" w-full bg-[#292929]">
      <div className={`w-full h-[80px] bg-black fixed z-50 border-y border-y-brand-clime left-0 ${expand ? 'top-[70px]' : 'bottom-0'}`}
        onClick={() => setExpand(!expand)}>
        <div className="text-white w-full flex justify-center items-center">
          <div className="w-[55px] h-1 bg-brand-clime my-[16px] rounded"></div>
        </div>
        <div className="flex justify-center items-center">
          <div className="text-brand-clime font-bold text-xl">
            {props?.data?.length} {!isMap ? "clubs" : "clubs nearby"}
          </div>
        </div>
      </div>
      <div className={`scrollbar-map bg-black z-50 w-full fixed top-[150px] left-0  overflow-y-scroll h-full ${expand ? 'block' : 'hidden'}`}>
        <div className=" h-full">
          {props.data && props.data.length ? (
            props.data.map((i: any, index: number) => {
              return (
                <div
                  className={`${isMap && "hover:bg-[#1C1C1C] transition-all"
                    } 'text-white  border-b-[1px] border-[rgba(255,255,255,0.4)] border-b-[rgba(255,255,255,0.4)] border-b-[1px]'+ ${i?.isShow ? "]" : ""
                    }`}
                  key={index}
                >
                  <div className="pt-[25px] flex flex-col">
                    {i?.image?.slice(-3) == "mp4" ? (
                      <video
                        onClick={() => navigate(`/studio/${i.id}`)}
                        autoPlay
                        muted
                        src={i.image}
                        className="max-w-[350px] w-full  h-[235px] mx-auto object-cover"
                      />
                    ) : (
                      <img
                        onClick={() => navigate(`/studio/${i.id}`)}
                        src={i.image}
                        className="max-w-[350px] w-full  h-[235px] mx-auto object-cover"
                      />
                    )}

                    <div
                      className={`px-[26px] py-[20px] text-brand-clime flex flex-col gap-[14px] ${isMap ? "min-w-full" : ""
                        } `}
                    >
                      <Link to={`/studio/${i.id}`}>
                        <div
                          className={`flex  items-center justify-between ${isMap ? "justify-between w-full" : ""
                            }`}
                        >
                          <span className="text-brand-clime text-[18px] font-bold">
                            {i.title}
                          </span>
                          <span className="text-brand-clime font-medium text-[18px] text-end gotham">
                            ${i.price}/week
                          </span>
                        </div>
                      </Link>
                      <div className="flex justify-between items-center">
                        <span className=" text-white gotham text-[18px] ">
                          {i.distance}
                        </span>
                        <img
                          src={DropDownIcon}
                          onClick={() =>
                            i.isShow ? unViewClub(index) : viewClub(index)
                          }
                        />
                      </div>
                      {i.isShow && (
                        <div className="text-white flex ">
                          <div className="w-1/2">
                            <span className="text-white max-w-[218px] text-sm font-normal Mono">
                              {i.address}
                            </span>
                            <p className="text-brand-clime max-w-[218px] text-sm font-normal gotham">
                              {i.phoneNumber}
                            </p>
                          </div>
                          <div className="w-1/2 grid  grid-flow-row grid-cols-3 items-center">
                            {i.access.map((i: any, index: number) => (
                              <>
                                <div
                                  className="w-[18px] h-[18px] flex justify-center items-center border-[1px] border-brand-clime rounded-full"
                                  key={index}
                                >
                                  <img src={CheckedMark} />
                                </div>
                                <p className="text-white col-span-2 text-[11.39px] tracking-widest">
                                  {i}
                                </p>
                              </>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
export default StudioList;
