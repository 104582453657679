import ArrowButton from '../../common/buttons/ArrowButton';
import CheckboxActive from '../../common/buttons/CheckboxActive';
import InputBase from '../../common/inputs/InputBase';
import React, { useEffect, useState } from 'react';
import SelectOutline from '../../common/dropdowns/SelectOutline';
import { useLazyQuery } from '@apollo/client';
import { listMembershipId, listMemberships } from '../../apis/mutation';
import { toastError } from '../../common/toasts/toast';
import { breakpoints } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import questionIcon from '../../assets/images/icons/questionIcon.svg';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
}
const Payment: React.FC<SlideProps> = ({
                                         onNext,
                                         onPrev,
                                         info,
                                         setInfo,
                                         handleCallApi,
                                       }) => {
  const [bank, setBank] = useState<boolean>(false);
  const [isSameInfo, setIsSameInfo] = useState<boolean>(false);
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    accountName: '',
    accountNumber: '',
    bsb: '',
  });
  const [paymentInfoCard, setPaymentInfoCard] = useState({
    cardNumber: '',
    cardExpiryDate: '',
    cardType: '',
    cardCvv: '',
    cardHolderName: '',
  });
  const setDataAccount = (e) => {
    if (e.target.name === 'cardCvv') {
      paymentInfoCard.cardType == 'American express'
          ? (e.target.maxLength = '4')
          : (e.target.maxLength = '3');
    }
    if (e.target.name === 'cardExpiryDate') {
      if (e.target.value.length === 2) {
        e.target.maxLength = '5';
        e.target.value = e.target.value + '/';
      } else if (
          e.target.value.length === 3 &&
          e.target.value.charAt(2) === '/'
      )
        e.target.value = e.target.value.replace('/', '');
    }
    if (e.target.name === 'cardNumber') {
      let cardTypeDetect = '';
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(e.target.value.trim())) {
        cardTypeDetect = 'Visa';
      } else if (
          /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
              e.target.value.trim()
          )
      ) {
        cardTypeDetect = 'Mastercard';
      } else if (/^3[47][0-9]{13}$/.test(e.target.value.trim())) {
        cardTypeDetect = 'American express';
      }
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
        cardType: cardTypeDetect,
      });
    } else
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
      });
    // { ...setPaymentInfo, [e.target.name]: e.target.value };
  };
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
    // { ...setPaymentInfo, [e.target.name]: e.target.value };
  };
  const handleNext = async () => {
    // setLoading(true);

    try {
      if (isSameInfo && Object.values(paymentInfoCard).some((i) => i == ''))
        throw Error('Please fill all of the payment info');
      if (!isSameInfo && Object.values(paymentInfoBank).some((i) => i == ''))
        throw Error('Please fill all of the payment info');
      if (bank && Object.values(paymentInfoBank).some((i) => i == ''))
        throw Error('Please fill all of the payment info');
      if (paymentInfoCard.cardExpiryDate != '') {
        let d = new Date();
        let currentYear = d.getFullYear();
        let currentMonth = d.getMonth() + 1;
        // get parts of the expiration date
        let parts = paymentInfoCard.cardExpiryDate.split('/');
        let year = parseInt(parts[1], 10) + 2000;
        let month = parseInt(parts[0], 10);
        // compare the dates
        if (
            year < currentYear ||
            (year == currentYear && month < currentMonth)
        ) {
          throw Error('Expiry date is not valid');
          // result = false;
        }
      }
      if (!acceptPayment)
        throw Error('Please accept the on going payment first');
      const handleCallApiInput = {
        ...info,
        cardDetails: !bank ? { ...paymentInfoCard } : { ...paymentInfoBank },
        paymentType: !bank ? 'CREDIT_CARD' : 'BANK_ACCOUNT',
      };
      setInfo(handleCallApiInput);
      await handleCallApi(handleCallApiInput);
    } catch (e) {
      toastError(e.message);
    } finally {
    }
  };
  const [membershipData, setMembershipData] = useState();
  const [listMemberShipApi] = useLazyQuery(listMembershipId);
  useEffect(() => {
    listMemberShipApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        setMembershipData(membership.getMembershipByBrandId.items);
      }
    });
  }, []);
  const [membershipCurrent, setMembershipCurrent] = useState();
  useEffect(() => {
    if (info.membershipId) {
      setMembershipCurrent(
          membershipData?.filter((i) => i.id === info.membershipId)[0]
      );
    }
  }, [info]);
  useEffect(() => {
    if (membershipCurrent?.paymentFrequency.type.toLowerCase() !== 'weeks') {
      setIsSameInfo(true);
    } else setIsSameInfo(false);
  }, [membershipCurrent]);
  const [acceptPayment, setAcceptPayment] = useState(false);
  const width = useWindowSize()
  return (
      <div
          id="scroll-hidden"
          className="h-full md:mx-0 overflow-y-scroll sm:pb-[70px] pb-[150px]"
      >
        {/* <div className="pb-[15px] mt-[44px] md:flex-col flex-row md:item-center item-start flex justify-between border-b border-b-[#82FA00] sm:mt-8">
        <div className="flex items-center gap-2 md:mb-0 ">
          <h2 className="heading2 text-[#82FA00] uppercase bebas">
            today’s payment
          </h2>
        </div>
        <div className="sm:hidden flex items-center gap-3 justify-between h-[18px]">
          <h2 className="uppercase gotham text-white text-sm -tracking-tight">
            total due today{' '}
          </h2>
          <h2 className="text-white uppercase font-bold gotham text-[20px] leading-[18px] bebas tracking-wider">
            $
            {membershipCurrent?.costPrice +
              membershipCurrent?.joiningFee +
              membershipCurrent?.joiningFee2 +
              membershipCurrent?.joiningFee3}
          </h2>
        </div>
      </div> */}
        <div className="pb-[30px] sm:pb-[8px] mt-[60px] flex-col md:flex-row item-center md:item-start  flex  justify-between border-b border-b-[#82FA00]">
          <div className="flex gap-2 md:self-start  h-[29px] items-start">
            <h2 className="text-[25px] font-black !tracking-[0.3px] !leading-[90%] text-[#82FA00] text-start uppercase ">
              today’s payment
            </h2>

          </div>
          <div className="hidden sm:flex items-center mt-0 md:mt-3 gap-3">
            <div className="flex items-end gap-x-2 h-[18px]">
              <h2 className="text-white font-normal !leading-[160%] text-sm font-abcmonument uppercase ">
                Total due today{' '}
              </h2>
              <h2 className="text-white inline-block  text-[14px] font-bold font-abcmonument uppercase ">
                $
                {membershipCurrent?.costPrice +
                    membershipCurrent?.joiningFee +
                    membershipCurrent?.joiningFee2 +
                    membershipCurrent?.joiningFee3}
              </h2>
            </div>
          </div>
        </div>
        <div className="flex sm:mt-[29px] sm:hidden items-center gap-3 justify-between mt-7">
          <h2 className="uppercase text-white sm:font-normal  font-abcmonument text-sm sm:text-sm  font-bold">
            total due today{' '}
          </h2>
          <h2 className="text-white uppercase sm:font-normal font-abcmonument sm:text-sm font-bold text-sm sm:text-white">
          $
            {membershipCurrent?.costPrice +
                membershipCurrent?.joiningFee +
                membershipCurrent?.joiningFee2 +
                membershipCurrent?.joiningFee3}
        </h2>
        </div>
        <div className="mt-[28px] grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-[13px]">
          <InputBase
              onchange={(e) => setDataAccount(e)}
              nameInput="cardHolderName"
              holder="Name on the card*"
              style="lg:w-full font-abcmonument"
              isRequired={true}
          />
          <InputBase
              onchange={(e) => setDataAccount(e)}
              nameInput="cardNumber"
              holder="Card number*"
              style="lg:w-full font-abcmonument"
              isRequired={true}
          />
          {/* <InputBase
                    holder="Card type*"
                    style="lg:w-full"
                    isRequired={true}
                /> */}
          <SelectOutline
              onSelect={(v) =>
                  setPaymentInfoCard({ ...paymentInfoCard, cardType: v })
              }
              blockStyle='!mt-[8px]'
              name="cardType"
              value={paymentInfoCard.cardType ? paymentInfoCard.cardType : ''}
              selectedLabel
              revertColor
              titleStyle="text-[12px]"
              style=" h-[50px] w-full font-abcmonument lg:w-full text-[14px]"
              placeholder="Card type*"
              // placeholderColor="text-slate-400"
              data={['Visa', 'Mastercard', 'American express']}
          />
          <InputBase
              onchange={(e) => setDataAccount(e)}
              nameInput="cardExpiryDate"
              holder="Expiry date*"
              style="lg:w-full font-abcmonument"
              isRequired={true}
          />
          <InputBase
              onchange={(e) => setDataAccount(e)}
              nameInput="cardCvv"
              holder="CCV/CVV*"
              style="lg:w-full font-abcmonument"
              isRequired={true}
          />
        </div>
        {membershipCurrent?.paymentFrequency.type.toLowerCase() === 'weeks' && (
            <>
              <div className="pb-[8px] mt-[60px] flex-col md:flex-row item-center md:item-start  flex  justify-between border-b border-b-[#82FA00]">
                <div className="flex gap-2 self-start md:self-end h-[29px] items-start">
                  <h2 className="!text-[30px] font-abcgravity !font-black !leading-[90%] !tracking-[0.3px] text-[#82FA00] text-start uppercase ">
                    Ongoing payment
                  </h2>
                </div>
                <div className="hidden sm:flex items-center mt-0 md:mt-3 gap-3">
                  <div className="flex items-end gap-x-2 h-[18px]">
                    <h2 className="text-brand-clime font-normal  font-abcmonument text-sm">
                      Direct debit{' '}
                      {membershipCurrent?.paymentFrequency.type.toLowerCase() ==
                          'weeks' && '(fortnightly) '}
                    </h2>
                    <h2 className="text-brand-clime inline-block  text-[14px] font-bold font-abcmonument uppercase ">
                      $
                      {membershipCurrent?.paymentFrequency.type.toLowerCase() !==
                      'weeks'
                          ? membershipCurrent?.costPrice
                          : membershipCurrent?.costPrice * 2}
                    </h2>
                  </div>
                </div>
              </div>
              <div className="flex sm:hidden items-center gap-3 mt-[29px] justify-between">
                <h2 className="uppercase font-abcmonument text-brand-clime text-sm sm:text-base sm:font-normal -tracking-tight">
                  Direct debit{' '}
                  {membershipCurrent?.paymentFrequency.type.toLowerCase() ==
                      'weeks' && '(fortnightly) '}
                </h2>
                <h2 className="text-brand-clime uppercase font-medium font-abcmonument sm:text-sm tracking-wider text-sm">
              $
                  {membershipCurrent?.paymentFrequency.type.toLowerCase() !==
                  'weeks'
                      ? membershipCurrent?.costPrice
                      : membershipCurrent?.costPrice * 2}
            </h2>
              </div>
              <div className="mt-[21px] flex items-start flex-col justify-between">
                <h6 className="font-abcmonument text-sm text-textSecondary">
                  Select your payment method:
                </h6>
                <div className="flex flex-col md:flex-row items-start md:items-center sm:mt-5 mt-[14px] gap-[32px] sm:gap-4 justify-between w-full">
                  <div className="flex items-center w-full md:w-auto gap-3 md:gap-0">
                    <div
                        onClick={() => {
                          !isSameInfo && setBank(true);
                        }}
                        className={`${
                            bank
                                ? 'bg-[#82FA00] !text-black sm:border-[#82FA00] '
                                : 'bg-transparent border-[#FFFFFF]'
                        } text-base sm:font-bold font-abcmonument !tracking-[0.6px] rounded md:rounded-r-none rounded-r cursor-pointer flex items-center justify-center sm:text-sm border w-[50%] md:w-[148px] h-[40px]  text-white uppercase`}
                    >
                      bank Account
                    </div>
                    <div
                        onClick={() => !isSameInfo && setBank(false)}
                        className={`${
                            !bank
                                ? 'bg-[#82FA00] !text-black sm:border-[#82FA00] '
                                : 'bg-transparent border-white'
                        } text-base sm:font-bold font-abcmonument !tracking-[0.6px] rounded md:rounded-l-none rounded-l cursor-pointer flex items-center justify-center border sm:text-sm bg-[#82FA00] w-[50%] md:w-[148px] h-[40px] text-white uppercase`}
                    >
                      {width && width < breakpoints.sm ? 'credit card' : 'card'}
                    </div>
                  </div>
                  {!bank && (
                      <div className="flex items-center gap-2">
                        <div
                            onClick={() => {
                              setIsSameInfo(!isSameInfo);
                              setBank(false);
                            }}
                        >
                          <CheckboxActive isChecked={isSameInfo} />
                        </div>
                        <p className="font-abcmonument text-base sm:font-normal sm:text-sm text-white ">
                          Same card as above
                        </p>
                      </div>
                  )}
                </div>
              </div>
              {/* Pay with Bank */}
              {bank && !isSameInfo && (
                  <div className="mt-[33px] grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-[13px]">
                    <InputBase
                        holder="Account name"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                        onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                        holder="Bank name"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                        onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                        holder="BSB*"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                        onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                        holder="Account number"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                        onchange={(e) => setDataBank(e)}
                    />
                  </div>
              )}
              {/* Pay with card */}
              {!isSameInfo && !bank && (
                  <div className="mt-[33px] grid grid-cols-1 md:grid-cols-2 gap-x-9 gap-y-[13px]">
                    <InputBase
                        holder="Name on the card*"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                    />
                    <InputBase
                        holder="Card number*"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                    />
                    <InputBase
                        holder="Expiry date*"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                    />
                    <InputBase
                        holder="CCV/CVV*"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                    />
                  </div>
              )}
            </>
        )}
        {/* Terms */}
        <div className="mt-[53px] flex items-start gap-4">
          <div className="w-[50px]">
            <CheckboxActive onChecked={setAcceptPayment} />
          </div>
          <p className="text-textSecondary text-left font-abcmonument text-sm">
            You have chosen to pay by direct debit. This agreement outlines the
            terms and conditions applicable to Your direct debit request. You
            agree to these terms and conditions unless You notify us otherwise
            before the first debit day. Definitions Account means the account held
            at Your financial institution from which We are authorised to arrange
            funds to be debited. Agreement means this Direct Debit Request Service
            Agreement between us and You, including the direct debit request.
            Business Day and Banking Day means a day other than a Saturday or a
            Sunday or a listed public holiday.
          </p>
        </div>
        {/* Button to actions */}
        <div className="mt-[60px] w-full flex items-center justify-between">
          <div onClick={() => onPrev()}>
            <div className="hidden md:flex">
              <ArrowButton text="back" isRight={false} />
            </div>
            <div className="flex md:hidden">
              <ArrowButton text="" isRight={false} />
            </div>
          </div>
          <div
              onClick={async () => {
                await handleNext();
              }}
          >
            <div className="hidden md:flex">
              <ArrowButton text="next" isRight={true} />
            </div>
            <div className="flex md:hidden">
              <ArrowButton text="pay now" isRight={true} />
            </div>
          </div>
        </div>
      </div>
  );
};

export default Payment;
