import ButtonActive from "../../common/buttons/Active";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import { RegisterContext } from "../../context/RegisterContext";
import ButtonSelected from "../../common/buttons/ButtonSelected";
import { Link } from "gatsby";
import { useLazyQuery } from "@apollo/client";
import { listMemberships } from "../../apis/mutation";
import { createMarkup } from "../../common/functions/setInnerHtml";

function PricesForBraddon(props) {
  const { isLabel, color, size, plan, setPlan } = props;
  const [expand, setExpand] = useState<boolean>(false);
  const [selected, setSelected] = useState<number>();
  const [currentPlan, setCurrentPlan] = useState();
  const { loading, data } = useFetch({
    params: "membership-plans",
    populate: "features",
  });
  const [price, setPrice] = useState();

  const order = ["single", "state", "national"];
  const orderFeatures = [
    "100 GYMS AUSTRALIA WIDE",
    "NO LOCK-IN CONTRACTS",
    "24/7 ACCESS",
    "STATE OF THE ART EQUIPMENT",
  ];
  const sortData = ({ data }) => {
    let type = {};
    if (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].attributes.type.toLowerCase() !== "student") {
          if (type[data[i].attributes.type]) {
            type[data[i].attributes.type].push(data[i]);
          } else {
            type[data[i].attributes.type] = [data[i]];
          }
        }
      }
      setPrice(type);
      setCurrentPlan(Object.keys(type)[0]);
    }
  };

  // useEffect(() => {
  //   if (data) sortData(data);
  // }, [data]);

  const setOpen = useContext(RegisterContext).setOpen;
  const [isChecked, setChecked] = useState(false);
  const position = ["weekly", "annual", "student"];
  const [listMemberShipApi] = useLazyQuery(listMemberships);
  useEffect(() => {
    listMemberShipApi().then((membership) => {
      membership = membership.data.listMemberships;
      if (membership) {
        sortDataGraphMembership(membership);
      }
    });
  }, []);

  const sortDataGraphMembership = ({ items }) => {
    let type = { weekly: [], annual: [], student: [] };
    let upgradeData = { weekly: [], annual: [], student: [] };

    const upgrade = ["CL ", "HR ", "GU ", "LO ", " + ", "RB "];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const result = upgrade.some((substr) =>
          items[i].description.toLowerCase().includes(substr.toLowerCase())
        );
        if (!result && items[i].isActive)
          if (
            items[i].paymentFrequency.type === "WEEKS" &&
            !items[i].description.toLowerCase().includes("student")
          )
            type.weekly.push(items[i]);
          else if (
            (items[i].paymentFrequency.type === "YEARS" ||
              items[i].paymentFrequency.type === "MONTHS") &&
            !items[i].description.toLowerCase().includes("student")
          )
            type.annual.push(items[i]);
          else type.student.push(items[i]);
      }
      setPrice(type);
      setCurrentPlan("weekly");
    };
  }
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && price && (
        <div className={`  w-[${size}] relative `}>
          {isLabel !== false && (
            <p className="uppercase memberText absolute  text-effect text-transparent text-[65px] Gravity-border top-1/2 -translate-y-1/2 -left-[98px] -rotate-90">
              BUILD a membership
            </p>
          )}
          <p className="uppercase memberTextMobile hidden text-center max-w-[380px] mx-auto leading-[54px] text-effect text-black text-[60px] Gravity-border ">
            BUILD a membership
          </p>
          <div className="mt-[40px] md:mt-[80px] overflow-hidden px-[24px] md:w-auto lg:w-[983px] w-full mx-auto">
            <div className="flex items-center flex-col md:flex-row justify-between">
              <div className="flex text-[16px] sm:text-2xl">
                <p className="text-white text-[16px] Mono font-medium uppercase tracking-wide">
                  membership prices for
                  <span className="text-brand-clime tracking-widest text-[16px] font-medium uppercase mx-2">
                    {props?.data?.name}
                  </span>
                  <span
                    className="text-[#A4A4A4] gotham lowercase text-base"
                    onClick={() => {
                      setOpen(!open);
                    }}
                  >
                    Change
                  </span>
                </p>
              </div>
              <div className="text-white flex mt-[18px] md:w-auto w-full md:mt-0 items-center">
                {Object.keys(price)
                  .sort(
                    (a, b) =>
                      position.indexOf(a.toLowerCase()) -
                      position.indexOf(b.toLowerCase())
                  )
                  .map((item: string, index: number) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPlan(item)}
                      className={`text-white font-bold Mono text-[11px] uppercase md:w-[113px] w-[33.33%] border-[0.8px] h-[40px] 
                    ${index === 0 ? "rounded-tl-[4px] rounded-bl-[4px]" : ""}
                    ${index === 1 ? "" : ""}
                    ${index === 2 ? "rounded-tr-[4px] rounded-br-[4px]" : ""}

                     ${currentPlan == item
                          ? "bg-brand-clime !text-black border-brand-clime"
                          : "bg-[black] border-white"
                        }`}
                    >
                      {item}
                    </button>
                  ))}
              </div>
            </div>
            {/* Plans */}
            <div className={`mt-[32px] bg-[${color}] rounded-[5px] `}>
              <div className="grid grid-flow-row md:grid-cols-2 lg:grid-cols-3 gap-0">
                {
                  // data.data
                  //   .filter(
                  //     (item: object) => item.attributes.type === currentPlan
                  //   )
                  //   .sort(
                  //     (a, b) =>
                  //       order.indexOf(a.attributes.title.toLowerCase()) -
                  //       order.indexOf(b.attributes.title.toLowerCase())
                  //   )
                  //   .
                  price?.[currentPlan]?.map((item: object, index: number) => {
                    return (
                      <div
                        key={index}
                        className={`px-[16px] flex flex-col md:px-10 md:pt-[43px] pt-[18px] pb-[21px] border-gray md:pb-[36px] border-[0.5px] ${index == 0
                          ? "rounded-tl-[5px] rounded-tr-[5px] sm:rounded-tr-none sm:rounded-bl-[5px] "
                          : ""
                          } ${index == 2
                            ? " sm:rounded-tr-[5px]  sm:rounded-br-[5px] rounded-bl-[5px] sm:rounded-bl-none"
                            : ""
                          }`}
                      >
                        <div className="flex items-center justify-between md:justify-between">
                          <div className="flex items-center justify-between w-full">
                            <h2 className="text-[16px] leading-[140%] uppercase gotham font-medium tracking-widest text-white mr-4 md:mr-0">
                              {item.membershipName}
                            </h2>
                            {/* {item.attributes.title?.toLowerCase() ==
                              "national" && (
                                <span className="h-[22px] w-[54px] sm:w-[76px] gotham border  border-brand-clime leading-[0.1em] font-medium bg-brand-clime text-[7.1px] sm:text-[10px] rounded-[100px] uppercase flex text-black justify-center items-center">
                                  popular
                                </span>
                              )} */}
                          </div>
                          <div
                            onClick={() => {
                              setExpand(!expand);
                              setSelected(index);
                            }}
                            className={`text-white flex md:hidden ${selected == index && expand
                              ? "rotate-180 transition-all"
                              : "rotate-0 transition-transform"
                              } float-right  justify-end`}
                          >
                            <svg
                              width="17"
                              height="17"
                              viewBox="0 0 17 10"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 1L8.25114 8.25114L15.5023 1"
                                stroke={
                                  selected === index && expand
                                    ? "#82FA00"
                                    : "gray"
                                }
                                strokeWidth="2.5"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="mt-[18px] text-white  font-bold flex justify-between items-center">
                          <div className="flex items-center">
                            <p className="text-[25px] leading-[90%]  font-normal md:text-[45px] bebas">
                              {(item.costPrice + "").split(".").length > 1
                                ? "$" + (item.costPrice + "").split(".")[0]
                                : "$" + item.costPrice}
                            </p>
                            <span className="text-[16px] md:text-[28px] ml-1 mt-2 bebas">
                              {(item.costPrice + "").split(".").length > 1 && "."}
                              {(item.costPrice + "").split(".")[1]}/{" "}
                              {item.paymentFrequency.type}
                            </span>
                          </div>
                          <div className="block md:hidden ">
                            <a href="https://members.hiitrepublic.com.au/join-now">
                              <ButtonActive
                                height={'auto'}
                                width={"100px"}
                                text="CHOOSE PLAN"
                              />
                            </a>
                          </div>
                        </div>

                        <div
                          className={`w-full h-[1px] mt-6  md:mb-[22px] bg-zinc-700 mb-[25px] ${expand && selected == index ? "" : "md:block hidden"
                            }`}
                        ></div>
                        {/* <div
                          className={`flex-col ${expand && selected === index
                            ? " h-auto opacity-100 transition-all"
                            : "transition-opacity md:h-auto h-0 overflow-hidden md:opacity-100 opacity-0"
                            }  flex gap-[14px] mb-[0px] md:mb-[37px] `}
                        >
                          {item.attributes.features.data
                            .sort((a, b) => {
                              const indexA = orderFeatures.indexOf(
                                a.attributes.title
                              );
                              const indexB = orderFeatures.indexOf(
                                b.attributes.title
                              );
                              if (
                                (indexA !== -1 && indexB !== -1) ||
                                (indexA === -1 && indexB === -1)
                              ) {
                                return indexA - indexB;
                              }
                              if (indexA === -1) {
                                return 1;
                              }
                              if (indexB === -1) {
                                return -1;
                              }
                            })
                            .map((item, index) => (
                              <div
                                className="flex items-start "
                                key={index}
                              >
                                <svg
                                  className="flex-none"
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="10.3918"
                                    cy="10.6437"
                                    r="9.39175"
                                    stroke="#82FA00"
                                  />
                                  <path
                                    d="M5.17188 10.6433L8.82422 14.2956L15.6072 7.5127"
                                    stroke="#82FA00"
                                  />
                                </svg>

                                <span className="ml-2 text-sm text-white  gotham uppercase">
                                  {item.attributes.title}
                                </span>
                              </div>
                            ))}
                          <span className="mt-[10px] md:hidden block gotham font-normal text-sm leading-[160%] text-gray">
                            *Subject to terms and conditions
                          </span>
                        </div> */}
                        <ul className={`flex-col ${expand && selected === index
                          ? " h-auto opacity-100 transition-all"
                          : "transition-opacity md:h-auto h-0 overflow-hidden md:opacity-100 opacity-0"
                          }  flex gap-[14px] mb-[0px] md:mb-[37px] `}>
                          {item.description.split(',').map(i => {
                            return <li className='flex items-center gap-[14px]'>
                              <span className='self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white'>
                                <svg
                                  className="flex-none"
                                  width="21"
                                  height="21"
                                  viewBox="0 0 21 21"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="10.3918"
                                    cy="10.6437"
                                    r="9.39175"
                                    stroke="#82FA00"
                                  />
                                  <path
                                    d="M5.17188 10.6433L8.82422 14.2956L15.6072 7.5127"
                                    stroke="#82FA00"
                                  />
                                </svg>
                              </span>
                              <li className='gotham text-start text-white text-sm' dangerouslySetInnerHTML={createMarkup(i.replace('\n', '<br/>'))}></li>
                            </li>
                          })}
                          {/* <li className='flex items-center gap-[14px]'>
                                <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                    <img className='w-auto h-auto' src={tick} alt="icon" />
                                </span>
                                <li className='gotham text-white text-sm'>24/7 access</li>
                            </li>
                            <li className='flex items-center gap-[14px]'>
                                <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                    <img className='w-auto h-auto' src={tick} alt="icon" />
                                </span>
                                <li className='gotham text-white text-sm'>No Lock-In Contract</li>
                            </li> */}
                        </ul>

                        {/* Select plan */}
                        <div className="hidden md:block  mt-auto">
                          {window.location.pathname !== "/register" ? (
                            <Link to="/register">
                              <ButtonSelected text="CHOOSE PLAN" />
                            </Link>
                          ) : (
                            <div onClick={() => setPlan(item)}>
                              <button
                                className={`w-full ${plan?.id == item?.id
                                  ? "bg-brand-clime text-black hover:text-black border-brand-clime"
                                  : "bg-transparent hover:text-brand-clime border-white text-white"
                                  } h-[40px] rounded-[4px] border-[0.8px] uppercase flex items-center justify-center font-medium transition-colors text-[11.2px] gotham hover:border-brand-clime`}
                              >
                                CHOOSE PLAN
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="md:flex-row flex-col flex justify-between md:items-center items-start md:mb-0 mb-[50px]">
              <div className="flex items-center gap-[15px]">
                <div
                  className={`w-[30px] my-[40px] rounded-[5px] h-[30px] border  flex justify-center items-center ${isChecked ? "border-brand-clime" : "border-white"
                    }`}
                  onClick={() => setChecked(!isChecked)}
                >
                  {isChecked && (
                    <svg
                      width="14"
                      height="10"
                      viewBox="0 0 14 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 4.69231L5.2 9L13 1"
                        stroke={"#82FA00"}
                        strokeWidth="1.02"
                      />
                    </svg>
                  )}
                </div>
                <p className="text-white Mono md:block hidden">
                  see junior rates* (for persons under 17 only)
                </p>
                <p className="gotham w-[266px] font-normal text-sm md:hidden block leading-[104%]">
                  See Junior rates <br /> (For persons under 17 only).{" "}
                </p>
              </div>
              <p className="text-[rgba(255,255,255,0.4)] md:block hidden md:mt-0 mt-[-20px] Mono">
                *Subject to terms and conditions
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PricesForBraddon;
