import React, { useState } from "react";
// import ButtonNavigate from "../common/buttons/ButtonNavigate";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
// import useFetchClubLime from "../../hooks/useFetchClubLime";
import useFetch from "../hooks/useFetch";
import Layout from "../components/layout/Layout";
import { navigate } from "gatsby";
import { createMarkup } from "../common/functions/setInnerHtml";
function ExploreClassType() {
  const col1 = [
    {
      title: "body pump",
      description:
        "Step into a BODYCOMBAT workout and you’ll punch and kick your way to fitness, burning up to 570 calories** along the way. This high-energy martial-arts inspired workout is totally non-contact and there are no complex moves to master.",
      subDescription: "Virtual classes available",
    },
    {
      title: "aqua",
      description:
        "Step into a BODYCOMBAT workout and you’ll punch and kick your way to fitness, burning up to 570 calories** along the way. This high-energy martial-arts inspired workout is totally non-contact and there are no complex moves to master.",
      subDescription: "Virtual classes available",
    },
    {
      title: "barre",
      description:
        "Step into a BODYCOMBAT workout and you’ll punch and kick your way to fitness, burning up to 570 calories** along the way. This high-energy martial-arts inspired workout is totally non-contact and there are no complex moves to master.",
      subDescription: "Virtual classes available",
    },
    {
      title: "barre(LES mills)",
      description:
        "Step into a BODYCOMBAT workout and you’ll punch and kick your way to fitness, burning up to 570 calories** along the way. This high-energy martial-arts inspired workout is totally non-contact and there are no complex moves to master.",
      subDescription: "Virtual classes available",
    },
  ];

  const col2 = [
    { title: "AB's BUTT's & THIGH's" },
    { title: "aqua" },
    { title: "barre" },
    { title: "barre(LES mills)" },
  ];
  const col3 = [
    { title: "AB's BUTT's & THIGH's" },
    { title: "aqua" },
    { title: "barre" },
    { title: "barre(LES mills)" },
  ];

  const [activeClass, setActiveClass] = useState(-1);
  const [activeQuestion, setActiveQuestion] = useState(-1);
  // const [activeClass3, setActiveClass3] = useState(-1);

  const { data, loading } = useFetch({
    params: "faq-page",
    populate:
      "collections,collections.title,collections.questions,collections.customMetaData, bottomBanner, bottomBanner.image",
  });

  return (
    <>
      {data != null && (
        <div className="text-white  px-0 md:px-[47px] mb-[80px] ">
          <p className="uppercase text-[18px] mx-6 tracking-[0.01em] font-abcmonument text-brand-clime xl:leading-[58px] my-[60px]">
            {data?.data?.attributes?.title
              ? data?.data?.attributes?.title
              : "Frequently Asked questions"}
          </p>
          <div className="grid grid-cols-1  grid-flow-row gap-x-[20px]">
            <div className="flex flex-col">
              {data?.data?.attributes?.collections?.map((item, index) => (
                <div
                  className={` py-[18.5px]  ${index == 0 ? "" : ""} ${
                    activeClass === index
                      ? "bg-[ #292929 ] "
                      : activeClass - 1 === index
                      ? "border-b-0"
                      : " md:border-white"
                  }`}
                >
                  <div
                    className={`relative uppercase flex justify-between text-lg font-abcmonument tracking-[0?.1em] font-medium ${
                      activeClass == index ? "text-brand-clime" : ""
                    }`}
                    onClick={() => {
                      setActiveClass(index);
                    }}
                  >
                    <p
                      className={`font-abcmonument w-full block pl-6 ${
                        activeClass == index
                          ? "text-brand-clime  hover:text-brand-clime  bebas text-[60px] whitespace-nowrap leading-[56px] truncate"
                          : "text-black hover:text-brand-clime  bebas text-[60px] text-effect bg-transparent whitespace-nowrap leading-[56px] truncate"
                      } `}
                    >
                      {item?.title}
                    </p>
                    <div className="h-[2px] w-[53px] bg-brand-clime absolute top-1/2 -translate-y-1/2 right-full"></div>
                  </div>

                  {activeClass == index &&
                    item?.questions?.map((i) => (
                      <div
                        className={`mx-6 ${
                          activeQuestion == JSON?.stringify(index) + i?.id
                            ? "bg-[#292929] p-[12px] border-y border-brand-clime"
                            : "border-y border-gray"
                        }`}
                      >
                        <div
                          className="flex justify-between items-center"
                          onClick={() =>
                            setActiveQuestion(JSON?.stringify(index) + i?.id)
                          }
                        >
                          <p
                            className={`${
                              activeQuestion == JSON?.stringify(index) + i?.id
                                ? "text-brand-clime font-abcmonument text-lg  font-abcmonument "
                                : "ml-[12px] py-[12px] font-abcmonument "
                            }`}
                          >
                            {i?.question}
                          </p>
                          <svg
                            width="27"
                            height="27"
                            viewBox="0 0 27 27"
                            className={`${
                              activeQuestion ===
                                JSON?.stringify(index) + i?.id &&
                              "rotate-45 text-brand-clime"
                            }`}
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.75 12.9655H6.55562"
                              stroke="currentColor"
                              strokeWidth="1.32496"
                            />

                            <path
                              d="M13.1523 19.7086L13.1523 6.59711"
                              stroke="currentColor"
                              strokeWidth="1.32496"
                            />
                          </svg>
                        </div>
                        {activeQuestion == JSON?.stringify(index) + i?.id && (
                          <div className="mt-[12px] font-abcmonument">
                            <p className="mb-[44px]">{i?.answer}</p>
                            {i?.customMetaData?.display && (
                              <ButtonNavigate
                                onClick={() => navigate(i?.customMetaData?.url)}
                                text={i?.customMetaData?.label}
                                isArrowRight={true}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="grid grid-cols-3 xl:grid-cols-6 border-y border-brand-clime ">
        <div className="col-span-3 xl:col-span-2 border-r border-brand-clime">
          <div className="lg:max-w-[343px] md:px-10 mx-auto my-[48px] flex flex-col gap-[40px]">
            <div
              dangerouslySetInnerHTML={createMarkup(
                data?.data?.attributes?.bottomBanner?.title
              )}
            ></div>

            <ButtonNavigate
              text="help me"
              isArrowRight={true}
              onClick={() =>
                navigate(data?.data?.attributes?.bottomBanner?.url)
              }
            />
          </div>
        </div>
        <div
          className="col-span-3 xl:col-span-4 bg-cover bg-left-bottom bg-no-repeat min-h-[460px] max-h-[460px] h-full"
          style={{
            backgroundImage: `url(${data?.data?.attributes?.bottomBanner?.image?.data?.attributes?.url})`,
          }}
        ></div>
      </div>
    </>
  );
}

export default function faq() {
  return (
    <Layout>
      <ExploreClassType />
    </Layout>
  );
}
