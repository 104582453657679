import React, { useState } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { navigate } from "gatsby";
export default function ExploreClassType() {
  const [activeClass, setActiveClass] = useState(-1);
  const [activeClass2, setActiveClass2] = useState(-1);
  const [activeClass3, setActiveClass3] = useState(-1);

  const [activeClassMouseIn, setActiveClassMouseIn] = useState(-1);
  const [activeClass2MouseIn, setActiveClass2MouseIn] = useState(-1);
  const [activeClass3MouseIn, setActiveClass3MouseIn] = useState(-1);

  const { data } = useFetch({
    params: "group-fitness-page",
    populate:
      "bottomClassType,bottomClassType.cols1,bottomClassType.cols2,bottomClassType.cols3",
  });

  return (
    <>
      {data != null && (
        <div className="text-white  px-0 md:px-[47px] mb-[80px] ">
          <p className="uppercase text-[45px] md:text-[65px] bebas leading-[58px] mb-[40px] mx-[14px] md:mx-0 Gravity">
            {data?.data?.attributes?.bottomClassType?.title ??
              "explore class type"}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-flow-row gap-x-[20px] mx-[14px] md:mx-0">
            <div className="flex flex-col">
              {data.data.attributes.bottomClassType.cols1.map((item, index) => (
                <div
                  className={`px-[27px] pt-[21px] pb-[17px] border-b-[1px]   ${
                    index == 0 ? "border-y" : ""
                  } ${
                    activeClass === index
                      ? "bg-[#292929] border-brand-clime border-y"
                      : activeClass - 1 === index
                      ? "border-b-0"
                      : "border-white"
                  }`}
                >
                  <div
                    className={`uppercase flex justify-between text-lg gotham tracking-[0.1em] font-medium ${
                      activeClass == index ? "text-brand-clime" : ""
                    }
                    ${activeClassMouseIn == index ? "text-brand-clime" : ""}
                    `}
                    onMouseEnter={() => setActiveClassMouseIn(index)}
                    onMouseLeave={() => setActiveClassMouseIn(-1)}
                    onClick={() => {
                      if (activeClass != index) {
                        setActiveClass(index);
                        setActiveClass2(-1);
                        setActiveClass3(-1);
                      }
                    }}
                  >
                    <p
                      className={`gotham w-full ${
                        activeClass == index
                          ? ""
                          : activeClass - 1 == index
                          ? ""
                          : ""
                      }`}
                    >
                      {item.title}
                    </p>
                    {activeClassMouseIn === index && activeClass != index && (
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.75 12.9655H6.55562"
                          stroke="currentColor"
                          strokeWidth="1.32496"
                        />
                        <path
                          d="M13.1523 19.7086L13.1523 6.59711"
                          stroke="currentColor"
                          strokeWidth="1.32496"
                        />
                      </svg>
                    )}
                    {activeClass == index && (
                      <div
                        onClick={() => {
                          setActiveClass(-1);
                        }}
                      >
                        <svg
                          className="rotate-45 cursor-pointer"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.75 12.9655H6.55562"
                            stroke="currentColor"
                            strokeWidth="1.32496"
                          />
                          <path
                            d="M13.1523 19.7086L13.1523 6.59711"
                            stroke="currentColor"
                            strokeWidth="1.32496"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div
                    className={`gotham text-sm font-normal text-white ${
                      activeClass === index ? "" : "hidden"
                    } `}
                  >
                    <p className="Mono text-base font-normal">
                      {item.description ? item.description : "N/A"}
                    </p>
                    <p className="pt-[25px] pb-[32px] gotham">
                      {item.isVirtualClass && " Virtual classes available"}
                    </p>
                    <ButtonNavigate
                      text="BOOK A CLASS"
                      isArrowRight={true}
                      onClick={() => navigate("/book-a-class")}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              {data.data.attributes.bottomClassType.cols2.map((item, index) => (
                <div
                  className={`px-[27px] pt-[21px] pb-[17px] border-b-[1px]   ${
                    index == 0 ? "border-y" : ""
                  } ${
                    activeClass2 === index
                      ? "bg-[#292929] border-brand-clime border-y"
                      : activeClass2 - 1 === index
                      ? "border-b-0"
                      : "border-white"
                  }`}
                >
                  <div
                    className={`uppercase flex justify-between text-lg gotham tracking-[0.1em] font-medium ${
                      activeClass2 == index ? "text-brand-clime" : ""
                    }
                    ${activeClass2MouseIn == index ? "text-brand-clime" : ""}
                    `}
                    onMouseEnter={() => setActiveClass2MouseIn(index)}
                    onMouseLeave={() => setActiveClass2MouseIn(-1)}
                    onClick={() => {
                      if (activeClass2 != index) {
                        setActiveClass(-1);
                        setActiveClass2(index);
                        setActiveClass3(-1);
                      }
                    }}
                  >
                    <p
                      className={`gotham w-full ${
                        activeClass2 == index
                          ? ""
                          : activeClass2 - 1 == index
                          ? " "
                          : ""
                      }`}
                    >
                      {item.title}
                    </p>
                    {activeClass2MouseIn === index && activeClass2 != index && (
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.75 12.9655H6.55562"
                          stroke="currentColor"
                          strokeWidth="1.32496"
                        />
                        <path
                          d="M13.1523 19.7086L13.1523 6.59711"
                          stroke="currentColor"
                          strokeWidth="1.32496"
                        />
                      </svg>
                    )}
                    {activeClass2 == index && (
                      <div
                        onClick={() => {
                          setActiveClass2(-1);
                        }}
                      >
                        <svg
                          className="rotate-45 cursor-pointer"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.75 12.9655H6.55562"
                            stroke="currentColor"
                            strokeWidth="1.32496"
                          />
                          <path
                            d="M13.1523 19.7086L13.1523 6.59711"
                            stroke="currentColor"
                            strokeWidth="1.32496"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div
                    className={`gotham text-sm font-normal text-white ${
                      activeClass2 === index ? "" : "hidden"
                    } `}
                  >
                    <p className="Mono text-base font-normal">
                      {item.description ? item.description : "N/A"}
                    </p>
                    <p className="pt-[25px] pb-[32px] gotham">
                      {item.isVirtualClass && "Virtual classes available"}
                    </p>
                    <ButtonNavigate
                      text="BOOK A CLASS"
                      isArrowRight={true}
                      onClick={() => navigate("/book-a-class")}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="flex flex-col">
              {data.data.attributes.bottomClassType.cols3.map((item, index) => (
                <div
                  className={`px-[27px] pt-[21px] pb-[17px] border-b-[1px]   ${
                    index == 0 ? "border-y" : ""
                  } ${
                    activeClass3 === index
                      ? "bg-[#292929] border-brand-clime border-y"
                      : activeClass3 - 1 === index
                      ? "border-b-0"
                      : "border-white"
                  }`}
                >
                  <div
                    className={`uppercase flex justify-between text-lg gotham tracking-[0.1em] font-medium ${
                      activeClass3 == index ? "text-brand-clime" : ""
                    }
                    ${activeClass3MouseIn == index ? "text-brand-clime" : ""}
                    `}
                    onMouseEnter={() => setActiveClass3MouseIn(index)}
                    onMouseLeave={() => setActiveClass3MouseIn(-1)}
                    onClick={() => {
                      if (activeClass3 != index) {
                        setActiveClass(-1);
                        setActiveClass2(-1);
                        setActiveClass3(index);
                      }
                    }}
                  >
                    <p
                      className={`gotham w-full ${
                        activeClass3 == index
                          ? ""
                          : activeClass3 - 1 == index
                          ? ""
                          : ""
                      }`}
                    >
                      {item.title}
                    </p>
                    {activeClass3MouseIn === index && activeClass3 != index && (
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.75 12.9655H6.55562"
                          stroke="currentColor"
                          strokeWidth="1.32496"
                        />
                        <path
                          d="M13.1523 19.7086L13.1523 6.59711"
                          stroke="currentColor"
                          strokeWidth="1.32496"
                        />
                      </svg>
                    )}
                    {activeClass3 == index && (
                      <div
                        onClick={() => {
                          setActiveClass3(-1);
                        }}
                      >
                        <svg
                          className="rotate-45 cursor-pointer"
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.75 12.9655H6.55562"
                            stroke="currentColor"
                            strokeWidth="1.32496"
                          />
                          <path
                            d="M13.1523 19.7086L13.1523 6.59711"
                            stroke="currentColor"
                            strokeWidth="1.32496"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div
                    className={`gotham text-sm font-normal text-white ${
                      activeClass3 === index ? "" : "hidden"
                    } `}
                  >
                    <p className="Mono text-base font-normal">
                      {item.description ? item.description : "N/A"}
                    </p>
                    <p className="pt-[25px] pb-[32px] gotham">
                      {item.isVirtualClass && "Virtual classes available"}
                    </p>
                    <ButtonNavigate
                      text="BOOK A CLASS"
                      isArrowRight={true}
                      onClick={() => navigate("/book-a-class")}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
