import React, {useEffect, useState } from 'react'
interface IProps {
    initial?: string;
    isYesNo?: boolean;
    noDivider?: boolean;
    onChange?: (value: string) => void;
    disabled?: boolean;

}
function Switch(props: IProps) {
    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onChange = (value: string) => {
            return;
        },
        initial,
        isYesNo = false,
        noDivider = false,
        disabled = false,
    } = props;
    const [mode, setMode] = useState<string>(null);

    useEffect(() => {
        onChange && onChange(mode);
    }, [mode]);

    useEffect(() => {
        setMode(initial);
    }, [initial]);

    return (
        <div className="flex items-center border border-white rounded-md cursor-pointer">
            <div
                onClick={() => !disabled && setMode('on')}
                className={`${
                    isYesNo ? 'w-[45px]' : 'w-[51px]'
                }  h-[40px] rounded-l  font-medium text-[11.2px] uppercase
                ${mode == 'on' ? 'bg-brand-clime text-black' : ' text-white'}
                ${noDivider ? '' : 'border-r-[0.5px] border-r-white'}
                flex items-center justify-center`}
            >
                {isYesNo ? 'yes' : 'on'}
            </div>

            <div
                onClick={() =>!disabled && setMode('off')}
                className={`${
                    isYesNo ? 'w-[45px]' : 'w-[51px]'
                } h-[40px]  rounded-r   font-medium text-[11.2px] uppercase 
                ${mode == 'off' ? 'bg-brand-clime text-black' : ' text-white'}
                ${noDivider ? '' : 'border-l-[0.5px] border-l-white'}
                flex items-center justify-center`}
            >
                {isYesNo ? 'no' : 'off'}
            </div>

        </div>
    )
}

export default Switch
