import React from "react";
import LoadingScreen from "../../common/loading/LoadingScreen";
import intro1 from "../../assets/images/homeClubLime/intro1.png";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { navigate } from "gatsby";
function Intro() {
  const { data, loading } = useFetch({
    params: "home-page",
    populate: "middleBanner.images, studioAccess,banner, banner.images ",
  });
  // const images = data?.data?.attributes?.middleBanner?.images;
  const orderFeatures = [
    "NO LOCK-IN CONTRACTS",
    "24/7 GYM ACCESS",
    "100+ GYMS AUSTRALIA WIDE",
    "STATE OF THE ART EQUIPMENT",
  ];
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          <div className="text-white sm:mt-[-7px] ">
            <div className="flex md:flex-row flex-col">
              {data.data.attributes.studioAccess?.data
                .sort((a, b) => {
                  const indexA = orderFeatures.indexOf(a.attributes.title);
                  const indexB = orderFeatures.indexOf(b.attributes.title);
                  if (
                    (indexA !== -1 && indexB !== -1) ||
                    (indexA === -1 && indexB === -1)
                  ) {
                    return indexA - indexB;
                  }
                  if (indexA === -1) {
                    return 1;
                  }
                  if (indexB === -1) {
                    return -1;
                  }
                })
                .map((item, index) => (
                  <div
                    key={index}
                    className="uppercase w-full md:w-1/4 py-[26px] text-center border-x border-y border-lime-primary  text-lime-primary text-xs lg:text-sm  Bossa font-medium"
                  >
                    {item.attributes.title ?? "24/7 access"}
                  </div>
                ))}
            </div>
          </div>
          <div className="flex bg-[#292929] flex-col-reverse  md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src={
                  data?.data?.attributes?.banner?.images?.data[0]?.attributes
                    ?.url ?? intro1
                }
              />
            </div>
            <div className="w-full md:w-1/2 flex md:items-center md:justify-center ">
              <div className="bg-[#292929] px-3 md:p-4 py-[66px]">
                <div className="md:text-[80px] lg:text-[98px] text-[60px] leading-[90%] Gravity ">
                  <p className="text-brand-clime Gravity">
                    {data?.data?.attributes?.banner?.title.split("<br/>")[0] ??
                      "MEMBERSHIPS"}
                  </p>
                  <p className="text-effect text-transparent  Gravity">
                    {data?.data?.attributes?.banner?.title.split("<br/>")[1] ??
                      "ON YOUR TERMS"}
                  </p>
                </div>
                <div className="sm:mt-[50px] mt-10">
                  <ButtonNavigate
                    onClick={() => navigate("/register")}
                    text="JOIN NOW"
                    isArrowRight={true}
                    style=""
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Intro;
