import React from 'react';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Header from './Header';

interface IProps {
  children: React.ReactNode;
}

function LayoutDashboard(props: IProps) {
  return (
    <div id="Layout" className="w-full">
      <div className="sm:flex block  bg-gradient-to-b from-[black] to-[#102111]">
        <Header />
        <Sidebar />
        <div className="sm:w-[calc(100%-339px)] w-full sm:max-h-[calc(100vh-40px)] max-h-none sm:min-h-none min-h-[calc(100vh-292.59px-78px)] overflow-auto sm:bg-[#13181b] bg-transparent">
          {props.children}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default LayoutDashboard;
