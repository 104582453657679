import React from "react";
import equipment1 from "../../assets/images/homeClubLime/equipment1.png";
import equipment2 from "../../assets/images/homeClubLime/equipment2.png";
import equipment3 from "../../assets/images/homeClubLime/equipment3.png";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { navigate } from 'gatsby'
interface props {
  data: any;
}
export default function Equipment({ data }: props) {
  return (
    <>
      <div className="text-white uppercase text-[65px] bebas font-normal grid-cols-1 md:grid-cols-3 grid-flow-row mt-[137px] md:grid hidden ">
        <div className="">
          <p className=" leading-[58px] Gravity ml-auto mr-[90px] mb-[39px] mt-auto w-[210px]">
            {data?.midCarrousels[0]?.title ?? "swimming pool"}
          </p>
          <img className="col-span-2 " src={
            data?.midCarrousels[0]?.background.data.attributes.url ??
            equipment1
          } />
        </div>
        <div className="">
          <img className="col-span-2 mx-auto mb-[39px]" src={
            data?.midCarrousels[1]?.background.data.attributes.url ??
            equipment2
          } />

          <p className="w-[343px]  leading-[58px] Gravity mx-auto">
            {data?.midCarrousels[1]?.title ?? "fully Equipped free weights"}
          </p>
        </div>
        <div className="">
          <p className="Gravity text-center mt-[160px]">
            {data?.midCarrousels[2]?.title ?? "sled track"}
          </p>
          <img
            className="col-span-2 w-auto ml-auto"
            src={
              data?.midCarrousels[2]?.background.data.attributes.url ??
              equipment3
            }
          />
        </div>
      </div>
      <div
        id="scroll"
        className="overflow-x-scroll my-6 text-[45px] md:hidden flex ml-[15px] uppercase text-white"
      >
        {data?.midCarrousels.map((i) => <div className="mr-[22px] min-w-[285px]">
          <img className="w-[285px] h-[323px]" src={i.background.data.attributes.url} />
          <p className="bebas">{i.title}</p>
        </div>)}
        {/* <div className="mr-[22px] min-w-[285px]">
          <img className="w-[285px] h-[323px]" src={equipment2} />
          <p className="bebas">fully Equipped free weights</p>
        </div>
        <div className="mr-[22px] min-w-[285px]">
          <img className="w-[285px] h-[323px] " src={equipment3} />
          <p className="bebas">sled track</p>
        </div> */}
      </div>
      <div className="justify-center hidden md:flex md:mb-[100px] mt-[50px] md:mt-0">
        <ButtonNavigate text="JOIN NOW" isArrowRight={true} onClick={() => navigate('/register')}></ButtonNavigate>
      </div>
    </>
  );
}
