import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import about1 from "../../assets/images/about/about1.jpg";
import useFetch from "../../hooks/useFetch";

function HomeSlider() {
  const { data } = useFetch({
    params: "about-page",
    populate: "topBanner,topBanner.image",
  });
  const sliderData = data?.data?.attributes?.topBanner;
  // const [slider, setSlider] = useState<any>();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots: any) => (
      <div
        className="cursor-pointer"
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          margin: "0 0 42px 74px",
        }}
      >
        <ul
          className="cursor-pointer"
          style={{
            width: "100%",
            margin: "0 9px 0 0",
            display: "flex",
            gap: "9px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div key={i} className="h-full bg-transparent flex items-center cursor-pointer">
        <div
          className="bg-brand-orange"
          style={{
            width: "100%",
            height: "2px",
          }}
        ></div>
      </div>
    ),
  };
  return (
    <>
      {sliderData && (
        <div className="homeCarousel aboutSlider lg:h-[690px] h-[555px] overflow-y-hidden overflow-x-hidden">
          <Slider
            // ref={
              // (c: any) => setSlider(c)
            // }
            {...settings}
            className="lg:h-[690px] h-[555px]"
          >
            {sliderData.map((i) => (
              <div className="w-full relative md:h-[690px] lg:h-full h-[555px] ">
                <img
                  
                  className="lg:w-[1440px] w-auto md:h-[690px] h-full opacity-90 object-cover"
                  src={i.image ? i.image.data.attributes.url : about1}
                />
                <p className="outline-text text-[50px]  lg:text-[80px]  leading-[72px] tracking-[0.05em] w-full text-center  absolute top-1/2 -translate-y-2/4 font-bold ">
                  {i.title ?? "hiit our way"}
                </p>
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

export default HomeSlider;
