import React, { useState } from "react";
import InputBase from "../../common/inputs/InputBase";
import lifestyle1 from "../../assets/images/homeClubLime/lifestyle1.png";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import Checkbox from "../../common/buttons/Checkbox";
import plus from "../../assets/images/memberships/plus.png";
// interface bannerType {
//   onClick: () => void;
// }
function TwoWeekFree({ setShow }: any) {
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  return (
    <div
      className={` w-[360px] overflow-y-scroll overflow-x-hidden border-[1px] border-brand-clime max-h-[748px] md:overflow-hidden h-[89vh] bg-black relative `}
    >
      <button
        className="w-fit z-10 absolute rotate-45 right-1 top-1 rounded-full border-brand-clime border-[1px]"
        onClick={() => setShow(false)}
      >
        <img src={plus} />
      </button>
      <img
        src={lifestyle1}
        className="w-[272px] h-[231px] ml-auto opacity-80 object-fill"
      />{" "}
      <p className=" Gravity tracking-wide text-[45px] text-white absolute top-12 pl-[14px] menu-text mb-[27px]">
        Promo Heading goes here on mobile
      </p>
      <div className=" absolute md:top-[250px] top-[206px] pb-[28px] px-[28px] mt-0 max-h-[595px] max-w-[360px] overflow-hidden">
        <p className="text-white gotham text-[14px] mb-6">
          This form has multiple uses, if no offers are available this form will
          be an opportunity to sign up to receive future offers.
        </p>
        <div className="flex flex-col gap-2">
          <InputBase
            width="100%"
            type="string"
            holder="First name*"
            isRequired={true}
            isShowRequired={formControlled.firstName}
            onchange={(e) => setForm({ ...form, firstName: e.target.value })}
          />
          <InputBase
            width="100%"
            type="string"
            holder="Last name*"
            isRequired={true}
            isShowRequired={formControlled.lastName}
            onchange={(e) => setForm({ ...form, lastName: e.target.value })}
          />
          <InputBase
            width="100%"
            type="email"
            holder="Email address*"
            errorText={errText.email}
            isRequired={true}
            isShowRequired={formControlled.email}
            onchange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <InputBase
            width="100%"
            type="string"
            holder="Phone number*"
            isRequired={true}
            isShowRequired={formControlled.phoneNumber}
            onchange={(e) => setForm({ ...form, phoneNumber: e.target.value })}
          />
        </div>
        <div className="flex gap-[14px] my-2 items-center mb-[20px] mt-[34px]">
          <div className="w-max">
            <Checkbox></Checkbox>
          </div>
          <p className="text-xs text-white gotham">
            I aggree terms & conditions and the privacy policy
          </p>
        </div>
        <ButtonNavigate
          text="UNLOCK PROMO"
          onClick={() => submit()}
          isArrowRight={true}
          style="border-b border-[#777777] text-[#777777] w-[188px] h-[56px]"
        ></ButtonNavigate>
      </div>
    </div>
  );
}
export default TwoWeekFree;
