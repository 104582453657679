import React from 'react';
interface btnType {
  text: string;
  height: number;
  width: string;
}
function ButtonActive({ text, height, width = '' }: btnType) {
  if (width) {
    width = width;
  } else width = 'max-content';
  return (
    <button
      style={{
        height: `${height}px`,
        width: `${width}`,
        // width: `${width != 0}?${width} px: ''px`,
      }}
      className={`border-white text-white rounded bg-transparent focus:border-b-[5px] focus:border-b-brand-clime focus:border-white focus:text-white  font-bold  border-b  border-t border-l bg-[black] border-r hover:border-white hover:text-white`}
    >
      {text}
    </button>
  );
}

export default ButtonActive;
