import React, { useContext, useState, useEffect } from 'react';
// import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link, navigate } from 'gatsby';
import PrimaryOutlinedButton from '../../../../common/buttons/PrimaryOutlinedButton';
import MembershipsList from '../../../../components/joining-process/Memberships';
import { AuthContext } from '../../../../apis/auth';
import ModalBase from '../../../../common/modals/ModalBase';
import tick from '../../../../assets/images/icons/tick.svg';
import { toastError, toastSuccess } from '../../../../common/toasts/toast';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  changeMembership,
  fullMemberData,
  updateMemberInfo,
} from '../../../../apis/mutation';
import moment from 'moment';
import ArrowButton from '../../../../common/buttons/ArrowButton';
import InputAnimated from '../../../../common/inputs/InputAnimated';
// import SearchClubNearby from "./billing/ChangeClub";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import CheckboxActive from '../../../../common/buttons/CheckboxActive';
import InputBase from '../../../../common/inputs/InputBase';
import InputNumber from '../../../../common/inputs/InputNumber';
import congratsImg from '../../../../assets/images/congrats.png';
import arrowRight from '../../../../assets/images/icons/arrowRight.svg';

import appstore from '../../../../assets/images/appstore.png';
import googlePlay from '../../../../assets/images/chplay.png';
import useWindowSize from '../../../../hooks/useWindowSize';
import { breakpoints } from '../../../../constants';
import Switch from '../../../../common/buttons/Switch';
import MyReactQuill from '../../../../components/MyReactQuill';
import useFetch from '../../../../hooks/useFetch';
import InputBaseDashBoard from "../../../../common/inputs/InputBaseDashBoard";
import InputNumberDashBoard from "../../../../common/inputs/InputNumberDashBoad";

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}

function ChangeClub({ membership, moveTab }) {
  const { width } = useWindowSize();
  const setDataAccount = (e) => {
    if (e.target.name === 'cardCvv') {
      paymentInfoCard.cardType == 'American express'
          ? (e.target.maxLength = '4')
          : (e.target.maxLength = '3');
    }
    if (e.target.name === 'cardExpiryDate') {
      if (e.target.value.length === 2) {
        e.target.maxLength = '5';
        e.target.value = e.target.value + '/';
      } else if (
          e.target.value.length === 3 &&
          e.target.value.charAt(2) === '/'
      )
        e.target.value = e.target.value.replace('/', '');
    }
    if (e.target.name === 'cardNumber') {
      let cardTypeDetect = '';
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(e.target.value.trim())) {
        cardTypeDetect = 'Visa';
      } else if (
          /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
              e.target.value.trim()
          )
      ) {
        cardTypeDetect = 'Mastercard';
      } else if (/^3[47][0-9]{13}$/.test(e.target.value.trim())) {
        cardTypeDetect = 'American express';
      }
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
        cardType: cardTypeDetect,
      });
    } else
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
      });
    // { ...setPaymentInfo, [e.target.name]: e.target.value };
  };
  const [paymentInfoCard, setPaymentInfoCard] = useState({
    cardNumber: '',
    cardExpiryDate: '',
    cardType: '',
    cardCvv: '',
    cardHolderName: '',
  });

  const [TAndC, setTAndC] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);

  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: dataPrivacyPolicy } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });

  const termAndCondition: IDataTermsAndConditionPage =
    dataTermAndCondition?.data?.attributes;
  const privacyPolicyData: IDataTermsAndConditionPage =
    dataPrivacyPolicy?.data?.attributes;

  const userInfo = useContext(AuthContext).userInfo;
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({
    memberDetails: {
      dob: userInfo?.dob,
      homeLocationId: userInfo?.homeLocationId,
      membershipId: membership?.[0]?.membershipId,
    },
  });

  const [updatedInfo, setUpdatedInfo] = useState({ ...userInfo });

  const [prefixEmergencyNumber, setPrefixEmergencyNumber] = React.useState<
    '+61' | '+64'
  >('+61');

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == 'emergencyContactNumber') {
      value = prefixEmergencyNumber + value;
      if (value.charAt(3) == '0') value = value.slice(0, 3) + value.slice(4);
    }
    setUpdatedInfo({ ...updatedInfo, [name]: value });
  };

  const [modal, setModal] = useState(false);
  const [membershipDetail, setMembershipDetail] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [changeMembershipApi] = useMutation(changeMembership);
  const handleSelect = (v) => {
    // setModal(true)
    setInfo({
      memberDetails: {
        ...info.memberDetails,
        NewMembershipId: v.memberDetails.NewMembershipId,
      },
    });
    setMembershipDetail({
      ...v.membershipDetails,
      membershipNew: v.memberDetails,
    });
  };
  const handleCallApiChangeMembership = () => {
    setModal(true);
  };

  function createMarkup(data) {
    return { __html: data };
  }

  const [isShowGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userInfo?.gender);

  const setRefresh = useContext(AuthContext).setRefreshData;
  const [updateMemberApi] = useMutation(updateMemberInfo);

  const handleUpdateInfo = async () => {
    try {
      const payload = {};
      for (let i = 0; i < Object.keys(userInfo).length; i++) {
        if (!(Object.values(updatedInfo)[i] === Object.values(userInfo)[i])) {
          payload[Object.keys(userInfo)[i]] = Object.values(updatedInfo)[i];
        }
      }

      payload.memberId = updatedInfo.memberId;

      delete payload.imageUrl;

      if (
        !updatedInfo.emergencyContactName ||
        !updatedInfo.emergencyContactNumber ||
        !updatedInfo.emergencyContactRelation
      ) {
        toastError('Please provide emergency contact');
        return;
      }
      const data = await updateMemberApi({ variables: { input: payload } });
    } catch (e) {
      toastError(e.message);
    } finally {
      setRefresh(true);
    }
  };
  const [accept, setAccept] = useState(false);
  const handleChangeMembership = async () => {
    try {
      setLoadingModal(true);

      await handleUpdateInfo();

      await changeMembershipApi({
        variables: {
          memberId: userInfo.memberId,
          membershipLocationId:
            membershipDetail.membershipNew.membershipLocationId,
          newMembershipId: membershipDetail.membershipNew.membershipId,
          oldMemberContractId: userInfo.contracts.items.filter(
            (i) => i.isActive
          )[0].id,
        },
      });
      toastSuccess('Successfully change member');
      setInfo({
        memberDetails: {
          ...info.memberDetails,
          membershipId: membershipDetail.membershipNew.membershipId,
        },
      });
      setStep(6);
      setModal(false);
    } catch (e) {
      toastError(e.message);
      setStep(1);
    } finally {
      setLoadingModal(false);
    }
  };

  const ConfirmMembershipInfo = () => {
    return (
      <div>
        <p className="hidden sm:block uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your new membership
        </p>
        <div className="border-b border-[rgba(255,255,255,0.4)]">
          <p className="hidden sm:block text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            MEMBERSHIP PLAN
          </p>
          <div className="flex justify-between pr-[13px] items-center">
            <p className="hidden sm:block text-white font-normal leading-[160%] text-xs mt-6">
              Membership Plan
            </p>
            <p className="hidden sm:block text-[rgba(255,255,255,0.57)] font-normal leading-[160%] text-xs mt-6 underline">
              Edit
            </p>
          </div>
          <div>
            <div className="flex justify-between pr-[13px] items-center mt-[16px] pt-4 sm:pt-[0px] pb-[6px] sm:pb-[0px] ">
              <p className="text-white sm:text-[11px] sm:font-medium text-base font-normal">
                {membershipDetail.membershipName}
              </p>
              <p className="text-white text-[11px] sm:font-medium sm:text-base font-normal">
                ${membershipDetail.costPrice}{' '}
                {membershipDetail.paymentFrequency.type === 'WEEKS' &&
                  'per week'}
              </p>
            </div>
            <div className=" justify-between pr-[13px] items-center pb-4 pb-[0px] sm:hidden flex ">
              <p className="text-white sm:text-[11px] sm:font-medium text-base font-normal">
                Activation Fee
              </p>
              <p className="text-white sm:text-[11px] sm:font-medium text-base font-normal">
                ${membershipDetail.joiningFee + membershipDetail.joiningFee2}
              </p>
            </div>
          </div>

          <p className="hidden sm:block h-[34px] mb-1 text-white text-xs font-normal leading-[132%] opacity-60 mt-[9px]">
            $27.80 fortnightly Direct Debit for 12 months fixed term
          </p>
        </div>
        <div className="border-b border-[rgba(255,255,255,0.4)]">
          <div className="pt-[17px] pb-[20px] pr-[13px] flex justify-between items-center">
            <div className="flex flex-col gap-[11px]">
              <p className="text-white font-normal text-xs sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
                Select start date*
              </p>
              <p className="text-white sm:font-medium sm:text-[11px] leading-[140%] uppercase text-base sm:leading-[21.36px] font-normal">
                {moment().format('DD MMMM YYYY')}
              </p>
            </div>
            <PrimaryOutlinedButton className="rounded-md w-[100px] !px-0">
              CHANGE
            </PrimaryOutlinedButton>
          </div>
        </div>
        <div className="flex justify-between pr-[13px] sm:items-center py-[16px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 flex-col sm:flex-row items-start sm:gap-y-1">
          <p className="text-white sm:text-[12px] font-medium text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
            Cost per fortnight
          </p>
          <p className="text-white sm:text-[11px] sm:font-medium text-base sm:leading-[21.36px] font-normal">
            $
            {membershipDetail.costPrice *
              (membershipDetail.paymentFrequency.type.toLowerCase() == 'weeks'
                ? membershipDetail.paymentFrequency.amount
                : 0)}
          </p>
        </div>
        <div className="flex justify-between pr-[13px] items-center sm:py-[16px] border-b border-[rgba(255,255,255,0.4)] py-4 flex-col sm:flex-row sm:items-start sm:gap-y-1">
          <p className="text-white sm:text-[12px] font-medium text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
            Total due today (pro-rata)
          </p>
          <p className="text-white sm:text-[11px] sm:font-medium text-base sm:leading-[21.36px] font-normal">
            $
            {membershipDetail?.costPrice +
              membershipDetail?.joiningFee +
              membershipDetail?.joiningFee2 +
              membershipDetail?.joiningFee3}
          </p>
        </div>
        <div className="flex justify-between pr-[13px] sm:items-center sm:py-[7px] border-b border-[rgba(255,255,255,0.4)] py-4 sm:flex-row flex-col items-start sm:gap-y-1">
          <p className="sm:text-white sm:text-[12px] sm:font-medium text-base text-textSecondary sm:leading-[21.36px] font-normal">
            Promo code
          </p>
          <input
            className="hidden sm:block text-white text-[11px] border-solid border-[1px] border-[rgba(255,255,255,0.40)] bg-transparent font-semibold rounded-md px-[8px] py-[9px] w-[142px]"
            defaultValue="PF-"
          />
          <p className="block sm:hidden text-white sm:font-medium sm:text-[12px] leading-[140%] uppercase text-base sm:leading-[21.36px] font-normal">
            PF-XXXXXX
          </p>
        </div>
        <div className="flex justify-between pr-[13px] sm:items-center py-[12px] border-b border-[rgba(255,255,255,0.4)] py-[0px]  sm:py-4 flex-col sm:flex-row items-start sm:gap-y-1">
          <p className="sm:text-white sm:text-[12px] sm:font-medium text-base text-textSecondary sm:leading-[21.36px] sm:font-normal">
            Direct debit starts
          </p>
          <p className="text-white sm:font-medium sm:text-[12px] leading-[140%] uppercase sm:text-base sm:leading-[21.36px] font-normal">
            {moment().add('2', 'weeks').format('DD/MM/YYYY')}
          </p>
        </div>
        <div className="hidden sm:flex items-center mt-[56px] justify-between">
          <div onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div
            onClick={() => {
              setStep(step + 1);
            }}
          >
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const [terms, setTerms] = useState<boolean>(false);
  const ConfirmYourDetails = () => {
    return (
      <div>
        <p className="hidden sm:block uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your details
        </p>
        <div className="">
          <p className="hidden sm:block text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            contact details
          </p>
          <div className="hidden  sm:grid grid-cols-5 gap-y-[14px] w-full pt-[14px] items-center">
            <div className="grid grid-cols-2 col-span-3">
              <InputAnimated
                readOnly
                label="First name"
                type="text"
                value={updatedInfo.surname}
                name="surname"
              />
              <InputAnimated
                readOnly
                label="Last name"
                type="text"
                value={updatedInfo.givenName}
                name="lastName"
              />
            </div>
            <div className="relative ">
              <div
                className={`flex items-center mt-[15px] pr-[12px] pb-[12px] bg-transparent border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1`}
                // onClick={() => setShowGender(!isShowGender)}
              >
                <div className="bg-transparent h-[20px] outline-none w-full  truncate text-white">
                  <span
                    className={`form__label text-[#848484]  absolute -mt-[4px]  transition-all duration-300
                            ${
                              selectedGender
                                ? 'top-1 left-0 text-[11px]'
                                : 'text-sm top-[15px] left-0'
                            }
                            `}
                  >
                    Gender
                  </span>
                  <span className="text-sm  font-medium uppercase tracking-widest">
                    {selectedGender}
                  </span>
                </div>
                <div
                  className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                    isShowGender
                      ? 'border-brand-clime rotate-[135deg]'
                      : 'border-gray'
                  }`}
                ></div>
              </div>
              <div
                className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${
                  isShowGender ? '' : 'hidden'
                }`}
              >
                {['MALE', 'FEMALE']?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="cursor-pointer uppercase  font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-brand-clime"
                    // onClick={() => {
                    //   setShowGender(false);
                    //   setSelectedGender(item);
                    //   // setDropdownSelected(true);
                    // }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className=" flex items-center justify-between -pt-[2px] pb-[8px] -mb-[5px]   h-[33px] mt-[10px] border-b border-[rgba(255,255,255,0.4)]">
              <span className="datepicker-toggle">
                <Flatpickr
                  onChange={(dateObj, dateStr) => {
                    // setUpdatedInfo({
                    //   ...updatedInfo,
                    //   dob: new Date(dateObj),
                    // })
                  }}
                  value={updatedInfo?.dob}
                  style={{color:'white'}}
                  placeholder="D/M/Y"
                  options={{
                    disableMobile: 'true',
                    dateFormat: 'd/m/Y',
                    enableTime: false,
                    time_24hr: false,
                  }}
                  disabled
                />
              </span>
              {/* <img className=" sticky" src={dateIcon} /> */}
            </div>
            <div className="grid w-full col-span-5 grid-cols-2">
              <InputAnimated
                readOnly
                label="Email"
                type="text"
                value={updatedInfo.email}
                name="givenName"
              />
              <InputAnimated
                readOnly
                label="Phone number"
                type="text"
                value={updatedInfo.mobileNumber}
                name="givenName"
              />
            </div>
          </div>
          <div className="flex justify-between pr-[13px] items-center mt-[16px]"></div>
        </div>
        <div className="hidden sm:block pb-[7px] border-b-2 border-b-white mt-[44px]">
          <h2 className="heading2 !text-[14px] text-white uppercase text-left font-medium">
            health check
          </h2>
        </div>
        <div className="sm:mt-5 flex sm:items-center justify-between flex-col sm:flex-row items-start sm:gap-y-5 mt-4">
          <h6 className=" sm:text-sm sm:text-gray text-white text-base sm:leading-[23px]">
            Do you have any injuries, disabilities or current health conditions?
            *
          </h6>
          <div className="flex items-center sm:gap-4 sm:mt-5 mt-4 gap-5 w-full sm:w-auto">
            <div
              onClick={() => setTerms(true)}
              className={`${
                terms ? 'bg-brand-clime border-none !text-black ' : 'bg-transparent border-white'
              } w-1/2 cursor-pointer flex items-center justify-center text-sm border rounded sm:w-[70px] h-[37px]  text-white uppercase`}
            >
              yes
            </div>
            <div
              onClick={() => setTerms(false)}
              className={`${
                !terms
                  ? 'bg-brand-clime border-none !text-black'
                  : 'bg-transparent border-white'
              } w-1/2 cursor-pointer flex items-center justify-center border text-sm rounded bg-brand-clime sm:w-[70px] h-[37px] text-white uppercase`}
            >
              no
            </div>
          </div>
        </div>
        {/* MOBILE */}
        {!terms && (
          <div className="block sm:hidden">
            <div className="pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                terms and conditions*
              </h2>
            </div>
            <div className="flex flex-col gap-y-5 mt-5 mb-7">
              <div className="flex items-center justify-between gap-x-4">
                <CheckboxActive />
                <p className="text-base text-white leading-[133.5%]">
                  I confirm I have read and accept the terms and conditions.
                </p>
              </div>
              <div className="flex items-center justify-between gap-x-4">
                <CheckboxActive />
                <p className="text-base text-white leading-[133.5%]">
                  I confirm I meet the requirements from the pre-exercise
                  checklist.
                </p>
              </div>
            </div>
          </div>
        )}

        {terms && (
          <div className="mt-5 border-b border-b-textSecondary sm:border-b-0">
            {/* DESKTOP */}
            <h2 className="hidden sm:block  text-sm uppercase text-textSecondary text-left">
              Please select the relevant box below*
            </h2>
            <p className="hidden sm:block text-textSecondary  text-[12px] mt-1 text-left">
              Note you must select one option, in order to proceed with this gym
              membership.
            </p>
            {/* MOBILE */}
            <div className="block sm:hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                pre-exercise checklist*
              </h2>
            </div>
            <p className="block sm:hidden text-textSecondary text-base leading-[21.36px] mt-3 sm:font-normal">
              Please complete the following questions, so we are aware of your
              current health situation:
            </p>
            {/* ------------ */}
            <div className="flex flex-col mt-4">
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  I will seek guidance from an appropriate health or medical
                  practitioner prior to undertaking exercise.
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start flex-row-reverse sm:flex-row justify-end  md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  Have you ever been told by a doctor that you have a heart
                  condition or have suffered a stroke?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>{' '}
              <div className="flex items-start flex-row-reverse sm:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  Do you ever have unexplained pains in your chest at rest or
                  during physical exercise?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>{' '}
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  Do you consistently feel faint or suffer from spells of
                  dizziness?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>{' '}
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  Do you suffer from type I or II diabetes and have trouble
                  controlling your blood glucose levels?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  I am under the supervision of a medical practitioner.
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  High or low blood pressure?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">Pregnant?</p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  Tightness in chest?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0 sm:px-3">
                <p className="text-gray  text-left">
                  Undergoing cancer treatment?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              {/* ANOTHER SECTION */}
              <div className="sm:mb-[15px] sm:hidden flex border-none flex-col gap-[3px] mt-[40px] sm:mt-[0px] mt-7 mb-0">
                <h2 className=" text-base uppercase text-white leading-[160%] font-bold text-left">
                  Please select the relevant box below*
                </h2>
                <p className="text-textSecondary border-none  text-[16px] leading-[133%] mt-1 text-start sm:mb-0 mb-5">
                  Note you must select one option, in order to proceed with this
                  gym membership.
                </p>
              </div>
              <div className="md:items-center sm:hidden flex flex-row-reverse md:flex-row justify-end md:justify-between gap-4 sm:px-0 items-start">
                <p className="sm:text-gray  leading-[135%] text-white  text-left">
                  I will seek guidance from an appropriate health or medical
                  practitioner prior to undertaking exercise.
                </p>
                <div className="flex gap-2 sm:mt-[0px] mt-1">
                  <CheckboxActive />
                </div>
              </div>
              <div className="sm:items-center sm:hidden flex flex-row-reverse md:flex-row justify-end md:justify-between gap-4 pb-[27px] sm:px-0 sm:pb-0 items-start">
                <p className="sm:text-gray leading-[135%] text-white   text-left">
                  I am under the supervision of a medical practitioner.
                </p>
                <div className="flex gap-2 sm:mt-1">
                  <CheckboxActive />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="hidden sm:block pb-[7px] border-b-2 border-b-white mt-[40px]">
          <h2 className=" heading2 !text-[14px] text-white uppercase text-left font-medium">
            Emergency Contact
          </h2>
        </div>
        <h2 className="block sm:my-0 my-7 sm:hidden relative font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
          Emergency Contact
        </h2>
        <div className="mt-[25px] grid sm:grid-cols-3 grid-cols-1 sm:gap-y-[23px] ">
          <InputBaseDashBoard
            nameInput="emergencyContactName"
            holder="Emergency contact name*"
            style="w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactName}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />
          <InputBaseDashBoard
            nameInput="emergencyContactRelation"
            holder="Relation to you*"
            style="w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactRelation}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />
          <div className='-mt-[6px]'>
            <InputNumberDashBoard
                prefix={prefixEmergencyNumber}
                onChangePrefix={(value) => setPrefixEmergencyNumber(value)}
                nameInput="emergencyContactNumber"
                holder="Emergency phone*"
                style="w-full"
                isRequired={true}
                value={updatedInfo.emergencyContactNumber}
                onchange={handleChange}
                revertColor={width && width <= breakpoints.sm ? true : false}
            />
          </div>
          {/* <div className="mt-[50px]"><h2 className="text-brand-clime text-[30px] bebas font-normal uppercase border-b border-b-brand-clime pb-2">TERMS AND CONDITIONS</h2></div> */}
        </div>
        {/* DESKTOP T&C*/}
        <div className="hidden sm:flex items-center gap-[16px] mt-6 px-1">
          <CheckboxActive onChecked={(e) => setAccept(true)} />
          <p className="text-gray  select-none max-w-[780px] text-xs">
            I agree to the{' '}
            <span
              className="underline cursor-pointer text-xs"
              onClick={() => setTAndC(true)}
            >
              terms & conditions
            </span>{' '}
            and the{' '}
            <span
              className="underline cursor-pointer text-xs"
              onClick={() => setPrivacyPolicy(true)}
            >
              privacy policy
            </span>{' '}
            attached to my membership.*
          </p>
        </div>
        {/* MOBILE T&C */}
        {terms && (
          <div>
            <div className="block sm:hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                Terms and conditions*
              </h2>
            </div>
            <div className="flex sm:hidden items-start gap-x-4 mt-6 px-1">
              <CheckboxActive onChecked={(e) => setAccept(true)} />
              <p className="text-base text-white leading-[133.5%]">
                I confirm I have read and accept the terms and conditions.
              </p>
            </div>
          </div>
        )}

        <div className="flex items-center mt-6 justify-between sm:mt-10">
          <div
            className="hidden sm:block"
            onClick={() => accept && setStep(step - 1)}
          >
            <ArrowButton text="back" isRight={false} />
          </div>
          <div
            className="block sm:hidden"
            onClick={() => accept && setStep(step - 1)}
          >
            <ArrowButton text="" isRight={false} />
          </div>
          <div
            onClick={() => {
              setStep(step + 1);
            }}
          >
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const [isSameInfo, setIsSameInfo] = useState<boolean>(false);
  const [bank, setBank] = useState<boolean>(false);

  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (step) {
      case 1:
        return 'CHANGE MEMBERSHIP';
      case 2:
        return 'CHANGE MEMBERSHIP';
      case 3:
        return 'CONFIRM YOU DETAILS';
      case 4:
        return 'CONFIRM YOUR PAYMENT DETAILS';
      case 5:
      case 6:
        return 'YOUR MEMBERSHIP IS NOW UPDATED';
      default:
        return 'NOT FOUND';
    }
  }, [step]);

  const ConfirmPaymentDetail = () => {
    return (
      <div>
        <p className="hidden sm:block uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your PAYMENT details
        </p>
        <div className="">
          <p className="hidden sm:block text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            Payment method
          </p>

          <div className="flex sm:hidden items-center justify-between gap-x-2 mt-8">
            <p className="text-base text-white font-bold leading-[160%] uppercase">
              Direct debit (fortnightly)
            </p>
            <p className="text-lg text-brand-clime font-bold leading-[160%]">
              $33.80
            </p>
          </div>

          <p className="text-base text-white text-opacity-50 leading-[21.36px] mt-3">
            Select your payment method:
          </p>

          <div className="flex items-center mt-5 gap-4 justify-between w-full sm:mt-4">
            <div className="flex items-center sm:w-full sm:gap-x-0 gap-x-2">
              <div
                onClick={() => {
                  !isSameInfo && setBank(true);
                }}
                className={`${
                  bank
                    ? 'bg-brand-clime !text-black border-none'
                    : 'bg-transparent border-white'
                } w-1/2 rounded-r sm:font-bold cursor-pointer flex items-center justify-center text-sm border sm:rounded-l sm:w-[148px] h-[40px]  text-white uppercase`}
              >
                bank account
              </div>
              <div
                onClick={() => !isSameInfo && setBank(false)}
                className={`${
                  !bank
                    ? 'bg-brand-clime  !text-black  border-none'
                    : 'bg-transparent border-white'
                } w-1/2 rounded-l sm:font-bold cursor-pointer flex items-center justify-center border text-sm sm:rounded-r bg-brand-clime sm:w-[152px] h-[40px] text-white uppercase`}
              >
                credit card
              </div>
            </div>
          </div>
          {!bank && (
            <div className="sm:hidden flex items-center gap-x-4 mb-7 mt-9">
              {/*<CheckboxActive onChecked={() => setIsSameInfo(!isSameInfo)} />*/}
              <p className="text-white text-base leading-[21.36px] font-normal">
                Same card as above
              </p>
            </div>
          )}
          <div>
            {bank && !isSameInfo && (
              <div className="mt-[33px] grid sm:grid-cols-6 gap-y-[13px] sm:gap-y-0 grid-cols-1 sm:mt-5">
                <div className="col-span-2 flex flex-col gap-y-5 mb-5 sm:mb-0 sm:gap-y-3">
                  <InputBase
                    holder="Account name"
                    style="w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                  <InputBase
                    holder="BSB*"
                    style="w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                </div>
                <div className="col-span-4 flex flex-col gap-y-5 sm:gap-y-3">
                  <InputBase
                    holder="Bank name"
                    style="w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                  <InputBase
                    holder="Account number"
                    style="w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                </div>
              </div>
            )}
            {!isSameInfo && !bank && (
              <div className="sm:mt-[33px] grid sm:grid-cols-6 gap-y-[13px] sm:gap-y-0 grid-cols-1 mt-5">
                <div className="col-span-2 flex flex-col gap-y-5 mb-5 sm:mb-0 sm:gap-y-3">
                  <InputBase
                    holder="Name on the card*"
                    style="w-full"
                    isRequired={true}
                  />
                  <InputBase
                    holder="Card number*"
                    style="w-full"
                    isRequired={true}
                  />
                </div>
                <div className="col-span-4 flex flex-col gap-y-5 sm:gap-y-3">
                  <InputBase
                      onchange={(e) => setDataAccount(e)}
                      nameInput="cardExpiryDate"
                      holder="Expiry date*"
                    style="w-full"
                    isRequired={true}
                  />
                  <InputBase
                      onchange={(e) => setDataAccount(e)}
                      nameInput="cardCvv"
                      holder="CCV/CVV*"
                    style="w-full"
                    isRequired={true}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center gap-3 mt-[38px] mb-[50px]">
            <div
            >
              <CheckboxActive />
            </div>
            <p className=" text-sm text-textSecondary max-w-[425px] text-white">
              I confirm these details are true and correct, my existing direct
              debit amount to amended.
            </p>
          </div>
        </div>
        <div className="flex items-center mt-6 justify-between">
          <div className="sm:block hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="block sm:hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="" isRight={false} />
          </div>
          <div
            onClick={() => {
              handleCallApiChangeMembership();
            }}
          >
            <ArrowButton text="confirm" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const congrats = () => {
    return (
      <div
        id="hide-scroll"
        className="grid-cols-1 !h-full sm:h-[70vh] md:h-full w-full overflow-y-scroll md:overflow-y-hidden grid sm:grid-cols-3 sm:gap-6 gap-0 sm:w-full"
      >
        <div className="col-span-2 sm:mt-0 mt-9">
          <h2 className="md:!hidden text-brand-clime border-b border-b-brand-clime pb-2 text-[25px] text-left flex hidden uppercase heading2">
            success, you’ve just <br /> change membership
          </h2>
          <div className="sm:mt-[47px] flex flex-col  items-start md:items-start w-full justify-between mt-0">
            <div className="flex flex-col justify-start items-start sm:mb-[20px] mb-6">
              <h2
                style={{ fontSize: '40px' }}
                className="font-bold uppercase text-white text-left sm:mb-3 mdmb-0 heading bebas sm:text-[40px] sm:text-left !mb-0"
              >
                Congrats, {userInfo.surname}!
              </h2>
              <div
                className="hidden sm:block mt-4"
                onClick={() => window.location.replace('/dashboard')}
              >
                <ArrowButton text="dashboard " isRight={true} />
              </div>
            </div>
            <div className="hidden sm:flex gap-x-12 items-start justify-between">
              <div>
                {/* <h2 className=' text-sm text-white text-left'>Your membership number</h2>
                      <p className=' text-textSecondary text-left tracking-[1px] text-[12px] mt-1'>123 456 789</p> */}
                <h2 className=" text-sm text-white text-left">
                  Username
                </h2>
                <p className=" text-textSecondary text-left tracking-[1px] text-[12px] mt-1">
                  {userInfo.email}
                </p>
              </div>
              <div>
                <h2 className=" text-sm text-white text-left">
                  Start date
                </h2>
                <p className=" text-textSecondary text-left tracking-[1px] text-[12px] mt-1">
                  Today, {moment().format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
            <div className="block sm:hidden">
              <h2 className="text-white text-base leading-[21.36px] mb-1">
                New membership starts
              </h2>
              <p className="text-textSecondary text-base leading-[21.36px]">
                {moment().format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="mt-[57px] sm:mt-0 mt-7">
            <h2 className="uppercase text-left pb-[10px] border-b border-b-brand-clime text-brand-clime heading2 f bebas sm:text-sm text-xl sm:font-normal font-bold">
              next steps
            </h2>
          </div>
          <div className="mt-[28px]">
            <div className="flex flex-col sm:flex-row items-start pb-3 border-b border-b-borderLine sm:items-center justify-between">
              <div className="flex sm:items-center gap-4 sm:gap-y-0 gap-x-5 items-start">
                <h1 className="font-bold text-white md:text-brand-clime heading bebas sn:text-sm text-[30px]">
                  01
                </h1>
                <div className="flex sm:block sm:flex-ow flex-col sm:gap-y-0 gap-y-3">
                  <p className="uppercase font-bold text-brand-clime md:text-white heading2 bebas sm:text-lg text-base">
                    Check your email
                  </p>
                  <p className="block sm:hidden text-white">
                    We’ve sent your membership number, contract and receipt to
                    your email.
                  </p>
                </div>
              </div>
              <p className="hidden sm:block text-white text-left md:text-right mt-2 md:mt-0">
                We’ve sent your membership number, and payment receipt to your
                nominated <br /> email address to your email address.
              </p>
            </div>
            <div className="flex pt-4 pb-3 flex-col md:flex-row items-start border-b border-b-borderLine md:items-center justify-between">
              <div className="flex sm:items-center gap-4 sm:gap-x--0 gap-x-5 items-start">
                <h1 className="font-bold text-white md:text-brand-clime heading bebas sm:text-sm text-[30px]">
                  02
                </h1>
                <div className="flex sm:block sm:flex-row flex-col gap-y-0 gap-y-3">
                  <p className="hidden sm:block uppercase font-bold text-brand-clime sm:text-white heading2 bebas sm:text-lg text-base">
                    Check the app
                  </p>
                  <p className="block sm:hidden uppercase font-bold text-brand-clime md:text-white heading2 bebas sm:text-lg text-base">
                    Download the app
                  </p>
                  <p className="block sm:hidden text-white">
                    Download the Club Lime Member ID App and get it set up on
                    your phone. Unlock your access pass.
                  </p>
                  <div className="flex sm:hidden mt-2 justify-between items-center gap-x-1">
                    <img
                      className="h-[34px] w-1/2"
                      src={appstore}
                      alt="appstore"
                    />
                    <img
                      className="h-[53px] w-1/2"
                      src={googlePlay}
                      alt="ggplay"
                    />
                  </div>
                </div>
              </div>
              <div className="hidden sm:block">
                <p className=" text-white text-left md:text-right mt-2 md:mt-0">
                  Download the Club Lime app, get it setup on your phone
                  <br />
                </p>
                <div className="mt-2 flex justify-start md:justify-end items-center gap-3">
                  <img
                    className="h-[34px] w-auto"
                    src={appstore}
                    alt="appstore"
                  />
                  <img
                    className="h-[53px] w-auto"
                    src={googlePlay}
                    alt="ggplay"
                  />
                </div>
              </div>
            </div>
            <div className="flex pt-6 pb-3 flex-col mdflex-row items-start md:items-center justify-between">
              <div className="flex sm:items-center gap-4 gap-x-5 sm:gap-x-0 items-start">
                <h1 className="font-bold text-white md:text-brand-clime heading bebas sm:text-sm text-[30px]">
                  03
                </h1>
                <div className="flex sm:block sm:flex-row flex-col sm:gap-y-0 gap-y-3">
                  <p className="uppercase text-left font-bold text-brand-clime md:text-white heading2 bebas sm:text-lg text-base">
                    Come to your club and <br />
                    start training
                  </p>

                  <p className="block sm:hidden text-white">
                    Congratulations on taking the first step of your fitness
                    journey at Club Lime. Simply use the Club Lime Member ID App
                    to access your gym and get your sweat on!
                  </p>
                </div>
              </div>
              <p className="hidden sm:block text-white text-left md:text-right mt-2 md:mt-0">
                Congratulations on taking the first step of your fitness journey
                at Club Lime. <br /> Simply use the mobile member app to
                access your gym and get your sweat on!
              </p>
            </div>
          </div>
        </div>
        <div className="sm:hidden block mt-10">
          <PrimaryOutlinedButton
            onClick={() => navigate('/dashboard')}
            className="h-[34px] w-full rounded flex items-center justify-between !px-0 mb-9"
          >
            {/* <span className="blank w-[45px]"></span> */}
            <p className="text-center w-full uppercase text-base font-bold">
              need a personal trainer?
            </p>
            <span className="border-l border-l-white px-3 flex h-[40px] items-center justify-center min-w-[45px]">
              <img className="w-auto h-auto" src={arrowRight} alt="icon" />
            </span>
          </PrimaryOutlinedButton>
          <PrimaryOutlinedButton
            onClick={() => navigate('/dashboard')}
            className="h-[52px] w-full uppercase rounded text-base text-center font-bold"
          >
            Take me to dashboard
          </PrimaryOutlinedButton>
        </div>
        <div className="hidden sm:block">
          <img className="h-full w-full" src={congratsImg} />
        </div>
      </div>
    );
  };
  return (
    <>
      {TAndC && (
        <ModalBase noBaseHeader onClickOutSide={() => setTAndC(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {privacyPolicy && (
        <ModalBase noBaseHeader onClickOutSide={() => setPrivacyPolicy(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {privacyPolicyData?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={privacyPolicyData?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      {modal && (
        <ModalBase
          closeModal={setModal}
          onClickOutSide={() => setModal(false)}
          loading={loadingModal}
        >
          <div className="cancelModal w-[688px] px-[33px]  pt-8  pb-10">
            <div className="cancelModalContent w-full h-full">
              <div className=" flex items-center mt-7">
                {/* Left content */}
                <div
                  className="w-1/2 sm:block hidden h-full relative opacity-80"
                  style={{
                    minWidth: '310px',
                    minHeight: '370px',
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url('/images/MyBookingsPage/cancel-booking-modal.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                ></div>
                {/* Right content */}
                <div className="sm:w-1/2 w-full sm:pl-0 !pl-[33px] h-full relative sm:static -left-7 sm:px-0 px-2">
                  <p className="text-sm text-brand-clime uppercase leading-4 font-medium mb-4">
                    Are you sure you want to change current membership to this?
                  </p>
                  <p className="uppercase text-white text-2xl leading-7 font-bold mb-7">
                    {membershipDetail.membershipName} <br />{' '}
                    <span>
                      {membershipDetail.description.split(',').map((i) => {
                        return (
                          <div className="flex items-end gap-1 mt-2">
                            <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                              <img
                                className="w-auto h-auto"
                                src={tick}
                                alt="icon"
                              />
                            </span>
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                i.replace('\n', '<br/>')
                              )}
                            ></span>
                          </div>
                        );
                      })}
                    </span>
                    <br /> ${membershipDetail.costPrice}
                  </p>

                  <div className="btnGroup flex items-center sm:gap-x-8  gap-x-4">
                    <PrimaryOutlinedButton
                      className="rounded-md sm:w-[108px] w-1/2"
                      onClick={() => handleChangeMembership()}
                    >
                      Yes
                    </PrimaryOutlinedButton>
                    <PrimaryOutlinedButton
                      className="rounded-md sm:w-[108px] w-1/2 hover:!bg-[#ff0000] hover:!border-[#ff0000]"
                      onClick={() => setModal(false)}
                    >
                      No
                    </PrimaryOutlinedButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBase>
      )}
      {/* DESKTOP DESIGN */}
      <div className="relative sm:block hidden">
        <div
          className="absolute -top-7 pl-4  text-sm tracking-[1.4px] font-medium text-white uppercase select-none"
          onClick={() => {
            step < 7 && setStep(step + 1);
          }}
        >
          Step {step}
        </div>
        <div
          className={`w-full h-[1px] mt-12 mb-6 bg-white grid grid-cols-5 relative`}
        >
          {new Array(step).fill('').map((i, idx) => (
            <div className="bg-brand-clime h-[4px] -mt-[2px]"></div>
          ))}
        </div>

        {step == 1 && (
          <>
            {/* <Link to="/dashboard/account/biling">
            <div className=" uppercase text-sm leading-[19px] text-white tracking-widest font-medium">
              <div className="w-[41px] h-[48px] p-[7px] pl-0">
                <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                  <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                </div>
              </div>
              <span className=" uppercase text-sm leading-[19px] text-white tracking-widest">
                billing
              </span>
            </div>
          </Link> */}
            <div className="">
              {/* <h6 className="uppercase text-white text-sm leading-[140%] tracking-widest ">
              step 1
            </h6> */}
              <p className="mt-[10px] font-abcgravity font-bold text-[30px] leading-[90%] uppercase text-white">
                Lets change your membership type
              </p>
              <h6 className="mt-[36px] text-white font-abcmonument text-sm leading-[140%] uppercase font-medium pb-[7px] w-full border-b border-b-white">
                Before you Start
              </h6>
              <div className="mt-[21px] flex flex-col  justify-start">
                <p className=" text-white  text-sm leading-[160%] font-normal">
                  Hello legend, we just wanted to let you know changing your
                  membership type will change the cost of your fortnightly
                  membership, please confirm you are happy to proceed
                </p>
                <div className="flex items-center justify-start mt-6 gap-[16px]">
                  <PrimaryOutlinedButton
                    onClick={() => setStep(2)}
                    className="uppercase text-white font-medium border-white border rounded h-[37px] w-[99px] font-abcmonument !text-[11.2px]"
                  >
                    yes
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    onClick={() => moveTab(0)}
                    className="uppercase text-white font-medium border-white border w-[107px] !px-0 rounded h-[37px] hover:!bg-[#DE0D0D] hover:!border-[#DE0D0D] hover:text-white  font-abcmonument !text-[11.2px]"
                  >
                    no thanks
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Change home club */}
        {step == 2 && (
          <>
            <p className="mt-[10px] bebas font-bold text-[30px] leading-[90%] uppercase text-white">
              Select a new plan
            </p>
            <MembershipsList
              onPrev={() => setStep(1)}
              onNext={() => {
                setStep(step + 1);
              }}
              info={info}
              setInfo={(v) => handleSelect(v)}
              setStep={setStep}
              changeMembership={true}
            />
          </>
        )}
        {/* Change Membership plan */}
        {step == 3 && <ConfirmMembershipInfo />}
        {/* Add & Confirm Upgrade */}
        {step == 4 && ConfirmYourDetails()}
        {/* Confirm Detail */}
        {step == 5 && ConfirmPaymentDetail(setStep)}
        {/* Your Payment */}
        {/* {step == 6 && <YourPayment setStep={setStep} />} */}
        {/* Congrats */}
        {step == 6 && congrats()}
      </div>

      {/* MOBILE DESIGN */}
      <div className="sm:hidden block px-6 pt-1 pb-3">
        <div className="flex items-start justify-between gap-x-5">
          <p className=" font-bold text-white text-[25px] uppercase">
            {renderHeadingTitleMobile}
          </p>
          <div
            onClick={() => navigate('/dashboard/memberships')}
            className="cursor-pointer h-[14px] mt-[2px]"
          >
            <img
              className="block w-full object-contain"
              src="/icons/icon_back.png"
              alt="icon-back"
            />
          </div>
        </div>
      </div>
      <div className="relative sm:hidden block px-6 pb-20">
        {/* EXCEPT THE CONGRATS STEP */}
        {step <= 4 && (
          <div className="mt-2 mb-5">
            <div className="h-[6px] w-full bg-transparent">
              <div
                style={{
                  width: `${step * 25}%`,
                }}
                className={`h-full bg-brand-clime`}
              ></div>
            </div>

            <div className="flex items-center justify-between mt-4">
              {[1, 2, 3, 4].map((mobileStep) => {
                return (
                  <div
                    key={mobileStep}
                    className={`${
                      step >= mobileStep ? 'bg-brand-clime !text-black ' : 'bg-richBlack '
                    } relative w-9 h-9 rounded-full border border-brand-clime flex items-center justify-center bebas font-bold leading-[18px] text-white text-xl text-center after:content-[' '] ${
                      mobileStep === 4
                        ? ' '
                        : ' after:absolute after:left-[calc(100%+1px)] after:top-1/2 after:h-[2px] after:w-[calc(25vw)] after:bg-textSecondary'
                    }  `}
                  >
                    {mobileStep}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {step === 1 && (
          <div className="">
            <h2 className="relative  font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
              BEFORE YOU START
            </h2>
            <p className="text-white text-base text-justify leading-[21.36px] mt-6 mb-8">
              Hi, we just wanted to let you know changing your membership will
              change the cost of your fortnightly membership, please confirm you
              are happy to proceed.
            </p>

            <div className="flex items-center justify-center gap-x-5 mt-1">
              <PrimaryOutlinedButton
                onClick={() => setStep(2)}
                className="w-1/2 uppercase text-white border-white border rounded h-[34px]  text-base font-bold leading-[160%]"
              >
                yes
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                onClick={() => moveTab(0)}
                className="w-1/2 uppercase text-white border-white border rounded h-[34px] hover:!bg-[#DE0D0D] hover:!border-[#DE0D0D] hover:text-white   text-base font-bold leading-[160%]"
              >
                no thanks
              </PrimaryOutlinedButton>
            </div>
          </div>
        )}

        {/* Change home club */}
        {step === 2 && (
          <div>
            <h2 className="relative font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
              SELECT MEMBERSHIP PLAN
            </h2>
            <MembershipsList
              onPrev={() => setStep(1)}
              onNext={() => {
                setStep(step + 1);
              }}
              info={info}
              setInfo={(v) => handleSelect(v)}
              setStep={setStep}
              changeMembership={true}
              isDashboardMobile
            />
          </div>
        )}

        {/* Change Membership plan - combine step 3 and 4 */}
        {step === 3 && (
          <div>
            <h2 className="relative  font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
              Membership plan
            </h2>
            <ConfirmMembershipInfo />

            <h2 className="mt-9 relative  font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
              health check
            </h2>
            {ConfirmYourDetails()}
          </div>
        )}

        {step === 4 && (
          <div>
            <h2 className=" block sm:hidden relative bebas font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
              Today's payment
            </h2>
            <div className="flex sm:hidden flex-col mt-8 gap-y-3 pb-7 border-b border-white border-opacity-40">
              <div className="flex items-center justify-between gap-x-2 ">
                <p className="text-base text-white font-bold uppercase">
                  Total due today
                </p>
                <p className="text-lg text-brand-clime font-bold">$132.80</p>
              </div>
              <div className="flex items-center justify-between gap-x-2 ">
                <p className="text-base text-white font-normal">
                  FLEXI membership
                </p>
                <p className="text-base text-white font-normal">$33.80</p>
              </div>
              <div className="flex items-center justify-between gap-x-2 ">
                <p className="text-base text-white font-normal">Activate fee</p>
                <p className="text-base text-white font-normal">$99.00</p>
              </div>
            </div>

            <h2 className="relative mt-7 bebas font-bold text-brand-clime text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-brand-clime">
              Ongoing payment details
            </h2>
            {ConfirmPaymentDetail()}
          </div>
        )}

        {step === 6 && <div>{congrats()}</div>}
      </div>
    </>
  );
}

export default ChangeClub;
