import React, { useState, useRef, useContext, useEffect } from "react";
import { timeLine } from "../../mock/memberships";
import { Link, navigate } from "gatsby";
import Details from "../components/joining-process/Details";
import Payment from "../components/joining-process/Payment";
import logo from "../assets/images/logo.png";
import Memberships from "../components/joining-process/Memberships";
import Health from "../components/joining-process/Health";
import YourMembership from "../components/joining-process/YourMembership";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import moment from 'moment';
import Congrats from "../components/joining-process/Congrats";
import {
  fullMemberData,
  listBrands,
  listMemberships,
  registerMutation,
  healthQuestionsByBrandId
} from "../apis/mutation";
import {
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { toastError, toastSuccess } from "../common/toasts/toast";
import { Auth } from "aws-amplify";
import InputBase from "../common/inputs/InputBase";
import ArrowButton from "../common/buttons/ArrowButton";
import CheckboxActive from "@/common/buttons/CheckboxActive";
import ModalBase from "../common/modals/ModalBase";
import MyReactQuill from "../components/MyReactQuill";
import useFetch from "../hooks/useFetch";
import useWindowSize from "../hooks/useWindowSize";
import { breakpoints } from "../constants";

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}

function register() {
  const [percent, setPercent] = useState<number>(25);
  const [current, setCurrent] = useState<number>(0);

  const swiperRef = useRef<Swiper | null>(null);
  const handleSlideClick = (index: number) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };
  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const currentIndex = swiperRef.current.swiper.activeIndex;
      setCurrent(currentIndex);
      currentIndex <= 4 && setPercent(25 * (currentIndex + 1));
    }
  };
  const [input, setInput] = useState({
    cardDetails: {
      cardCvv: '',
      cardExpiryDate: '',
      cardHolderName: '',
      cardNumber: '',
      cardType: '',
    },
    memberDetails: {
      address: '',
      brandId: '',
      country: '',
      dob: '',
      email: '',
      gender: '',
      givenName: '',
      homeLocationId: '',
      joinLocationId: '',
      mobileNumber: '',
      postCode: 2000,
      state: '',
      suburb: '',
      surname: '',
      healthQuestionnaire:'',
    },
    paymentType: '',
    membershipLocationId: '',
  });
  const qr = registerMutation;
  const [signupMember] = useMutation(qr);
  const handleCallApi = async (inputsData: any) => {
    try {
      // console.log({ inputsData });
      const userAge = moment(new Date()).diff(
          moment(inputsData.memberDetails.dob),
          'years'
      );
      setLoading(true);
      let payload = { ...inputsData };

      if (userAge < 18) {
        const GuardianDetailsInput = {
          GuardianName: inputsData.memberDetails.emergencyContactName,
          GuardianEmail: inputsData.memberDetails.emergencyContactEmail,
          GuardianMobileNumber: inputsData.memberDetails.emergencyContactNumber,
          GuardianRelationship:
          inputsData.memberDetails.emergencyContactRelation,
        };
        payload = { ...payload, under18MemberGuardianDetail: GuardianDetailsInput };
      }
      const data = await signupMember({
        variables: {
          input: { ...payload },
        },
      });
      if (data.data.signupMemberViaWeb.error)
        throw Error(data.data.signupMemberViaWeb.error);
      toastSuccess('successfully sign up account');
      handleSlideClick(4);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [isRedirectLink, setRedirectLink] = useState(null);
  const [confirmData, setConfirmData] = useState();
  const [listMemberShipApi] = useLazyQuery(listMemberships);
  const [healthQuestionnaires] = useLazyQuery(healthQuestionsByBrandId);
  const [listBrand] =useLazyQuery(listBrands)

  useEffect(() => {
    listBrand();
    healthQuestionnaires().then((res) => console.log({ res }));
    const parseQueryString = () => {
      var str = window.location.search;
      var objURL = {};

      str.replace(
          new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
          function ($0, $1, $2, $3) {
            objURL[$1] = $3;
          }
      );
      return objURL;
    };
    const qr = parseQueryString();
    if (
        Object.keys(qr).length &&
        qr.inviteToken &&
        qr.email &&
        qr.brandId &&
        qr.memberId
    ) {
      setRedirectLink(true);
      setConfirmData(qr);
    } else {
      setRedirectLink(false);
    }
  }, []);
  const [qrMember] = useLazyQuery(fullMemberData);
  const mapInfo = (e) => {
    setConfirmInfo({ ...confirmInfo, [e.target.name]: e.target.value });
  };
  const [confirmInfo, setConfirmInfo] = useState({});

  const [isUnderAge, setIsUnderAge] = React.useState<boolean>(false);

  const handleUnderAge = () => {
    setIsUnderAge(true);
  };

  const confirmSignUp = async () => {
    try {
      setLoading(true);
      const data = await qrMember({
        variables: {
          memberId: confirmData.memberId,
        },
      });

      await Auth.signUp({
        username: confirmData.email,
        password: confirmInfo.password,
        attributes: {
          email: confirmData.email,
          'custom:inviteToken': confirmData.inviteToken,
          'custom:brandId': confirmData.brandId,
          'custom:memberId': confirmData.memberId,
          'custom:mobileNumber': data.data.getMember.mobileNumber,
        },
      });
      // alert("successfully confirm user");
      navigate('/');
      // const user = await Auth.signIn("dupiffoyimme-3304@yopmail.com", "Tranqhuy8110");
      toastSuccess('successfully confirm !');
    } catch (e) {
      // alert(e.message);
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [isLoading, setLoading] = useState(false);

  const [TAndC, setTAndC] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);

  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: dataPrivacyPolicy } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });

  const termAndCondition: IDataTermsAndConditionPage =
      dataTermAndCondition?.data?.attributes;
  const privacyPolicyData: IDataTermsAndConditionPage =
      dataPrivacyPolicy?.data?.attributes;

  const { width } = useWindowSize();

  return (
    <>
      {TAndC && (
          <ModalBase noBaseHeader onClickOutSide={() => setTAndC(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {privacyPolicy && (
        <ModalBase noBaseHeader onClickOutSide={() => setPrivacyPolicy(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {privacyPolicyData?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={privacyPolicyData?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {isLoading && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#82FA00] rounded-full m-auto "></div>
        </div>
      )}
      {!isRedirectLink && (
        <div id="registerPage" className="min-h-[100vh] bg-black">
          <div className=" mx-auto max-w-[1440px] relative">
            <div>

              <div
                  className="cursor-pointer absolute py-[37px] flex-none right-0 "
                  onClick={() => {
                    if (isUnderAge && width && width <= breakpoints.sm) {
                      setIsUnderAge(false);
                    } else {
                      navigate("/");
                    }
                  }}
                  //  to={isUnderAge && width && width <= breakpoints.sm ? '' : '/'}
              >
                <div className="">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="49"
                      viewBox="0 0 48 49"
                      fill="none"
                  >
                    <path
                        d="M12.4321 36.4923C5.99926 30.0594 5.9701 19.6673 12.3565 13.2809C18.7429 6.89449 29.135 6.92366 35.5679 13.3565C42.0007 19.7894 42.0299 30.1815 35.6435 36.5679C29.2571 42.9543 18.865 42.9251 12.4321 36.4923Z"
                        stroke="#82FA00"
                        strokeWidth="1.11532"
                    />
                    <path
                        d="M30.0188 30.3115L18.9313 19.2241"
                        stroke="#82FA00"
                        strokeWidth="1.11532"
                    />
                    <path
                        d="M18.8047 30.4343L29.8225 19.4165"
                        stroke="#82FA00"
                        strokeWidth="1.11532"
                    />
                  </svg>
                </div>
              </div>
            </div>
          <div className="sm:py-[40px] py-[50px]  mr-10 ml-7">
            <div className="sm:mx-6 lg:!mx-[14px] mx-0  w-auto md:h-[36px] h-[21px]">
              <svg xmlns="http://www.w3.org/2000/svg" width="211" height="25" viewBox="0 0 211 25" fill="none">
                <g clipPath="url(#clip0_547_27749)">
                  <path d="M53.4978 18.9079C51.0052 21.3639 48.799 22.3664 45.8828 22.3664C40.6782 22.3664 36.598 18.012 36.598 12.4539V12.3869C36.598 6.77392 40.5746 2.54136 45.8463 2.54136C49.4389 2.54136 51.6299 4.1899 53.2997 5.80491L53.6532 6.1462L55.576 4.17162L55.2012 3.82728C53.0346 1.84051 50.4049 0 45.8859 0C39.1242 0 33.8281 5.46972 33.8281 12.4539V12.5209C33.8281 19.5813 38.984 24.9078 45.8189 24.9078C50.4902 24.9078 53.2601 22.7991 55.3505 20.7331L55.7101 20.3766L53.8421 18.5666L53.4978 18.9048V18.9079Z" fill="#82FA00"/>
                  <path d="M62.0293 0.395996H59.3203V24.5115H75.6045V21.9366H62.0293V0.395996Z" fill="#82FA00"/>
                  <path d="M95.0566 14.404C95.0566 19.5172 92.497 22.3328 87.853 22.3328C83.2091 22.3328 80.5184 19.3831 80.5184 14.2394V0.395996H77.8125V14.404C77.8125 20.861 81.6337 24.8741 87.789 24.8741C93.9444 24.8741 97.7656 20.7848 97.7656 14.2059V0.395996H95.0597V14.404H95.0566Z" fill="#82FA00"/>
                  <path d="M118.542 12.0242C120.553 10.8389 121.641 8.96484 121.641 6.63982V6.57279C121.641 5.01262 121.092 3.67185 120.008 2.58705C118.591 1.17315 116.333 0.393066 113.645 0.393066H103.602V24.5116H114.139C119.477 24.5116 122.927 21.8393 122.927 17.7073V17.6402C122.927 15.0196 121.415 13.0968 118.539 12.0242H118.542ZM106.314 13.6453H113.651C116.095 13.6453 120.194 14.1634 120.194 17.6433V17.7103C120.194 20.3187 117.902 21.9398 114.215 21.9398H106.317V13.6453H106.314ZM113.45 11.0705H106.314V2.971H113.615C116.924 2.971 118.902 4.3788 118.902 6.74038V6.80742C118.902 9.43715 116.811 11.0705 113.45 11.0705Z" fill="#82FA00"/>
                  <path d="M129.453 0.41748H134.749V19.6758H146.749V24.4904H129.453V0.41748Z" fill="#82FA00"/>
                  <path d="M150.555 0.41748H155.851V24.4904H150.555V0.41748Z" fill="#82FA00"/>
                  <path d="M161.938 0.41748H167.648L173.974 10.5951L180.303 0.41748H186.01V24.4904H180.748V8.77595L173.974 19.0572H173.837L167.13 8.87955V24.4904H161.938V0.41748Z" fill="#82FA00"/>
                  <path d="M192.055 0.41748H210.213V5.12845H197.317V10.0131H208.665V14.7241H197.317V19.7794H210.384V24.4904H192.055V0.41748Z" fill="#82FA00"/>
                  <path d="M3.41896 12.4539C3.41896 7.47173 7.47173 3.41896 12.4539 3.41896C16.8205 3.41896 20.5564 6.53624 21.3395 10.8297L21.3639 10.9547H24.8164L24.792 10.781C23.9632 4.63479 18.658 0 12.4539 0C5.58552 0 0 5.58552 0 12.4539C0 17.235 2.79733 21.6504 7.1274 23.7072L7.3468 23.8108V19.9013L7.28281 19.8556C4.86333 18.1613 3.41896 15.3945 3.41896 12.4539Z" fill="#82FA00"/>
                  <path d="M21.3434 14.0781C20.6761 17.7348 17.8025 20.6266 14.1672 21.3244V5.68921H10.7513V21.3244H10.7422V24.7891L10.8763 24.8073C11.4156 24.8774 11.9489 24.9109 12.4608 24.9109C18.6649 24.9109 23.9701 20.2762 24.7989 14.13L24.8233 13.9563H21.3708L21.3464 14.0812L21.3434 14.0781Z" fill="#82FA00"/>
                </g>
                <defs>
                  <clipPath id="clip0_547_27749">
                    <rect width="210.379" height="24.9078" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>

                </div>
            {/* Navigation */}
            {!isUnderAge && current < 4 && (
                <div
                    style={{ width: percent + '%' }}
                    className={`md:hidden h-[6px] flex  transition-transform bg-brand-clime z-10 ${
                        current == 4 ? 'md:hidden block' : ' '
                    }`}
                ></div>
            )}
            <div className="flex items-start justify-between pb-[11px]  w-full lg:w-[960px] md:!pl-10 sm:pl-2 sm:pr-2">
              {!isUnderAge && current < 4 && (
                  <>
                    <div className="hidden mx-6 lg:mx-0 md:flex items-center cursor-pointer justify-evenly md:justify-between w-full gap-x-8">
                      {timeLine.map((item: any, index: number) => (
                          <div
                              onClick={() =>
                                  current >= index ? handleSlideClick(index) : null
                              }
                              key={index}
                              className="md:px-0 flex items-center w-1/4"
                          >
                            <div
                                className={`w-[34px] h-[34px] rounded-[50%] text-white flex items-center justify-center font-bold text-sm ${
                                    current >= index && 'bg-brand-clime !text-black'
                                } ${
                                    current < index &&
                                    'border border-white  opacity-60 text-white'
                                } ${
                                    current > index &&
                                    'border border-brand-clime text-black'
                                } `}
                            >
                              {index + 1}
                            </div>
                            <p
                                className={`hidden md:block ml-[15px] uppercase  tracking-widest font-bold font-abcmonument  text-[10px] ${
                                    current < index ? 'text-gray' : 'text-white'
                                }`}
                            >
                              {item}
                            </p>
                          </div>
                      ))}
                    </div>
                    <div className="flex  md:hidden items-center justify-between w-full sm:mx-6 mx-[14px] mt-[20px] cursor-pointer">
                      {timeLine.map((mobileStep, mobileIndex) => {
                        return (
                            <div
                                key={mobileStep}
                                className={`${
                                    current >= mobileIndex
                                        ? 'bg-brand-clime '
                                        : 'bg-richBlack text-white '
                                } relative w-9 h-9 rounded-full border border-brand-clime flex items-center justify-center font-abcgravity font-bold leading-[18px] text-black text-xl text-center after:content-[' '] ${
                                    mobileIndex === 3
                                        ? ' '
                                        : ' after:absolute after:left-[calc(100%+1px)] after:top-1/2 after:h-[2px] after:w-[calc(25vw)] after:bg-textSecondary'
                                }  `}
                            >
                              {mobileIndex + 1}
                            </div>
                        );
                      })}
                    </div>
                  </>
              )}
              {current >= 4 && (
                  <h2 className="text-brand-clime hidden !pl-1.5 font-bold text-[25px] md:flex uppercase">
                    congrats, you’ve just joined club lime
                  </h2>
              )}
              {/* Close */}
              <div
                  onClick={() => {
                    if (isUnderAge && width && width <= breakpoints.sm) {
                      setIsUnderAge(false);
                    } else {
                      navigate('/');
                    }
                  }}
                  //  to={isUnderAge && width && width <= breakpoints.sm ? '' : '/'}
              >

              </div>
            </div>

            <div className="flex items-start  w-full gap-8 max-w-[1440px] justify-between mx-auto">
              <div className="flex flex-col w-full lg:w-[960px]">
                <div
                  className=" sm:mx-[0px] pr-0 xl:pr-[80px] "
                >
                  <div
                      style={{ width: percent > 100 ? '100%' : percent + '%' }}
                      className={`flex h-[6px] hidden md:block ml-10 transition-transform bg-brand-clime z-10 ${
                          current == 4 ? 'hidden md:block' : ' '
                      }`}
                      onClick={() => {
                        handleSlideClick(current + 1);
                      }}
                  ></div>
                </div>
                <div
                  id="scroll-hidden"
                  className={`w-auto mx-[14px] sm:mx-10  ${
                    current == 4
                      ? "h-[calc(100vh-150px)]"
                      : "h-[calc(100vh-221px)]"
                  } overflow-auto md:overflow-hidden `}
                >
                  <Swiper
                    allowTouchMove={false}
                    ref={swiperRef}
                    slidesPerView={1}
                    initialSlide={current}
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <Details
                        onPrev={() => true}
                        onNext={() => handleSlideClick(1)}
                        info={input}
                        setInfo={setInput}
                        onUnderAge={handleUnderAge}
                        isUnderAge={isUnderAge}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Memberships
                        onPrev={() => handleSlideClick(0)}
                        onNext={() => handleSlideClick(2)}
                        info={input}
                        setInfo={setInput}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Health
                        onPrev={() => handleSlideClick(1)}
                        onNext={() => handleSlideClick(3)}
                        info={input}
                        setInfo={setInput}
                        openModalTC={() => setTAndC(true)}
                        openModalPP={() => setPrivacyPolicy(true)}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Payment
                        onPrev={() => handleSlideClick(2)}
                        onNext={() => handleSlideClick(4)}
                        info={input}
                        setInfo={setInput}
                        handleCallApi={handleCallApi}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Congrats info={input} moveSlide={handleSlideClick} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <YourMembership
                slideMove={(v: number) => handleSlideClick(v)}
                current={current}
                info={input}
                setInfo={setInput}
              />
            </div>
          </div>
        </div>
      )}
      {isRedirectLink && (
        <div className="bg-black h-[100vh] w-[100vw] flex items-center justify-center relative">
          <div className="flex flex-col gap-6 z-10 sm:mx-6">
            <div className="ml-[12px] mt-11 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="500" height="50" viewBox="0 0 204 24" fill="none">
                <g clipPath="url(#clip0_997_2097)">
                  <path d="M33.2266 12.2738V12.2124C33.2266 6.07189 38.1021 0.986328 44.7545 0.986328C48.8724 0.986328 51.3424 2.41271 53.7142 4.45908L52.4946 5.63762C50.4844 3.80803 48.1794 2.44449 44.72 2.44449C39.1882 2.44449 35.005 6.69258 35.005 12.1503V12.2117C35.005 17.6998 39.2527 21.9789 44.7545 21.9789C48.0819 21.9789 50.3538 20.7693 52.6918 18.5994L53.8462 19.6522C51.4084 21.9168 48.7719 23.4357 44.6885 23.4357C38.1021 23.4364 33.2266 18.5062 33.2266 12.2738Z" fill="#B3E833"/>
                  <path d="M58.6406 1.35938H60.3531V21.5766H73.8897V23.0652H58.6406V1.35938Z" fill="#B3E833"/>
                  <path d="M77.0781 14.0422V1.35938H78.7906V13.8869C78.7906 19.0028 81.7226 21.9488 86.5966 21.9488C91.2733 21.9488 94.2714 19.2817 94.2714 14.0422V1.35938H95.9838V13.8551C95.9838 20.0888 92.1314 23.4055 86.5306 23.4055C80.9973 23.4055 77.0781 20.1192 77.0781 14.0422Z" fill="#B3E833"/>
                  <path d="M102.79 1.35938H112.31C114.911 1.35938 117.019 2.07327 118.304 3.28146C119.26 4.18108 119.787 5.32783 119.787 6.69279V6.75422C119.787 9.7002 117.744 11.1887 115.867 11.9019C118.634 12.5847 121.07 14.0719 121.07 17.0793V17.1421C121.07 20.7695 117.778 23.0644 112.803 23.0644H102.789V1.35938H102.79ZM118.04 6.84814C118.04 4.46 115.966 2.84719 112.277 2.84719H104.503V11.3744H112.112C115.571 11.3744 118.04 9.79412 118.04 6.90957V6.84814ZM112.311 12.8637H104.503V21.5766H112.871C116.823 21.5766 119.326 19.8706 119.326 17.1428V17.08C119.326 14.4143 116.855 12.8637 112.311 12.8637Z" fill="#B3E833"/>
                  <path d="M126.484 1.35938H131.556V18.7239H143.05V23.0652H126.484V1.35938Z" fill="#B3E833"/>
                  <path d="M146.695 1.35938H151.768V23.0652H146.695V1.35938Z" fill="#B3E833"/>
                  <path d="M157.598 1.35938H163.066L169.126 10.537L175.185 1.35938H180.654V23.0652H175.615V8.8945L169.126 18.166H168.994L162.571 8.98842V23.0652H157.598V1.35938Z" fill="#B3E833"/>
                  <path d="M186.445 1.35938H203.837V5.60817H191.484V10.0116H202.354V14.259H191.484V18.8178H204.002V23.0652H186.445V1.35938Z" fill="#B3E833"/>
                  <path d="M7.296 19.3438C4.8365 17.7225 3.22533 15.0357 3.22533 12C3.22533 7.05708 7.49702 3.03566 12.7476 3.03566C17.413 3.03566 21.3029 6.21184 22.1123 10.3872H25.3759C24.535 4.53054 19.1937 0 12.7468 0C5.71859 0 0 5.38355 0 12C0 16.7819 2.98681 20.9177 7.296 22.8448V19.3438Z" fill="#B3E833"/>
                  <path d="M14.3593 20.8337V5.54883H11.1348V20.833C11.1318 20.8323 11.128 20.8316 11.125 20.8316V23.8998C11.6568 23.964 12.1969 24 12.7467 24C19.1936 24 24.5356 19.4695 25.375 13.6128H22.1114C21.3981 17.296 18.2867 20.2003 14.3593 20.8337Z" fill="#B3E833"/>
                </g>
                <defs>
                  <clipPath id="clip0_997_2097">
                    <rect width="204" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="text-white uppercase text-[40px] leading-[36px] text-center my-6 ">
              Member confirm
            </div>
            <InputBase
              onchange={(e) => mapInfo(e)}
              nameInput="password"
              holder="Password*"
              style="lg:w-full"
              isRequired={true}
              type="password"
            />
            <InputBase
              onchange={(e) => mapInfo(e)}
              nameInput="confirm"
              holder="Confirm Password*"
              style="lg:w-full"
              isRequired={true}
              type="password"
            />
            <div className="flex justify-end items-center">
              <ArrowButton
                text="NEXT"
                isRight={true}
                onclick={() => confirmSignUp()}
              />
            </div>
          </div>
          <div className="absolute bottom-0 w-[100vw] h-[44vh] bg-gradient-to-b from-[black] to-[#102111]"></div>
        </div>
      )}
    </>
  );
}

export default register;
