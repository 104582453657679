import React, { useState, useEffect, useContext } from "react";
import { menuMobile } from "../../../mock/homePage";
import { Link, navigate } from "gatsby";
import noNameAvatar from "../../assets/images/icons/noNameAvatar.png";
import logo from "../../assets/images/vivaBrands/clublime-logo.png";
import { Auth } from "aws-amplify";
import { AuthContext } from "../../apis/auth";
const Navigation = (props) => {
  const { toggle, setToggle } = props;
  const [isLogged, setIsLogged] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    if (window.localStorage.getItem("userInfo") == "Deval") {
      setIsLogged(true);
      setUserInfo(JSON.parse(window.localStorage.getItem("contact")));
    } else {
      setIsLogged(false);
    }
  }, []);
  const reCreate = useContext(AuthContext).Init;

  const handleLogout = () => {
    Auth.signOut().then(() => {
      window.localStorage.removeItem("userInfo");
      navigate("/login");
      window.localStorage.getItem("userInfo")
        ? setIsLogged(true)
        : setIsLogged(false);
      reCreate();
    });
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      setIsLogged(true);
    }).catch(e => setIsLogged(false))
  }, []);

  const [drop, setDrop] = useState(false);
  const [logout, setLogout] = useState(false);
  return (
    <div
      className={`bg-black w-full h-full fixed left-0 top-0 transform transition-all  ${toggle
        ? "translate-x-0 delay-75 transition-transform opacity-100"
        : "delay-75 hidden translate-x-[100%] transition-opacity "
        } `}
    >
      <div className="flex items-center justify-between  h-[70px] px-6 xl:px-[15px]">
        <img src={logo} className="w-[140px]" alt="logo" />
        <div
          onClick={() => setToggle(false)}
          className="h-[70px] w-[50px] flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="43"
            height="43"
            viewBox="0 0 43 43"
            fill="none"
          >
            <path
              d="M32.5547 31.5044L11.3001 10.2498"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M11.0547 31.7466L32.1758 10.6255"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>
        </div>
      </div>
      <div className="h-[78px] flex items-center justify-center border-y border-y-lime-primary py-[13px] px-6 w-full gap-[23px]">
        {isLogged ?<Link className="w-full" to="/dashboard">
          <button className="bg-lime-primary rounded-[5px] w-full border border-lime-primary h-[49px] font-medium text-black text-sm uppercase">
            dashboard
          </button>
        </Link> : <Link className="w-full" to="/register">
          <button className="bg-lime-primary rounded-[5px] w-full border border-lime-primary h-[49px] font-medium text-black text-sm uppercase">
            JOIN NOW
          </button>
        </Link> }

        {isLogged ? (
          <button
            className="bg-black rounded-[5px] border border-white w-full h-[49px] font-medium text-white text-sm uppercase"
            onClick={() => handleLogout()}
          >
            log out
          </button>
        ) : (
          <Link className="w-full" to="/login">
            {" "}
            <button className="bg-black rounded-[5px] border border-white w-full h-[49px] font-medium text-white text-sm uppercase">
              log in
            </button>
          </Link>
        )}
      </div>
      <div className="mt-[28px] flex flex-col pl-[24px] gap-[21px] gap-y-10">
        {isLogged && <div className="w-[238px] flex-row-reverse gap-6 items-center justify-end relative flex xl:hidden">
          <p className="text-white Bossa text-sm ">
            Hello {userInfo?.lastName?.toUpperCase()}
          </p>
          <img
            onClick={() => {
              setDrop(!drop);
            }}
            src={noNameAvatar}
          />
          {drop && (
            <div className="absolute flex flex-col top-[120%] right-0 text-white bg-[#13181b] border-brand-clime border rounded-md p-3 gap-2">
              <Link
                to="/dashboard"
                className="hover:text-brand-clime gotham text-sm "
              >
                My Dashboard
              </Link>
              <Link
                to="/dashboard/account"
                className="hover:text-brand-clime gotham text-sm "
              >
                My Profile
              </Link>
              <div
                onClick={() => {
                  setLogout(true);
                  Auth.signOut().then(() => {
                    reCreate()
                  });
                }}
                className="hover:text-brand-clime gotham text-sm "
              >
                Logout
              </div>
            </div>
          )}
        </div>}
        {menuMobile.map((item) => (
          <Link to={`/${item.path}`}>
            <span className="text-white tracking-widest font-normal Bossa text-[15px] uppercase">
              {item.name}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Navigation;
