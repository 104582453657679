export const dropdown = {
    location: {
        label: 'location',
        value: [{ 'state': ['act', 'nsw', 'qld', 'vic'] }]
    }
    , sort: {
        label: 'sort by',
        value: [{ 'sort by': ['a-z', 'z-a', 'closest to me'] }]
    },
    features: {
        label: 'features',
        value: [{
            'basic': ['gym', 'pool', 'aquatic']
        }]
    },
    jobType: {
        label: "job type", value: [{
            'type': ['manager', 'staff', 'director']
        }]
    },
    time: {
        label: "full-time", value: [{
            'time': ['part-time', 'full-time', 'night']
        }]
    }
}