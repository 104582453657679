import React, { useContext, useState, useEffect } from 'react';
// import DashBoardLayout from "../../../components/layout/DashBoardLayout";
// import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link, navigate } from 'gatsby';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import LayoutDashboard from '../../../components/LayoutDashboard';
import InputAnimated from '../../../common/inputs/InputAnimated';
import Password from './components/Password';
import Notification from './components/Notifications';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { fullMemberData, updateMemberInfo } from '../../../apis/mutation';
// import { toast } from "react-toastify";
import { Amplify, Auth, Storage } from 'aws-amplify';
import { toastError, toastSuccess } from '../../../common/toasts/toast';
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../../../apis/auth';
import Biling from './components/Billing';
import { breakpoints } from '../../../constants';
import useWindowSize from '../../../hooks/useWindowSize';
import PrimaryOutlinedButton from '../../../common/buttons/PrimaryOutlinedButton';
import moment from 'moment';
import { uppercaseFirstLetter } from '../../../helpers/string.helper';

function Account() {
  const profileTabs = [
    'personal information',
    'password',
    'billing information',
    'notifications',
  ];

  const [avatar, setAvatar] = useState(null);

  const handleChangeAvatar = async (e: any) => {
    const file = e.target.files[0];
    if (file) setAvatar(file);
  };
  const [tabCurrent, setTab] = useState(0);
  const [isShow, setShow] = useState(false);
  const [selectedState, setSelectedState] = useState(null);

  const [isEditPersonalInfo, setIsEditPersonalInfo] =
      React.useState<boolean>(false);
  const [isEditContactDetails, setIsEditContactDetails] =
      React.useState<boolean>(false);

  const [userInfo, setUserInfo] = useState();
  const [updatedInfo, setUpdatedInfo] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    gender: '',
    givenName: '',
    homeLocationId: '',
    imageUrl: '',
    joinLocationId: '',
    memberId: '',
    postCode: '',
    state: '',
    suburb: '',
    surname: '',
    title: '',
    workPhone: '',
    secondaryEmail: '',
    middleName: '',
    mobileNumber: '',
    emergencyContactRelation2: '',
    emergencyContactRelation: '',
    emergencyContactNumber2: '',
    emergencyContactNumber: '',
    emergencyContactName2: '',
    emergencyContactName: '',
    emergencyContactEmail2: '',
    emergencyContactEmail: '',
    country: '',
    address: '',
  });

  const initUpdatedInfo = React.useRef<any>(null);
  const initGender = React.useRef<any>(null);
  const initState = React.useRef<any>(null);

  const [isShowGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState();
  const handleSelectedGender = (value) => {
    setUpdatedInfo({ ...updatedInfo, gender: value });
    setSelectedGender(value);
  };
  const handleSelectedState = (value) => {
    value != 'NONE' ? setSelectedState(value) : setSelectedState(null);
  };

  const color = {
    'club lime': '#B3E833',
    'hiit republic': '#FF6900',
    'ground up': '#A4ACD7',
  };
  const [qrMember] = useLazyQuery(fullMemberData);

  const fetchData = async () => {
    const userData = JSON.parse(localStorage.getItem('currentUserDetail'));
    await Auth.currentAuthenticatedUser()
        .then((user) => {
          qrMember({
            variables: {
              memberId: user.attributes['custom:memberId'],
            },
          })
              .then(async (res) => {
                const userData = res.data.getMember;
                localStorage.setItem('currentUserDetail', JSON.stringify(userData));
                const imgUrl = userData?.imageUrl
                    ? await Storage.get(userData.imageUrl)
                    : '/icons/default-avatar.svg';
                const userInfoHandle = {
                  memberId: userData.memberId,
                  // aliasMemberId: userData.aliasMemberId,
                  email: userData.email,
                  secondaryEmail: userData.secondaryEmail,
                  // title: userData.title,
                  givenName: userData.givenName,
                  surname: userData.surname,
                  mobileNumber: userData.mobileNumber,
                  workPhone: userData.workPhone,
                  homePhone: userData.homePhone,
                  emergencyContactName: userData.emergencyContactName,
                  emergencyContactEmail: userData.emergencyContactEmail,
                  emergencyContactNumber: userData.emergencyContactNumber,
                  emergencyContactRelation: userData.emergencyContactRelation,
                  emergencyContactName2: userData.emergencyContactName2,
                  emergencyContactEmail2: userData.emergencyContactEmail2,
                  emergencyContactNumber2: userData.emergencyContactNumber2,
                  emergencyContactRelation2: userData.emergencyContactRelation2,
                  imageUrl: imgUrl,
                  address: userData.address,
                  suburb: userData.suburb,
                  state: userData.state,
                  postCode: userData.postCode,
                  country: userData.country,
                  // joinDate: userData.joinDate,
                  // getLinksSms: userData.getLinksSms,
                  // accepted: userData.accepted,
                  // brandId: userData.brandId,
                  // type: userData.type,
                  // role: userData.role,
                  // staffKind: userData.staffKind,
                  // isBlocked: userData.isBlocked,
                  homeLocationId: userData.homeLocationId,
                  // firstName: userData?.givenName?.split(" ")[0],
                  // lastName: userData?.givenName?.split(" ")[2],
                  middleName: userData.middleName,
                  dob: new Date(userData.dob.split('-').reverse().join('-')),
                  gender: userData.gender,
                  contracts: userData.contracts,
                };
                setSelectedGender(userInfoHandle.gender);
                setSelectedState(userInfoHandle.state);
                setUpdatedInfo(userInfoHandle);

                // INIT
                initUpdatedInfo.current = { ...userInfoHandle };
                initGender.current = userInfoHandle.gender;
                setUserInfo(userInfoHandle);

              })
              .catch((err) => console.log(err));
        })
        .catch(() => navigate('/login'));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const setRefresh = useContext(AuthContext).setRefreshData;

  const [updateMemberApi] = useMutation(updateMemberInfo);
  const [isLoading, setLoading] = useState(false);

  const handleUpdateInfo = async () => {
    try {
      setLoading(true);
      const payload = {};

      for (let i = 0; i < Object.keys(userInfo).length; i++) {
        if (!(Object.values(updatedInfo)[i] === Object.values(userInfo)[i])) {
          payload[Object.keys(userInfo)[i]] = Object.values(updatedInfo)[i];
        }
      }
      // if (payload.firstName || payload.middleName || payload.lastName) {
      //     payload.givenName =
      //         updatedInfo.firstName + " " + updatedInfo.middleName + " " + updatedInfo.lastName;
      //     delete payload.firstName;
      //     delete payload.lastName;
      //     delete payload.middleName;
      // }
      if (payload.dob)
        payload.dob = new Date(updatedInfo.dob).toISOString().split('T')[0];
      // const payload = { ...updatedInfo };
      // let dob = new Date(updatedInfo.dob);
      // dob = dob.setDate(dob.getDate() + 1);
      // dob = new Date(dob);
      // payload.dob = dob;
      // payload.gender = payload.gender.toUpperCase();

      // payload.givenName =
      //   payload.firstName + " " + payload.middleName + " " + payload.lastName;
      // delete payload.firstName;
      // delete payload.lastName;
      // delete payload.middleName;
      // delete payload.imageUrl;
      payload.memberId = updatedInfo.memberId;

      if (
          !updatedInfo.emergencyContactName ||
          !updatedInfo.emergencyContactNumber ||
          !updatedInfo.emergencyContactRelation
      ) {
        toastError('Please provide at least one emergency contact');
        return;
      }
      const data = await updateMemberApi({ variables: { input: payload } });
      // toast.success("updated successfully");
      toastSuccess('Updated successfully');
      setTimeout(() => fetchData(), 1000);
      setIsEditPersonalInfo(false);
      setIsEditContactDetails(false);
    } catch (e) {
      toastError(e.message);
    } finally {
      setRefresh(true);
      setLoading(false);
    }
  };
  const handleChange = (e) => {
    setUpdatedInfo({ ...updatedInfo, [e.target.name]: e.target.value });
  };
  const handleUploadImg = async (e) => {
    // const file = e.target.files[0];
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      file = reader.result;
    };
    // reader.readAsDataURL(file);

    if (file) {
      try {
        const uuid = uuidv4() + '.' + file.name.split('.')[1];
        const payload = { ...updatedInfo };
        const name = `memberImages/${payload.memberId}/profile/${uuid}`;

        await Storage.put(name, file);
        const data = await Storage.get(name);
        await updateMemberApi({
          variables: { input: { imageUrl: name, memberId: payload.memberId } },
        });
        setUpdatedInfo({ ...updatedInfo, imageUrl: data });
        setAvatar(data);
        toastSuccess('Successfully post your file');
        e.target.value = '';
        setRefresh(true);
      } catch (error) {
        console.log('Error uploading file: ', error);
        toastError('Error uploading file');
      }
    }
  };
  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (tabCurrent) {
      case 0:
        return 'personal information';
      case 1:
        return 'reset password';
      case 2:
        return 'billing information';
      case 3:
        return 'notifications';
      default:
        return 'NOT FOUND';
    }
  }, [tabCurrent]);

  const { width } = useWindowSize();

  return (
      <LayoutDashboard>
        {isLoading && (
            <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
              <div className=" animate-spin h-12 w-12  border-r-8 border-8 border-gray border-r-white rounded-full m-auto "></div>
            </div>
        )}

        <div className="px-0 py-0 sm:px-[61.5px] sm:py-[50px]">
          {width && width >= breakpoints.sm && (
              <>
                <Link to="/dashboard">
                  <div className=" flex gap-[8px] items-center ">
                    <div className="group w-[41px] h-[48px] p-[7px] pl-0">
                      <div className="border overflow-hidden border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                        <img
                            className="duration-300 transition-all group-hover:-translate-x-1/2 h-[15px] w-[15px]"
                            src="/icons/arrow-left-short.svg"
                        />
                      </div>
                    </div>
                    <span className="font-abcmonument uppercase text-sm leading-[19px] text-white tracking-widest">
                  dashboard
                </span>
                  </div>
                </Link>
                <p className="text-white text-[40px] font-black leading-[90%] mt-[20px] mb-[40px] uppercase font-abcgravity">
                  my profile
                </p>
                <div className="flex gap-x-[9px] gap-y-0 w-full">
                  {profileTabs.map((i, idx) => (
                      <div
                          key={idx}
                          className={`font-abcmonument text-white tracking-[1.4px] font-sm text-center rounded-t-md text-sm py-[7px] cursor-pointer uppercase ${
                              idx === 0 ? 'w-[31.3%]' : ''
                          } ${idx === 1 ? 'w-[16.3%]' : ''} ${
                              idx === 2 ? 'w-[29%]' : ''
                          } ${idx === 3 ? 'w-[23.4%]' : ''} ${
                              idx === tabCurrent
                                  ? 'bg-brand-clime !text-black font-bold'
                                  : 'bg-[#414141] font-medium hover:bg-[#a0a0a0]'
                          }`}
                          onClick={() => setTab(idx)}
                      >
                        {i}
                      </div>
                  ))}
                </div>
                <div className="w-full bg-brand-clime h-[2px]"></div>
              </>
          )}
          <div className="sm:hidden block px-6 pt-1 pb-3">
            <div className="flex items-center justify-between gap-x-2">
              <p className=" font-bold text-white text-[25px] leading-[22.5px] uppercase">
                {renderHeadingTitleMobile}
              </p>
              {tabCurrent !== 0 && (
                  <div
                      onClick={() => setTab(0)}
                      className="cursor-pointer h-[14px]"
                  >
                    <img
                        className="block w-full object-contain"
                        src="/icons/icon_back.png"
                        alt="icon-back"
                    />
                  </div>
              )}
            </div>
          </div>
          {tabCurrent == 0 && (
              <>
                <div className="sm:mt-[30px] w-full border border-transparent sm:border-[rgba(255,255,255,0.4)] borer-[1.5px] rounded-md  bg-transparent sm:bg-[#13181b] sm:hover:bg-[#1F2528] mt-3 hover:bg-transparent">
                  <div className="sm:flex sm:h-[57px] h-[28px] items-center justify-between hidden border-b border-white">
                    <p className="border-b border-white   px-6  py-[20px]  h-[57px]   border-b-white text-white font-bold  flex justify-between items-center uppercase">
                      personal information
                    </p>
                  </div>

                  {/* MOBILE */}
                  <div className="block sm:hidden px-6">
                    <div className="flex items-start gap-x-4">
                      <div className="w-[55px] h-[60px] rounded-[5px] overflow-hidden">
                        <img
                            className={`block w-full ${
                                updatedInfo?.imageUrl
                                    ? 'object-contain'
                                    : 'object-cover'
                            }`}
                            src={
                              updatedInfo?.imageUrl
                                  ? updatedInfo?.imageUrl
                                  : '/icons/default-avatar.svg'
                            }
                        />
                      </div>
                      <div className="flex flex-col">
                        <p className="text-base text-white font-bold uppercase leading-[21.36px]">
                          {updatedInfo?.surname || ''}
                        </p>
                        <div className="flex gap-x-6">
                          <div className="flex flex-col">
                            <p className="text-textSecondary text-sm leading-[160%]">
                              D.O.B.
                            </p>
                            <p className="text-white text-base leading-[133.5%]">
                              {moment(updatedInfo?.dob).format('MM/YYYY')}
                            </p>
                          </div>
                          <div className="flex flex-col">
                            <p className="text-textSecondary text-sm leading-[160%]">
                              Gender
                            </p>
                            <p className="text-white text-base leading-[133.5%]">
                              {uppercaseFirstLetter(selectedGender || '')}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <PrimaryOutlinedButton className="uppercase rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] w-[152px] mt-6 flex items-center justify-center">
                      Update
                    </PrimaryOutlinedButton>
                  </div>

                  {/* DESKTOP */}
                  <div className="sm:grid hidden grid-cols-8 px-[30px] py-[27px]">
                    <div className="col-span-2 ">
                      <div className="w-full h-full max-w-[205px] max-h-[227px] flex items-center justify-center pr-[27px] mx-auto">
                        <div className="group relative w-full h-full">
                          <img
                              className="block w-full h-full object-cover"
                              src={
                                updatedInfo?.imageUrl
                                    ? updatedInfo?.imageUrl
                                    : '/icons/default-avatar.svg'
                              }
                          />
                          <div className="overlayAvatar hidden group-hover:flex items-center justify-center absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-[#13181B] bg-opacity-80 border border-white rounded-md cursor-pointer">
                            <label
                                htmlFor="uploadNewImage"
                                className="text-xs font-semibold uppercase rounded-md cursor-pointer bg-transparent px-3 py-2 text-white border border-solid border-white transition-all duration-300 hover:bg-brand-clime hover:border-brand-clime"
                            >
                              UPLOAD NEW PHOTO
                            </label>
                            <input
                                id="uploadNewImage"
                                type="file"
                                className="hidden"
                                accept="image/png, image/jpeg, image/png"
                                onChange={(e) => handleUploadImg(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-6 grid grid-rows-3 gap-y-6">
                      <div className=" grid grid-cols-2 gap-5">
                        <InputAnimated
                            onChange={handleChange}
                            label="First name"
                            type="text"
                            // value="string"
                            value={updatedInfo.surname}
                            name="surname"
                            disabled
                        />
                        {/* <InputAnimated
                                            onChange={handleChange}
                                            label="Middle name"
                                            type="text"
                                            // value="string"
                                            value={updatedInfo.middleName}
                                            name="middleName"
                                        /> */}
                        <InputAnimated
                            onChange={handleChange}
                            label="Last name"
                            type="text"
                            value={updatedInfo.givenName}
                            // value="string"
                            name="givenName"
                            disabled
                        />
                      </div>
                      <div className=" grid grid-cols-2 gap-5">
                        <div className="relative flex items-center justify-between py-2 h-[33px] mt-[9px] border-b border-[rgba(255,255,255,0.4)]">
                      <span className="datepicker-toggle">
                        <Flatpickr
                            onChange={(dateObj, dateStr) => {
                              setUpdatedInfo({
                                ...updatedInfo,
                                dob: new Date(dateObj),
                              });
                            }}
                            // defaultDate: userInfo?.dob,
                            value={updatedInfo?.dob}
                            placeholder="D/M/Y"
                            options={{
                              disableMobile: 'true',
                              dateFormat: 'd/m/Y',
                              enableTime: false,
                              time_24hr: false,
                            }}
                            disabled
                        />
                      </span>
                          <label className="text-[12px] absolute left-0 text-gray top-[-9px]">
                            DOB
                          </label>
                          {/* <img className=" sticky" src={dateIcon} /> */}
                        </div>
                        {/* <InputAnimated
                                            label="Gender"
                                            type="text"
                                            onChange={handleChange}
                                            value={updatedInfo?.gender}
                                            name="gender"
                                        /> */}
                        <div className="relative ">
                          <div
                              className={`flex items-center mt-[15px] pr-[12px] pb-[6px] bg-transparent border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1`}
                              // onClick={() => {
                              //   if (!isEditPersonalInfo) return;
                              //   setShowGender(!isShowGender);
                              // }}
                          >
                            <div className="bg-transparent h-[20px] outline-none w-full  truncate text-white">
                          <span
                              className={`form__label text-gray font-abcmonument absolute  transition-all duration-300
                            ${
                                  selectedGender
                                      ? 'top-0 left-0 text-[11px]'
                                      : 'text-sm top-[12px] left-0'
                              }
                            `}
                          >
                            Gender
                          </span>
                              <span className="text-sm font-abcmonument font-medium uppercase tracking-widest">
                            {selectedGender}
                          </span>
                            </div>
                            <div
                                className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                                    isShowGender
                                        ? 'border-brand-clime rotate-[135deg]'
                                        : 'border-gray'
                                }`}
                            ></div>
                          </div>
                          <div
                              className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${
                                  isShowGender ? '' : 'hidden'
                              }`}
                          >
                            {['MALE', 'FEMALE']?.map((item: any, index: number) => (
                                <div
                                    key={index}
                                    className="cursor-pointer uppercase font-abcmonument font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-brand-clime"
                                    onClick={() => {
                                      setShowGender(false);
                                      handleSelectedGender(item);
                                      // setDropdownSelected(true);
                                    }}
                                >
                                  {item}
                                </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* <div className="flex items-center gap-[18px]">
                    <div
                      onClick={() => handleUpdateInfo()}
                      className="w-[131.48px] hover:bg-brand-clime hover:border-brand-clime hover:text-white  h-[37px] flex items-center justify-center font-bold text-white font-abcmonument text-[11.2px] lg:h-[37px]  uppercase rounded border border-white cursor-pointer"
                    >
                      update
                    </div>
                    <div
                      className={`w-[143.48px] hover:bg-[#DE0D0D] hover:border-[#DE0D0D] hover:text-white  h-[37px] flex items-center justify-center font-bold text-white font-abcmonument text-[11.2px] lg:h-[37px]  uppercase rounded border border-white cursor-pointer ${!isEditPersonalInfo
                        ? ' pointer-events-none opacity-40 '
                        : '  '
                        }`}
                      onClick={() => {
                        setIsEditPersonalInfo(false);
                        setUpdatedInfo(initUpdatedInfo.current);
                        setSelectedGender(initGender.current);
                      }}
                    >
                      cancel changes
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="sm:mt-[30px] w-full sm:border-[rgba(255,255,255,0.4)] border-[1.5px] rounded-md bg-transparent border-transparent sm:bg-[#13181b] sm:hover:bg-[#1F2528] hover:bg-transparent mt-[50px]">
                  <div className="flex items-center sm:h-[57px] h-[28px] justify-between border-transparent border-b sm:border-white ">
                    <p className="   px-6  py-[20px]  h-[57px]  text-[25px] sm:text-sm text-white font-medium  flex justify-between items-center uppercase">
                      contact details
                    </p>
                    <div
                        className={`hidden sm:block rounded-full cursor-pointer !px-4 ${
                            isEditContactDetails
                                ? 'pointer-events-none opacity-40'
                                : ' '
                        }`}
                        onClick={() => setIsEditContactDetails(true)}
                    >
                      <img
                          className="block w-5 h-5"
                          src="/icons/pencil-white.png"
                          alt="edit"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-1 sm:grid-cols-8 px-6 sm:px-[30px] pb-0 pt-[15px] !sm:py-[27px] gap-[18px] !sm:gap-y-[30px] !sm:gap-x-[18px] !gap-y-2">
                    <div className="col-span-4">
                      <InputAnimated
                          label="Email address (primary)"
                          type="text"
                          value={updatedInfo?.email}
                          // value="string"
                          onChange={handleChange}
                          name="email"
                          disabled={true}
                          noUnderline={!isEditContactDetails}
                      />
                    </div>
                    <div className="col-span-4">
                      <InputAnimated
                          label="Email address (secondary)"
                          type="text"
                          value={updatedInfo?.secondaryEmail}
                          onChange={handleChange}
                          // value="string"
                          name="secondaryEmail"
                          disabled={!isEditContactDetails}
                          noUnderline={!isEditContactDetails}
                      />
                    </div>
                    <div className="col-span-4 grid-cols-1 grid sm:grid-cols-2 sm:gap-[18px] gap-2">
                      <InputAnimated
                          label="Mobile number"
                          type="text"
                          value={updatedInfo?.mobileNumber}
                          onChange={handleChange}
                          // value="string"
                          name="mobileNumber"
                          disabled={!isEditContactDetails}
                          noUnderline={!isEditContactDetails}
                      />
                      <InputAnimated
                          label="Other number"
                          type="number"
                          value={updatedInfo?.mobileNumber2}
                          onChange={handleChange}
                          // value="string"
                          name="mobileNumber2"
                          disabled={!isEditContactDetails}
                          noUnderline={!isEditContactDetails}
                      />
                    </div>
                    <div className="col-span-4 grid-cols-1 grid sm:grid-cols-2 sm:gap-[18px] gap-2">
                      <InputAnimated
                          label="Suburb"
                          type="text"
                          value={
                            updatedInfo?.suburb == 'undefined'
                                ? ''
                                : uppercaseFirstLetter(updatedInfo?.suburb)
                          }
                          onChange={handleChange}
                          // value="string"
                          name="suburb"
                          disabled={!isEditContactDetails}
                          noUnderline={!isEditContactDetails}
                      />
                      <div className="grid sm:grid-cols-2 sm:gap-[18px] grid-cols-4 gap-3">
                        <div className="relative ">
                          <div
                              className={`flex items-center mt-[20px] pr-[12px] pb-[7px]  border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1 ${
                                  !isEditContactDetails ? 'border-transparent' : ' '
                              }`}
                              onClick={() => {
                                if (!isEditContactDetails) return;
                                setShow(!isShow);
                              }}
                          >
                            <div className=" h-[20px] outline-none w-full  truncate text-white">
                          <span
                              className={`form__label text-[#848484] font-abcmonument absolute  transition-all duration-300
                            ${
                                  selectedState
                                      ? 'top-0 left-0 text-[11px]'
                                      : 'text-sm top-[15px] left-0'
                              }
                            `}
                          >
                            State
                          </span>
                              <span className="text-sm font-abcmonument font-medium uppercase tracking-widest">
                            {selectedState}
                          </span>
                              {/* {selectedState?.map((i, index) => (
                            <span
                              key={index}
                              className="text-sm font-abcmonument font-medium uppercase tracking-widest"
                              style={{ color: color[i] }}
                            >
                              {i}
                            </span>
                          ))} */}
                            </div>
                            {isEditContactDetails && (
                                <div
                                    className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                                        isShow
                                            ? 'border-brand-clime rotate-[135deg]'
                                            : 'border-gray'
                                    }`}
                                ></div>
                            )}
                          </div>
                          <div
                              className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${
                                  isShow ? '' : 'hidden'
                              }`}
                          >
                            {['NONE', 'ACT', 'NSW', 'SYD', 'QUEEN']?.map(
                                (item: any, index: number) => (
                                    <div
                                        key={index}
                                        className="cursor-pointer uppercase font-abcmonument font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-brand-clime"
                                        onClick={() => {
                                          setShow(false);
                                          handleSelectedState(item);
                                          // setDropdownSelected(true);
                                        }}
                                    >
                                      {item}
                                    </div>
                                )
                            )}
                          </div>
                        </div>
                        <InputAnimated
                            label="Postcode"
                            type="number"
                            value={updatedInfo?.postCode}
                            // value="string"
                            onChange={handleChange}
                            name="postCode"
                            disabled={!isEditContactDetails}
                            noUnderline={!isEditContactDetails}
                        />
                      </div>
                    </div>
                  </div>
                  <p className="border-b font-abcmonument sm:border-white font-bold sm:text-[16px] tracking-[1.4px] border-b-transparent text-white px-6 py-0 sm:py-[20px] uppercase leading-[140%] text-[25px]  sm:mt-0 mt-12">
                    emergency contact details
                  </p>
                  <div className="grid grid-cols-1 sm:grid-cols-8 pt-[15px] pb-0 sm:pb-[27px] px-6 sm:px-[30px] !sm:py-[27px] sm:gap-[18px] sm:gap-y-[30px] gap-x-[18px] gap-y-2">
                    <h2 className="block sm:hidden w-full sm:col-span-1 col-span-4 uppercase text-base text-brand-clime font-bold leading-[160%] pb-1 border-b border-brand-clime">
                      Contact 1
                    </h2>
                    <div className="col-span-4">
                      <InputAnimated
                          label="Contact 1 - name*"
                          type="text"
                          value={updatedInfo?.emergencyContactName}
                          // value="string"
                          onChange={handleChange}
                          name="emergencyContactName"
                          disabled={!isEditContactDetails}
                          noUnderline={!isEditContactDetails}
                      />
                    </div>
                    <div className="col-span-4 grid grid-cols-1 sm:grid-cols-3 !sm:gap-[18px] gap-y-2">
                      <div className="sm:col-span-2 col-span-1">
                        <InputAnimated
                            label="Mobile number*"
                            type="text"
                            // value="string"
                            value={updatedInfo?.emergencyContactNumber}
                            onChange={handleChange}
                            name="emergencyContactNumber"
                            disabled={!isEditContactDetails}
                            noUnderline={!isEditContactDetails}
                        />
                      </div>
                      <div>
                        <InputAnimated
                            label="Relationship*"
                            type="text"
                            value={updatedInfo?.emergencyContactRelation}
                            // value="string"
                            onChange={handleChange}
                            name="emergencyContactRelation"
                            disabled={!isEditContactDetails}
                            noUnderline={!isEditContactDetails}
                        />
                      </div>
                    </div>
                    <h2 className="block sm:hidden w-full sm:col-span-1 col-span-4 uppercase text-base text-brand-clime font-bold leading-[160%] pb-1 border-b border-brand-clime">
                      Contact 2
                    </h2>
                    <div className="col-span-4 ">
                      <InputAnimated
                          label="Contact 2 - name"
                          value={updatedInfo?.emergencyContactName2}
                          type="text"
                          // value="string"
                          onChange={handleChange}
                          name="emergencyContactName2"
                          disabled={!isEditContactDetails}
                          noUnderline={!isEditContactDetails}
                      />
                    </div>
                    <div className="col-span-4 grid-cols-1 grid sm:grid-cols-3 !sm:gap-[18px] gap-y-2">
                      <div className="sm:col-span-2 col-span-1">
                        <InputAnimated
                            label="Mobile number"
                            type="text"
                            value={updatedInfo?.emergencyContactNumber2}
                            // value="string"
                            onChange={handleChange}
                            name="emergencyContactNumber2"
                            disabled={!isEditContactDetails}
                            noUnderline={!isEditContactDetails}
                        />
                      </div>
                      <div>
                        <InputAnimated
                            label="Relationship"
                            type="text"
                            // value="string"
                            value={updatedInfo?.emergencyContactRelation2}
                            onChange={handleChange}
                            name="emergencyContactRelation2"
                            disabled={!isEditContactDetails}
                            noUnderline={!isEditContactDetails}
                        />
                      </div>
                    </div>
                    {/* MOBILE */}
                    <div className="block sm:hidden col-span-4">
                      {isEditContactDetails ? (
                          <div className="flex items-center justify-between gap-x-5 w-full mt-3">
                            <PrimaryOutlinedButton className="flex w-1/2 uppercase rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] justify-center items-center">
                              Save
                            </PrimaryOutlinedButton>
                            <PrimaryOutlinedButton
                                onClick={() => setIsEditContactDetails(false)}
                                className="flex w-1/2 uppercase rounded-[5px] text-base font-bold leading-[160%] min-h-[34px] h-[34px] justify-center items-center !text-textError border-textError hover:border-textError hover:bg-textError hover:!text-black"
                            >
                              Cancel
                            </PrimaryOutlinedButton>
                          </div>
                      ) : (
                          <PrimaryOutlinedButton
                              onClick={() => setIsEditContactDetails(true)}
                              className="flex uppercase rounded-[5px] text-base font-bold leading-[160%] text-white min-h-[34px] h-[34px] w-[152px] mt-3 justify-center items-center"
                          >
                            Update
                          </PrimaryOutlinedButton>
                      )}
                    </div>
                    {/* DESKTOP */}
                    <div className="hidden sm:flex justify-start gap-[18px] col-span-3">
                      <div
                          className={`w-[131.48px] hover:bg-brand-clime hover:border-brand-clime hover:text-black  h-[37px] flex items-center justify-center font-bold text-white font-abcmonument text-[11.2px] lg:h-[37px]  uppercase rounded border border-white cursor-pointer ${
                              !isEditContactDetails
                                  ? ' pointer-events-none opacity-40 '
                                  : '  '
                          }`}
                          onClick={() => handleUpdateInfo()}
                      >
                        update
                      </div>
                      <div
                          className={`w-[143.48px] hover:bg-[#DE0D0D] hover:border-[#DE0D0D] hover:text-white  xl:h-[37px] flex items-center justify-center font-bold text-white font-abcmonument text-[11.2px] h-[37px]  uppercase rounded border border-white cursor-pointer ${
                              !isEditContactDetails
                                  ? ' pointer-events-none opacity-40 '
                                  : '  '
                          }`}
                          onClick={() => {
                            setIsEditContactDetails(false);
                            setUpdatedInfo(initUpdatedInfo.current);
                          }}
                      >
                        cancel changes
                      </div>
                    </div>
                  </div>
                </div>
                <div className="buttonGroup flex sm:hidden flex-col gap-y-5 sm:mt-4 sm:px-0 px-6 sm:pb-0 pb-20 mt-16">
                  <PrimaryOutlinedButton
                      onClick={() => setTab(1)}
                      className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    reset Password
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                      onClick={() => setTab(2)}
                      className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    billing Information
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                      onClick={() => setTab(3)}
                      className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    Notifications
                  </PrimaryOutlinedButton>
                </div>
              </>
          )}
          {tabCurrent == 1 && <Password />}
          {tabCurrent == 2 && <Biling userInfo={userInfo} />}
          {tabCurrent == 3 && <Notification />}

          {/* {tabCurrent == 2 && <Billing />} */}
        </div>
      </LayoutDashboard>
  );
}

export default Account;
