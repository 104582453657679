import React, { useState } from "react";
import ggPlay from "../../assets/images/ggplay.png";
import appStore from "../../assets/images/appstore.png";
import ButtonActive from "../../common/buttons/Active";
import InputBase from "../../common/inputs/InputBase";
import useFetch from "../../hooks/useFetch";

function MembersAppBanner() {
  const { data, loading } = useFetch({
    params: "global",
    populate: "appPromotion, appPromotion.image,appPromotion.items.icon",
  });
  function createMarkup(data) {
    return { __html: data };
  }

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
  });
  const [errText, setErrText] = useState({ email: "" });
  // const setOpen = useContext(RegisterContext).setOpen;

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  return (
    <>
      {data && !loading && (
        <>
          <div className=" xl:block hidden">
            <div className="flex items-center justify-between mx-[4.9%]">
              <div className="w-1/3">
                <div
                  className="font-bold text-[62px] leading-[100%] text-white -tracking-[0.03em]"
                  dangerouslySetInnerHTML={createMarkup(
                    data.data.attributes.appPromotion.title
                  )}
                >
                  {/* <h1 className="outline-text-1">
              take control <br /> with our
            </h1>
            <h1>members app</h1> */}
                </div>
                <div className="flex gap-3">
                  <a href={data.data.attributes.appPromotion.appStoreUrl}>
                    <img
                      src={appStore}
                      className="mt-[24px] w-[148px] ml-2 mb-[20px]"
                    />
                  </a>
                  <a href={data.data.attributes.appPromotion.playStoreUrl}>
                    <img
                      src={ggPlay}
                      className=" mt-[20px] w-[170px] h-[52px] object-cover"
                    />
                  </a>
                </div>
              </div>
              <div className="">
                <img
                  className="h-[520px]"
                  src={
                    data.data.attributes?.appPromotion.image?.data.attributes
                      .url
                  }
                  alt="mobile"
                />
              </div>
              <div className="w-1/3">
                <div className="mt-[32px]">
                  {data.data.attributes?.appPromotion.items.map((i) => (
                    <div className="my-[14.1px] flex gap-[16px] gap-y-[32px] items-center">
                      <img
                        className="h-[38.7px]"
                        src={i.icon.data.attributes.url}
                        alt="booking"
                      />
                      <p className="text-white text-base font-semibold">
                        {i.text}
                      </p>
                    </div>
                  ))}
                  {/* <div>
                  <img className="h-[38.7px]" src={booking} alt="booking" />
                </div>
                <div className="my-[28.3px]">
                  <img className="h-[38.7px]" src={track} alt="booking" />
                </div>
                <div id="freepass">
                  <img className="h-[38.7px]" src={acheieve} alt="booking" />
                </div> */}
                </div>
              </div>
            </div>
            <div className="text-white  px-[70px] flex justify-between mt-[75px] mb-[100px]">
              <div className="text-[60px] leading-[110%] w-[40%] flex-col justify-between">
                <p className="text-white font-bold text-[55px] -tracking-[0.03em]">
                  get your
                  <br />
                  <span className="text-brand-orange font-bold">
                    {" "}
                    free pass.
                  </span>
                </p>
                <p className="text-[24px] font-bold mt-[50px]">
                  <span className="text-white">i want to visit</span>
                  <span className="text-brand-orange mx-2">braddon, act </span>
                  <span className="text-gray underline graphik-regular text-base">
                    change
                  </span>
                </p>
              </div>

              <div id="#freepass">
                <div className="grid grid-rows-2 grid-flow-col gap-x-[41px] gap-y-[2px]">
                  <InputBase
                    width="315px"
                    type="string"
                    holder="First name*"
                    isRequired={true}
                    isShowRequired={formControlled.firstName}
                    onchange={(e) =>
                      setForm({ ...form, firstName: e.target.value })
                    }
                  />
                  <InputBase
                    width="315px"
                    type="string"
                    holder="Last name*"
                    isRequired={true}
                    isShowRequired={formControlled.lastName}
                    onchange={(e) =>
                      setForm({ ...form, lastName: e.target.value })
                    }
                  />
                  <InputBase
                    width="315px"
                    type="email"
                    holder="Email address*"
                    isRequired={true}
                    isShowRequired={formControlled.email}
                    onchange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    errorText={errText.email}
                  />
                  <InputBase
                    width="315px"
                    type="string"
                    holder="Phone number*"
                    isRequired={true}
                    isShowRequired={formControlled.phoneNumber}
                    onchange={(e) =>
                      setForm({ ...form, phoneNumber: e.target.value })
                    }
                  />
                </div>
                <div
                  className="float-right mt-[28px]"
                  onClick={() => {
                    submit();
                  }}
                >
                  <ButtonActive text="lets go" height={53} />
                </div>
              </div>
            </div>
          </div>
          <div className="xl:hidden block">
            <div className="flex flex-col items-center justify-between mx-[4.9%]">
              <div className="mb-[27px]">
                <img
                  className="h-[319px]"
                  src={
                    data.data.attributes?.appPromotion.image?.data.attributes
                      .url
                  }
                  alt="mobile"
                />
              </div>
              <div className="w-full">
                <div
                  className="font-bold text-[62px] leading-[100%] md:text-center text-white -tracking-[0.03em]"
                  dangerouslySetInnerHTML={createMarkup(
                    data.data.attributes.appPromotion.title
                  )}
                ></div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <div className="mt-[32px]">
                    {data.data.attributes?.appPromotion.items.map((i) => (
                      <div className="my-[14.1px] flex gap-[16px] items-center">
                        <img
                          className="h-[38.7px]"
                          src={i.icon.data.attributes.url}
                          alt="booking"
                        />
                        <p className="text-white text-base font-semibold">
                          {i.text}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-1/2 flex flex-col justify-end ">
                  <a href={data.data.attributes.appPromotion.appStoreUrl}>
                    <img
                      src={appStore}
                      className="mt-[24px] w-[148px] ml-2 mb-[20px]"
                    />
                  </a>
                  <a href={data.data.attributes.appPromotion.playStoreUrl}>
                    <img
                      src={ggPlay}
                      className="  w-[170px] h-[52px] object-cover"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="text-white mt-[20px] md:flex-col flex flex-col justify-between mx-[26px] ">
              <div className="text-[60px] leading-[110%] w-full flex-col justify-between">
                <p className="text-white font-bold text-[55px] -tracking-[0.03em] md:text-center">
                  get your
                  <br />
                  <span className="text-brand-orange font-bold">
                    {" "}
                    free pass.
                  </span>
                </p>
                <p className="text-[16px] font-bold md:text-center">
                  <span className="text-white">i want to visit</span>
                  <span className="text-brand-orange mx-2">braddon, act </span>
                  <span className="text-gray underline graphik-regular text-base">
                    change
                  </span>
                </p>
              </div>

              <div id="#freepass">
                <div className=" gap-x-[41px] gap-y-[2px]  md:items-center md:grid md:grid-cols-2 md:grid-flow-row ">
                  <InputBase
                    width="100%"
                    type="string"
                    holder="First name*"
                    isRequired={true}
                    isShowRequired={formControlled.firstName}
                    onchange={(e) =>
                      setForm({ ...form, firstName: e.target.value })
                    }
                  />
                  <InputBase
                    width="100%"
                    type="string"
                    holder="Last name*"
                    isRequired={true}
                    isShowRequired={formControlled.lastName}
                    onchange={(e) =>
                      setForm({ ...form, lastName: e.target.value })
                    }
                  />
                  <InputBase
                    width="100%"
                    type="email"
                    holder="Email address*"
                    isRequired={true}
                    isShowRequired={formControlled.email}
                    onchange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                    errorText={errText.email}
                  />
                  <InputBase
                    width="100%"
                    type="string"
                    holder="Phone number*"
                    isRequired={true}
                    isShowRequired={formControlled.phoneNumber}
                    onchange={(e) =>
                      setForm({ ...form, phoneNumber: e.target.value })
                    }
                  />
                </div>
                <div
                  className="flex justify-between mt-[28px]"
                  onClick={() => {
                    submit();
                  }}
                >
                  <p className="text-[14px] graphik-regular text-[rgba(255,255,255,0.5)]">
                    Required*
                  </p>
                  <ButtonActive text="lets go" height={53} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default MembersAppBanner;
