import React, { useRef, useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import { workAbout } from "../../../mock/work";
import ButtonActive from "../../common/buttons/Active";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ArrowRight45 from "../../assets/images/icons/ArrowRight45.svg";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import moment from "moment";
import InputBase from "../../common/inputs/InputBase";
import SelectOption from "../../common/dropdowns/SelectOption";
import workWithUs4 from "../../assets/images/work-with-us/work-with-us-4.png";
import workWithUs3 from "../../assets/images/work-with-us/work-with-us-3.png";
import workWithUs2 from "../../assets/images/work-with-us/work-with-us-2.png";
import workWithUs1 from "../../assets/images/work-with-us/work-with-us-1.png";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import { dropdown } from "../../../mock/dropdown";
import Dropdown from "../../common/dropdowns/Dropdown";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import SelectOutline from "../../common/dropdowns/SelectOutline";
import InputNumber from "../../common/inputs/InputNumber";
import { createMarkup } from "../../common/functions/setInnerHtml";
function WorkWithUs() {
  const { data, loading } = useFetch({
    params: "work-with-us-page",
    populate:
      "aboutUs,aboutUs.image,topBanner,topBanner.leftImage,topBanner.rightImage,value,value.image,applyJob",
  });

  const { data: jobs } = useFetch({
    params: "work-with-uses",
    populate: "image,studio",
  });


  const [appear, setAppear] = useState<boolean>(false);
  const [workId, setWorkId] = useState<string>("");

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    location: "",
    department: "",
    position: "",
    heard: "",
  });
  const [formControlled, setFormControlled] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    location: false,
    department: false,
    position: false,
    heard: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" && e != "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  const [isSelected, setSelected] = useState({
    location: [],
    "sort by": [],
    features: [],
    position: false,
  });
  const [idFile, setId] = useState<File | null>(null);

  return (
    <Layout>
      {loading && <LoadingScreen />}
      {!loading && (
        <>
          {/* Intro */}
          <div className="relative xl:h-[634px] w-full">
            <div className="xl:h-[630px] smallTablet:h-[575px] flex smallTablet:flex-row smallTablet:gap-[63px] gap-0 justify-between flex-col-reverse xl:grid xl:grid-cols-2 ">
              <div className="flex xl:justify-center xl:items-end">
                <img
                  src={
                    data?.data?.attributes?.topBanner?.leftImage?.data
                      ?.attributes?.url
                      ? data?.data?.attributes?.topBanner?.leftImage?.data
                        ?.attributes?.url
                      : workWithUs1
                  }
                />
              </div>
              <div className="justify-end items-start xl:flex">
                <div className="bg-[#1B1B1B]  xl:w-[695px] min-h-[546px]">
                  <img
                    className="ml-[56px] max-h-[370px]"
                    src={
                      data?.data?.attributes?.topBanner?.rightImage?.data
                        ?.attributes?.url
                        ? data?.data?.attributes?.topBanner?.rightImage?.data
                          ?.attributes?.url
                        : workWithUs2
                    }
                  />
                  <div className="mt-[56px] xl:mx-0 mx-6 xl:ml-[56px] uppercase">
                    <p className="text-brand-clime text-sm tracking-[0.01em] font-abcmonument xl:text-lg">
                      {data?.data?.attributes?.topBanner?.title
                        ? data?.data?.attributes?.topBanner?.title
                        : "work hard, play hard"}
                    </p>
                    <p className="text-effect text-transparent text-7xl xl:text-[98px] Gravity">
                      {data?.data?.attributes?.topBanner?.description
                        ? data?.data?.attributes?.topBanner?.description
                        : "Join the team"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="smallTablet:mt-[100px] mt-0 ">
            <p
              className="uppercase text-center Gravity text-effect text-transparent text-6xl xl:text-[100px] xl:leading-[90px]"
              dangerouslySetInnerHTML={createMarkup(
                data?.data?.attributes?.aboutUs?.textTop
                  ? data?.data?.attributes?.aboutUs?.textTop
                  : `When you pick a job
                    <br />
                    DOn’t pick a lemon`
              )}
            ></p>
            <div className="flex smallTablet:flex-row flex-col gap-[48px] xl:px-[174px] xl:py-0 py-6 lg:py-0 smallTablet:py-0 px-6">
              <div className="smallTablet:max-w-[433px] max-w-full flex flex-col  justify-center text-white">
                <p className="Gravity text-3xl xl:text-[65px] xl:leading-[58px] uppercase">
                  {data?.data?.attributes?.aboutUs?.title
                    ? data?.data?.attributes?.aboutUs?.title
                    : "ABOUT US"}
                </p>
                <p className="Mono text-xs  max-w-[438px] ">
                  {data?.data?.attributes?.aboutUs?.description
                    ? data?.data?.attributes?.aboutUs?.description
                    : `Club Lime is a fitness company like no other. With over 100
                    gyms Australia wide, we’re changing the way Aussies across the
                    country feel about the gym. With 24/7 access, affordable
                    memberships and no lock-in contracts we’re making it easier
                    than ever to make working out an accessible part of any
                    lifestyle. We offer world-class personal training, group
                    fitness and state-of-the-art-facilities to meet every Aussie
                    where they’re at on their fitness journey. Join us in making
                    fitness down right enjoyable.`}
                </p>
              </div>
              <div>
                <img
                  className="h-full object-cover"
                  src={
                    data?.data?.attributes?.aboutUs?.image?.data?.attributes
                      ?.url
                      ? data?.data?.attributes?.aboutUs?.image?.data?.attributes
                        ?.url
                      : workWithUs3
                  }
                />
              </div>
            </div>
            <p
              className="text-center uppercase Gravity text-6xl xl:leading-[90px] xl:text-[100px]"
              dangerouslySetInnerHTML={createMarkup(
                data?.data?.attributes?.aboutUs?.textBottom
                  ? data?.data?.attributes?.aboutUs?.textBottom
                  : `<span class="text-effect text-transparent Gravity">pick</span><span class="text-brand-clime Gravity">Club lime</span><br /><span class="text-effect text-transparent Gravity">it's a lifestyle</span>`
              )}
            ></p>
          </div>
          <div className="smallTablet:mt-[50px] mt-[100px] grid smallTablet:grid-cols-2 md:ml-6 ml-0">
            <div className="flex justify-end items-end">
              <img
                src={
                  data?.data?.attributes?.value?.image?.data?.attributes?.url
                    ? data?.data?.attributes?.value?.image?.data?.attributes
                      ?.url
                    : workWithUs4
                }
              />
            </div>
            <div className="flex flex-col justify-end items-start text-white pl-6 xl:pl-[50px] ">
              <p className="Gravity smallTablet:text-[65px] text-[55px] text-white uppercase">
                {data?.data?.attributes?.value?.title
                  ? data?.data?.attributes?.value?.title
                  : "Our values"}
              </p>
              <p className="lg:text-[98px] text-[64px] Gravity text-effect text-transparent lg:leading-[90px] leading-[65px] w-min">
                {data?.data?.attributes?.value?.description
                  ? data?.data?.attributes?.value?.description
                  : "Energetic Inclusive Innovative Empowering Playful"}
              </p>
            </div>
          </div>
          <div className="lg:mt-[113px] mt-[50px] max-w-[1300px] xl:mx-auto mx-6">
            <h1 className="max-w-[467px] lg:px-0 tracking-tight font-bold text-[40px] bold lg:text-[60px] leading-[110%] text-white text-left uppercase">
              current opportunities
            </h1>
            <div className="flex md:flex-row flex-col text-white mt-[45px]">
              <div className="h-[59px] md:w-[38%] w-full bg-black  flex justify-start items-center text-[18px] font-bold lg:gap-[22px] gap-2 pl-1 xl:pl-[59px] border-y border-r border-l border-brand-clime">
                <img className="" src={SearchIcon} />
                <input
                  id="studioSearch"
                  className="focus:outline-none placeholder:text-white md:placeholder:text-[16px] placeholder:text-[14px] w-full bg-black"
                  placeholder="City, suburb or postcode"
                  onKeyUp={() => { }}
                />
                {/* City, suburb or postcode</input> */}
              </div>
              <div className="grid flex-1 md:grid-cols-3 grid-cols-1">
                <Dropdown
                  background="black"
                  style="h-[59px] xl:border-l-0 border-l bg-black  font-[18px] font-bold px-[24px] border-y border-r border-brand-clime"
                  data={dropdown.location}
                  // onChange={onChange}
                  // onSelect={() => props.filterFeat(isSelected.features)}
                  selectedData={isSelected.features}
                ></Dropdown>
                <Dropdown
                  background="black"
                  style="h-[59px] xl:border-l-0 border-l bg-black font-[18px] font-bold px-[24px] border-y border-r border-brand-clime"
                  data={dropdown.jobType}
                  // onChange={onChange}
                  // onSelect={() => props.filterFeat(isSelected.features)}
                  selectedData={isSelected.features}
                ></Dropdown>
                <Dropdown
                  background="black"
                  style="h-[59px] xl:border-l-0 border-l  bg-black font-[18px] font-bold px-[24px] border-y border-r border-brand-clime"
                  data={dropdown.time}
                  // onChange={onChange}
                  // onSelect={() => props.filterFeat(isSelected.features)}
                  selectedData={isSelected.features}
                ></Dropdown>
              </div>
            </div>
            <div className="mt-[57px] grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-x-[87px]  smallTablet:gap-x-5 gap-x-5 md:gap-y-[49px] gap-y-[34px]">
              {jobs?.data?.map((item) => (

                <Link
                  state={{ id: item?.id }}
                  to={`/work-with-us/recruitment/${item.id}`}
                >
                  <div
                    onMouseEnter={() => {
                      setAppear(true);
                      setWorkId(item?.id);
                    }}
                    onMouseLeave={() => setAppear(false)}
                    className=" h-[467px] border border-brand-clime transition-all overflow-hidden bg-no-repeat relative flex justify-between flex-col"
                  >
                    {appear && workId == item?.id && (
                      <img
                        src={item?.attributes?.image?.data?.attributes?.url}
                        className="absolute transition-all left-0 top-0 w-full h-full object-cover -z-10"
                      />
                    )}
                    <h6 className="uppercase md:max-w-[293px] max-w-full md:p-5 p-4 text-white font-bold tracking-tight md:text-[52.3px] text-[35px] leading-[110%]">
                      {item?.attributes?.title}
                    </h6>
                    <div className="md:p-[27px] p-4" >
                      <h5 className="text-brand-clime font-bold  mb-[15px] text-[20px] leading-[100%] uppercase">
                        {item?.attributes?.position}
                      </h5>
                      <p className="graphik-regula w-full font-normal text-sm leading-[160%] graphik-regular text-white ">
                        {item?.attributes?.subtitle}
                      </p>
                    </div>
                    {/* <span className="graphik-regular font-normal text-sm  text-white">
                      {item?.attributes?.desc}
                    </span> */}
                  </div>
                </Link>


              ))}
            </div>
          </div>
          <div className="w-full border-y border-y-brand-clime expressions flex mt-[110px]">
            <div className="border-r border-brand-clime justify-between gap-2 flex lg:flex-col sm:flex-row flex-col xl:px-[70px] xl:py-[62px] p-6">
              <h2 className="font-bold text-white  tracking-tight lowercase expression_title text-[60px] max-w-[337px] leading-[110%]">
                {/* {data?.data?.attributes?.requestTitle} */}
                {data?.data?.attributes?.applyJob?.title
                  ? data?.data?.attributes?.applyJob?.title
                  : "Expressions of Interest"}
              </h2>
              <p className="mt-[211px] expression_dec graphik-regular font-normal text-sm leading-[160%] text-white max-w-[372px]">
                {/* {data?.data?.attributes?.requestDescription} */}

                {data?.data?.attributes?.applyJob?.description
                  ? data?.data?.attributes?.applyJob?.description
                  : `Positions are always available to the right candidate in our
                  head office and 100+ club locations. Submit your expression of
                  interest to join our team.`}
              </p>
            </div>
            <div className="xl:ml-[87px] xl:mr-[72px] xl:py-[62px] xl:w-full mx-6">
              <div className="grid xl:grid-cols-2 smallTablet:grid-cols-2 grid-cols-1 gap-y-[4px] gap-x-[40px] graphik-regular text-brand-clime">
                <InputBase
                  style={" xl:w-full "}
                  type="string"
                  holder="First name*"
                  isRequired={true}
                  isShowRequired={formControlled.firstName}
                  onchange={(e) =>
                    setForm({ ...form, firstName: e.target.value })
                  }
                />
                <InputBase
                  style={" xl:w-full "}
                  type="email"
                  holder="Email address*"
                  isRequired={true}
                  isShowRequired={formControlled.email}
                  errorText={errText.email}
                  onchange={(e) => setForm({ ...form, email: e.target.value })}
                />
                <InputBase
                  style={" xl:w-full "}
                  type="string"
                  holder="Last name*"
                  isRequired={true}
                  isShowRequired={formControlled.lastName}
                  onchange={(e) =>
                    setForm({ ...form, lastName: e.target.value })
                  }
                />
                <InputNumber
                  style={" xl:w-full "}
                  type="string"
                  holder="Phone number*"
                  isRequired={true}
                  isShowRequired={formControlled.phoneNumber}
                  onchange={(e) =>
                    setForm({ ...form, phoneNumber: "+61" + e.target.value })
                  }
                />

                <SelectOutline
                  titleStyle="text-[12px]"
                  title=""
                  isShowRequired={formControlled.location}
                  style=" h-[54px] graphik-regular text-[14px]  xl:w-full "
                  placeholder="location*"
                  placeholderColor="text-slate-400"
                  data={["act", "nsw", "qld", "vic"]}
                ></SelectOutline>
                <SelectOutline
                  titleStyle="text-[12px]"
                  isShowRequired={formControlled.department}
                  style=" h-[54px] graphik-regular text-[14px]  xl:w-full"
                  placeholder="Department*"
                  placeholderColor="text-slate-400"
                  data={["dev", "marketing"]}
                ></SelectOutline>
                <SelectOutline
                  titleStyle="text-[12px]"
                  // title="Position*"
                  isShowRequired={formControlled.position}
                  style=" h-[54px] graphik-regular text-[14px]  xl:w-full"
                  placeholder="Position*"
                  placeholderColor="text-slate-400"
                  data={["shred", "amrap"]}
                ></SelectOutline>
                <SelectOutline
                  titleStyle="text-[12px]"
                  title=""
                  isShowRequired={formControlled.heard}
                  style=" h-[54px] graphik-regular text-[14px]  xl:w-full"
                  placeholder="How did you hear about us?.*"
                  placeholderColor="text-slate-400"
                  data={["gyms", "friends", "internet", "advertise"]}
                ></SelectOutline>
                <label className="mt-[34px] flex cursor-pointer items-center gap-4 xl:col-span-2">
                  <label>
                    <input
                      onChange={(e) => setId(e.target.files[0])}
                      type="file"
                      className="hidden"
                    />
                    <div className="w-[30px] h-[30px] rounded border border-white flex items-center justify-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        className={`${idFile ? "rotate-45" : ""}`}
                      >
                        <path
                          d="M4.16 4.12L0 4.12L0 5.56L4.16 5.56L4.16 9.68L5.64 9.68L5.64 5.56L9.8 5.56L9.8 4.12L5.64 4.12L5.64 0L4.16 0L4.16 4.12Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </label>
                  <span className="gotham text-sm leading-[160%] font-normal text-white">
                    {idFile
                      ? idFile?.name
                      : "Attach files (cover letter/resume)"}
                  </span>
                </label>
              </div>

              <div className="mt-[36px] flex md:flex-row flex-col justify-between md:items-center items-start">
                <p className="gotham text-xs md:mb-0 mb-4 leading-[19px] text-white max-w-[418px]">
                  Attach your cover letter and/or any additional files
                  (optional) File type must be Word or PDF and total file size
                  must be 1mb or lower.{" "}
                </p>
                <ButtonNavigate
                  onClick={() => {
                    submit();
                  }}
                  isArrowRight={true}
                  text="send now"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
}

export default WorkWithUs;
