import React, { useRef, useState } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { navigate } from "gatsby";

interface videoSlide {
  videoData: string[];
  topTitle: string;
  titleLeft: string;
  titleRight: string;
}

function MemberStories({
  videoData = [
    "https://hit-clublime-cms.s3.ap-southeast-2.amazonaws.com/Club_Lime_2021_Brand_Video_Fitness_is_a_Lifestyle_d13aa0f160.mp4",
  ],
  topTitle,
  titleLeft,
  titleRight,
}: videoSlide) {
  const settings = {
    className: "center-slide",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 1,
    variableWidth: true,
    arrows: false,
    focus: false,
    centerMode: true,
  };
  const [slider, setSlider] = useState();
  const videoRef = useRef();

  const [play, setIsPlay] = useState(false);

  const playPause = (e: any) => {
    const allVideo = document.getElementsByTagName("video");
    for (let i = 0; i < allVideo.length; i++) {
      document.getElementsByTagName("video")[i].pause();
    }
    if (play) e.target.pause();
    else {
      e.target.play();
    }
  };
  const [currentPlay, setCurrentPlay] = useState(0);

  return (
    <div className="slideVideo md:h-[670px] h-[470px] w-full  bg-[#212121] lg:mb-[85px] mt-[21px] relative">
      <span className="absolute left-[290px] h-[832px] hidden md:block bg-[#cccc] w-[1px] -top-[70px]"></span>
      <span className="absolute right-[290px] h-[832px] bg-[#cccc]  hidden md:block w-[1px] -top-[70px]"></span>
      <h6 className="uppercase Gravity font-normal md:text-[65px] text-[47px] transform md:translate-y-[-27px] translate-y-[-20px] leading-[90%] text-white text-center">
        {topTitle ?? `your lifestyle`}
      </h6>
      <span className=" neue-border text-transparent  hidden md:block  font-normal text-[99px] -rotate-90 origin-left left-[300px] md:left-[70px] -bottom-7 absolute">
        {titleLeft ?? "REACH YOUR GOALS"}
      </span>
      <span className=" neue-border text-transparent  hidden md:block  font-normal text-[99px] -rotate-90 origin-right right-[285px] md:right-[70px] -top-1 absolute ">
        {titleRight ?? "Change your life"}
      </span>
      <p className="neue-border text-[60px] absolute top-8 w-full flex md:hidden justify-center text-transparent whitespace-nowrap overflow-hidden">
        {titleLeft ?? "REACH YOUR GOALS"}
      </p>
      <p className="neue-border text-[60px] absolute bottom-0 w-full flex md:hidden justify-center text-transparent whitespace-nowrap overflow-hidden">
        {titleRight ?? "Change your life"}
      </p>
      {videoData.length && (
        <Slider
          {...settings}
          ref={(c: any) => setSlider(c)}
          className="offer-slider md:mt-[30px] mt-[80px]"
        >
          {videoData.map((i, index) => (
            <div
              key={index}
              className="relative"
            >
              {play ? (
                index === currentPlay ? (
                  ""
                ) : (
                  <svg
                    className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="74"
                    height="78"
                    viewBox="0 0 74 78"
                    fill="none"
                  >
                    <path
                      d="M2.35892 4.13952C2.3446 2.62145 3.96041 1.64154 5.29996 2.35592L70.8866 37.3333C72.2985 38.0862 72.2985 40.1098 70.8867 40.8627L5.29676 75.8426C3.95796 76.5566 2.34283 75.5781 2.35569 74.0609L2.66935 37.0451L2.35892 4.13952Z"
                      stroke="#82FA00"
                      strokeWidth="3"
                    />
                  </svg>
                )
              ) : (
                <svg
                  className="absolute left-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="74"
                  height="78"
                  viewBox="0 0 74 78"
                  fill="none"
                >
                  <path
                    d="M2.35892 4.13952C2.3446 2.62145 3.96041 1.64154 5.29996 2.35592L70.8866 37.3333C72.2985 38.0862 72.2985 40.1098 70.8867 40.8627L5.29676 75.8426C3.95796 76.5566 2.34283 75.5781 2.35569 74.0609L2.66935 37.0451L2.35892 4.13952Z"
                    stroke="#82FA00"
                    strokeWidth="3"
                  />
                </svg>
              )}
              <video
                ref={index == 0 ? videoRef : null}
                onEnded={() => setIsPlay(false)}
                className="md:w-[626px] w-[243px] md:h-[513px] h-[232px] object-cover"
                onClick={(e) => {
                  slider.slickGoTo(index);
                  setIsPlay(!play);
                  setCurrentPlay(index);
                  playPause(e);
                }}
              >
                <source src={i} type="video/mp4"></source>
              </video>
            </div>
          ))}
        </Slider>
      )}
      <div className="lg:flex hidden w-full justify-center transform translate-y-[35px]">
        <ButtonNavigate
            onClick={()=>navigate('/register')}

          text="BECOME A MEMBER"
          style="h-[45px] w-[187px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
          isArrowRight={true}
        ></ButtonNavigate>
      </div>
    </div>
  );
}

export default MemberStories;
