import course from '../src/assets/images/memberships/course.png';
import mem1 from '../src/assets/images/memberships/mem1.png';
import mem2 from '../src/assets/images/memberships/mem2.png';
import mem3 from '../src/assets/images/memberships/mem3.png';
import mem4 from '../src/assets/images/memberships/mem4.png';
import mem5 from '../src/assets/images/memberships/mem5.png';
import mem6 from '../src/assets/images/memberships/mem6.png';
import logo1 from '../src/assets/images/memberships/logo1.png';
import logo2 from '../src/assets/images/memberships/logo2.png';
import logo3 from '../src/assets/images/memberships/logo3.png';
import logo4 from '../src/assets/images/memberships/logo4.png';
import logo5 from '../src/assets/images/memberships/logo5.png';
import logo6 from '../src/assets/images/memberships/logo6.png';
import braddon from '../src/assets/images/memberships/coach7.png';
import ons1 from '../src/assets/images/memberships/ons1.png';
import ons2 from '../src/assets/images/memberships/ons2.png';
import ons3 from '../src/assets/images/memberships/ons3.png';
import ons4 from '../src/assets/images/memberships/ons4.png';
import ons5 from '../src/assets/images/memberships/ons5.png';
import ons6 from '../src/assets/images/memberships/ons6.png';

export const memberships = [
  {
    title: 'benefits of owning a hiit',
    titleExpanded: 'expanded point about the benefit',
    image: course,
    desc: 'Insert extra information about the industry-leading coaches. Insert extra information about the industry-leading coaches.  ',
  },
  {
    title: 'benefits of owning a hiit',
    titleExpanded: 'expanded point about the benefit',
    image: course,
    desc: 'Insert extra information about the industry-leading coaches. Insert extra information about the industry-leading coaches.  ',
  },
  {
    title: 'benefits of owning a hiit',
    titleExpanded: 'expanded point about the benefit',
    image: course,
    desc: 'Insert extra information about the industry-leading coaches. Insert extra information about the industry-leading coaches.  ',
  },
  {
    title: 'benefits of owning a hiit',
    titleExpanded: 'expanded point about the benefit',
    image: course,
    desc: 'Insert extra information about the industry-leading coaches. Insert extra information about the industry-leading coaches.  ',
  },
  {
    title: 'benefits of owning a hiit',
    titleExpanded: 'expanded point about the benefit',
    image: course,
    desc: 'Insert extra information about the industry-leading coaches. Insert extra information about the industry-leading coaches.  ',
  },
];

export const plans = [
  {
    title: 'single',
    price: '16.90',
    popular: false,
    type: 'weekly',
    desc: ['24/7 access*', 'Includes one club', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'state',
    price: '24.90',
    popular: true,
    type: 'weekly',
    desc: ['24/7 access*', 'Includes all clubs in ACT', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'national',
    price: '32.90',
    popular: false,
    type: 'weekly',
    desc: ['24/7 access*', 'Includes all clubs in AUS', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'national',
    price: '32.90',
    popular: false,
    type: 'annual',
    desc: ['24/7 access*', 'Includes all clubs in AUS', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'state',
    price: '24.90',
    popular: false,
    type: 'annual',
    desc: ['24/7 access*', 'Includes all clubs in ACT', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'single',
    price: '16.90',
    popular: true,
    type: 'annual',
    desc: ['24/7 access*', 'Includes one club', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'national',
    price: '32.90',
    popular: false,
    type: 'fixed tearm',
    desc: ['24/7 access*', 'Includes all clubs in AUS', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'single',
    price: '16.90',
    popular: true,
    type: 'fixed tearm',
    desc: ['24/7 access*', 'Includes one club', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'state',
    price: '24.90',
    popular: false,
    type: 'fixed tearm',
    desc: ['24/7 access*', 'Includes all clubs in ACT', 'Cancel anytime*', 'Free aaccess pass'],
  },
];

export const yourUpgrades = {
  title: 'select your upgrades',
  clubs: [
    {
      image: mem1,
      logo: logo1,
      description: ['High intensity interval training', 'Includes all hiit studios', 'Cancel anytime'],
      priceForWeek: 23,
      priceForNight: 13,
      color: '#82FA00',
    },
    {
      image: mem2,
      logo: logo2,
      description: ['Reformer pilates, hot yoga, barre', 'Includes all Ground Up studios', 'Cancel anytime'],
      priceForWeek: 23,
      priceForNight: 34,
      color: '#A4ACD7',
    },
    {
      image: mem3,
      logo: logo3,
      description: ['Access to ladies only clubs', 'Includes over 60 locations', 'Cancel anytime'],
      priceForWeek: 34,
      priceForNight: 23,
      color: '#ED288F',
    },
    {
      image: mem4,
      logo: logo4,
      description: ['Access to our two pools', 'Includes ANU and CISAC', 'Cancel anytime'],
      priceForWeek: 12,
      priceForNight: 25,
      color: '#034EA2',
    },
    {
      image: mem5,
      logo: logo5,
      description: ['Access to VIP only clubs', 'Includes Pyrmont and Lorem ', 'Cancel anytime'],
      priceForWeek: 15,
      priceForNight: 34,
      color: '#82FA00',
    },
    {
      image: mem6,
      logo: logo6,
      description: ['Access to ladies only clubs', 'Includes over 60 locations', 'Cancel anytime'],
      priceForWeek: 14,
      priceForNight: 23,
      color: '#4CC1BB',
    },
  ],
};

export const yourAddOns = {
  title: 'choose your add-ons',
  addOns: [
    {
      image: ons1,
      width: 221,
      height: 104,
      title: 'Pt Starter pack',
      description:
        'Includes 2 PT sessions et accusamus et iusto odio digni, eos et accusamus et iusto odio dignissimos, et iusto odio.',
      priceForWeek: 10,
      priceForNight: 'One-off payment',
    },
    {
      image: ons2,
      width: 96,
      height: 111.71,
      title: 'Digital membership',
      description:
        'Vero eos et accusamus et iusto odio digni, eos et accusamus et iusto odio dignissimos, et iusto odio dignit.',
      priceForWeek: 10,
      priceForNight: 'One-off payment',
    },
    {
      image: ons3,
      width: 221,
      height: 104,
      title: 'home workout kit',
      description:
        'Includes 5 Club Lime branded bands engineered by our trainers, our TRX and ro eos et accusamus et iusto odio digni, eos.',
      priceForWeek: 10,
      priceForNight: 'One-off payment',
    },
    {
      image: ons4,
      width: 125,
      height: 112,
      title: 'Drink bottle',
      description:
        'Vero eos et accusamus et iusto odio digni, eos et accusamus et iusto odio dignissimos, et iusto odio dignit.',
      priceForWeek: 10,
      priceForNight: 'One-off payment',
    },
    {
      image: ons5,
      width: 89,
      height: 85,
      title: 'BodiTrax Assessment',
      description:
        'Vero eos et accusamus et iusto odio digni, eos et accusamus et iusto odio dignissimos, et iusto odio dignit.',
      priceForWeek: 10,
      priceForNight: 'One-off payment',
    },
    {
      image: ons6,
      width: 103,
      height: 113,
      title: 'Towel',
      description:
        'Vero eos et accusamus et iusto odio digni, eos et accusamus et iusto odio dignissimos, et iusto odio dignit.',
      priceForWeek: 80,
      priceForNight: 'One-off payment',
    },
  ],
};

export const yourDetail = {
  title: 'when do you want to start?',
  contactText: 'contact details',
  health: {
    title: 'health check',
    description: 'Do you have any injuries, disabilities or current conditions?*',
    tickTitle: 'Tick all that apply:*',
    listApply: [
      'High or low blood pressure?',
      'Pregnant?',
      'Tightness in chest?',
      'Undergoing cancer treatment?',
      'High or low blood pressure?',
      'Lorem ipsum',
      'High or low blood pressure?',
      'Other (please specify)',
    ],
  },
  emergencyText: 'emergency contact',

  privacy:
    'I agree to the terms & conditions and the privacy policy attached to my membership. I understand that I can cancel at anytime with 14 days notice as outlined in the contract.',
};

export const yourMemberships = {
  title: 'your membership',
  homeClubText: 'Home club',
  membershipPlanText: 'Membership plan',
  promoCodeText: 'Promo code',
  promoCode: [
    {
      code: 'ABC-XYZ',
      discount: 20,
    },
    {
      code: 'FOR-YOU',
      discount: 100,
    },
  ],
  dateText: 'First direct debit',
  costPerForNightText: 'Cost per fornight',
  upgradesText: 'Upgrades/add-ons',
  totalText: 'Total due today*',
  contractText: 'Membership contract',
  description: '   This is the pro-rata amount. The cost of your membership before direct debit commences.',
};

export const payment = {
  upfrontText: 'Upfront payment',
  upfrontSubText: 'Total due today',
  ongoingText: 'ongoing payment details',
  ongoingSubText: 'Direct debit (fornightly)',
  ongoingAcceptText: 'Same card as above',
  selectPaymentText: 'Select your payment method:',
  buttonNextText: 'pay now',
};

export const congrats = {
  title: 'congrats',
  signUpText: 'sign up complete',
  info: {
    memText: 'Member ID',
    startDateText: 'Start date',
    portalText: 'Member portal password',
    attention: 'Don’t sweat it. We have sent this information to your email.',
  },
  nextSteps: {
    title: 'next steps',
    steps: [
      {
        name: 'check your email',
        desc: 'We’ve sent your membership no, contract and receipt to your email.',
      },
      {
        name: 'download the app',
        desc: 'Download the app and get it set up on your phone. Unlock your access pass.',
      },
      {
        name: 'start training',
        desc: 'Come pick up your drink bottle and get your sweat on. A new you awaits. Congrats on taking the first step.',
      },
    ],
  },
  nextStepsDashboard: {
    title: 'next steps',
    steps: [
      {
        name: 'check your email',
        desc: 'We’ve sent your new contract and receipt to your email.',
      },
      {
        name: 'check the app',
        desc: 'Check out the changes to your Member ID App.',
      },
      {
        name: 'come check out your new home club',
        desc: 'Come get your sweat on at your new home club. A new you awaits.',
      },
    ],
  },
};

export const selectClub = {
  introText: 'We’re ​excited to have you join us and we’ll see you at the gym!​',
  title: 'your home club is set to',
  address: 'Unit 75, 76/30 Lonsdale St, Braddon ACT 2612',
  phone: '13 22 44',
  email: 'anu@clublime.com.au',
  desc: ['100+ classes per week', 'sled track', 'parking on premises'],
  question: 'is this correct ?',
  image: braddon,
  labelButton: ['no', 'yes'],
};

export const timeLine = [ 'details', 'membership', 'health', 'payment'];
