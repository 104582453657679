import React from "react";

interface props {
  data: any;
}

export default function Gallery({ data }: props) {
  const scrollIntoView = (id: string) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };
  return (
    <>
      <div className="md:grid z-10 sticky top-[69px] hidden grid-cols-1 grid-flow-row md:grid-flow-row md:grid-cols-5 bg-black">
        <div
          onClick={() => scrollIntoView("Gallery")}
          className=" uppercase relative overflow-y-hidden border-y border-brand-clime text-brand-clime py-[22px] flex items-center justify-center jumpNavigate"
        >
          Gallery
        </div>
        <div
          onClick={() => scrollIntoView("Membership")}
          className=" uppercase relative overflow-y-hidden md:border-x border-y  border-brand-clime text-brand-clime py-[22px] flex items-center justify-center jumpNavigate"
        >
          Membership
        </div>
        <div
          onClick={() => scrollIntoView("Club-features")}
          className=" uppercase relative overflow-y-hidden border-y border-brand-clime text-brand-clime py-[22px] flex items-center justify-center jumpNavigate"
        >
          Club features
        </div>
        <div
          onClick={() => scrollIntoView("Classes")}
          className=" uppercase relative overflow-y-hidden md:border-x border-y border-brand-clime text-brand-clime py-[22px] flex items-center justify-center jumpNavigate"
        >
          Classes
        </div>
        <div
          onClick={() => scrollIntoView("Free-trial")}
          className=" uppercase relative overflow-y-hidden border-y border-brand-clime text-brand-clime py-[22px] flex items-center justify-center jumpNavigate"
        >
          Free trial
        </div>
      </div>
      <div id="Gallery">
        <div
          id="scroll"
          className="max-w-[1440px] flex overflow-auto pb-[37px] md:pb-[44px] mb-[50px] md:mb-[111px] h-[305px] md:h-auto"
        >
          {data?.gallery?.data?.map((i: any, index: number) => (
            <img key={index} src={i.attributes.url} />
          ))}
        </div>
      </div>
    </>
  );
}
