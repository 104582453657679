import React, { useEffect, useState, useContext, useCallback } from "react";
import { HeadFC, navigate } from "gatsby";
import { Router, useLocation } from "@reach/router";
import Login from "../routes/Login";
import Register from "../routes/Register";
import HomePage from "../routes";
import PrivateRoute from "../components/PrivateRoute";
import Memberships from '../components/DashBoard/DashboardPages/Memberships';
import About from "../routes/About";
import Studio from "../routes/studio/index";
import BlogLayout from "../routes/blog";
import BlogDetail from "../routes/blog/[id]";
import studioId from "../routes/studio/[id]";
import ClassDetail from "../routes/class";
import BookAClass from "../routes/class/book-a-class";
import NotFoundPage from "../routes/404";
import WorkWithUs from "../routes/work";
import WorkDetail from "../routes/work/[id]";
import Access from "../routes/Access";
import Contact from "../routes/contact";
import Legal from "../routes/legal";
import Faq from "../routes/faq";
import { Franchise } from "../routes/Franchise";
import { StoreProvider } from "../context/RegisterContext";
import LifeStyle from "../routes/LifeStyle";
import index from "../routes/PT-Careers";
import PersonalTraining from "../routes/Personal-training";
import { Toaster } from "react-hot-toast";

import GroupFitness from "../routes/GroupFitness";
import DashBoard from "../routes/DashBoard";
import Timetable from '../components/DashBoard/DashboardPages/Timetable';
import MyBookings from '../routes/DashBoard/MyBookings';
import Account from '../routes/DashBoard/Account';
import ClubSearch from '../routes/DashBoard/ClubSearch';
import ApolloWrapper from '../apis/apolloWrapper';
// import Benefits from "../routes/dashboard/Benefits";
// import Bookings from "../routes/dashboard/Bookings";
// import Timetable from "../routes/dashboard/Timetable";
// import Upgrade from "../routes/dashboard/upgrade/Upgrade";
// import UpgradeCongrats from "../routes/dashboard/upgrade/Congrats";
// import Account from "../routes/dashboard/account/Account";
// import AccountPassWord from "../routes/dashboard/account/Password";
// import Biling from "../routes/dashboard/account/Biling";
// import Notifications from "../routes/dashboard/account/Notifications";
// import ChangeClub from "../routes/dashboard/billing/ChangeClub";
// import DashboardMembership from "../routes/dashboard/Membership";

// import { client } from "../apis/graphql-config";
import { ApolloProvider } from "@apollo/client";
import ForgotPassword from "../routes/ForgotPassword";

// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { SEO } from "../components/SEO";
import useFetch from "../hooks/useFetch";
import { AuthProvider } from "../apis/auth";

import { ApolloLink } from "apollo-link";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { Amplify, Auth } from "aws-amplify";
import { setContext } from "@apollo/client/link/context";
import { createHttpLink } from "apollo-link-http";
import { createAuthLink } from "aws-appsync-auth-link";


const IndexPage = () => {

  return (
    <AuthProvider >
      {/* <ToastContainer /> */}
      <Toaster position="top-right" reverseOrder={false} />
      <ApolloWrapper>
          <StoreProvider>
            <Router basepath="/">
              <PrivateRoute path="/" component={HomePage} />
              <PrivateRoute
                path="/forgot-password"
                component={ForgotPassword}
              />
              <PrivateRoute path="/life-style" component={LifeStyle} />
              <PrivateRoute path="/group-fitness" component={GroupFitness} />
              <PrivateRoute path="/legal" component={Legal} />
              <PrivateRoute path="/about" component={About} />
              <PrivateRoute path="/faq" component={Faq} />
              <PrivateRoute path="/studio" component={Studio} />
              <PrivateRoute path="/contact" component={Contact} />
              <PrivateRoute path="/own-a-hiit" component={Franchise} />
              <PrivateRoute path="/studio/:id" component={studioId} />
              <PrivateRoute path="/blog" component={BlogLayout} />
              <PrivateRoute path="/blog/:id" component={BlogDetail} />
              <PrivateRoute path="/class" component={ClassDetail} />
              <PrivateRoute path="/class/:id" component={ClassDetail} />
              <PrivateRoute path="/book-a-class" component={BookAClass} />
              <PrivateRoute path="/memberships" component={Memberships} />
              <PrivateRoute path="/work-with-us" component={WorkWithUs} />
              <PrivateRoute
                path="/work-with-us/recruitment/:id"
                component={WorkDetail}
              />
              <PrivateRoute path="/pt-careers" component={index} />
              <PrivateRoute
                path="/personal-training"
                component={PersonalTraining}
              />

              <PrivateRoute path="/dashboard" component={DashBoard} />
              <PrivateRoute component={Timetable} path="/dashboard/class/timetable" />
              <PrivateRoute component={MyBookings} path="/dashboard/class/bookings" />
              <PrivateRoute component={Memberships} path="/dashboard/memberships" />
              <PrivateRoute component={Account} path="/dashboard/account" />
              <PrivateRoute component={ClubSearch} path="/dashboard/club-search" />
              {/*<PrivateRoute path="/dashboard/benefits" component={Benefits} />*/}

              {/*<PrivateRoute*/}
              {/*  path="/dashboard/class/bookings"*/}
              {/*  component={Bookings}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/class/timetable"*/}
              {/*  component={Timetable}*/}
              {/*/>*/}
              
              {/*<PrivateRoute path="/dashboard/upgrade" component={Upgrade} />*/}
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/upgrade-congrats"*/}
              {/*  component={UpgradeCongrats}*/}
              {/*/>*/}
              
              {/*<PrivateRoute path="/dashboard/account" component={Account} />*/}
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/account/password"*/}
              {/*  component={AccountPassWord}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/account/biling"*/}
              {/*  component={Biling}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/account/notifications"*/}
              {/*  component={Notifications}*/}
              {/*/>*/}
              
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/billing/change-club"*/}
              {/*  component={ChangeClub}*/}
              {/*/>*/}
              {/*<PrivateRoute*/}
              {/*  path="/dashboard/memberships/"*/}
              {/*  component={DashboardMembership}*/}
              {/*/>*/}

              <Login path="/login" />
              <Access path="/access" />
              <Register path="/register" />
              <NotFoundPage path="*" />
            </Router>
          </StoreProvider>
    </ApolloWrapper>
    </AuthProvider>
  );
};

export default IndexPage;

export const Head: HeadFC = () => {
  const path = useLocation()
  const [content, setContent] = useState<string>('')
  const { data: seo } = useFetch({ params: 'global', populate: 'seo' })

  useEffect(() => {
    path.pathname.replace(/\//g, "") == ''
      ? setContent(seo?.data?.attributes?.seo?.[0])
      : setContent(seo?.data?.attributes?.seo?.find((item: any) => item?.key == path.pathname.replace(/\//g, "")))
  }, [seo, path.pathname])

  return (<SEO title="Clublime" description={content?.value} />)
}
