import ArrowButton from "../../common/buttons/ArrowButton";
import React, { useState, useEffect, useContext } from "react";

import { useLazyQuery } from "@apollo/client";
import { listMembershipId, listMemberships } from "../../apis/mutation";
import { toastError } from "../../common/toasts/toast";
import moment from "moment";
import { RegisterContext } from "../../context/RegisterContext";

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
  info: any;
  setInfo: (v) => void;
  changeMembership: boolean;

  isDashboardMobile?: boolean;
}
const Memberships: React.FC<SlideProps> = ({
  onNext,
  onPrev,
  info,
  setInfo,
  changeMembership = false,
  isDashboardMobile = false,
}) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const [type, setType] = useState<string>("fort");
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
  }, []);
  const [age, setAge] = useState(18);
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
    listMemberShipApi();
  }, [age]);

  useEffect(() => {
    const ageInfo = moment(new Date()).diff(
      moment(info?.memberDetails?.dob),
      "years"
    );
    info?.memberDetails?.dob && info?.memberDetails?.dob != ""
      ? setAge(ageInfo)
      : setAge(18);
  }, [info]);
  // const { data } = useFetch({
  //     params: "membership-upgrades",
  //     populate: "logo,image,features,price",
  // });

  // Check value upgrades exists
  // const handleCheck = (club: object) => {
  //     setUpgrades((prev) => {
  //         const isChecked = upgrades.some(
  //             (item) => item?.attributes?.title === club?.attributes?.title
  //         );
  //         return isChecked
  //             ? upgrades?.filter(
  //                 (item) => item?.attributes?.title !== club?.attributes?.title
  //             )
  //             : [...prev, club];
  //     });
  // };

  // Total Price
  // let total = 0;
  // upgrades.forEach((e) => {
  //     total += e?.attributes?.price?.[1]?.value;
  // });

  const [listMemberShipApi] = useLazyQuery(listMemberships);
  const [listMembershipIdApi] = useLazyQuery(listMembershipId);

  // const sortData = ({ items }) => {
  //     let type = { weekly: [], annual: [], student: [] };
  //     let upgradeData = [];

  //     const upgrade = ["CL ", "HR ", "GU ", "LO ", " + ", "RB "];
  //     if (items) {
  //         for (let i = 0; i < items.length; i++) {
  //             const result = upgrade.some((substr) =>
  //                 items[i].description.toLowerCase().includes(substr.toLowerCase())
  //             );
  //             if (result && items[i].isActive) {
  //                 upgradeData.push(items[i]);
  //             }
  //         }
  //         setUpgradeData(upgradeData);
  //     }
  // };
  const [price, setPrice] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  //   const sortData = ({ items }, age) => {
  //     let type = { weekly: [], annual: [], student: [], pif: [] };
  //     let upgradeData = { weekly: [], annual: [], student: [], pif: [] };

  //     const upgrade = ["CL ", "HR ", "GU ", "LO ", " + ", "RB "];
  //     if (items) {
  //       for (let i = 0; i < items.length; i++) {
  //         const lowerClassName = items[i].membershipName.toLowerCase();
  //         const lowerDesc = items[i].description.toLowerCase();
  //         const result = upgrade.some((substr) =>
  //           lowerDesc.includes(substr.toLowerCase())
  //         );

  //         if (!result && items[i].isActive) {
  //           if (
  //             true
  //             // (age === 16 && lowerClassName.includes("flexi")) ||
  //             // (age > 16 &&
  //             //   (lowerClassName.includes("flexi") ||
  //             //     lowerClassName.includes("power") ||
  //             //     (!changeMembership && lowerClassName.includes("guest")))) ||
  //             // lowerDesc.includes("pif") ||
  //             // (age <= 13 && lowerClassName.includes("junior")) ||
  //             // (age > 13 && age < 16 && lowerClassName.includes("youth"))
  //           ) {
  //             if (lowerDesc.includes("pif")) type.pif.push(items[i]);
  //             else if (
  //               items[i].paymentFrequency.type === "WEEKS" &&
  //               !lowerDesc.includes("student")
  //             )
  //               type.weekly.push(items[i]);
  //             else if (
  //               (items[i].paymentFrequency.type === "YEARS" ||
  //                 items[i].paymentFrequency.type === "MONTHS") &&
  //               !lowerDesc.includes("student")
  //             )
  //               type.annual.push(items[i]);
  //             else type.student.push(items[i]);
  //           }
  //         }
  //       }
  //       setPrice(type);
  //       setCurrentPlan("weekly");
  //       setType("weekly");
  //       // setOptions(['weekly',"annual","student"]);
  //     }
  //   };
  const sortData = ({ items }) => {
    let type = { weekly: [], annual: [], student: [] };
    let upgradeData = [];

    const upgrade = ["CL ", "HR ", "GU ", "LO ", " + ", "RB "];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const result = upgrade.some((substr) =>
            items[i]?.description?.toLowerCase()?.includes(substr?.toLowerCase())
        );
        if (!result && items[i]?.isActive)
          if (items[i].paymentFrequency.type === "WEEKS")
            type.weekly.push(items[i]);
          else if (
              items[i].paymentFrequency.type === "YEARS" ||
              items[i].paymentFrequency.type === "MONTHS"
          )
            type.annual.push(items[i]);
          else type.student.push(items[i]);
        else if (result && items[i]?.isActive) {
          upgradeData.push(items[i]);
        }
      }
      setUpgradeData(upgradeData);
      setPrice(type);
      setType("weekly");
      setCurrentPlan("weekly");

      // setOptions(['weekly',"annual","student"]);
    }
  };

  const [upgradeData, setUpgradeData] = useState();
  function createMarkup(data) {
    return { __html: data };
  }

  if (isDashboardMobile) {
    return (
      <div className="mt-6">
        <p className="text-white leading-[21.36px] mb-3">
          Do you have a promo code?
        </p>
        <div className="flex items-center w-full border-b border-white border-opacity-40 bg-white rounded h-[42px] px-2 py-3">
          <div className="flex items-center w-full">
            <p className="text-base font-bold leading-[160%] text-black uppercase">
              PF-
            </p>
            <input className="w-full pl-1 text-base font-bold leading-[160%] text-black uppercase" />
          </div>
        </div>

        <div className="flex items-center w-full mt-6">
          <button
            onClick={() => setType("weekly")}
            className={`border border-solid leading-[160%]  text-base md:text-xs font-bold uppercase h-[52px] w-[50%] rounded-l ${
              type == "weekly"
                ? "bg-[#82FA00] text-black border-[#82FA00] border-r-0"
                : " bg-transparent text-white border-white border-r-0"
            } `}
          >
            fortnightly
          </button>
          <button
            onClick={() => setType("annual")}
            className={`border border-solid leading-[160%] text-base font-bold uppercase h-[52px] w-[50%] rounded-r ${
              type == "annual"
                ? "bg-[#82FA00] text-black border-[#82FA00] border-l-0 "
                : " bg-transparent  text-white border-white border-l-0 "
            } `}
          >
            paid in full
          </button>
        </div>

        {/* Memberships */}
        <div className="grid mt-[30px] grid-cols-1 gap-y-5">
          {price?.[type]?.map((item: object, index: number) => {
            return (
              <div
                key={index}
                className="relative bg-black border border-textSecondary rounded-md overflow-hidden"
              >
                <div className="flex flex-col items-center justify-center pt-7 pb-5 px-4">
                  <h2 className="uppercase font-abcmonument text-[25px] font-bold text-[#82FA00] text-center leading-[22.5px]">
                    {item.membershipName}{" "}
                    {age > 13 && age < 16 && (
                      <span className="heading2 text-[rgba(37,51,213,1)]">
                        YOUTH
                      </span>
                    )}
                  </h2>

                  <div
                    className={`mt-3 ${
                      type == "pif" ? "text-center" : "text-start"
                    }`}
                  >
                    <span
                      className={` text-[56px] font-medium text-white leading-[90%] ${
                        type == "pif" && "justify-self-center"
                      }`}
                    >
                      {" "}
                      {(item.costPrice + "").split(".").length > 1
                        ? "$" + (item.costPrice + "").split(".")[0]
                        : "$" + item.costPrice}
                      {(item.costPrice + "").split(".").length > 1 && "."}
                      {(item.costPrice + "").split(".")[1]}
                    </span>
                    {type != "pif" && (
                      <span
                        span
                        className="uppercase text-white  text-[18px] font-normal leading-[160%]"
                      >
                        /{" "}
                        {item.paymentFrequency.type.charAt(
                          item.paymentFrequency.type.length - 1
                        ) == "s"
                          ? item.paymentFrequency.type.slice(0, -1)
                          : item.paymentFrequency.type.slice(0, -1)}
                      </span>
                    )}
                    {type != "weekly" && (
                      <p className="text-center text-white text-base leading-[160%] font-bold mt-1">
                        SINGLE PAYMENT
                      </p>
                    )}
                    {type == "weekly" && (
                      <p
                        className={`mt-[6px] leading-[110%] text-center text-base text-white text-opacity-60`}
                      >
                        {item.costPrice *
                          (item.paymentFrequency.type.toLowerCase() == "weeks"
                            ? item.paymentFrequency.amount
                            : 0) +
                          " fortnightly Direct Debit"}
                      </p>
                    )}
                  </div>
                </div>
                {!changeMembership ||
                (changeMembership &&
                  item.id != info.memberDetails.membershipId) ? (
                  <div className="w-full mt-auto">
                    <div
                      onClick={() => {
                        changeMembership
                          ? setInfo &&
                            setInfo({
                              memberDetails: {
                                ...info.memberDetails,
                                membershipId: item.id,
                                NewMembershipId: item.id,
                                membershipLocationId:
                                  item?.membershipLocations?.items?.[0]?.id,
                              },
                              membershipDetails: { ...item },
                            })
                          : setInfo &&
                            setInfo({
                              ...info,
                              membershipId: item.id,
                              membershipLocationId:
                                item?.membershipLocations?.items?.[0]?.id,
                            });
                      }}
                    >
                      <div
                        className={`flex items-center justify-center w-full border-t border-gray h-10 bg-transparent text-gray text-base text-center font-medium uppercase leading-[34.426px] ${
                          info?.membershipId === item?.id ||
                          (changeMembership &&
                            item.id == info.memberDetails.NewMembershipId)
                            ? " !bg-[#82FA00] !text-black"
                            : " "
                        }`}
                      >
                        {info?.membershipId === item?.id ||
                        (changeMembership &&
                          item.id == info.memberDetails.NewMembershipId)
                          ? "selected"
                          : "choose plan"}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="w-full mt-auto">
                    <div>
                      <div className="flex items-center justify-center w-full h-10 bg-richBlack text-white text-base text-center font-medium uppercase leading-[34.426px]">
                        your current plan
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        {/* Buttons to actions */}
        <div className="flex items-center mt-[38px] justify-between">
          <div onClick={() => onPrev()}>
            <div className="flex">
              <ArrowButton text="" isRight={false} />
            </div>
          </div>
          <div
            onClick={() => {
              if (
                info?.membershipId ||
                (changeMembership && info.memberDetails.NewMembershipId)
              )
                onNext();
              else toastError("Please select a membership");
            }}
          >
            <div className="flex">
              <ArrowButton text="next" isRight={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  const yourClub = useContext(RegisterContext).yourClub;

  return (
    <div
      id="scroll-hidden"
      className="w-full overflow-y-scroll sm:pb-[70px] pb-[150px] h-full "
    >
      <div className="flex items-start md:items-center flex-col md:flex-row pt-[42px]  justify-between">
        <div className="flex flex-col gap-2 border-b  pb-3 md:border-b-transparent border-b-brand-clime w-full">
          <h6 className=" !tracking-[0.3px] leading-[90%] text-white text-left uppercase text-sm">
            Membership options for{" "}
          </h6>
          <h2 className="uppercase md:table-auto text-[25px] leading-[90%] font-black text-start  !tracking-[0.3px] text-[#82FA00] ">
            Club lime                   {yourClub?.name}

          </h2>
        </div>
        <p className="text-white leading-[21.36px] mb-3 block md:hidden mt-[28px] md:mt-0">
          Do you have a promo code?
        </p>
        <div className=" flex md:hidden sm:mb-[27px] items-center w-full border-b border-white border-opacity-40 bg-white rounded h-[42px] px-2 py-3">
          <div className="flex items-center w-full">
            <p className="text-base font-bold leading-[160%] text-black uppercase">
              PF-
            </p>
            <input className="w-full pl-1 text-base font-bold leading-[160%] text-black uppercase" />
          </div>
        </div>
        <div className="flex border w-full md:w-[300px] min-w-none sm:min-w-[300px] mt-4 md:mt-0 border-transparent rounded-md items-center">
          <button
            onClick={() => setType("weekly")}
            className={`${
              type == "weekly"
                ? "bg-[#82FA00] text-black"
                : "bg-transparent border-y border-l md:border-y md:border-white border-brand-clime md:bg-transparent text-[#82FA00] md:text-white "
            } font-abcmonument text-[11px]  !tracking-[0.6px] md:font-bold uppercase  font-medium rounded-l-md text-base md:text-xs h-[40px] w-[50%]`}
          >
            fortnightly
          </button>
          <button
            onClick={() => setType("annual")}
            className={`${
              type == "annual"
                ? "bg-[#82FA00] text-black "
                : "bg-transparent border-y border-r md:border-y md:border-white border-brand-clime md:bg-transparent text-[#82FA00] md:text-white "
            }  font-abcmonument  text-[11px] md:font-bold uppercase font-medium text-base md:text-xs rounded-r-md  h-[40px] w-[50%]`}
          >
            paid in full
          </button>
        </div>
      </div>
      {/* Memberships */}
      <div
        className={`grid mt-5 ${
          price?.[type]?.length > 3
            ? "grid-cols-1 lg:grid-cols-3"
              : 'sm:grid-flow-col grid-flow-row'
        }`}
      >
        {price?.[type]
          ?.map((item: object, index: number) => {
            const currentData = price?.[type]
            return (
              <>
                <div
                  key={index}
                  className={`relative sm:pt-[33px] pt-[30px]  py-[33px] border border-b-transparent sm:border-gray px-0  sm:px-[50px] grid  justify-center w-full 
                  ${index == 2 ? "rounded-tr" : ""}
                  ${index == 0 ? "rounded-tl" : ""}
                  ${index + 1 == currentData.length ? "rounded-br" : ""}
                  ${
                    currentData.length > 3
                      ? currentData.length % 3 == 1
                        ? index == currentData.length - 1 && "rounded-bl"
                        : index == currentData.length - 2 && "rounded-bl"
                      : ""
                  }
                  ${
                    currentData.length > 3
                      ? currentData.length % 3 == 1
                        ? index == currentData.length - 2 && "rounded-br"
                        : index == currentData.length - 3 && "rounded-br"
                      : ""
                  }
                  ${currentData.length == 1 && "!rounded"}
                  ${
                    changeMembership &&
                    item.id == info.memberDetails.membershipId
                      ? "bg-[#414141]"
                      : "bg-richBlack"
                  }`}
                >
                  <div className="sm:max-w-[222px] max-w-[264px] w-[264px]  flex flex-col">
                    <h2 className="uppercase text-center text-[25px] leading-[22.5px] font-bold  !tracking-[0.7px]  sm:text-center text-[#FFF] min-h-[45px]">
                      {item.membershipName}{" "}
                      {age > 13 && age < 16 && (
                        <span className="heading2 text-[rgba(37,51,213,1)]">
                          YOUTH
                        </span>
                      )}
                    </h2>
                    {changeMembership &&
                      item.id == info.memberDetails.membershipId && (
                        <div className="absolute right-0 top-0 rounded-bl-md text-white uppercase h-[26px] text-center py-1 px-2 text-sm bg-[#82FA00] font-semibold">
                          {item.id == info.memberDetails.membershipId &&
                            "your current plan"}
                        </div>
                      )}
                    <div
                      className={`mt-4 sm:text-center ${
                        type == "pif" ? "text-center" : "text-center"
                      }`}
                    >
                      <span
                        className={`font-abcgravity font-medium !leading-[90%] !tracking-[0.45px] sm:text-center text-[45px] text-[#FFFF]  ${
                          type == "pif" && "justify-self-center"
                        }`}
                      >
                        {" "}
                        {(item.costPrice + "").split(".").length > 1
                          ? "$" + (item.costPrice + "").split(".")[0]
                          : "$" + item.costPrice}
                        {(item.costPrice + "").split(".").length > 1 && "."}
                        {(item.costPrice + "").split(".")[1]}
                      </span>
                      {type != "pif" && (
                        <span className="uppercase font-abcgravity !leading-[90%] !font-black text-[28px] text-white  !tracking-[0.28px]  ">
                          /{" "}
                          {item.paymentFrequency.type.charAt(
                            item.paymentFrequency.type.length - 1
                          ) == "s"
                            ? item.paymentFrequency.type.slice(0, -1)
                            : item.paymentFrequency.type.slice(0, -1)}
                        </span>
                      )}
                      <p
                        className={`mt-[6px] font-abcmonument text-base md:text-xs sm:text-center h-[34px] mb-[7px] text-center leading-[132%]  ${
                          type == "weekly"
                            ? "text-[rgba(255,255,255,0.6)] text-center text-xs font-normal"
                            : "text-white text-center text-base font-medium"
                        }`}
                      >
                        {type == "weekly"
                          ? "$" +
                            item.costPrice *
                              (item.paymentFrequency.type.toLowerCase() ==
                              "weeks"
                                ? item.paymentFrequency.amount
                                : 0) +
                            " fortnightly Direct Debit"
                          : "SINGLE PAYMENT"}
                      </p>
                    </div>
                    {/* <span className='font-abcgravity text-gray mt-2 text-[12px]'>$878.80 single payment</span> */}
                    <div className="border-t border-t-borderLine">
                      <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] gap-[8px] sm:gap-[10px] ">
                        {item.description.split(",").map((i,index:number) => {
                          return (
                            <li key={index} className="flex items-center sm:gap-[14px] gap-[10px]">
                              <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-brand-clime">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="9"
                                  viewBox="0 0 12 9"
                                  fill="none"
                                >
                                  <path
                                    d="M1.17188 4.45871L4.82422 8.11106L11.6072 1.32812"
                                    stroke="#82FA00"
                                  />
                                </svg>
                              </span>
                              <span
                                className="font-abcmonument text-start sm:font-normal leading-[16px] !leading-[160%] text-white text-sm sm:text-[14px]  text-base font-bold"
                                dangerouslySetInnerHTML={createMarkup(
                                  i.replace("\n", "<br/>")
                                )}
                              ></span>
                            </li>
                          );
                        })}
                        {/* <li className='flex items-center gap-[14px]'>
                                <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                    <img className='w-auto h-auto' src={tick} alt="icon" />
                                </span>
                                <li className='font-abcgravity text-white text-sm'>24/7 access</li>
                            </li>
                            <li className='flex items-center gap-[14px]'>
                                <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                    <img className='w-auto h-auto' src={tick} alt="icon" />
                                </span>
                                <li className='font-abcgravity text-white text-sm'>No Lock-In Contract</li>
                            </li> */}
                      </ul>
                    </div>
                    {(!changeMembership ||
                      (changeMembership &&
                        item.id != info.memberDetails.membershipId)) && (
                      <div className="hidden sm:block w-full mt-auto">
                        <div
                          onClick={() => {
                            changeMembership
                              ? setInfo &&
                                setInfo({
                                  memberDetails: {
                                    ...info.memberDetails,
                                    membershipId: item.id,
                                    NewMembershipId: item.id,
                                    membershipLocationId:
                                      item?.membershipLocations?.items?.[0]?.id,
                                  },
                                  membershipDetails: { ...item },
                                })
                              : setInfo &&
                                setInfo({
                                  ...info,
                                  membershipId: item.id,
                                  membershipLocationId:
                                    item?.membershipLocations?.items?.[0]?.id,
                                });
                          }}
                        >
                          <div
                            className={`h-[40px] cursor-pointer transition-all w-full  !tracking-[0.6px] hover:text-black hover:bg-[#82FA00] hover:!border-[#82FA00] border border-white rounded-md flex font-abcmonument text-[12px] uppercase font-bold items-center justify-center text-white ${
                              info?.membershipId === item?.id ||
                              (changeMembership &&
                                item.id == info.memberDetails.NewMembershipId)
                                ? "bg-[#82FA00] !text-black !border-[#82FA00]"
                                : ""
                            }`}
                          >
                            {info?.membershipId === item?.id ||
                            (changeMembership &&
                              item.id == info.memberDetails.NewMembershipId)
                              ? "selected"
                              : "choose plan"}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {(!changeMembership ||
                  (changeMembership &&
                    item.id != info.memberDetails.membershipId)) && (
                  <div className="block sm:hidden w-full mt-auto">
                    <div
                      onClick={() => {
                        changeMembership
                          ? setInfo &&
                            setInfo({
                              memberDetails: {
                                ...info.memberDetails,
                                membershipId: item.id,
                                NewMembershipId: item.id,
                                membershipLocationId:
                                  item?.membershipLocations?.items?.[0]?.id,
                              },
                              membershipDetails: { ...item },
                            })
                          : setInfo &&
                            setInfo({
                              ...info,
                              membershipId: item.id,
                              membershipLocationId:
                                item?.membershipLocations?.items?.[0]?.id,
                            });
                      }}
                    >
                      <div
                        className={`h-[40px] mb-[18px] text-base cursor-pointer transition-all w-full hover:bg-[#82FA00] border  rounded-b-md flex font-medium  uppercase items-center  justify-center ${
                          info?.membershipId === item?.id ||
                          (changeMembership &&
                            item.id == info.memberDetails.NewMembershipId)
                            ? "bg-transparent border "
                            : "bg-transparent border text-gray hover:text-black"
                        }`}
                      >
                        {info?.membershipId === item?.id ||
                        (changeMembership &&
                          item.id == info.memberDetails.NewMembershipId)
                          ? "selected"
                          : "choose plan"}
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
            // <div className='py-[33px] relative border border-gray border-l-0 rounded-tr rounded-br bg-richBlack px-[50px] flex flex-col justify-center w-full'>
            //     <h2 className='uppercase heading2 text-white'>flexi</h2>
            //     <div className='flex items-end gap-2 mt-4'>
            //         <h1 className='bebas text-[45px] text-white '>$13.90</h1>
            //         <span className='uppercase text-white bebas text-[16px] '>/ week</span>
            //     </div>
            //     <span className='font-abcgravity text-gray mt-2 text-[12px]'>$878.80 single payment</span>
            //     <ul className='mt-7 w-full flex flex-col pt-3  gap-[10px] border-t border-t-borderLine'>
            //         <li className='flex items-center gap-[14px]'>
            //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
            //                 <img className='w-auto h-auto' src={tick} alt="icon" />
            //             </span>
            //             <li className='font-abcgravity text-white text-sm'>All clubs Australia wide</li>
            //         </li>
            //         <li className='flex items-center gap-[14px]'>
            //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
            //                 <img className='w-auto h-auto' src={tick} alt="icon" />
            //             </span>
            //             <li className='font-abcgravity text-white text-sm'>24/7 access</li>
            //         </li>
            //         <li className='flex items-center gap-[14px]'>
            //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
            //                 <img className='w-auto h-auto' src={tick} alt="icon" />
            //             </span>
            //             <li className='font-abcgravity text-white text-sm'>No Lock-In Contract</li>
            //         </li>
            //     </ul>
            //     <div className='mt-[34px] w-full'>
            //         <ChoosePlan />
            //     </div>
            //     <span className='absolute bg-[#82FA00] flex w-[103px] h-[27px] text-[11px] font-bold tracking-widest items-center font-abcgravity uppercase top-0 right-0 justify-center text-white'>popular</span>
            // </div>
          })}
      </div>
      {/*<div*/}
      {/*  className={`h-[49px]  w-full bg-[#1B1B1B] border border-[#6C6C6C] ${*/}
      {/*    toggle ? "rounded-md" : "rounded-t-md"*/}
      {/*  } mt-[37px] px-[25px] flex items-center justify-between`}*/}
      {/*>*/}
      {/*  <h6 className=" font-bold text-sm uppercase !leading-[30.123px] !tracking-[0.7px] text-white">*/}
      {/*    select you upgrades*/}
      {/*  </h6>*/}
      {/*  <div*/}
      {/*    onClick={() => setToggle(!toggle)}*/}
      {/*    className="h-full flex items-center lg:justify-center justify-end md:w-[70px]"*/}
      {/*  >*/}
      {/*    <svg*/}
      {/*      className={`${*/}
      {/*        toggle*/}
      {/*          ? "rotate-180 transition-transform"*/}
      {/*          : "rotate-0 transition-transform"*/}
      {/*      }`}*/}
      {/*      xmlns="http://www.w3.org/2000/svg"*/}
      {/*      width="17"*/}
      {/*      height="11"*/}
      {/*      viewBox="0 0 17 11"*/}
      {/*      fill="none"*/}
      {/*    >*/}
      {/*      <path*/}
      {/*        d="M15.4961 9.5332L8.53373 2.00436L1.00488 8.96672"*/}
      {/*        stroke="#82FA00"*/}
      {/*        strokeWidth="1.5"*/}
      {/*      />*/}
      {/*    </svg>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div*/}
      {/*  className={`${*/}
      {/*    toggle ? "h-0 overflow-hidden opacity-0 hidden" : "opacity-100"*/}
      {/*  } xl:min-full min-w-full  transition-transform grid lg:grid-cols-3 grid-cols-1 bg-[#1B1B1B] text-white gap-0 max-w-[796px]`}*/}
      {/*>*/}
      {/*  {upgradeData?.map((item) => (*/}
      {/*    <div className="px-[20px] border  border-[#6C6C6C] pt-[28px] pb-[14px]">*/}
      {/*      /!*  Logo *!/*/}
      {/*      /!* <img*/}
      {/*          className="lg:h-[104px] h-[131px] w-full object-cover"*/}
      {/*          src={item?.attributes?.image?.data?.attributes?.url}*/}
      {/*        /> *!/*/}
      {/*      /!* <img*/}
      {/*          className="h-[21px] w-auto mt-[20px]"*/}
      {/*          src={item?.attributes?.logo?.data?.attributes?.url}*/}
      {/*        /> *!/*/}
      {/*      /!* Description *!/*/}
      {/*      <div className="">*/}
      {/*        {item.membershipName}*/}
      {/*        /!* {item?.attributes?.features?.data?.map((i) => (*/}
      {/*            <div className="flex mb-[12px] gap-[10px] items-center">*/}
      {/*              <svg*/}
      {/*                width="15"*/}
      {/*                height="15"*/}
      {/*                viewBox="0 0 21 21"*/}
      {/*                fill="none"*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*              >*/}
      {/*                <circle*/}
      {/*                  cx="10.3918"*/}
      {/*                  cy="10.6437"*/}
      {/*                  r="9.39175"*/}
      {/*                  stroke={item?.attributes?.color}*/}
      {/*                />*/}
      {/*                <path*/}
      {/*                  d="M5.17188 10.6433L8.82422 14.2956L15.6072 7.5127"*/}
      {/*                  stroke={item?.attributes?.color}*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*              <p className="text-[12px]  leading-[160%] font-normal gotham">*/}
      {/*                {i?.attributes?.title}*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*          ))} *!/*/}
      {/*      </div>*/}
      {/*      /!* Price *!/*/}
      {/*      <div className=" flex items-center justify-between min-h-[40px] mt-auto">*/}
      {/*        <div className="flex-col self-start">*/}
      {/*          <p className="text-[14px] tracking-widest font-bold text-white">*/}
      {/*            ${item?.costPrice}/{item.paymentFrequency.type.toLowerCase()}*/}
      {/*          </p>*/}
      {/*          {item.paymentFrequency.type.toLowerCase() == "weeks" && (*/}
      {/*            <p className="text-[12px] font-normal leading-[160%] opacity-50  gotham text-white">*/}
      {/*              $*/}
      {/*              {parseInt(item.costPrice) **/}
      {/*                parseInt(item.paymentFrequency.amount)}{" "}*/}
      {/*              per fornight{" "}*/}
      {/*            </p>*/}
      {/*          )}*/}
      {/*        </div>*/}

      {/*        /!* <Selected*/}
      {/*            style="h-[28px] w-[38px] rounded-[5px] "*/}
      {/*            isPlus={true}*/}
      {/*          /> *!/*/}
      {/*        <div*/}
      {/*          className=" w-fit flex-none"*/}
      {/*          onClick={() => {*/}
      {/*            changeMembership*/}
      {/*              ? setInfo &&*/}
      {/*                setInfo({*/}
      {/*                  memberDetails: {*/}
      {/*                    ...info.memberDetails,*/}
      {/*                    membershipId: item.id,*/}
      {/*                    NewMembershipId: item.id,*/}
      {/*                    membershipLocationId:*/}
      {/*                      item?.membershipLocations?.items?.[0]?.id,*/}
      {/*                  },*/}
      {/*                  membershipDetails: { ...item },*/}
      {/*                })*/}
      {/*              : setInfo &&*/}
      {/*                setInfo({*/}
      {/*                  ...info,*/}
      {/*                  membershipId: item.id,*/}
      {/*                  membershipLocationId:*/}
      {/*                    item?.membershipLocations?.items?.[0]?.id,*/}
      {/*                });*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          <div*/}
      {/*            className={`h-[28px] w-[38px] rounded-md font-medium hover:text-brand-clime sm:font-bold cursor-pointer transition-all border  rounded-b-md flex font-abcgravity text-[12px] uppercase items-center  justify-center ${*/}
      {/*              info?.membershipId === item?.id ||*/}
      {/*              (changeMembership &&*/}
      {/*                item.id == info.memberDetails.NewMembershipId)*/}
      {/*                ? " border-white bg-white text-black"*/}
      {/*                : "bg-transparent  text-white border-white"*/}
      {/*            }`}*/}
      {/*          >*/}
      {/*            {info?.membershipId === item?.id ||*/}
      {/*            (changeMembership &&*/}
      {/*              item.id == info.memberDetails.NewMembershipId) ? (*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="40"*/}
      {/*                height="30"*/}
      {/*                viewBox="0 0 40 30"*/}
      {/*                fill="none"*/}
      {/*              >*/}
      {/*                <rect*/}
      {/*                  x="1"*/}
      {/*                  y="1"*/}
      {/*                  width="38"*/}
      {/*                  height="28"*/}
      {/*                  rx="4.71246"*/}
      {/*                  fill="white"*/}
      {/*                  stroke="white"*/}
      {/*                  stroke-width="0.942492"*/}
      {/*                />*/}
      {/*                <path*/}
      {/*                  d="M14 13.6923L18.2 18L26 10"*/}
      {/*                  stroke="black"*/}
      {/*                  stroke-width="1.5"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            ) : (*/}
      {/*              <svg*/}
      {/*                xmlns="http://www.w3.org/2000/svg"*/}
      {/*                width="10"*/}
      {/*                height="10"*/}
      {/*                viewBox="0 0 10 10"*/}
      {/*                fill="none"*/}
      {/*              >*/}
      {/*                <path*/}
      {/*                  d="M4.25 9.8H5.75V5.68H9.89V4.24H5.75L5.75 0.119999H4.25L4.25 4.24L0.11 4.24V5.68L4.25 5.68V9.8Z"*/}
      {/*                  fill="white"*/}
      {/*                />*/}
      {/*              </svg>*/}
      {/*            )}*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  ))}*/}
      {/*</div>*/}

      {/* Terms */}
      {/* <div className='mt-[34px] flex items-center gap-3'>
                <CheckboxActive />
                <span className='text-white font-abcgravity'>See junior or student rates (For persons under 17 only).</span>

            </div> */}

      {/* Buttons to actions */}
      <div className="flex items-center mt-[56px] justify-between">
        <div onClick={() => onPrev()}>
          <div className="hidden md:flex">
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="flex md:hidden">
            <ArrowButton text="" isRight={false} />
          </div>
        </div>
        <div
          onClick={() => {
            if (
              info?.membershipId ||
              (changeMembership && info.memberDetails.NewMembershipId)
            )
              onNext();
            else toastError("Please select a membership");
          }}
        >
          <div className="hidden md:flex">
            <ArrowButton text="next" isRight={true} />
          </div>
          <div className="flex md:hidden">
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memberships;
