import React, { useEffect, useState } from "react";
import logoViva from "../assets/images/viva-logo.svg";
import ggStore from "../assets/images/icons/ggStore.png";
import appStore from "../assets/images/icons/appStore.png";
import NewsLetterSignUp from "./home/NewsLetterSignUp";
import { footer } from "../../mock/footer";
import { Link } from "gatsby";
import ExploreViva from "./animated/ExploreViva";
import LoadingScreen from "../common/loading/LoadingScreen";
import clubLogo from "../assets/images/vivaBrands/clublime-logo.png";
import useFetch from "../hooks/useFetch";

function Footer() {
  const [exploreModal, setExploreModal] = useState<boolean>(false);
  const [footerInfo, setFooter] = useState();
  const { data, loading } = useFetch({
    params: "footer",
    populate:
      "image,socials,socials.image,brands,brands.logo,links, links.listLink",
  });

  useEffect(() => {
    if (data) setFooter(data);
  }, [data]);

  return (
    <>
      {loading && <LoadingScreen />}
      {data != null && footerInfo && (
        <div>
          <NewsLetterSignUp />
          <div className="">
            <div className=" justify-between px-[30px] mb-[55px]  mt-[52px] flex-col ">
              <img src={clubLogo} className="mb-[42px] w-[193px]" alt="Logo" />

              <div className=" grid grid-cols-4 grid-flow-row gap-y-3 xl:grid-cols-12 xl:grid-flow-row text-white cursor-pointer ">
                {/* //explore */}
                <div className="col-span-2">
                  <h2 className="text-gray mb-[11px] Mono font-medium tracking-widest">
                    {footerInfo.data.attributes.links[0].title}
                  </h2>
                  <div className="flex flex-col gap-2 text-white">
                    {footerInfo.data.attributes.links[0].listLink.map(
                      (item, index) => (
                        <div key={index} className="Mono">
                          {/* {item.name === "Classes" || item.name == "Timetable" ? (
                          <a
                            href="https://members.hiitrepublic.com.au/classes"
                            className="font-abcmonument"
                          >
                            {item.name}
                          </a>
                        ) : ( */}
                          <Link to={`${item.link}`}>
                            <span className="Mono">{item.title}</span>
                          </Link>
                          {/* )} */}
                        </div>
                      )
                    )}
                  </div>
                </div>

                {/* //support */}
                <div className="col-span-2">
                  <h2 className="text-gray mb-[11px] Mono font-medium tracking-widest">
                    {footerInfo.data.attributes.links[1].title}
                  </h2>
                  <div className="flex flex-col gap-2 text-white">
                    {footerInfo.data.attributes.links[1].listLink.map(
                      (item, index) => (
                        <Link key={index} to={`${item.link}`}>
                          <span className="Mono">{item.title}</span>
                        </Link>
                      )
                    )}
                  </div>
                </div>

                {/* //joinUs */}
                <div className="col-span-2">
                  <h2 className="text-gray Mono mb-[11px] font-medium tracking-widest">
                    {footerInfo.data.attributes.links[2].title}
                  </h2>
                  <div className="flex flex-col gap-2 text-white">
                    {footerInfo.data.attributes.links[2].listLink.map(
                      (item, index) => (
                        <Link to={`${item.link}`} key={index}>
                          <span className="Mono">{item.title}</span>
                        </Link>
                      )
                    )}
                  </div>
                  {/* //member */}
                  <div className="mt-[39px]">
                    <h2 className="text-gray mb-[11px] font-medium tracking-widest Mono">
                      {footerInfo.data.attributes.links[3].title}
                    </h2>
                    <div className="flex flex-col gap-2 text-white">
                      {footerInfo.data.attributes.links[3].listLink.map(
                        (item, index) => (
                          <a href={`${item.link}`} key={index}>
                            <span className="Mono">{item.title}</span>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
                {/* OUR BRANDS */}
                <div className="col-span-2">
                  <h2 className="text-gray Mono text-[15px] mb-[11px] font-medium tracking-widest">
                    {footerInfo.data.attributes.links[4].title}
                  </h2>
                  <div className="flex flex-col gap-2 text-white">
                    {footerInfo.data.attributes.links[4].listLink.map(
                      (item, index) => (
                        <Link to={`${item.link}`} key={index}>
                          <span className="Mono">{item.title}</span>
                        </Link>
                      )
                    )}
                  </div>
                </div>
                {/* //appId */}
                <div className="col-span-4 text-start">
                  <div className="flex text-white  flex-col">
                    <div>
                      <h2 className="text-gray Mono font-medium mt-[20px] xl:mt-0 tracking-widest">
                        {footer.membersIdApp.title}
                      </h2>
                    </div>{" "}
                    <div className="flex ">
                      <a
                        href={footerInfo?.data.attributes.appStoreUrl}
                        target="_blank"
                      >
                        <img src={appStore} className="mt-[2px]" />
                      </a>
                      <a
                        href={footerInfo?.data.attributes.playStoreUrl}
                        target="_blank"
                      >
                        <img src={ggStore} className=" " />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" grid xl:grid-cols-7 xl:grid-flow-row grid-cols-4 grid-flow-row">
              <div className="flex xl:flex-[60%] flex-[100%] flex-col xl:flex-row col-span-4 xl:mx-0 mx-[14px]  xl:ml-[30px] border-t border-[#666666]">
                {footerInfo?.data.attributes.brands.map((e, index) => (
                  <div
                    key={index}
                    className="flex w-full xl:w-1/3 items-center justify-center border-b border-[#666666] xl:border-r"
                  >
                    <img
                      src={e.logo.data.attributes.url}
                      alt="logo"
                      className="w-[157px] my-[15px]"
                    />
                  </div>
                ))}
              </div>
              <div className="mx-[14px]  xl:col-span-3 col-span-4 flex xl:pl-[40px] xl:pr-[20px] items-center py-[16px]  xl:mx-0  xl:border-y border-b  justify-between border-[#666666] xl:mr-[30px] ">
                <p className="text-white w-9/12  Mono leading-[160%] font-normal text-[14px]">
                  {footerInfo?.data.attributes.exploreText ??
                    "Club Lime is a subsidiary company of Viva Leisure"}
                </p>
                <div onClick={() => setExploreModal(true)}>
                  <img src={logoViva} alt="logo" className="w-[80px]" />
                </div>
                {/* Explore Viva Modal */}
                {exploreModal && (
                  <ExploreViva setExploreModal={setExploreModal} />
                )}
              </div>
            </div>
            <div className="flex  mx-[14px] xl:mx-[30px] mb-[30px]  xl:pl-[78px] py-[16px] xl:pr-[0px] md:justify-end justify-start  items-center bg-black  text-left border-[#666666] border-b ">
              <h2 className="text-white Mono font-normal text-[16px]">
                {footerInfo?.data.attributes.copyrightText ??
                  "Viva Leisure Pty Ltd Copyright © 2023"}
              </h2>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
