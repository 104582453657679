export const StudioMail = {
    "Campbelltown 2 (Tindall Street)": "campbelltown@clublime.com.au",
    "Campbelltown (Macarthur Square)": "macarthursquare@clublime.com.au",
    "Corrimal": "corrimal@clublime.com.au",
    "Gregory Hills": "gregoryhills@clublime.com.au",
    "Shellharbour": "shellharbour@clublime.com.au",
    "Wollongong": "wollongong@clublime.com.au",
    "Blacktown": "blacktown@clublime.com.au",
    "Castle Hill": "castlehill@clublime.com.au",
    "Hurstville": "hurstville@clublime.com.au",
    "Liverpool": "liverpool@clublime.com.au",
    "Penrith": "penrith@clublime.com.au",
    "Rouse HIll": "rousehill@clublime.com.au",
    "Wetherill Park": "wetherillpark@clublime.com.au",
    "Burwood": "burwood@clublime.com.au",
    "Five Dock": "fivedock@clublime.com.au",
    "Rhodes (GFitness)": "rhodes@clublime.com.au",
    "St Peters": "stpeters@clublime.com.au",
    "Gladesville": "gladesville@clublime.com.au",
    "Blacktown": "blacktown@clublime.com.au",
    "ANU": "anu@clublime.com.au",
    "Belconnen (Oatley Court)": "belconnen@clublime.com.au",
    "Belconnen (Westfield)": "belconnenmall@clublime.com.au",
    "Braddon": "braddon@clublime.com.au",
    "Campbell": "campbell@clublime.com.au",
    "Conder": "conder@clublime.com.au",
    "Constitution Place (City)": " constitutionpl@clublime.com.au",
    "Coombs": "coombs@clublime.com.au",
    "Curtin": "curtin@clublime.com.au",
    "Denman Prospect": "denmanprospect@clublime.com.au",
    "Dickson": "dickson@clublime.com.au",
    "Erindale": "erindale@clublime.com.au",
    "Fyshwick": "fyshwick@clublime.com.au",
    "Googong": "googong@clublime.com.au",
    "Goulburn - Hume St": "goulburn@clublime.com.au",
    "Goulburn - Lansdowne St": "goulburn@clublime.com.au",
    "Greenway": "greenway@clublime.com.au",
    "Greenway Ladies Only": "info@greenway.clubpink.com.au",
    "Gungahlin": "gungahlin@clublime.com.au",
    "Gungahlin Platinum": "gungahlinplatinum@clublime.com.au",
    "Kambah": "kambah@clublime.com.au",
    "Kingston - Club Lime": "kingstonclub@clublime.com.au",
    "Kippax": "kippax@clublime.com.au",
    "Mawson": "mawson@clublime.com.au",
    "Mitchell": "mitchell@clublime.com.au",
    "Phillip": "phillip@clublime.com.au",
    "Queanbeyan": "queanbeyan@clublime.com.au",
    "Weston": "weston@clublime.com.au",
    "Woden": "woden@clublime.com.au",
    "Dickson": "dickson@clublime.com.au",
    "Maroochydoore": "maroochydoore@clublime.com.au",
    "Mooloolaba": "mooloolaba@clublime.com.au",
    "Newstead Gasworks": "gasworks@clublime.com.au",
    "Noosaville": "noosaville@clublime.com.au",
    "Redcliffe": "redcliffe@clublime.com.au",
    "Sippy Downs": "sippydowns@clublime.com.au",
    "Teneriffe": "teneriffe@clublime.com.au",
    "Broadbeach": "broadbeach@clublime.com.au",
    "Cleveland": "cleveland@clublime.com.au",
    "East Brisbane": "eastbrisbane@clublime.com.au",
    "Ipswich": "ipswich@clublime.com.au",
    "Norman Park (Active Life Fitness)": "normanpark@clublime.com.au",
    "Springfield": "springfield@clublime.com.au",
    "Victoria Point": "victoriapoint@clublime.com.au",
    "West End": "westend@clublime.com.au",
    "Yamanto": "yamanto@clublime.com.au",
    "Ballarat": "ballarat@clublime.com.au",
    "Belmont (Geelong)": "belmont@clublime.com.au",
    "Brunswick": "brunswick@clublime.com.au",
    "Coburg": "coburg@clublime.com.au",
    "South Yarra": "southyarra@clublime.com.au",
    "Thomastown (Stryve)": "thomastown@clublime.com.au",
    "Forest Hill": "foresthill@clublime.com.au",
    "Malvern": "malvern@clublime.com.au",
    "Mulgrave": "mulgrave@clublime.com.au",
    "Nunawading": "nunawading@clublime.com.au",
    "Oakleigh": "oakleigh@clublime.com.au",
    "Scoresby": "scoresby@clublime.com.au",
    "Shepparton": "shepparton@clublime.com.au",
}