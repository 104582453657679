import course from '../src/assets/images/StudioDetailInfo/classes.jpg';
export const timeTable = [
  {
    title: '24/7 access',
    descTitle:"what you're in for",

    image: course,
    desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
    time: '07:30-8:15',
    class: 'republic throwdown',
    coach: 'laura b',
    club: 'anu',
    studio: 1,
    sweatRating: 3.5,
    mood: 'hardcore',
    almostFull:'17/20'
  },
  {
    title: 'industry-leading coaches',
    descTitle:"what you're in for",

    image: course,
    desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
    time: '08:30-9:15',
    class: 'republic peak',
    coach: 'tom r',
    club: 'campbell',
    studio: 1,
    sweatRating: 4,
    mood: 'hardcore',
    almostFull:'17/20'
  },
  {
    title: 'keyleses entry',
    descTitle:"what you're in for",

    image: course,
    desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
    time: '09:30-10:00',
    class: 'republic amrap',
    coach: 'caitlin f',
    club: 'braddon',
    studio: 1,
    sweatRating: 4,
    mood: 'hardcore',
    almostFull:'17/20'
  },
  {
    title: 'no lock in contracts',
    descTitle:"what you're in for",

    image: course,
    desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
    time: '10:30-11:00',
    class: 'republic yoga',
    coach: 'sam d',
    club: 'fyshwick',
    studio: 1,
    sweatRating: 4,
    mood: 'hardcore',
    almostFull:'17/20'
  },
  {
    title: 'unlimited classes',
    descTitle:"what you're in for",

    image: course,
    desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
    time: '11:30-12:00',
    class: 'republic shred',
    coach: 'ashton k',
    club: 'braddon',
    studio: 1,
    sweatRating: 4,
    mood: 'hardcore',
    almostFull:'17/20'
  },
];


export const timeTableDashBoard = [
  {
    '1/1': [{
      title: '24/7 access',
      descTitle: "what you're in for",
      brand: 'club lime',
      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '07:30-8:15',
      class: 'republic throwdown',
      coach: 'laura b',
      club: 'anu',
      studio: 1,
      sweatRating: 3.5,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'industry-leading coaches',
      descTitle: "what you're in for",
      brand: 'hiit republic',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '08:30-9:15',
      class: 'republic peak',
      coach: 'tom r',
      club: 'campbell',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'keyleses entry',
      descTitle: "what you're in for",
      brand: 'ground up',
      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '09:30-10:00',
      class: 'republic amrap',
      coach: 'caitlin f',
      club: 'braddon',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'no lock in contracts',
      descTitle: "what you're in for",
      brand: 'ground up',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '10:30-11:00',
      class: 'republic yoga',
      coach: 'sam d',
      club: 'fyshwick',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'unlimited classes',
      descTitle: "what you're in for",
      brand: 'club lime',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '11:30-12:00',
      class: 'republic shred',
      coach: 'ashton k',
      club: 'braddon',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },]
  }, {
    '2/1': [{
      title: '24/7 access',
      descTitle: "what you're in for",
      brand: 'club lime',
      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '07:30-8:15',
      class: 'republic throwdown',
      coach: 'laura b',
      club: 'anu',
      studio: 1,
      sweatRating: 3.5,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'industry-leading coaches',
      descTitle: "what you're in for",
      brand: 'hiit republic',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '08:30-9:15',
      class: 'republic peak',
      coach: 'tom r',
      club: 'campbell',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'keyleses entry',
      descTitle: "what you're in for",
      brand: 'ground up',
      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '09:30-10:00',
      class: 'republic amrap',
      coach: 'caitlin f',
      club: 'braddon',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'no lock in contracts',
      descTitle: "what you're in for",
      brand: 'ground up',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '10:30-11:00',
      class: 'republic yoga',
      coach: 'sam d',
      club: 'fyshwick',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'unlimited classes',
      descTitle: "what you're in for",
      brand: 'club lime',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '11:30-12:00',
      class: 'republic shred',
      coach: 'ashton k',
      club: 'braddon',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },]
  }, {
    '3/1': [{
      title: '24/7 access',
      descTitle: "what you're in for",
      brand: 'club lime',
      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '07:30-8:15',
      class: 'republic throwdown',
      coach: 'laura b',
      club: 'anu',
      studio: 1,
      sweatRating: 3.5,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'industry-leading coaches',
      descTitle: "what you're in for",
      brand: 'hiit republic',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '08:30-9:15',
      class: 'republic peak',
      coach: 'tom r',
      club: 'campbell',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'keyleses entry',
      descTitle: "what you're in for",
      brand: 'ground up',
      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '09:30-10:00',
      class: 'republic amrap',
      coach: 'caitlin f',
      club: 'braddon',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'no lock in contracts',
      descTitle: "what you're in for",
      brand: 'ground up',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '10:30-11:00',
      class: 'republic yoga',
      coach: 'sam d',
      club: 'fyshwick',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },
    {
      title: 'unlimited classes',
      descTitle: "what you're in for",
      brand: 'club lime',

      image: course,
      desc: 'Vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi. Vero eos et accusamus et iusto odio dignissimos ducimus atque corrupti. ',
      time: '11:30-12:00',
      class: 'republic shred',
      coach: 'ashton k',
      club: 'braddon',
      studio: 1,
      sweatRating: 4,
      mood: 'hardcore',
      almostFull: '17/20'
    },]
  }
];



export const plans = [
  {
    title: 'single',
    price: '16.90',
    popular: false,
    type: 'weekly',
    desc: ['24/7 access*', 'Includes one club', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'state',
    price: '24.90',
    popular: true,
    type: 'weekly',
    desc: ['24/7 access*', 'Includes all clubs in ACT', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'national',
    price: '32.90',
    popular: false,
    type: 'weekly',
    desc: ['24/7 access*', 'Includes all clubs in AUS', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'national',
    price: '32.90',
    popular: false,
    type: 'annual',
    desc: ['24/7 access*', 'Includes all clubs in AUS', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'state',
    price: '24.90',
    popular: false,
    type: 'annual',
    desc: ['24/7 access*', 'Includes all clubs in ACT', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'single',
    price: '16.90',
    popular: true,
    type: 'annual',
    desc: ['24/7 access*', 'Includes one club', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'national',
    price: '32.90',
    popular: false,
    type: 'fixed tearm',
    desc: ['24/7 access*', 'Includes all clubs in AUS', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'single',
    price: '16.90',
    popular: true,
    type: 'fixed tearm',
    desc: ['24/7 access*', 'Includes one club', 'Cancel anytime*', 'Free aaccess pass'],
  },
  {
    title: 'state',
    price: '24.90',
    popular: false,
    type: 'fixed tearm',
    desc: ['24/7 access*', 'Includes all clubs in ACT', 'Cancel anytime*', 'Free aaccess pass'],
  },
];
