import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import logo from "../assets/images/vivaBrands/clublime-logo.png";
import { Link, navigate } from "gatsby";
import { menuNavbar } from "../../mock/homePage";
import { HambergerMenu } from "./animated/HambergerMenu";
import { RegisterContext } from "../context/RegisterContext";
import ChangeLocation from "./map/ChangeLocation";
import noNameAvatar from "../assets/images/icons/noNameAvatar.png";
import ModalBase from "../common/modals/ModalBase";
import { Auth } from "aws-amplify";
import { AuthContext } from "../apis/auth";

function Navbar() {
  const setOpen = useContext(RegisterContext).setOpen;
  const [toggle, setToggle] = useState<boolean>(false);

  const [isLogged, setIsLogged] = useState<boolean>(false);

  const [animated, setIsAnimated] = useState(false);
  const [currentItem, setCurrentItem] = useState<number>();

  const reCreate = useContext(AuthContext).Init;

  // Active When jumping new page
  useEffect(() => {
    menuNavbar.map((i, index) => {
      if (window.location.pathname === "/" + i.path) {
        setCurrentItem(index);
      }
    });
    window.localStorage.getItem("userInfo") && setIsLogged(true);
  }, []);
  const openChange = useContext(RegisterContext).setOpen;
  const yourClub = useContext(RegisterContext).yourClub;

  // Logout
  // const handleLogout = () => {
  //   navigate('/login')
  // }
  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    try {
      if (window.localStorage.getItem("userInfo") == "Deval") {
        setLoggedIn(true);
        setUserInfo(JSON.parse(window.localStorage.getItem("currentUserDetail")));
      } else {
        setLoggedIn(false);
      }
    } catch (err) { }
  }, []);
  const [drop, setDrop] = useState(false);
  const [logout, setLogout] = useState(false);
  return (
    <>
      {logout && (
        <div className="">
          <ModalBase closeModal={setLogout}>
            <div className=" w-[800px] h-[400px] p-6 flex flex-col items-center justify-center">
              <h2 className="bebas text-[35px] font-normal leading-[90%] text-white uppercase">
                are you sure you want to log out ?{" "}
              </h2>
              <div className="flex items-center mt-[30px] justify-center gap-[20px]">
                <button
                  onClick={async () => {
                    window.localStorage.removeItem("userInfo");
                    navigate("/login");
                    await Auth.signOut();
                    reCreate();
                  }}
                  className="w-[110px] h-[40px] uppercase rounded border-white border bg-black text-[11.2px] gotham  text-white hover:bg-white hover:text-black transition-all"
                >
                  log out
                </button>
                <button className="w-[110px] h-[40px] border-white uppercase border rounded text-[11.2px] gotham  bg-black text-white hover:bg-white hover:text-black transition-all">
                  cancel
                </button>
              </div>
            </div>
          </ModalBase>
        </div>
      )}
      <ChangeLocation />
      <div className="bg-[black]  w-full header flex items-center border-b border-brand-clime  justify-between h-[70px] px-[12px] md:px-[40px] sticky top-[0px] z-[50]">
        <Link to="/">
          <img src={logo} className="w-[140px] sm:w-[230px]" alt="logo" />
        </Link>
        <div className="menu-mobile hidden">
          <HambergerMenu />
        </div>
        <ul
          id="animated-menu"
          onClick={() => setIsAnimated(true)}
          className="flex  items-center cursor-pointer text-[18px] gap-7 text-white font-bold "
        >
          {menuNavbar.map((item, index: number) => (
            <div className="cursor-pointer">
              <Link to={"/" + item.path} key={index}>
                <li
                  className={`relative cursor-pointer  text-sm ${currentItem == index ? "text-brand-clime" : "text-white"
                    } font-medium uppercase`}

                  onClick={() => setCurrentItem(index)}
                >
                  {item.name}
                  {/* curr:{currentItem} idx:{index} */}
                  {currentItem === index && (
                    <motion.div
                      layoutId="underline"
                      style={{ height: "4px" }}
                      className="absolute bottom-[-25px] w-full left-0 bg-brand-clime"
                    ></motion.div>
                  )}
                </li>
              </Link>
            </div>
          ))}
        </ul>
        {!loggedIn ? (
          <div className="flex relative h-[70px] text-[18px] register items-center gap-[22px]">
            <Link to="/register">
              <button className="bg-lime-primary text-[14px] w-[108px] h-[37px] border-[1px] rounded-[5px]  text-center text-black cursor-pointer font-medium Mono">
                JOIN NOW
              </button>
            </Link>
            {isLogged ? (
              <button
                onClick={() => {
                  window.localStorage.removeItem("userInfo");
                  navigate("/login");
                }}
                className="text-[14px] w-[108px] h-[37px] border-[1px] rounded-[5px] border-white text-center text-white cursor-pointer  gotham font-medium"
              >
                LOG OUT
              </button>
            ) : (
              <Link to="/login">
                <button className="text-[14px] w-[108px] h-[37px] border-[1px] rounded-[5px] border-white text-center text-white cursor-pointer font-medium Mono">
                  LOG IN
                </button>
              </Link>
            )}

            <div className="absolute text-white top-full right-0 bg-[#292929] w-[297px] h-[33px] rounded-br-[5px] rounded-bl-[5px] border-b border-l border-r border-brand-clime">
              <div className="w-full flex h-full justify-around items-center">
                <div className="w-[9px] h-[9px] bg-lime-primary rounded-full"></div>
                <div className="font-medium gotham text-sm uppercase">
                  {yourClub?.attributes?.title},{' '}
                  {yourClub?.attributes?.address?.Name
                    ? yourClub?.attributes?.address?.Name
                    : yourClub?.attributes?.address?.data?.attributes?.Name}
                </div>
                <div
                  onClick={() => setOpen(true)}
                  className="text-gray underline  graphik-regular text-sm cursor-pointer gotham"
                >
                  Change
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[238px]  gap-6 items-center justify-end relative xl:flex hidden">
            <p className="text-white Bossa text-sm w-max">
              Hello {userInfo?.surname?.toUpperCase()}
            </p>
            <img
              onClick={() => {
                setDrop(!drop);
              }}
              src={noNameAvatar}
            />
            {drop && (
              <div className="absolute flex flex-col top-[120%] right-0 text-white bg-[#13181b] border-brand-clime border rounded-md p-3 gap-2">
                <Link
                  to="/dashboard"
                  className="hover:text-brand-clime gotham text-sm "
                >
                  My Dashboard
                </Link>
                <Link
                  to="/dashboard/account"
                  className="hover:text-brand-clime gotham text-sm "
                >
                  My Profile
                </Link>
                <div
                  onClick={() => setLogout(true)}
                  className="hover:text-brand-clime gotham text-sm "
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Animated menu */}
      <div
        onClick={() => setIsAnimated(false)}
        style={{ display: animated ? "block" : "none" }}
        className="fixed top-0 z-10  w-full h-full"
      ></div>

      <div
        id="animated-menu"
        className={animated ? "animated mt-[40px]" : "hide-menu "}
      >
        {/* <AnimatedMenu
          currentItem={currentItem}
          setAnimated={setIsAnimated}
          setCurrentItem={setCurrentItem}
        /> */}
      </div>
      <p className="text-brand-clime sticky xl:hidden block   w-max h-0  left-full right-[69px]  top-[95%] transform  z-100  origin-left  text-xl ml-5">
        <div className="h-[46px]  bg-black cursor-pointer w-[192px] mr-[23px] rounded-tl-[10px] rounded-tr-[10px] gotham font-normal flex items-center text-[16px] justify-center border border-brand-clime">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="9"
            viewBox="0 0 9 9"
            fill="none"
          >
            <circle cx="4.5" cy="4.5" r="4.5" fill="#82FA00" />
          </svg>
          <span className="gotham text-[13px] text-white ml-[7px]">
            {" "}
            Braddon, ACT
          </span>
          <p
            className="text-gray gotham text-[12px] underline font-normal ml-3"
            onClick={() => openChange(true)}
          >
            Change
          </p>
        </div>
      </p>
    </>
  );
}

export default Navbar;
