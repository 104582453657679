import React from "react";
import classForE1 from "../../assets/images/homeClubLime/classForE1.png";
import classForE2 from "../../assets/images/homeClubLime/classForE2.png";
import useFetch from "../../hooks/useFetch";

export default function ClassForEveryBody() {
  const { data } = useFetch({
    params: "group-fitness-page",
    populate: "midBanner, midBanner.img1, midBanner.img2",
  });

  return (
    <>
      {data && data.data != null && (
        <div className="text-brand-clime md:flex-row flex-col md:px-[47px] py-[70px] flex gap-x-10">
          <div className="w-full md:w-[47%] ">
            <div className="px-[14px] md:px-0">
              <p className="text-effect text-transparent bebas text-[70px] md:text-[100px] leading-[90%] ">
                {data.data.attributes.midBanner.title.split("<br/>")[0] ??
                  "A CLASS"}
              </p>{" "}
              <p className="text-brand-clime bebas text-[70px] md:text-[100px] leading-[90%] md:text-start">
                {" "}
                {data.data.attributes.midBanner.title.split("<br/>")[1] ??
                  "FOR EVERY BODY"}
              </p>
            </div>
            <img
              className="mt-[46px] md:w-auto w-[300px] ml-auto md:ml-0 md:mt-[88px] md:block hidden "
              src={
                data.data.attributes.midBanner.img2.data?.attributes?.url ??
                classForE2
              }
            />
             <img
              className="mt-[46px] md:w-auto w-[300px] ml-auto md:ml-0 md:mt-[88px] md:hidden block "
              src={
                data.data.attributes.midBanner.img1.data?.attributes?.url ??
                classForE1
              }
            />
          </div>
          <div className="w-full md:w-[53%]">
            <img
              className="md:ml-auto mr-auto md:w-auto w-[273px] mt-[40px] md:mt-0 mb-[50px] md:mb-[88px] md:block hidden "
              src={
                data.data.attributes.midBanner.img1.data?.attributes?.url ??
                classForE1
              }
            />
             <img
              className="md:ml-auto mr-auto md:w-auto w-[273px] mt-[40px] md:mt-0 mb-[50px] md:mb-[88px] md:hidden block  "
              src={
                data.data.attributes.midBanner.img2.data?.attributes?.url ??
                classForE2
              }
            />
            <div className="mx-[14px] md:mx-0">
              <p className="text-brand-clime tracking-[0.1em] gotham mb-[20px] uppercase">
                {data?.data?.attributes?.midBanner?.altTitle ??
                  "36+ class types Lorem ipsum"}
              </p>
              <p className="text-white text-base Mono">
                {data?.data?.attributes?.midBanner?.description ??
                  `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                aliquam erat volutpat. Ut wisi enim ad minim veniam, quis
                nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                in hendrerit in vulputate velit esse molestie consequat, vel.`}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
