import React from 'react';
import loadingData from '../../assets/images/loading.mp4';

function LoadingScreen() {
  return (
    <video style={{ width: '100%', objectFit: 'fill', height: '513px' }} autoPlay>
      <source src={loadingData} type="video/mp4"></source>
    </video>
  );
}

export default LoadingScreen;
