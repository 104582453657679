import React, { ReactNode } from "react"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
interface SeoProps {
    title: string,
    description: string,
    pathname: string,
    children: ReactNode

}
export const SEO = ({ title, description, pathname, children }: SeoProps) => {
    const { title: defaultTitle, description: defaultDescription, image, siteUrl, } = useSiteMetadata()

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image}`,
        url: `${siteUrl}${pathname || ``}`,
    }

    return (
        <>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            {children}
        </>
    )
}