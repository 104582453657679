import React, { useContext } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import SelectOption from "../../common/dropdowns/SelectOption";
import phoneIcon from "../../assets/images/icons/phoneIcon.svg";
import mailIcon from "../../assets/images/icons/mailIcon.svg";
import { RegisterContext } from "../../context/RegisterContext";
import { navigate } from "gatsby";

interface props {
  data: any;
}

export default function Intro({ data, graphData }: props) {
  const yourClub = useContext(RegisterContext).yourClub;
  const setOpen = useContext(RegisterContext).setOpen;
  return (
    <div className="text-brand-clime mx-[14px] md:mx-[44px] my-[50px]">
      <p className="Gravity uppercase text-[98px] font-normal leading-[90px]  mb-[73px]">
        {graphData?.name ?? "no data"}
      </p>
      <div className="grid md:grid-cols-3 lg:grid-cols-4 md:grid-flow-row gap-x-[70px] grid-cols-1 grid-flow-row gap-y-5">
        <div>
          <p className="text-brand-clime gotham">STARTING FROM</p>
          <div className="mt-[36px] mb-[45px]">
            <p className="text-brand-clime text-[48px] leading-[72px] bebas ">
              <span className="text-[80px] bebas">
                ${(data?.price + "").split(".")[0]}
              </span>
              .{(data?.price + "").split(".")[1]}/WEEK
            </p>
          </div>
          <ButtonNavigate text="JOIN NOW " isArrowRight={true} onClick={()=>navigate("/register")} />
        </div>
        <div>
          <p className="text-brand-clime gotham ">FIND US</p>
          <div>
            <p className="text-white gotham text-sm font-normal mb-[45px] mt-[25px]">
              {graphData?.address?.address ??
                "Unit 75, 76/30 Lonsdale St, Braddon ACT 2612"}
            </p>
            {/* <p>CONTACT US</p> */}
            {/* <SelectOption
              isTitle={true}
              title="Staffed hours"
              style="border-0 w-[206px] px-0 py-0 graphik-regular"
              titleStyle="graphik-regular mb-[11px] text-[16px]"
              placeholder={"Today 9:00am - 5:00pm"}
              placeholderColor="text-white"
              data={["Today 9:00am - 5:00pm", "Monday 11:00am - 5:00pm"]}
            ></SelectOption> */}
            <SelectOption
              isTitle={true}
              title="CONTACT US"
              style="border-0 px-0 py-0 gotham"
              titleStyle="gotham mb-[8px] text-[16px]  "
              placeholder={"Today 9:00am - 5:00pm"}
              placeholderColor="text-white"
              data={
                data?.openTime?.map(
                  (i) =>
                    i.from.split(":").splice(0, 2).join(":") +
                    " - " +
                    i.to.split(":").splice(0, 2).join(":")
                )
                // ["Today 9:00am - 5:00pm", "Monday 11:00am - 5:00pm"]
              }
            ></SelectOption>
            <span className="flex  gap-[19px] my-[26px]">
              <img src={phoneIcon} />
              <p className="gotham text-white">
                {graphData?.phoneNumber ?? "13 22 44"}
              </p>
            </span>
            <span className="flex gap-[19px] ">
              <img src={mailIcon} />
              <p className="gotham text-white">
                {graphData?.email ?? "braddon@clublime.com.au"}
              </p>
            </span>
          </div>
        </div>
        <div>
          <p className="text-brand-clime gotham ">OPEN 24/7</p>
          <div className="mt-[25px] mb-[45px]">
            <SelectOption
              isTitle={true}
              title="STAFFED HOURS"
              style="border-0 px-0 py-0 gotham"
              titleStyle="gotham mb-[8px] text-[16px]  "
              placeholder={"Today 9:00am - 5:00pm"}
              placeholderColor="text-white"
              data={
                graphData?.locationAccessTimes?.items?.map(
                  (i) => i.day + ' | ' +
                    i.openTime +
                    " - " +
                    i.closeTime
                )
                // ["Today 9:00am - 5:00pm", "Monday 11:00am - 5:00pm"]
              }
            ></SelectOption>
            <div className="mt-[40px]">
              <div className="flex justify-between mb-[11px]">
                <p className="text-white gotham text-sm">Select location:</p>
                <div className="text-gray underline gotham text-sm cursor-pointer gotham" onClick={() => setOpen(true)}>
                  Change State
                </div>
              </div>
              <div className="w-full  flex border border-white rounded-[5px] h-full justify-between items-center py-[10px] px-[20px]">
                <div className="flex items-center gap-[10px]">
                  <div className="w-[9px] h-[9px] bg-brand-clime rounded-full"></div>
                  <div className="font-medium gotham text-sm  text-white uppercase">
                    {yourClub?.attributes?.title},{"ACT "}
                    {yourClub?.attributes?.address?.Name
                      ? yourClub?.attributes?.address?.Name
                      : yourClub?.attributes?.address?.data?.attributes?.Name}
                  </div>
                </div>
                <div
                  className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 text-brand-clime ${"border-brand-clime "}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-span-2 md:col-start-2 md:col-end-4 md:mt-5 lg:col-span-1 lg:col-start-auto lg:col-end-auto lg:mt-0 :col-span-1 :col-start-auto :col-end-auto :mt-0">
          <p className="text-brand-clime gotham mb-[27px] uppercase">
            ABOUT {data?.title ?? "no data"}
          </p>
          <p className="text-white text-sm font-normal gotham mb-[20px]">
            {data?.description ??
              `Ascend from the hustle and bustle of Lonsdale Street into a fitness
            oasis. Enjoy plenty of cardio, strength and conditioning gear,
            stacks of space, plus energizing circuit classes.`}
          </p>
          <ButtonNavigate text="TAKE A VIRTUAL TOUR" />
        </div>
      </div>
    </div>
  );
}
