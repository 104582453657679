import React, {
  useEffect,
  useState,
} from 'react';

import warnCircle from '../assets/images/icons/warnCircle.svg';
import ButtonNavigate from '../common/buttons/ButtonNavigate';
import SelectOutline from '../common/dropdowns/SelectOutline';
import InputBase from '../common/inputs/InputBase';
import InputTextArea from '../common/inputs/textArea';
import Layout from '../components/layout/Layout';
import useFetch from '../hooks/useFetch';

function contactForm() {
  const { data, loading } = useFetch({
    params: "contact-us-page",
    populate: "sideLink,sideLink.image",
  });
  const studioMocking = useFetch({ params: "studios", populate: "" });
  const [filteredStudio, setFilteredStudio] = useState([]);
  useEffect(() => {
    if (studioMocking.data && filteredStudio.length == 0) {
      const dataFiltered = studioMocking.data.data
        .filter((c) => c.attributes.email === "braddon@clublime.com.au")
        .map((i) => i.attributes.title);
      setFilteredStudio(dataFiltered);
    }
  }, [studioMocking]);

  function createMarkup(data) {
    return { __html: data };
  }
  const [active, setActive] = useState(false);
  const activeValue = (e) => {
    setActive(true);
    return e.target.value === ""
      ? ((e.target.style.borderColor = ""),
        (e.target.style.color = ""),
        setActive(false))
      : ((e.target.style.borderColor = "#82FA00"),
        (e.target.style.color = "#82FA00"),
        setActive(true));
  };

  const [form, setForm] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    location: "",
    message: "",
  });
  const [formControlled, setFormControlled] = useState({
    fullName: false,
    email: false,
    phoneNumber: false,
    location: false,
    message: false,
  });
  const [errText, setErrText] = useState({ email: "" });

  const submit = () => {
    let control = { ...formControlled };
    Object.keys(form).map((e) => {
      if (form[e] == "" || e === "email") control = { ...control, [e]: true };
      else if (e === "email") {
        if (form[e] === "") {
          control = { ...control, [e]: true };
          setErrText({ ...errText, email: "" });
        } else if (
          !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            form[e]
          )
        ) {
          setErrText({ ...errText, email: "invalid email address" });
          control = { ...control, [e]: true };
        } else control = { ...control, [e]: false };
      } else control = { ...control, [e]: false };
      setFormControlled(control);
    });
  };
  return (
    !loading &&
    data && (
      <>
        <p className="uppercase text-transparent text-effect text-[50px] lg:text-[98.92px] leading-[89px] -tracking-[0.03em] font-bold my-[71px] mx-[4.9%] ">
          {data.data.attributes.title}
        </p>
        <div className="flex mx-[4.9%] flex-col-reverse md:flex-col lg:flex-row mb-[106px]">
          <div className="text-white  w-full lg:w-3/5">
            <p className="text-2xl font-bold leading-6 text-brand-clime font-abcmonument">
              {data.data.attributes.subtitle}
            </p>
            <p className=" font-normal text-base mb-8 font-abcmonument">
              {data.data.attributes.description}
            </p>
            <div className="grid md:grid-rows-2 md:grid-flow-col gap-x-[50px] gap-y-[12px]">
              <InputBase
                width="100%"
                type="string"
                label="Full name"
                holder="Full name*"
                isRequired={true}
                isShowRequired={formControlled.fullName}
                onchange={(e) => setForm({ ...form, fullName: e.target.value })}
              />
              <InputBase
                width="100%"
                type="string"
                holder="Phone number*"
                isRequired={true}
                isShowRequired={formControlled.phoneNumber}
                onchange={(e) =>
                  setForm({ ...form, phoneNumber: e.target.value })
                }
              />
              <InputBase
                width="100%"
                type="email"
                holder="Email address*"
                isActiveData={true}
                isRequired={true}
                isShowRequired={formControlled.email}
                onchange={(e) => setForm({ ...form, email: e.target.value })}
                errorText={errText.email}
              />
              <SelectOutline
                titleStyle="text-[12px]"
                title="location*"
                style=" h-[54px] graphik-regular text-[14px] w-full"
                placeholder="Location*"
                placeholderColor="text-slate-400"
                isShowRequired={formControlled.location}
                data={["anu", "belconnen", "braddon"]}
                onSelect={(v) => setForm({ ...form, location: v })}
              ></SelectOutline>
            </div>
            <div className="mt-[12px] relative flex w-[100%]">
              <div className="flex flex-col w-full">

                <InputTextArea
                  onInput={(e) => {
                    activeValue(e);
                  }}
                  name='message'
                  className="mt-1  h-[136px] w-full placeholder:text-white  bg-black border-b graphik-regular border-gray placeholder-slate-400 block sm:text-sm text-start focus:outline-none"
                  placeholder="message"
                  onChange={(e) =>
                    setForm({ ...form, message: e.target.value })
                  }
                />
              </div>

              <div className="absolute top-10  right-3 graphik-regular font-normal text-xs text-brand-clime">
                {formControlled.message ? (
                  <>
                    This Field is required{" "}
                    <img className="w-4 ml-1 h-4 inline" src={warnCircle} />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="flex w-full gap-[20px]  flex-col-reverse md:flex-row mt-[23px] md:mt-[50px]  justify-between">
              <p className="text-white max-w-[416px] font-abcmonument text-sm leading-[22px] text-gray ">
                {data.data.attributes?.faq}
              </p>
              <ButtonNavigate
                style="h-[56px] w-[171px] border-b border-[#777777] text-[#777777]"
                text="Get in touch"
                onClick={() => {
                  submit();
                }}
                isArrowRight
              ></ButtonNavigate>
            </div>
          </div>
          <div className="w-full mb-10 md:mb-[0px] md:mt-20 lg:w-2/5 text-white flex md:flex-row md:flex-wrap md:items-start md:justify-start lg:flex-col lg:justify-end lg:gap-[43px] flex-col justify-end gap-[43px]">
            {data.data.attributes.sideLink.map((i, index) => (
              <div className="flex items-start md:w-[calc(50%-50px)] lg:w-full" key={index}>
                <img
                  className=" ml-[0px] lg:ml-[64px]  mr-[24px]"
                  src={i?.image?.data?.attributes?.url}
                />
                <div>
                  <p className="text-brand-clime text-lg font-bold mb-[22px] font-abcmonument uppercase">
                    {i?.title}
                  </p>
                  <div dangerouslySetInnerHTML={createMarkup(i?.html)}></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  );
}

function ContactUs() {
  return <Layout>{contactForm()}</Layout>;
}

export default ContactUs;
