import React, { useState, useEffect, useRef } from "react";
import warnCircle from "../../assets/images/icons/warnCircle.svg";

type selectDropdownInfo = {
  style: string;
  data: Array<string>;
  title: string;
  titleStyle: string;
  placeholder: string;
  // placeholderColor: string;
  isTitle: boolean;
  isShowRequired: boolean;
  onSelect: any;
};

function SelectOption({
  style,
  data = [],
  title,
  titleStyle,
  placeholder,
  // placeholderColor,
  isTitle = false,
  isShowRequired = false,
  onSelect = () => null,
}: selectDropdownInfo) {
  const [isShow, setShow] = useState(false);
  const [selected, setSelected] = useState("");
  // const [dropdownSelected, setDropdownSelected] = useState(false);

  useEffect(() => {
    if (isTitle) setSelected(data[0]);
  }, [data]);
  useEffect(() => {
    onSelect(selected);
  }, [selected]);
  const target = useRef<any>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (isShow && target.current && !target.current?.contains(e.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isShow]);
  return (
    <div ref={target}>
      {!isTitle ? (
        <div className="relative">
          <span
            className={` after:ml-0.5 after:text-white block  font-medium graphik-regular text-white ${titleStyle}`}
          >
            {""}
          </span>
          <span
            className={`absolute  block text-[12px] graphik-regular font-medium  transition-all duration-300 ${selected ? "top-0 left-0 text-white" : "top-10 left-7 text-gray"
              }`}
          >
            {placeholder}
          </span>
          <div
            className={`
          flex items-center mt-1 px-6 py-4 bg-black border cursor-pointer w-full focus:ring-1 ${style} ${selected
                ? "border-brand-clime text-brand-clime justify-between "
                : "border-[#FFFFFF] text-[#FFFFFF] justify-end"
              } `}
            onClick={() => setShow(!isShow)}
          >
            {isShowRequired && !selected ? (
              <div className="graphik-regular w-[45%] absolute top-[53%] mr-[25px] right-[20px] text-end font-normal text-xs text-brand-clime">
                This Field is required
                <img className="w-4 ml-1 h-4 inline" src={warnCircle} />
              </div>
            ) : (
              ""
            )}
            <input
              type="text"
              className="bg-black outline-none w-full"
              onInput={(e) => setSelected(e.target.value)}
              value={selected}
            />
            <div
              className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${isShow ? "border-brand-clime rotate-[135deg]" : ""
                }`}
            ></div>
          </div>
          <div
            id="scrollVert"
            className={`w-full bg-black max-h-[240px] overflow-y-auto  border-slate-400 text-white border-2 absolute z-40 ${isShow ? "" : "hidden"
              }`}
          >
            {data.map((item: any, index: number) => (
              <div
                key={index}
                className="cursor-pointer px-6 py-4 hover:bg-slate-400"
                onClick={() => {
                  setSelected(item);
                  // setDropdownSelected(true);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="relative">
          <span
            className={` after:ml-0.5 after:text-white block  font-medium graphik-regular text-white ${titleStyle}`}
          >
            {title}
          </span>

          <div
            className={`
      flex items-center mt-1 px-6 py-4 bg-black border cursor-pointer w-full focus:ring-1 justify-between  ${style} ${selected
                ? "border-brand-clime text-white "
                : "border-[#FFFFFF] text-gray "
              } `}
            onClick={() => setShow(!isShow)}
          >
            {selected ? selected : placeholder}
            <div
              className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 text-brand-clime ${isShow ? "border-brand-clime rotate-[135deg]" : ""
                }`}
            ></div>
          </div>
          <div
          id="scrollVert"
            className={`w-full bg-black max-h-[240px] overflow-y-auto  border-slate-400 text-white border-2 absolute z-40 ${isShow ? "" : "hidden"
              }`}
          >
            {data.map((item: any, index: number) => (
              <div
                key={index}
                className="cursor-pointer px-6 py-4 hover:bg-slate-400"
                onClick={() => {
                  setSelected(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectOption;
