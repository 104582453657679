import { AuthContext } from '../../../apis/auth';
import { getMember, getMemberContractByMemberId } from '../../../apis/mutation';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Auth, Storage } from 'aws-amplify';
import { navigate } from 'gatsby';
import React from 'react';
import { IMenu, MENU_ID, menus } from './data';
import ModalBase from '../../../common/modals/ModalBase';
import PrimaryOutlinedButton from '../../../common/buttons/PrimaryOutlinedButton';
import logo from "../../../assets/images/logo.png";

const Header = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [activeMenu, setActiveMenu] = React.useState<string>(MENU_ID.DASHBOARD);

  const [isLogOut, setIsLogOut] = React.useState<boolean>(false);

  const [userInfo, setUserInfo] = React.useState<any>(null);
  const [userInfoMemberShip, setUserInfoMemberShip] = React.useState<
      Array<any>
  >([]);

  const [getMembership] = useLazyQuery(getMemberContractByMemberId);

  const mountedFunction = () => {
    try {
      Auth.currentAuthenticatedUser()
          .then((user) => {
            client
                .query({
                  query: getMember,
                  variables: {
                    memberId: user.attributes['custom:memberId'],
                  },
                })
                .then(async (res) => {
                  const data = res.data.getMember;
                  const imgUrl = data?.imageUrl
                      ? await Storage.get(data.imageUrl)
                      : '/icons/Dashboard/profile.svg';
                  setUserInfo({ ...data, imageUrl: imgUrl });
                  localStorage.setItem(
                      'currentUserDetail',
                      JSON.stringify(res.data.getMember)
                  );
                });

            getMembership({
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
                .then((membership) => {
                  setUserInfoMemberShip(
                      membership.data.getMemberContractByMemberId.items.filter(
                          (i: any) => i.isActive
                      )
                  );
                })
                .catch((error) => {
                  console.log(error);
                });
          })
          .catch((e) => {});
    } catch (e) {
      console.log(e);
      navigate('/');
    }
  };
  React.useEffect(() => {
    mountedFunction();
  }, []);
  const client = useApolloClient();
  const reInit = React.useContext(AuthContext).Init;
  const refresh = React.useContext(AuthContext).refreshData;
  const setRefresh = React.useContext(AuthContext).setRefreshData;
  React.useEffect(() => {
    if (refresh) {
      client
          .refetchQueries({
            include: ['getMember'], // Consider using "active" instead!
          })
          .then(() => {
            mountedFunction();
            setRefresh(false);
          });
    }
  }, [refresh]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onChangeMenu = (menu: IMenu) => {
    if (menu?.isNotReady) {
      return;
    }
    if (
        menu.id.toLowerCase() === MENU_ID.CLASS &&
        userInfoMemberShip?.length > 0
    ) {
      navigate(menu.link);
    } else {
      navigate(menu.link);
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const arraySlugs = window.location.pathname.split('/').slice(2);
      setActiveMenu(arraySlugs?.[0] || MENU_ID.DASHBOARD);
    }
  }, [typeof window]);

  return (
      <header className="sm:hidden block">
        <div className="pt-[42px] pb-5">
          <div className="mb-9 px-6">
            <p className="text-white text-base font-bold leading-[160%] uppercase mb-[10px]">
              MEMBER PORTAL
            </p>
            <div className="h-[26px] w-[269px]" onClick={() => navigate('/dashboard')}>
              <svg xmlns="http://www.w3.org/2000/svg" width="204" height="24" viewBox="0 0 204 24" fill="none">
                <g clipPath="url(#clip0_997_2097)">
                  <path d="M33.2266 12.2738V12.2124C33.2266 6.07189 38.1021 0.986328 44.7545 0.986328C48.8724 0.986328 51.3424 2.41271 53.7142 4.45908L52.4946 5.63762C50.4844 3.80803 48.1794 2.44449 44.72 2.44449C39.1882 2.44449 35.005 6.69258 35.005 12.1503V12.2117C35.005 17.6998 39.2527 21.9789 44.7545 21.9789C48.0819 21.9789 50.3538 20.7693 52.6918 18.5994L53.8462 19.6522C51.4084 21.9168 48.7719 23.4357 44.6885 23.4357C38.1021 23.4364 33.2266 18.5062 33.2266 12.2738Z" fill="#B3E833"/>
                  <path d="M58.6406 1.35938H60.3531V21.5766H73.8897V23.0652H58.6406V1.35938Z" fill="#B3E833"/>
                  <path d="M77.0781 14.0422V1.35938H78.7906V13.8869C78.7906 19.0028 81.7226 21.9488 86.5966 21.9488C91.2733 21.9488 94.2714 19.2817 94.2714 14.0422V1.35938H95.9838V13.8551C95.9838 20.0888 92.1314 23.4055 86.5306 23.4055C80.9973 23.4055 77.0781 20.1192 77.0781 14.0422Z" fill="#B3E833"/>
                  <path d="M102.79 1.35938H112.31C114.911 1.35938 117.019 2.07327 118.304 3.28146C119.26 4.18108 119.787 5.32783 119.787 6.69279V6.75422C119.787 9.7002 117.744 11.1887 115.867 11.9019C118.634 12.5847 121.07 14.0719 121.07 17.0793V17.1421C121.07 20.7695 117.778 23.0644 112.803 23.0644H102.789V1.35938H102.79ZM118.04 6.84814C118.04 4.46 115.966 2.84719 112.277 2.84719H104.503V11.3744H112.112C115.571 11.3744 118.04 9.79412 118.04 6.90957V6.84814ZM112.311 12.8637H104.503V21.5766H112.871C116.823 21.5766 119.326 19.8706 119.326 17.1428V17.08C119.326 14.4143 116.855 12.8637 112.311 12.8637Z" fill="#B3E833"/>
                  <path d="M126.484 1.35938H131.556V18.7239H143.05V23.0652H126.484V1.35938Z" fill="#B3E833"/>
                  <path d="M146.695 1.35938H151.768V23.0652H146.695V1.35938Z" fill="#B3E833"/>
                  <path d="M157.598 1.35938H163.066L169.126 10.537L175.185 1.35938H180.654V23.0652H175.615V8.8945L169.126 18.166H168.994L162.571 8.98842V23.0652H157.598V1.35938Z" fill="#B3E833"/>
                  <path d="M186.445 1.35938H203.837V5.60817H191.484V10.0116H202.354V14.259H191.484V18.8178H204.002V23.0652H186.445V1.35938Z" fill="#B3E833"/>
                  <path d="M7.296 19.3438C4.8365 17.7225 3.22533 15.0357 3.22533 12C3.22533 7.05708 7.49702 3.03566 12.7476 3.03566C17.413 3.03566 21.3029 6.21184 22.1123 10.3872H25.3759C24.535 4.53054 19.1937 0 12.7468 0C5.71859 0 0 5.38355 0 12C0 16.7819 2.98681 20.9177 7.296 22.8448V19.3438Z" fill="#B3E833"/>
                  <path d="M14.3593 20.8337V5.54883H11.1348V20.833C11.1318 20.8323 11.128 20.8316 11.125 20.8316V23.8998C11.6568 23.964 12.1969 24 12.7467 24C19.1936 24 24.5356 19.4695 25.375 13.6128H22.1114C21.3981 17.296 18.2867 20.2003 14.3593 20.8337Z" fill="#B3E833"/>
                </g>
                <defs>
                  <clipPath id="clip0_997_2097">
                    <rect width="204" height="24" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>

          <div className="flex items-start justify-between gap-3 mb-5 px-6">
            <div className="flex items-center gap-x-[14px]">
              <img
                  className="rounded-full border border-white max-w-[61px] max-h-[61px] w-[61px] h-[61px]"
                  src={userInfo?.imageUrl || ''}
                  alt="user avatar"
              />
              <p className="font-bicyclette font-bold text-brand-clime text-3xl leading-[30px] uppercase">
                {userInfo?.surname ?? 'name'}
              </p>
            </div>

            <div className="relative mt-[6px] cursor-pointer">
              <svg
                  width="23"
                  height="26"
                  viewBox="0 0 23 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M22.7376 20.1096C21.9933 19.4461 21.3417 18.6854 20.8003 17.8482C20.2093 16.6924 19.8551 15.4302 19.7584 14.1357V10.323C19.7635 8.28973 19.026 6.3246 17.6843 4.79679C16.3427 3.26899 14.4893 2.28368 12.4725 2.02601V1.03037C12.4725 0.757098 12.3639 0.49502 12.1707 0.301788C11.9775 0.108557 11.7154 0 11.4421 0C11.1688 0 10.9068 0.108557 10.7135 0.301788C10.5203 0.49502 10.4117 0.757098 10.4117 1.03037V2.04144C8.41295 2.3177 6.58199 3.30896 5.25799 4.83163C3.93399 6.3543 3.20667 8.3052 3.21074 10.323V14.1357C3.11407 15.4302 2.75983 16.6924 2.16879 17.8482C1.63692 18.6835 0.995788 19.4441 0.262416 20.1096C0.180088 20.1819 0.114106 20.2709 0.0688591 20.3707C0.0236124 20.4705 0.000138769 20.5788 0 20.6884V21.7381C0 21.9428 0.0813157 22.1391 0.226058 22.2838C0.370801 22.4286 0.567115 22.5099 0.771812 22.5099H22.2282C22.4329 22.5099 22.6292 22.4286 22.7739 22.2838C22.9187 22.1391 23 21.9428 23 21.7381V20.6884C22.9999 20.5788 22.9764 20.4705 22.9311 20.3707C22.8859 20.2709 22.8199 20.1819 22.7376 20.1096ZM1.60537 20.9663C2.32347 20.2726 2.95573 19.4952 3.48859 18.6508C4.23309 17.255 4.66748 15.7149 4.76208 14.1357V10.323C4.73147 9.41845 4.8832 8.517 5.20822 7.67233C5.53325 6.82765 6.02493 6.05701 6.65398 5.40631C7.28304 4.75561 8.0366 4.23815 8.8698 3.88474C9.70301 3.53133 10.5988 3.34921 11.5039 3.34921C12.4089 3.34921 13.3047 3.53133 14.1379 3.88474C14.9711 4.23815 15.7247 4.75561 16.3537 5.40631C16.9828 6.05701 17.4745 6.82765 17.7995 7.67233C18.1245 8.517 18.2762 9.41845 18.2456 10.323V14.1357C18.3402 15.7149 18.7746 17.255 19.5191 18.6508C20.052 19.4952 20.6842 20.2726 21.4023 20.9663H1.60537Z"
                    fill="white"
                />
                <path
                    d="M11.5392 25.0877C12.0254 25.0765 12.4919 24.8938 12.8563 24.5717C13.2207 24.2497 13.4595 23.8091 13.5304 23.328H9.4707C9.54362 23.8222 9.79357 24.2731 10.1741 24.5968C10.5545 24.9205 11.0397 25.095 11.5392 25.0877Z"
                    fill="white"
                />
              </svg>
              <div className="w-[11px] h-[11px] bg-[#FF0000] rounded-full absolute top-0"></div>
            </div>
          </div>

          <div
              onClick={toggleOpen}
              className="relative rounded-md h-[54px] bg-richBlack flex items-center justify-between pl-5 pr-2 mx-[14px] cursor-pointer"
          >
            <p className="text-white text-base leading-[47.5px] font-bold uppercase">
              {menus.map((menu) => {
                if (menu.id !== activeMenu) return null;
                return menu.name;
              })}
            </p>
            <div className="h-5 w-[20px] cursor-pointer">
              {isOpen?<img  className="block w-full object-contain"
                            src={`/icons/close.png`}
                            alt="menu"/>:
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 50 33" fill="none" className="block w-full object-contain">
                    <line x1="3.5" y1="29.5" x2="46.5" y2="29.5" stroke="#FFF" strokeWidth="7" stroke-linecap="round"/>
                    <line x1="3.5" y1="16.5" x2="46.5" y2="16.5" stroke="#FFF" strokeWidth="7" stroke-linecap="round"/>
                    <line x1="3.5" y1="3.5" x2="46.5" y2="3.5" stroke="#FFF" strokeWidth="7" stroke-linecap="round"/>
                  </svg>
              }
            </div>

            {isOpen && (
                <div className="absolute left-0 right-0 top-[58px] w-full rounded bg-richBlack z-[1000]">
                  {/* MAIN TABS */}
                  <div className="py-1">
                    {menus.map((menuItem: IMenu) => {
                      return (
                          <div
                              key={menuItem.id}
                              onClick={(
                                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                              ) => {
                                event.stopPropagation();
                                onChangeMenu(menuItem);
                              }}
                              className={`cursor-pointer h-11 text-base  text-white leading-[47.5px] font-bold uppercase px-5 ${
                                  activeMenu === menuItem.id
                                      ? '  bg-brand-clime !text-black border-r-8 border-primary'
                                      : ''
                              }`}
                          >
                            {menuItem.name}
                          </div>
                      );
                    })}
                  </div>
                  {/* SUB TABS */}
                  <div className="">
                    <div
                        onClick={() => setIsLogOut(true)}
                        className="cursor-pointer flex items-center overflow-hidden border-b text-black border-black bg-primary h-11 text-base leading-[47.5px] font-bold uppercase px-5"
                    >
                      <svg
                          className="w-[19.7px] h-[18px] mr-[9.9px]"
                          width="22"
                          height="20"
                          viewBox="0 0 22 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            d="M12.1429 5.71429V2.5C12.1429 1.67157 11.4713 1 10.6429 1H2.5C1.67157 1 1 1.67157 1 2.5V17.5C1 18.3284 1.67157 19 2.5 19H10.6429C11.4713 19 12.1429 18.3284 12.1429 17.5V13.4286"
                            stroke="black"
                        />
                        <path
                            d="M16.5645 5.51074L20.7148 9.66112L16.5645 13.8115"
                            stroke="black"
                        />
                        <path d="M20.7143 9.57129L7 9.57129" stroke="black" />
                      </svg>
                      LOG OUT
                    </div>
                    <div className="cursor-pointer rounded-b-md flex items-center text-black overflow-hidden bg-primary h-11 text-base leading-[47.5px] font-bold uppercase px-5">
                      <svg
                          className="w-[17.3px] h-[17.3px] mr-[13.28px]"
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                            cx="9.34772"
                            cy="9.34772"
                            r="8.65241"
                            stroke="black"
                        />
                        <path
                            d="M6.95703 7.55272C6.95703 7.55272 6.99057 6.75337 7.73825 6.11244C8.1822 5.7319 8.71535 5.62169 9.19307 5.61491C9.62933 5.60934 10.0192 5.68612 10.2524 5.80433C10.6509 6.00731 11.4291 6.50146 11.4291 7.55272C11.4291 8.65896 10.7495 9.16037 9.97685 9.71289C9.20425 10.2654 9.00674 10.8073 9.00674 11.4283"
                            stroke="black"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                        />
                        <path
                            d="M9.19135 13.2172C9.43834 13.2172 9.63856 13.0169 9.63856 12.77C9.63856 12.523 9.43834 12.3228 9.19135 12.3228C8.94436 12.3228 8.74414 12.523 8.74414 12.77C8.74414 13.0169 8.94436 13.2172 9.19135 13.2172Z"
                            fill="black"
                        />
                      </svg>
                      HELP
                    </div>
                  </div>
                </div>
            )}
          </div>
        </div>

        {isLogOut && (
            <ModalBase closeModal={setIsLogOut}>
              <div className="xs:w-unset sm:w-[700px] h-[400px] flex flex-col items-center justify-center">
                <h2 className="sm:text-[40px] font-bold text-center xs:px-6 xs:text-2xl sm:px-24 leading-[90%] text-white uppercase bebas">
                  are you sure you want to log out?
                </h2>
                <div className="flex items-center mt-[30px] justify-center gap-[20px] ">
                  <PrimaryOutlinedButton
                      onClick={() => {
                        window.localStorage.removeItem('userInfo');
                        window.localStorage.removeItem('login');
                        navigate('/login');
                        Auth.signOut().then((res) => {
                          reInit();
                        });
                      }}
                      className="rounded-md w-[108px] !px-0 uppercase"
                  >
                    log out
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                      onClick={() => setIsLogOut(false)}
                      className="rounded-md w-[108px] !px-0 uppercase"
                  >
                    cancel
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </ModalBase>
        )}
      </header>
  );
};

export default Header;
