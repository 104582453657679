import React from 'react';
import BookAClassToolBar from '../../components/classes/BookAClassToolBar';
import GetYourFreePass from '../../components/home/GetYourFreePass';
import Layout from '../../components/layout/Layout';

function BookAClass() {
  return (
    <Layout>
      <BookAClassToolBar />
      <GetYourFreePass/>
    </Layout>
  );
}

export default BookAClass;
