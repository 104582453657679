import React, { useState, useEffect } from "react";
import Menu from "../../assets/images/icons/MenuIcon.svg";
import MapIcon from "../../assets/images/icons/MapIcon.svg";
import MapActive from "../../assets/images/icons/map.svg";
import MenuActive from "../../assets/images/icons/menu.svg";
import SearchIcon from "../../assets/images/icons/SearchIcon.svg";
import Dropdown from "../../common/dropdowns/Dropdown.tsx";
import { dropdown } from "../../../mock/dropdown";
import positionFilterIcon from "../../assets/images/icons/positionFilterIcon.svg";
import filterIconMobile from "../../assets/images/icons/filterIconMobile.svg";
import { Map } from "../map/Map";

function StudioSearch(props: any) {
  const { isMap, setIsMap } = props;
  const [isSelected, setSelected] = useState({
    location: [],
    "sort by": [],
    features: [],
    position: false,

  });

  const onChange = (e: boolean, name: any, type: string) => {
    // let nameArray = "";
    if (name?.text === "closest to me") {
      // props.sortPosition(name);
      setSelected({
        ...isSelected,
        position: e,
      });
    } else {
      if (e || type == "sort by") {
        if (type == "sort by")
          setSelected({
            ...isSelected,
            [type]: name,
          });
        else
          setSelected({
            ...isSelected,
            [type]: (isSelected as any)[type].concat(name),
          });
      } else {
        if (type !== "sort by") {
          setSelected({
            ...isSelected,
            [type]: (isSelected as any)[type].filter((i: string) => i != name),
          });
        }
      }
    }
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        () => {
          setLocationAllow(true);
        },
        () => {
          setLocationAllow(false);
        }
      );
    } else {
      setLocationAllow(false);
    }
  }, []);
  const [mobileFilter, setMobileFilter] = useState(false);

  const [locationAllow, setLocationAllow] = useState(null);
  return (
    <>
      <div className="text-white lg:mx-[9px] lg:mt-[30px]  z-10 relative md:flex hidden md:flex-wrap lg:flex-nowrap">
        <div className="h-[59px] lg:w-[35.5%] md:w-2/4 bg-[#292929] flex justify-start items-center text-[18px] font-bold gap-[22px] pl-[59px] border-y border-r border-brand-clime">
          <img className="bg-[#292929] " src={SearchIcon} />
          <input
            id="studioSearch"
            className="focus:outline-none w-full bg-[#292929]"
            placeholder="City, suburb or postcode"
            onKeyUp={props.set}
          />
          {/* City, suburb or postcode</input> */}
        </div>
        <Dropdown
          style="h-[59px]  bg-[#292929] lg:w-[17.56%] md:w-1/4  font-[18px] font-bold px-[24px] border-y border-r border-brand-clime"
          data={dropdown.location}
          onChange={onChange}
          onSelect={() => props.filterLocation(isSelected.location)}
          selectedData={isSelected.location}
        ></Dropdown>
        <Dropdown
          style="h-[59px]  bg-[#292929] lg:w-[18.6%] md:w-1/4  font-[18px] font-bold px-[24px] border-y border-r border-brand-clime"
          data={dropdown.features}
          onChange={onChange}
          onSelect={() => props.filterFeat(isSelected.features)}
          selectedData={isSelected.features}
        ></Dropdown>
        <div className="lg:hidden md:block md:w-3/5 h-[59px] bg-[#292929] px-[24px] border-y border-r border-brand-clime relative flex"></div>
        {locationAllow != null && (
          <Dropdown
            style="h-[59px]  bg-[#292929]  lg:w-[15.7%] md:w-1/5 font-[18px] font-bold px-[24px] border-y border-r border-brand-clime"
            justOne={true}
            data={dropdown.sort}
            onChange={onChange}
            onSelect={() => {
              // props.sortPosition();
              props.sortData(isSelected["sort by"], isSelected["position"]);
            }}
            selectedData={isSelected["sort by"]}
            isLocation={locationAllow}
          ></Dropdown>
        )}
        <div className="h-[59px] lg:w-[12.7%] md:w-1/5 bg-[#292929] flex border-y  border-brand-clime">
          <div
            onClick={() => setIsMap(false)}
            className={`bg-[#292929]  text-brand-clime w-1/2 h-full ${!isMap ? "border-b-brand-clime border-b-[5px]" : ""
              }  flex justify-center items-center`}
          >
            <img className="bg-[#292929]" src={!isMap ? Menu : MenuActive} />
          </div>
          <div
            onClick={() => setIsMap(true)}
            className={`bg-[#292929] border-l border-brand-clime text-brand-clime w-1/2 h-full ${isMap ? "border-b-[5px] border-b-brand-clime" : ""
              } flex justify-center items-center`}
          >
            <img className="bg-[#292929]" src={isMap ? MapActive : MapIcon} />
          </div>
        </div>
      </div>
      <div className="md:hidden flex ">
        <div className="h-[59px] relative w-full mx-3 my-3 bg-[#292929] flex justify-start items-center text-[18px] font-bold gap-[22px] px-[14px] border-[1px] border-brand-clime">
          <img className="bg-[#292929] " src={SearchIcon} />
          <input
            id="studioSearch"
            className="focus:outline-none w-full bg-[#292929] text-white"
            placeholder="City, suburb or postcode"
            onKeyUp={props.set}
          />
          {/* City, suburb or postcode</input> */}
          <img src={positionFilterIcon} />
          <label>
            <img
              src={filterIconMobile}
              onClick={() => {
                setMobileFilter(!mobileFilter);
              }}
            />
            <div
              className={`absolute w-full  left-0 top-full border-[1px] border-brand-clime bg-[#292929] text-white ${mobileFilter ? "" : "hidden"
                }`}
            >
              <Dropdown
                style="h-[59px] w-full bg-[#292929] z-[9999]  font-[18px] font-bold px-[24px]"
                data={dropdown.location}
                onChange={onChange}
                onSelect={() => props.filterLocation(isSelected.location)}
                selectedData={isSelected.location}
              ></Dropdown>
              <Dropdown
                style="h-[59px] w-full bg-[#292929]  font-[18px] font-bold px-[24px]"
                justOne={true}
                data={dropdown.sort}
                onChange={onChange}
                onSelect={() => props.sortData(isSelected["sort by"])}
                selectedData={isSelected["sort by"]}
              ></Dropdown>
            </div>
          </label>
        </div>
      </div>{" "}

      <div className="lg:hidden block"><Map /></div>
    </>
  );
}
export default StudioSearch;
