import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import moment from "moment";
import SelectOutline from "../../common/dropdowns/SelectOutline";
import InputBase from "../../common/inputs/InputBase";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import ArrowButton from "../../common/buttons/ArrowButton";
import { Script } from "gatsby";
import dateIcon from "../../assets/images/icons/calendar.svg";
import InputNumber from "../../common/inputs/InputNumber";
import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import {
  getBrandByName,
  getLocationByBrandId,
  listBrands,
  listMembershipId,
  listLocationEmail,
  listMemberships,
} from "../../apis/mutation";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { RegisterContext } from "../../context/RegisterContext";
import { Storage } from "aws-amplify";
import { toastError } from "../../common/toasts/toast";
import useFetch from "../../hooks/useFetch";
import ArrowButtonSecondary from "@/common/buttons/ArrowButtonSecondary";
import PrimaryOutlinedButton from "../../common/buttons/PrimaryOutlinedButton";
import { uppercaseFirstLetter } from "../../helpers/string.helper";
import { link } from "fs";
import markerImage from "../../assets/images/location-marker.png";

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
  onUnderAge: () => void;
  isUnderAge: boolean;
}

const Details: React.FC<SlideProps> = ({
  onNext,
  onPrev,
  setInfo,
  info,
  onUnderAge,
  isUnderAge,
}) => {
  const yourClub = useContext(RegisterContext).yourClub;
  const setYourClub = useContext(RegisterContext).setYourClub;

  const [contact, setContact] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    dob: "",
    postCode: "",
  });
  const [checking, setChecking] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const [prefixNumber, setPrefixNumber] = React.useState<"+61" | "+64">("+61");

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (e.target.name === "postCode" && e.target.value.length > 4) {
      value = e.target.value.slice(0, 4)+'';
    }
    if (e.target.name === "phone") {
      value = prefixNumber + value;
      if (value.charAt(3) == "0") value = value.slice(0, 3) + value.slice(4);
    }
    setContact({ ...contact, [e.target.name]: value });
  };
  const [getMemberByEmail] = useLazyQuery(gql`
    query getMemberByEmail($email: AWSEmail!) {
      getMemberByEmail(email: $email) {
        items {
          email
          givenName
        }
      }
    }
  `);
  const client = useApolloClient();
  const validation = () => {
    if (moment(new Date()).diff(moment(contact.dob), "years") < 18) {
      if (
        !(
          contact.emergencyContactName &&
          contact.emergencyContactName != "" &&
          contact.emergencyContactRelation &&
          contact.emergencyContactRelation != "" &&
          contact.emergencyContactNumber &&
          contact.emergencyContactNumber != "" &&
          contact.emergencyContactEmail &&
          contact.emergencyContactEmail != ""
        )
      ) {
        throw Error("please fulfill all the emergency contact info");
      } else {
        if (
          !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(contact.emergencyContactEmail)
        ) {
          throw Error("You have entered an invalid emergency email address!");
        }
        if (
          !/^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/.test(
              '0' + contact.emergencyContactNumber.split(prefixNumber)[1]
          )
        ) {
          throw Error("You have entered an invalid emergency phone number!");
        }
      }
    }
    const dataChecking = { ...contact };
    if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(dataChecking.email)) {
      throw Error("You have entered an invalid email address!");
    }
    if (
      !/^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/.test(
        "0" + dataChecking.phone.split(prefixNumber)[1]
      )
    ) {
      throw Error("You have entered an invalid phone number!");
    }
  };
  const handleSubmit = async () => {
    try {
      client.refetchQueries({
        include: ["getMemberByEmail"], // Consider using "active" instead!
      });
      validation();
      const dataChecking = { ...contact };
      delete dataChecking.middleName;
      const isExist = await getMemberByEmail({
        variables: { email: contact.email.toUpperCase() },
      });
      if (isExist?.data?.getMemberByEmail?.items?.length > 0)
        throw Error("this email is already been used by another user!");
      if (Object.values(dataChecking).every((x) => x !== "")) {
        setInfo({
          ...info,
          memberDetails: {
            ...info.memberDetails,
            address: "2 act",
            state: "ACT",
            postCode: contact.postCode,
            dob: contact.dob,
            email: contact.email,
            gender: contact.gender.toUpperCase(),
            givenName: contact.firstName,
            middleName: contact.middleName,
            surname: contact.lastName,
            mobileNumber: contact.phone,
            emergencyContactEmail: contact.emergencyContactEmail,
            emergencyContactNumber: contact.emergencyContactNumber,
            emergencyContactRelation: contact.emergencyContactRelation,
            emergencyContactName: contact.emergencyContactName,

            // brandId: location?.brandId,
          },
        });
        onNext();
      } else setChecking(true);
    } catch (e) {
      toastError(e.message);
    }
  };
  const [getLocation] = useLazyQuery(listLocationEmail);
  const [graphLocations, setGraphLocations] = useState([]);
  const [getBrandList] = useLazyQuery(listBrands);

  const [getBrandId] = useLazyQuery(getBrandByName);
  const [getLocationByBrand] = useLazyQuery(getLocationByBrandId);
  const [listMemberShipApi] = useLazyQuery(listMembershipId);
  const [membership, setMembership] = useState<any>();

  const { data: dataPromotionBanner } = useFetch({
    params: "global",
    populate: "promotion, promotion.image",
  });

  const isShowPromotionBanner = React.useMemo(() => {
    return moment(moment().format("YYYY-MM-DD")).isBetween(
      dataPromotionBanner?.data?.attributes?.promotion?.startDate,
      dataPromotionBanner?.data?.attributes?.promotion?.endDate,
      undefined,
      "[]"
    );
  }, [dataPromotionBanner]);

  useEffect(() => {
    getBrandId().then((res) => {
      const brandId = res.data.getBrandByName.items[0].id;
      getLocationByBrand({ variables: { brandId } }).then(async (location) => {
        const data = await Promise.all(
          location.data.getLocationByBrandId.items.map(async (i) => {
            const img =
              (await Storage.get(i.clubImage)) ??
              "https://media.tacdn.com/media/attractions-splice-spp-674x446/09/c3/33/97.jpg";
            return { ...i, clubImage: img };
          })
        );
        setGraphLocations(data);
        setLocation(data[0]);
      });
    });
    listMemberShipApi().then((membership) => {
      membership = membership.data;
      membership = membership?.getMembershipByBrandId?.items
        .filter((i) => i.brandId === "0abcb677-156c-4a67-b650-62c2a0ae3bd0")
        .sort((a, b) => a.costPrice - b.costPrice);
      setMembership(membership);
    });
  }, []);

  const [currentStud, setCurrentStud] = useState();
  const [showLocation, setShowLocation] = useState([]);
  const inputThing = (value: string) => {
    if (value) {
      const temp = [];
      for (let i = 0; i < graphLocations.length; i++) {
        if (
          graphLocations[i]?.address?.address
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          graphLocations[i]?.name?.toLowerCase().includes(value.toLowerCase())
        ) {
          temp.push(graphLocations[i]);
        }
      }
      return setShowLocation(temp);
    }
    return setShowLocation([]);
  };
  const CustomInput = ({ value, defaultValue, inputRef, ...props }) => {
    return <input {...props} defaultValue={defaultValue} ref={inputRef} />;
  };

  const setLocation = (location) => {
    if (location.country == "AUSTRALIA") setPrefixNumber("+61");
    else setPrefixNumber("+61");
    const map = document.getElementById("currentStudioMap");
    // map.src = `https://maps.google.com/maps?q=${currentStud?.geoLocation.latitude},${currentStud?.geoLocation.longitude}&hl=es&z=14&amp;output=embed`;
    setInfo({
      ...info,
      memberDetails: {
        ...info.memberDetails,
        country: location.country,
        homeLocationId: location.id,
        joinLocationId: location.id,
        address: location?.address?.address,
        brandId: location?.brandId,
        // postCode: parseInt(location?.address?.postCode),
        state: location?.address?.state,
        suburb: location?.suburb ?? "new",
      },
    });
    setCurrentStud(location);
    setYourClub(location);
    setShowLocation([]);
    setGeolocation({
      lat: location.geoLocation.latitude,
      lng: location.geoLocation.longitude,
    });
  };
  const [Geolocation, setGeolocation] = useState({
    lat: -35.417400886,
    lng: 149.064989477,
  });
  React.useEffect(() => {
    if (moment(new Date()).diff(moment(contact.dob), "years") <= 13) {
      onUnderAge();
    }
  }, [contact.dob]);
  const mapRef = useRef();
  const onload = useCallback((map: any) => (mapRef.current = map), []);

  React.useEffect(() => {
    if (isUnderAge == false) {
      setContact({ ...contact, dob: "" });
    }
  }, [isUnderAge]);
  const mapSrc =
    "https://maps.google.com/maps?q=-35.417400886,149.064989477&hl=es&z=14&amp;output=embed";
  return (
    <div
      id="scroll-hidden"
      className="md:mx-0 mt-8 sm:mt-[44px] md:pb-[60px] items-start register flex  justify-between h-full overflow-auto pb-unset pb-0 hiddenScrollWidth overscroll-y-cover"
    >
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`}
        onLoad={() => setLoaded(true)}
      />
      {moment(new Date()).diff(moment(contact.dob), "years") <= 13 ? (
        <div className="text-white text-start pb-[120px]">
          <p className="text-[40px] leading-[116.5%] mb-2 font-bold font-abcgravity sm:hidden md:block">
            WOW, YOU ARE STARTING YOUR
            <br /> FITNESS JOURNEY AT THE AGE OF{" "}
            {moment(new Date()).diff(moment(contact.dob), "years")}!
          </p>
          {contact?.firstName && (
            <h2 className="sm:hidden block text-[#82FA00] font-abcgravity text-[25px] font-bold leading-[90%] uppercase border-b border-b-[#82FA00] py-2 mb-4">
              welcome, {contact?.firstName}!
            </h2>
          )}
          <p className="sm:text-lg sm:mb-10 sm:font-bold text-base font-abcmonument sm:text-white sm:leading-[133.5%] mb-5">
            To get you started and finish the sign-up process, you are required
            to come to Club Lime club with your parent or guardian during
            staffed hours.
          </p>
          <p className="sm:text-[#82FA00] !text-[25px] font-bold uppercase mb-3 font-abcgravity text-white sm:text-base sm:font-bold sm:leading-[160%] sm:uppercase sm:mb-5 sm:border-b sm:border-white sm:border-opacity-40 sm:py-2">
            visit us in {currentStud?.name}
          </p>
          <div className="sm:hidden flex flex-col justify-between">
            <div className="text-base font-abcmonument text-white">
              ADDRESS
              <span className="block font-abcmonument text-base text-textSecondary">
                {currentStud?.address?.address}
              </span>
              {/* <span className="block text-base text-textSecondary">
                ACT 2900
              </span> */}
              <a
                href={"tel:" + currentStud?.phoneNumber}
                className="block font-abcmonument text-base text-textSecondary"
              >
                P: {currentStud?.phoneNumber}
              </a>
              <a
                href={"mailto:" + currentStud?.email}
                className="block font-abcmonument text-base text-textSecondary"
              >
                E: {currentStud?.email}
              </a>
            </div>
            <div className="text-base font-abcmonument text-white mt-5">
              STAFFED HOURS
              <span className="block font-abcmonument text-base text-textSecondary">
                Monday to Thursday
              </span>
              <span className="block font-abcmonument text-base text-textSecondary">
                10:00am-2:00pm & 3.00pm-7.00pm
              </span>
              <span className="block font-abcmonument text-base text-textSecondary">
                Friday
              </span>
              <span className="block font-abcmonument text-base text-textSecondary">
                10:00am-2:00pm
              </span>
              <span className="block font-abcmonument text-base text-textSecondary">
                Saturday
              </span>
              <span className="block font-abcmonument text-base text-textSecondary">
                9:00am-1:00pm
              </span>
            </div>
          </div>
          <div className="flex gap-[32px] flex-col md:flex-row sm:mt-5">
            <div className="w-full md:w-[281px] h-[155px]">
              <GoogleMap
                zoom={14}
                center={{ lat: 1 * Geolocation.lat, lng: 1 * Geolocation.lng }}
                // options={options}
                onLoad={onload}
                mapContainerClassName="mapContainer"
              >
                <Marker
                  // onClick={() => {}}
                  position={{
                    lat: 1 * Geolocation.lat,
                    lng: 1 * Geolocation.lng,
                  }}
                  icon={markerImage}
                />
              </GoogleMap>
            </div>
            <div className="hidden leading-none sm:flex flex-col justify-between">
              <div className="text-[16px] font-abcmonument">
                ADDRESS
                <br />
                {currentStud?.address?.address}
                <br />
                <a
                  className="font-abcmonument"
                  href={"tel:" + currentStud?.phoneNumber}
                >
                  P: 02 5112 2222
                </a>
                <br />
                <a
                  href={"mailto:" + currentStud?.email}
                  className="lowercase font-abcmonument"
                >
                  E: {currentStud?.email}
                </a>
              </div>
              <div className="text-[16px] font-abcmonument leading-none  mt-4 md:mt-0">
                STAFFED HOURS
                <br />
                Monday to Thursday - 10:00am-2:00pm & 3.00pm-7.00pm
                <br />
                Friday - 10:00am-2:00pm
                <br />
                Saturday - 9:00am-1:00pm
              </div>
            </div>
          </div>
          <div className="flex gap-[19px] mt-6 mb-[47px] w-full">
            {/* <PrimaryOutlinedButton text="GET DIRECTION" noArr /> */}
            <PrimaryOutlinedButton
              className="rounded-md !py-[12px] !min-h-[37px] !w-full sm:!w-[131px]  !px-0 !text-[11px] "
              onClick={() => {
                const link = document.createElement("a");
                link.href = `https://www.google.com/maps?saddr=My+Location&daddr=${currentStud.geoLocation.latitude},${currentStud.geoLocation.longitude}&q=food&amp;z=14`;
                link.target = "_blank";
                link.click();
              }}
            >
              GET DIRECTION
            </PrimaryOutlinedButton>
            {/* <PrimaryOutlinedButton text="LARGER MAP" noArr /> */}
            <PrimaryOutlinedButton
              className="rounded-md !py-[12px] !min-h-[37px] !w-full sm:!w-[131px] !px-0 !text-[11px] "
              onClick={() => {
                const a = document.createElement("a");
                a.href = `https://www.google.com/maps?q=${currentStud.geoLocation.latitude},${currentStud.geoLocation.longitude}&q=food&amp;z=14`;
                a.target = "_blank";
                a.click();
              }}
            >
              LARGER MAP
            </PrimaryOutlinedButton>
          </div>
          <div className="w-[157px] md:block hidden ">
            <ArrowButton
              text="back"
              onclick={() => {
                setContact({ ...contact, dob: "" });
              }}
            />
          </div>
        </div>
      ) : (
        <div className="w-full">
          <h1 className="text-[#82FA00] text-left  font-bold text-[25px] leading-[90%] uppercase border-b border-b-brand-clime pb-2 w-full">
            Select your home club
          </h1>

          <div className="w-full  justify-between">
            <div>
              <div className="xl:mt-4 mt-[5px] text-white w-auto lg:w-full relative">
                <div>
                  <div className="flex border-b border-borderLine min-w-full items-center relative justify-between">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 25 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {" "}
                      <line
                        x1="15.8912"
                        y1="14.8002"
                        x2="24.1415"
                        y2="22.2113"
                        stroke={"#82FA00"}
                        strokeWidth="1.57426"
                      />
                      <circle
                        cx="10.0022"
                        cy="10.3639"
                        r="7.15275"
                        transform="rotate(-72.0294 10.0022 10.3639)"
                        stroke={"#82FA00"}
                        strokeWidth="1.57426"
                      />
                    </svg>
                    <input
                      id="searchLocation"
                      className="bg-transparent  py-[15px] ml-[15px] font-abcmonument font-normal w-full  sm:text-sm text-base leading-[160%] text-white outline-none"
                      onChange={(e) => inputThing(e.target.value)}
                      // {...getInputProps({ placeholder: "" })}
                    />
                    <span
                      className="absolute bottom-3 cursor-pointer right-0 text-white graphik-regular text-[18px] font-thin"
                      onClick={() => {
                        document.getElementById("searchLocation").value = "";
                        setShowLocation([]);
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="23"
                        viewBox="0 0 23 23"
                        fill="none"
                      >
                        <path
                          d="M16.7559 16.4368L5.66841 5.34938"
                          stroke="white"
                          strokeWidth="1.11532"
                        />
                        <path
                          d="M5.54199 16.5595L16.5598 5.5417"
                          stroke="white"
                          strokeWidth="1.11532"
                        />
                      </svg>
                    </span>
                  </div>
                  {showLocation.length > 0 && (
                    <div
                      className={`absolute top-[calc(100%+16px)] w-full border bg-black border-[#82FA00] sm:rounded-md rounded-none z-20 max-h-[50vh] overflow-auto `}
                      id="scrollVert"
                    >
                      
                      {showLocation.length > 0 &&
                        showLocation.map((location) => (
                          <div
                            className={`grid border-b border-brand-clime  cursor-pointer hover:bg-[#2533D5] ${
                              location.clubImage ? "" : ""
                            }`}
                            onClick={() => {
                              document.getElementById("searchLocation").value =
                                uppercaseFirstLetter(location?.name) +
                                ", " +
                                location.address.state;
                              setLocation(location);
                            }}
                          >
                            <p className="text-start px-[14px] pt-3 pb-[16px] hover:text-black  hover:bg-[#82FA00] bg-black border-[#82FA00]">
                              {uppercaseFirstLetter(location?.name)},{" "}
                              {location?.address?.state}
                            </p>

                            {/* {location.clubImage && (
                              <div>
                                <img src={location?.clubImage} />
                              </div>
                            )}
                            <div className="col-span-2 py-2 text-start justify-between flex flex-col">
                              <p className="font-abcgravity w-full text-[#7681FF]">
                                {location?.name}
                              </p>
                              <p className="graphik-regular w-full ">
                                {location?.address?.address}
                              </p>
                            </div>
                            <div className="py-2 flex flex-col justify-between justify-self-end text-[#7681FF]">
                              <p className="graphik-regular text-end">
                                {location?.phoneNumber}
                              </p>
                              <p className="text-white text-end font-abcgravity text-sm ">
                                From ${membership[0].costPrice}/week
                              </p>
                            </div> */}
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-white text-start flex flex-col mt-[21px] font-abcmonument gap-2 uppercase text-sm font-medium">
                Do you have a promo code?
                <input
                  className="text-black font-abcmonument sm:text-sm text-base font-semibold rounded-md px-[6px] py-[9px] w-[147px]"
                  defaultValue="PF-"
                />
              </div>
              {isShowPromotionBanner && (
                <img
                  className="mt-[40px] sm:mt-6"
                  src={
                    dataPromotionBanner?.data?.attributes?.promotion?.image
                      ?.data?.attributes?.url || ""
                  }
                />
              )}
              {/* <div className="mt-6">
                            <InputBase nameInput=""
                                    holder="Promo Code*"
                                    style="lg:w-full"
                                    isRequired={true}/>
                        </div> */}
              {/* Contact Detail */}
              <div className="mt-[43px] sm:mt-8">
                <h2 className="text-[#82FA00] text-left font-bold text-[25px] uppercase leading-[90%] border-b border-b-brand-clime pb-2 ">
                  contact details
                </h2>
              </div>
              <div className="mt-[17px]">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-y-[21px] text-white gap-x-[32px]">
                  <InputBase
                    isShowRequired={checking}
                    onchange={handleChangeInput}
                    nameInput="firstName"
                    holder="First name*"
                    style="lg:w-full font-abcmonument"
                    isRequired={true}
                    value={contact.firstName}
                    revertColor
                  />
                  <InputBase
                    isShowRequired={checking}
                    onchange={handleChangeInput}
                    nameInput="lastName"
                    holder="Last name*"
                    style="lg:w-full font-abcmonument"
                    isRequired={true}
                    value={contact.lastName}
                    revertColor
                  />
                  <div className="md:col-span-2 col-span-1">
                    <InputBase
                      isShowRequired={checking}
                      onchange={handleChangeInput}
                      nameInput="email"
                      holder="Email*"
                      style="lg:w-full font-abcmonument"
                      isRequired={true}
                      value={contact.email}
                      revertColor
                    />
                  </div>
                  <div className='-mt-[6px]'>
                    <InputNumber
                        prefix={prefixNumber}
                        onChangePrefix={(value) => setPrefixNumber(value)}
                        isShowRequired={checking}
                        onchange={handleChangeInput}
                        nameInput="phone"
                        holder="Phone number*"
                        style="lg:w-full font-abcmonument"
                        isRequired={true}
                        value={contact.phone}
                        revertColor
                    />
                  </div>
                  <label>
                    <div
                      className={`w-full border-b flex h-[50px]  flex-col pb-[px]  relative ${
                        contact.dob !== ""
                          ? "border-b-brand-clime"
                          : "border-b-borderLine"
                      } `}
                    >
                      <div className="absolute flex  bottom-0 w-full pb-[10px]">
                        <Flatpickr
                          onChange={(dateObj, dateStr) =>
                            setContact({
                              ...contact,
                              dob: moment(new Date(dateObj)).format(
                                "YYYY-MM-DD"
                              ),
                            })
                          }
                          className='!sm:text-[14px] !text-base'
                          value={new Date(contact.dob)}
                          options={{
                            dateFormat: "d/m/Y",
                            enableTime: false,
                            time_24hr: false,
                            allowInput: false,
                            disableMobile: "true",
                          }}
                        />
                      </div>
                      <label
                        className={`font-abcmonument text-gray text-sm text-[14px] absolute left-0 ${
                          contact.dob !== ""
                            ? "text-gray top-[0px]"
                            : "text-[#ccc] top-[22px] sm:bottom-[28px]"
                        }`}
                      >
                        DOB*
                      </label>
                      {checking && contact.dob == "" && (
                        <div className="absolute right-10 bottom-2 flex items-center">
                          <span className="text-[12px] font-abcgravity text-[#FF6900]">
                            {" "}
                            This Field is required
                          </span>
                          <img
                            className="w-4 ml-[12px]"
                            src={"/icons/warn-circle.svg"}
                          />
                        </div>
                      )}
                      <div className="absolute -z-10 right-3 bottom-[14px]">
                        <img className="w-auto h-auto" src={dateIcon} />
                      </div>
                    </div>
                  </label>
                  <SelectOutline
                    name="gender"
                    contact={contact}
                    setContact={setContact}
                    isShowRequired={checking}
                    titleStyle="text-[12px]"
                    style=" h-[50px] w-full leading-[160%] font-normal font-abcmonument lg:w-full text-[14px]"
                    placeholder="Gender*"
                    placeholderColor="text-slate-400"
                    data={["Male", "Female", "Other"]}
                    value={contact.gender}
                    revertColor
                  />

                  <InputBase
                    isShowRequired={checking}
                    // onchange={setContact}
                    onchange={handleChangeInput}
                    nameInput="postCode"
                    holder="Postcode*"
                    style="lg:w-full font-abcmonument"
                    isRequired={true}
                    type="number"
                    value={contact.postCode}
                    revertColor
                  />
                </div>
              </div>

              {/* Emergency contact */}
              {moment(new Date()).diff(moment(contact.dob), "years") < 18 && (
                <div className="mt-[50px]">
                  <h2 className="text-[#82FA00] text-left text-[30px] uppercase font-bold leading-[90%] border-b border-b-brand-clime pb-2 font-abcgravity">
                    parent/guardian & Emergency Contact
                  </h2>
                  <div className="md:flex flex w-full mt-6 md:flex-row flex-col md:gap-y-5 gap-y-[21px]">
                    <InputBase
                      isShowRequired={checking}
                      onchange={handleChangeInput}
                      positionMessage="bottom"
                      nameInput="emergencyContactName"
                      holder="Emergency contact name*"
                      style="md:w-[25%] w-full"
                      value={contact?.emergencyContactName}
                      isRequired={true}
                      revertColor
                    />
                    <InputBase
                      isShowRequired={checking}
                      onchange={handleChangeInput}
                      positionMessage="bottom"
                      nameInput="emergencyContactRelation"
                      holder="Relation to you*"
                      style="md:w-[15%] w-full"
                      value={contact?.emergencyContactRelation}
                      isRequired={true}
                      revertColor
                    />
                    <InputBase
                      isShowRequired={checking}
                      onchange={handleChangeInput}
                      positionMessage="bottom"
                      nameInput="emergencyContactNumber"
                      holder="Emergency phone*"
                      style="md:w-[20%] w-full"
                      value={contact?.emergencyContactNumber}
                      isRequired={true}
                      revertColor
                    />
                    <InputBase
                      isShowRequired={checking}
                      onchange={handleChangeInput}
                      positionMessage="bottom"
                      nameInput="emergencyContactEmail"
                      holder="Email address*"
                      style="md:w-[50%] w-full"
                      value={contact?.emergencyContactEmail}
                      isRequired={true}
                      revertColor
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            onClick={() => {
              handleSubmit();
            }}
            className="mt-[54px] w-max  ml-auto flex justify-end md:mb-0 mb-[100px]"
          >
            <div className="hidden md:flex">
              <ArrowButton text="NEXT" isRight={true} />
            </div>
            <div className="flex md:hidden">
              <ArrowButton text="NEXT" isRight={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
