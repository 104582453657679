import React from 'react';
interface IProps {
    text: string;
    isRight: boolean;
    onclick: () => void;
    noArr: boolean;
}
function ArrowButton(props: IProps) {
    const { isRight, text, onclick, noArr } = props;
    return (
        <>
            <div
                className={` ${
                    !isRight ? 'flex-row-reverse' : 'flex-row'
                } border border-white hover:border-brand-clime hover:text-brand-clime group  transition-all flex items-center justify-between rounded`}
                onClick={() => {
                    onclick && onclick();
                }}
            >
                {text && (
                    <button className="text-[11px] font-abcmonument font-bold group-hover:text-brand-clime text-white uppercase px-10 h-[40px] ">
                        {text}
                    </button>
                )}
                {!noArr && (
                    <span
                        className={`${
                            isRight ? 'border-l   border-l-white' : 'border-r border-r-white group-hover:border-r-brand-clime'
                        } ${
                            !text ? ' !border-r-0 ' : ' '
                        } px-3 flex group-hover:border-l-brand-clime   h-[40px] items-center justify-center`}
                    >
                    {isRight?
                        <svg
                            width="25"
                            height="12"
                            viewBox="0 0 25 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.3867 1L23.0017 6.21395L17.3867 11.4279"
                                stroke='white'
                                className='group-hover:stroke-brand-clime'
                                strokeWidth="1.5"
                            />
                            <path d="M22.7069 5.96704H0" stroke='white'  className='group-hover:stroke-brand-clime' strokeWidth="1.5" />
                        </svg>:
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.49609 9L1.00408 4.82884L5.49609 0.65768" stroke='white'  className='group-hover:stroke-brand-clime' strokeWidth="1.2"/>
                            <path d="M1.23682 5.02734L19.4023 5.02734" stroke='white'  className='group-hover:stroke-brand-clime' strokeWidth="1.2"/>
                        </svg>

                    }


          </span>
                )}
            </div>
        </>
    );
}

export default ArrowButton;
