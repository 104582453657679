import React from "react";
import GetYourFreePass from "../components/home/GetYourFreePass";
import HomeSlider from "../components/home/HomeSlider";
import Intro from "../components/home/Intro";
import Memberships from "../components/home/Memberships";
import StudiosNearYou from "../components/home/StudiosNearYou";
import Layout from "../components/layout/Layout";

function HomePage() {
  return (
    <Layout>
      {/* <button onClick={() => callf()}>test btn</button> */}
      <HomeSlider />
      <Intro />
      <StudiosNearYou />
      <Memberships />
      <GetYourFreePass />
    </Layout>
  );
}

export default HomePage;
