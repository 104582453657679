import React, { useEffect, useState } from "react";
import InputBase from "../common/inputs/InputBase";
import { navigate } from "@reach/router";
import ButtonNavigate from "../common/buttons/ButtonNavigate";

function Access() {
  // const [status, setStatus] = useState('login');
  const status = "login";

  const [ready, setReady] = useState(false);
  const [account, setAccount] = useState({
    password: "",
  });

  const handleChangeValue = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    setReady(true);
    navigate(`/`);
  };

  useEffect(() => {
    if (account.password === "123123" && ready) {
      window.localStorage.setItem("userGatsby", "cris");
    }
  }, [handleSubmit]);

  return (
    <div className="text-white w-full">
      <div className="flex justify-center mt-[30px]"></div>

      {/* Form login */}
      {status === "login" && (
        <div className="w-full flex md:block flex-col items-center justify-center md:w-[473px] absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] mx-auto">
          <h1 className="text-[50px] text-white font-bold text-center mb-[45px]">
            View Page
          </h1>
          <div className="grid grid-rows-2 grid-flow-col  gap-y-[18px]">
            {/* <InputBase
              onchange={handleChangeValue}
              width="315px"
              type="password"
              holder="First name*"
              
            /> */}
            <InputBase
              nameInput="password"
              width="100%"
              type="password"
              // label="Password"
              holder="Password*"
              isRequired={true}
              // isShowRequired={formControlled.firstName}
              // onchange={(e) =>
              //   setForm({ ...form, firstName: e.target.value })
              // }
              onchange={handleChangeValue}
            />
            {/* <InputBase
              onchange={handleChangeValue}
              nameInput="password"
              width="100%"
              type="password"
              label="Password"
              holder="Password*"
            /> */}
          </div>

          <div className="flex items-center justify-center md:justify-end w-full mt-[-30px]">
            <div onClick={handleSubmit}>
              <ButtonNavigate text="Login" isArrowRight={true} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Access;
