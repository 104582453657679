import ArrowButton from '../../common/buttons/ArrowButton';
import CheckboxActive from '../../common/buttons/CheckboxActive';
import InputBase from '../../common/inputs/InputBase';
import InputNumber from '../../common/inputs/InputNumber';
import { toastError } from '../../common/toasts/toast';
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';
import Switch from '../../common/buttons/Switch';
import { breakpoints } from '../../constants';
import useWindowSize from '../../hooks/useWindowSize';
import { healthQuestionsByBrandId } from '../../apis/mutation';
import { useLazyQuery } from '@apollo/client';
interface IQuestion {
    createdAt: string;
    brandId: string;
    expectedAnswer: 'yes' | 'no' | null | '';
    id: string;
    isActive: boolean;
    isDeleted: boolean;
    question: string;
    mandatory: boolean;
    answerType: string;
    sectionId: string;
    sectionLabel: string;
    __typename?: string;
}
interface UIQuestion extends IQuestion {
    userValue: string;
}
interface SlideProps {
    onNext: () => void;
    onPrev: () => void;
    info: any;
    setInfo: () => void;
    openModalTC: () => void;
    openModalPP: () => void;
}
const Health: React.FC<SlideProps> = ({
                                          onNext,
                                          onPrev,
                                          info,
                                          setInfo,
                                          openModalTC,
                                          openModalPP,
                                      }) => {
    const [terms, setTerms] = useState<boolean>(false);
    const [prefixNumber, setPrefixNumber] = React.useState<'+61' | '+64'>('+61');
    const [emergency, setEmergency] = useState({
        emergencyContactName: '',
        emergencyContactRelation: '',
        emergencyContactEmail: '',
        emergencyContactNumber: '',
    });
    const validate = () => {
        if (
            !/^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/.test(
                '0' + emergency.emergencyContactNumber.split(prefixNumber)[1]
            )
        ) {
            if (
                !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(emergency.emergencyContactEmail)
            ) {
                toastError('You have entered an invalid emergency email address!');
                return false;
            }
            toastError('You have entered an invalid emergency phone number!');
            return false;
        }

        return true;
    };
    const setDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        const name = e.target.name;
        if (name === 'emergencyContactNumber') {
            value = prefixNumber + value;
            if (value.charAt(3) == '0') value = value.slice(0, 3) + value.slice(4);
        }
        setEmergency({
            ...emergency,
            [name]: value,
        });
    };
    const [policy1, setPolicy1] = useState(false);
    const [policy2, setPolicy2] = useState(false);
    const { width } = useWindowSize();
    const healthCheck = () => {
        if (
            healthQuestions.every((i: UIQuestion) =>
                i.answerType === 'NUMBER'
                    ? i.userValue === i.expectedAnswer?.toLowerCase() ||
                    !i.expectedAnswer
                    : i.answerType === 'STRING'
                        ? i.mandatory
                            ? i.userValue != ''
                            : true
                        : false
            )
        ) {
            return true;
        }
        setIsSubmitted(true);
        toastError('Please read and accept the pre-exercise check list here');
        return false;
    };
    const handleSubmit = () => {
        if (
            (width && width <= breakpoints.sm && policy1 && policy2) ||
            (width && width > breakpoints.sm && policy1 && policy2)
        ) {
            const dataChecking = { ...emergency };
            dataChecking.emergencyContactEmail === '' &&
            delete dataChecking.emergencyContactEmail;
            const healthData = [...healthQuestions].reduce(
                (obj, item: UIQuestion) => ({ ...obj, [item.id]: item.userValue }),
                {}
            );
            if (Object.values(dataChecking).every((i) => i && i != '')) {
                // validate inputs
                if (validate() && healthCheck()) {
                    !terms && delete info.memberDetails.healthQuestionnaire;
                    //@ts-ignore
                    !terms
                        ? //@ts-ignore
                        setInfo({
                            ...info,
                            memberDetails: {
                                ...info.memberDetails,
                                ...emergency,
                                healthQuestionnaire: JSON.stringify(healthData),
                            },
                        })
                        : //@ts-ignore
                        setInfo({
                            ...info,
                            memberDetails: {
                                ...info.memberDetails,
                                ...emergency,
                                healthQuestionnaire: JSON.stringify(healthData),
                            },
                        });
                    onNext();
                }
            } else
                !Object.values(dataChecking).every((i) => i && i != '') &&
                toastError('Please fill all the inputs except emergency email first');
        } else {
            !(policy1 && policy2) &&
            toastError('Please accept our Terms and Conditions');
        }
    };
    useEffect(() => {
        if (info?.memberDetails) {
            const data = {
                emergencyContactName: info?.memberDetails?.emergencyContactName,
                emergencyContactRelation: info?.memberDetails?.emergencyContactRelation,
                emergencyContactNumber: info?.memberDetails?.emergencyContactNumber,
                emergencyContactEmail: info?.memberDetails?.emergencyContactEmail,
            };
            if (!info?.memberDetails?.emergencyContactEmail) {
                delete data.emergencyContactEmail;
            }
            Object.values(data).filter((i) => i != '' && i).length != 0 &&
            setEmergency({ ...data });
        }
    }, [info?.memberDetails]);
    const [healthQuestions, setHealthQuestions] = useState<UIQuestion[]>([
        // sort section
        // active && delete
        {
            id: '8ae71b0d-fc73-4ad4-a1f5-2a0ebb24328d',
            brandId: '6dec4e5f-7a07-4a7e-a809-2c0c1df01366',
            createdAt: '2023-08-12T23:27:12.460Z',
            isActive: true,
            isDeleted: false,
            question:
                'I will seek guidance from an appropriate health or medical practitioner prior to undertaking exercise.',

            mandatory: true,
            expectedAnswer: 'yes',
            answerType: 'enum',
            userValue: '',

            sectionId: '2',
            sectionLabel: 'section 1',
        },
        {
            id: '1cdba556-6746-45b8-8ce5-01cb0354047c',
            brandId: '6dec4e5f-7a07-4a7e-a809-2c0c1df01366',
            createdAt: '2023-08-12T23:25:12.460Z',
            expectedAnswer: null,
            isActive: false,
            isDeleted: true,
            mandatory: true,
            question:
                'Have you ever been told by a doctor that you have a heart condition or have suffered a stroke?',
            answerType: 'enum',
            sectionId: '3',
            sectionLabel: 'section 1',
            userValue: '',
        },
        {
            id: 'b9c3ce32-e722-4ab7-99ef-afee4fcb2b7b',
            brandId: '6dec4e5f-7a07-4a7e-a809-2c0c1df01366',
            createdAt: '2023-08-12T23:25:12.460Z',
            expectedAnswer: null,
            isActive: true,
            isDeleted: false,
            mandatory: true,
            question:
                'Do you ever have unexplained pains in your chest at rest or during physical exercise?',
            answerType: 'enum',
            sectionId: '1',
            sectionLabel: 'section 1',
            userValue: '',
        },
    ]);
    const healthQuestionSections = useMemo(() => {
        const result: any = [...healthQuestions].reduce(
            (obj, item: UIQuestion) => ({
                ...obj,
                [item.sectionLabel]: [...[item].concat(obj[item.sectionLabel])].filter(
                    (i) => i
                ),
            }),
            {}
        );
        return result;
    }, [healthQuestions]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [getHealthQuestion] = useLazyQuery(healthQuestionsByBrandId);
    useEffect(() => {
        getHealthQuestion().then((res: any) => {
            const questions: UIQuestion[] =
                res.data.getHealthQuestionnairesByBrandId.items.map((i: IQuestion) => {
                    return { ...i, userValue: '' };
                });
            setHealthQuestions(questions);
        });
    }, []);

    const handleOnOffInitial = (i: UIQuestion) => {
        if (i?.mandatory) {
            if (i.userValue !== '') {
                return i.userValue !== ''
                    ? i.userValue == 'no'
                        ? 'off'
                        : 'on'
                    : 'off';
            } else {
                return 'off';
            }
        }
        return null;
    };
    return (
        <>
            <div
                id="scroll-hidden"
                className="mx-0 h-full overflow-y-scroll sm:pb-[70px] pb-[150px]"
            >
                <div className="sm:pb-[15px] flex flex-col pb-[7px] border-b border-b-primary sm:mt-[44px] mt-8">
                    <h2 className="heading2 text-brand-clime uppercase text-left ">
                        health check
                    </h2>
                    {isSubmitted &&
                        healthQuestions.filter((i) => i.sectionId === '1').length &&
                        !healthQuestions
                            .filter((i) => i.sectionId === '1')
                            .every(
                                (i) =>
                                    !i.mandatory &&
                                    i.expectedAnswer !== i.userValue &&
                                    i.expectedAnswer !== ''
                            ) && (
                            <span className="text-red-500 text-sm text-left  self-start">
                your answer is not matching our expected please read the
                sentence again question with "!" icon
              </span>
                        )}
                </div>
                {/* <div className="mt-6 flex md:items-start items-center md:flex-col flex-row justify-between">
          <h6 className="text-sm lg:text-center sm:!text-start text-left text-white font-normal sm:text-base ">
            Do you have any injuries, disabilities or current conditions? *
          </h6>
          <div className="flex items-center md:w-full gap-4 sm:gap-[9px] md:mt-5 mt-0">
            <div
              onClick={() => setTerms(true)}
              className={`${
                terms ? 'bg-primary border-none' : 'bg-transparent border-white'
              } sm:text-base sm:font-medium cursor-pointer flex items-center justify-center text-sm border rounded w-[70px] md:w-[50%] h-[37px]  text-white uppercase`}
            >
              yes
            </div>
            <div
              onClick={() => setTerms(false)}
              className={`${
                !terms
                  ? 'bg-primary border-none'
                  : 'bg-transparent border-white'
              } sm:text-base sm:font-medium cursor-pointer flex items-center justify-center border text-sm rounded bg-primary w-[70px] md:w-[50%] h-[37px] text-white uppercase`}
            >
              no
            </div>
          </div>
        </div> */}

                <div className="mt-5 ">
                    <div className="hidden sm:flex items-center flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0">
                        <div>
                            <h2 className=" text-sm uppercase text-textSecondary text-left">
                                Please select the relevant box below*
                            </h2>
                            <p className="text-textSecondary  text-[12px] mt-1 text-left">
                                Note you must select one option, in order to proceed with this
                                gym membership.
                            </p>
                        </div>
                    </div>

                    {/* MOBILE */}
                    <div className="block sm:hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8 text-left">
                        <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                            pre-exercise checklist*
                        </h2>
                    </div>
                    <p className="block sm:hidden text-textSecondary text-base leading-[21.36px] mt-3 sm:font-normal text-left mb-2">
                        Please complete the following questions, so we are aware of your
                        current health situation:
                    </p>

                    <div className="flex flex-col  ">
                        {healthQuestions
                            .filter((i) => i.sectionLabel.toLowerCase() == 'health check')
                            .filter((i) => i.answerType === 'NUMBER')
                            .map((i, index: number) => {
                                const initValue = handleOnOffInitial(i);
                                return (
                                    <div
                                        key={index}
                                        className="relative group flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 py-3 px-0"
                                    >
                                        <div className="text-gray flex items-center gap-[5px]  text-left">
                                            {isSubmitted &&
                                                i.mandatory &&
                                                i.expectedAnswer !== '' &&
                                                i.userValue !== i.expectedAnswer && (
                                                    <div className="tooltip inline-block  text-red-500">
                                                        <svg
                                                            className="text-red-500"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                                stroke="currentColor"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                            <path
                                                                d="M12 8V13"
                                                                stroke="currentColor"
                                                                stroke-width="1.5"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                            <path
                                                                d="M11.9941 16H12.0031"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                        </svg>{' '}
                                                        <span className="triangle invisible absolute bottom-[calc(100%+2px)] left-[5px] w-[13px] h-3 rotate-45 border-white border-b border-r z-10 bg-brand-clime"></span>
                                                        <span className="border border-white tooltiptext bottom-[calc(100%+8px)]">
                              expected answer is {i.expectedAnswer}{' '}
                            </span>
                                                    </div>
                                                )}
                                            {i.question}
                                        </div>

                                        <Switch
                                            initial={initValue}
                                            onChange={(value: string) => {
                                                const data: UIQuestion[] = [...healthQuestions].map(
                                                    (item, idx) => {
                                                        return {
                                                            ...item,
                                                            userValue:
                                                                item.id == i.id
                                                                    ? value == 'on'
                                                                        ? 'yes'
                                                                        : 'no'
                                                                    : item.userValue,
                                                        };
                                                    }
                                                );
                                                setHealthQuestions([...data]);
                                            }}
                                            isYesNo
                                        />
                                    </div>
                                );
                            })}
                        <div className="grid grid-cols-2 grid-flow-row gap-4">
                            {healthQuestions
                                .filter((i) => i.sectionLabel.toLowerCase() == 'health check')
                                .filter((i) => i.answerType === 'STRING')
                                .map((i, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex items-start flex-col gap-4 py-3 px-0 relative"
                                        >
                                            <InputBase
                                                holder={i.question}
                                                style="w-full"
                                                disabled={
                                                    moment(new Date()).diff(
                                                        moment(info?.memberDetails?.dob),
                                                        'years'
                                                    ) < 18
                                                }
                                                isRequired={true}
                                                onchange={(e) => {
                                                    const data: UIQuestion[] = [...healthQuestions].map(
                                                        (item, idx) => {
                                                            return {
                                                                ...item,
                                                                userValue:
                                                                    i.id === item.id
                                                                        ? e.target.value
                                                                        : item.userValue,
                                                            };
                                                        }
                                                    );
                                                    setHealthQuestions([...data]);
                                                }}
                                                value={i.userValue}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                        {/* ANOTHER SECTION */}
                        <div className="sm:mb-[15px] sm:hidden flex border-none flex-col gap-[3px] sm:mt-[40px] mt-7 mb-0">
                            <h2 className=" text-base uppercase text-white leading-[160%] font-bold text-left">
                                Please select the relevant box below*
                            </h2>
                            <p className="text-textSecondary border-none  text-[16px] leading-[133%] mt-1 text-start mb-5">
                                Note you must select one option, in order to proceed with this
                                gym membership.
                            </p>
                        </div>
                        <div className="sm:items-center sm:hidden flex flex-row-reverse md:flex-row justify-end md:justify-between gap-4 px-0 items-start">
                            <p className="sm:text-gray  leading-[135%] text-white  text-left">
                                I will seek guidance from an appropriate health or medical
                                practitioner prior to undertaking exercise.
                            </p>
                            <div className="flex gap-2 mt-1 sm:top-0">
                                <CheckboxActive />
                            </div>
                        </div>
                        <div className="sm:items-center sm:hidden flex flex-row-reverse md:flex-row justify-end md:justify-between gap-4 pb-[27px] px-0 pb-0 items-start">
                            <p className="sm:text-gray leading-[135%] text-white   text-left">
                                I am under the supervision of a medical practitioner.
                            </p>
                            <div className="flex gap-2 sm:mt-0 mt-1">
                                <CheckboxActive />
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    <div className="sm:pb-[15px] flex flex-col pb-[7px] border-b border-b-brand-clime sm:mt-[24px] mt-8">
                        <h2 className="heading2 text-brand-clime uppercase text-left ">
                            medical declaration
                        </h2>
                        {isSubmitted &&
                            !healthQuestions.filter((i) => i.sectionId === '2').length &&
                            !healthQuestions
                                .filter((i) => i.sectionId === '2')
                                .every(
                                    (i) =>
                                        !i.mandatory &&
                                        i.expectedAnswer !== i.userValue &&
                                        i.expectedAnswer !== ''
                                ) && (
                                <span className="text-red-500 text-sm text-left  self-start">
                  your answer is not matching our expected please read the
                  sentence again question with "!" icon
                </span>
                            )}
                    </div>
                    <div className="flex flex-col  ">
                        {healthQuestions
                            .filter(
                                (i) => i.sectionLabel.toLowerCase() == 'medical declaration'
                            )
                            .filter((i) => i.answerType === 'NUMBER')
                            .map((i, index: number) => {
                                const initValue = handleOnOffInitial(i);
                                return (
                                    <>
                                        {i.answerType === 'NUMBER' && (
                                            <div
                                                key={index}
                                                className="relative group flex items-start flex-row-reverse md:flex-row justify-end md:justify-between gap-4 pt-5 pb-3 px-0"
                                            >
                                                <div className="text-gray flex items-center gap-[5px]  text-left">
                                                    {isSubmitted &&
                                                        i.mandatory &&
                                                        i.userValue !== i.expectedAnswer && (
                                                            <div className="tooltip inline-block  text-red-500">
                                                                <svg
                                                                    className="text-red-500"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                                        stroke="currentColor"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M12 8V13"
                                                                        stroke="currentColor"
                                                                        stroke-width="1.5"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                    <path
                                                                        d="M11.9941 16H12.0031"
                                                                        stroke="currentColor"
                                                                        stroke-width="2"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                    />
                                                                </svg>{' '}
                                                                <span className="triangle invisible absolute bottom-[calc(100%+2px)] left-[5px] w-[13px] h-3 rotate-45 border-white border-b border-r z-10 bg-primary"></span>
                                                                <span className="border border-white tooltiptext bottom-[calc(100%+8px)]">
                                  expected answer is {i.expectedAnswer}{' '}
                                </span>
                                                            </div>
                                                        )}
                                                    {i.question}
                                                </div>
                                                <Switch
                                                    initial={initValue}
                                                    onChange={(value: string) => {
                                                        const data: UIQuestion[] = [...healthQuestions].map(
                                                            (item, idx) => {
                                                                return {
                                                                    ...item,
                                                                    userValue:
                                                                        item.id == i.id
                                                                            ? value == 'on'
                                                                                ? 'yes'
                                                                                : 'no'
                                                                            : item.userValue,
                                                                };
                                                            }
                                                        );
                                                        setHealthQuestions([...data]);
                                                    }}
                                                    isYesNo
                                                />
                                            </div>
                                        )}
                                    </>
                                );
                            })}
                        <div className="grid grid-cols-2 grid-flow-row gap-4">
                            {healthQuestions
                                .filter(
                                    (i) => i.sectionLabel.toLowerCase() == 'medical declaration'
                                )
                                .filter((i) => i.answerType === 'STRING')
                                .map((i, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="flex items-start flex-col gap-4 py-3 px-0 relative"
                                        >
                                            <InputBase
                                                holder={i.question}
                                                style="w-full"
                                                disabled={
                                                    moment(new Date()).diff(
                                                        moment(info?.memberDetails?.dob),
                                                        'years'
                                                    ) < 18
                                                }
                                                isRequired={true}
                                                onchange={(e) => {
                                                    const data: UIQuestion[] = [...healthQuestions].map(
                                                        (item, idx) => {
                                                            return {
                                                                ...item,
                                                                userValue:
                                                                    i.id === item.id
                                                                        ? e.target.value
                                                                        : item.userValue,
                                                            };
                                                        }
                                                    );
                                                    setHealthQuestions([...data]);
                                                }}
                                                value={i.userValue}
                                            />
                                        </div>
                                    );
                                })}
                        </div>
                        {/* ANOTHER SECTION */}
                        <div className="sm:mb-[15px]  sm:hidden flex border-none flex-col gap-[3px] sm:mt-[40px] mt-7 mb-0">
                            <h2 className=" text-base uppercase text-white leading-[160%] font-bold text-left">
                                Please select the relevant box below*
                            </h2>
                            <p className="text-textSecondary border-none  text-[16px] leading-[133%] mt-1 text-start mb-5">
                                Note you must select one option, in order to proceed with this
                                gym membership.
                            </p>
                        </div>
                        <div className="sm:items-center sm:hidden flex flex-row-reverse md:flex-row justify-end md:justify-between gap-4 px-0 items-start">
                            <p className="sm:text-gray  leading-[135%] text-white  text-left">
                                I will seek guidance from an appropriate health or medical
                                practitioner prior to undertaking exercise.
                            </p>
                            <div className="flex gap-2 sm:mt-1">
                                <CheckboxActive />
                            </div>
                        </div>
                        <div className="sm:items-center sm:hidden flex flex-row-reverse  md:flex-row justify-end md:justify-between gap-4 sm:pb-[27px] px-0 pb-0 items-start">
                            <p className="sm:text-gray leading-[135%] text-white   text-left">
                                I am under the supervision of a medical practitioner.
                            </p>
                            <div className="flex gap-2 sm:mt-0 mt-1">
                                <CheckboxActive />
                            </div>
                        </div>
                    </div>
                </>
                {(!terms || (width && width > breakpoints.sm)) && (
                    <div className="pt-5 mt-[15px] border-t border-t-transparent sm:border-t-textSecondary ">
                        <h2 className=" sm:text-sm text-base pb-2 md:pb-0 font-bold uppercase sm:text-textSecondary text-white text-left sm:border-transparent border-b border-textSecondary">
                            terms and conditions*
                        </h2>
                        <div className="flex flex-col gap-6 md:gap-4 mt-3">
                            <div className="flex items-center text-white sm:text-[#FFF] gap-4 md:justify-between flex-row-reverse md:flex-row justify-end">
                                <p className="text-white sm:text-gray  text-left font-normal">
                                    I agree to the{' '}
                                    <span
                                        className="underline cursor-pointer"
                                        onClick={openModalTC}
                                    >
                    terms & conditions
                  </span>{' '}
                                    and the{' '}
                                    <span
                                        className="underline cursor-pointer"
                                        onClick={openModalPP}
                                    >
                    privacy policy
                  </span>{' '}
                                    attached to my membership.*
                                </p>
                                <CheckboxActive onChecked={setPolicy1} />
                            </div>
                            <div className="flex items-center gap-4 md:justify-between flex-row-reverse md:flex-row justify-end">
                                <p className="sm:text-gray text-white  text-left font-normal">
                                    I confirm I meet the requirements from the pre-exercise
                                    checklist
                                </p>
                                <CheckboxActive onChecked={setPolicy2} />
                            </div>
                        </div>
                    </div>
                )}
                <div className="sm:mt-[47px] mt-8 border-b border-b-primary">
                    <h2 className="heading2 sm:pb-[15px] pb-[7px] text-primary uppercase text-left ">
                        {moment(new Date()).diff(
                            moment(info?.memberDetails?.dob),
                            'years'
                        ) < 18
                            ? 'parent/guardian eMergency contact'
                            : 'eMergency contact'}
                    </h2>
                </div>
                <div className="sm:mt-[25px] grid grid-cols-1 md:grid-cols-2 gap-6 mt-3">
                    <InputBase
                        nameInput="emergencyContactName"
                        holder="Emergency contact name*"
                        style="lg:w-full"
                        disabled={
                            moment(new Date()).diff(
                                moment(info?.memberDetails?.dob),
                                'years'
                            ) < 18
                        }
                        isRequired={true}
                        onchange={setDetail}
                        value={emergency.emergencyContactName}
                    />
                    <InputBase
                        nameInput="emergencyContactRelation"
                        holder="Relation to you*"
                        style="lg:w-full"
                        disabled={
                            moment(new Date()).diff(
                                moment(info?.memberDetails?.dob),
                                'years'
                            ) < 18
                        }
                        isRequired={true}
                        onchange={setDetail}
                        value={emergency.emergencyContactRelation}
                    />
                    <div className='-mt-[6px]'>
                        <InputNumber
                            nameInput="emergencyContactNumber"
                            holder="Emergency phone*"
                            style="lg:w-full"
                            isRequired={true}
                            onchange={setDetail}
                            prefix={prefixNumber}
                            onChangePrefix={(value) => setPrefixNumber(value)}
                            value={emergency.emergencyContactNumber}
                            disabled={
                                moment(new Date()).diff(
                                    moment(info?.memberDetails?.dob),
                                    'years'
                                ) < 18
                            }
                        />
                    </div>
                    <InputBase
                        nameInput="emergencyContactEmail"
                        holder="Emergency email*"
                        style="lg:w-full"
                        disabled={
                            moment(new Date()).diff(
                                moment(info?.memberDetails?.dob),
                                'years'
                            ) < 18
                        }
                        isRequired={true}
                        onchange={setDetail}
                        value={emergency.emergencyContactEmail}
                    />
                    {/* <div className="mt-[50px]"><h2 className="text-brand-clime text-[30px] bebas font-normal uppercase border-b border-b-brand-clime pb-2">TERMS AND CONDITIONS</h2></div> */}
                </div>
                {width && width <= breakpoints.sm && terms && (
                    <div className="mt-10">
                        <h2 className="  leading-[160%] pb-2 md:pb-0  uppercase text-white text-left border-b sm:border-transparent border-textSecondary text-base sm:font-normal font-bold">
                            terms & conditions*
                        </h2>
                        <div className="flex flex-col gap-6 md:gap-4 mt-3">
                            <div className="flex items-center gap-4 justify-between flex-row-reverse  md:flex-row">
                                <p className="sm:text-gray text-base text-white  text-left">
                                    I confirm I have read and accept the terms and conditions.
                                </p>
                                <CheckboxActive onChecked={setPolicy2} />
                            </div>
                        </div>
                    </div>
                )}
                {/* {moment(new Date()).diff(moment(info.memberDetails.dob), "years") < 18 && <><div className='pb-[15px] border-b border-b-primary mt-[44px]'>
                <h2 className='heading2 text-primary uppercase font-bicyclette text-left'>parent/guardian & Emergency Contact </h2>
            </div>
            </>} */}
                {/* Button to actions */}
                <div className="mt-[60px] w-full flex items-center justify-between">
                    <div onClick={() => onPrev()}>
                        <div className="hidden md:flex">
                            <ArrowButton text="back" isRight={false} />
                        </div>
                        <div className="flex md:hidden">
                            <ArrowButton text="" isRight={false} />
                        </div>
                    </div>
                    <div onClick={() => handleSubmit()}>
                        <div className="hidden md:flex">
                            <ArrowButton text="next" isRight={true} />
                        </div>
                        <div className="flex md:hidden">
                            <ArrowButton text="next" isRight={true} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Health;
