import React from "react";
export function arrowRight() {
  return (
    <svg
      width="25"
      height="12"
      viewBox="0 0 25 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3867 1L23.0017 6.21395L17.3867 11.4279"
        stroke="currentColor"

        strokeWidth="1.5"
      />
      <path d="M22.7069 5.96704H0" stroke="currentColor" strokeWidth="1.5" />
    </svg>
  );
}
