import React, { useContext, useState } from 'react';
import DashBoardLayout from '../../../components/layout/DashBoardLayout';
import arrowLeftShort from '../../../assets/images/icons/arrowLeftShort.svg';
import { Link } from 'gatsby';
import InputBase from '../../../../common/inputs/InputBase';
import { toastError, toastSuccess } from '../../../../common/toasts/toast';
import { Auth } from 'aws-amplify';
import ThreeDot from '../../../../common/loading/ThreeDot';
// import { Auth } from "aws-amplify";
// import { toast } from "react-toastify";

function Password() {
  const resetPassword = async () => {
    try {
      setLoading(true);
      if (
        !Boolean(
          formData &&
            Object.values(formData).every((v) => v != '' && v) &&
            formData.newPassword === formData.confirmNewPassword
        )
      ) {
        throw new Error(
          'Please fill in all inputs and ensure that the new password matches the confirm new password.'
        );
      }
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        formData.oldPassword,
        formData.newPassword
      );
      toastSuccess('Successfully update password !');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [formData, setFormData] = useState({});
  const setData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [isLoading, setLoading] = useState(false);
  return (
    <div className=" ">
      {/* Layout */}{' '}
      <div className="sm:mt-[30px] mt-8 pb-20 relative border sm:border border-0 border-gray rounded-md sm:pb-6 sm:hover:bg-[#1F2528] hover:bg-transparent">
        {isLoading && <ThreeDot />}
        <div className="hidden sm:block border-b  border-white tracking-[1.4px] text-sm text-white px-6 font-medium  py-[20px] uppercase">
          reset password
        </div>

        <div className="sm:mt-[37px] px-[27px] grid sm:grid-cols-2 gap-x-[30px] sm:gap-y-[60px] grid-cols-1 gap-y-5 mt-0">
          <div>
            <InputBase
              type="password"
              holder="Existing password"
              style="w-full"
              isRequired={true}
              nameInput="oldPassword"
              onchange={setData}
            />
          </div>
          <div className="blank sm:block hidden"></div>
          <InputBase
            type="password"
            holder="New password"
            style="w-full"
            isRequired={true}
            nameInput="newPassword"
            onchange={setData}
          />
          <InputBase
            type="password"
            holder="Confirm new password"
            style="w-full"
            isRequired={true}
            nameInput="confirmNewPassword"
            onchange={setData}
          />
          <div className="gap-[25pxx]">
            <div className=""></div>
            <div
              onClick={() => {
                resetPassword();
              }}
              className="sm:w-[158px] cursor-pointer flex justify-center items-center hover:font-medium font-bold sm:font-normal hover:text-black hover:bg-brand-clime sm:h-[37px] border-b-gray border sm:text-gray hover:border-primary sm:text-[11px] text-[12px]  rounded sm:border-white text-white w-[234px] h-[34px] sm:mt-0 mt-7"
            >
              CHANGE PASSWORD
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Password;
