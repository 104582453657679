import React, { useEffect, useState } from 'react';
function CheckboxActive(props: any) {
  const { onChecked, isDashboard, isChecked } = props;
  const [isAccepted, setIsAccepted] = useState(false);
  useEffect(() => {
    if (typeof onChecked == 'function') {
      onChecked(isAccepted);
    }
  }, [isAccepted]);
  useEffect(() => {
    setIsAccepted(isChecked)
  }, [isChecked]);
  return (
      <button
          onClick={() => setIsAccepted(!isAccepted)}
          style={isAccepted ? isDashboard ? { border: '1px solid white', background: 'white' } : { border: '1px solid #B3E833' ,background: '' } : { border: '1px solid white', backgroundColor: '' }}
          className={`flex-none text-white font-bold rounded h-[30px] w-[30px] border-t border-l border-r border-white flex justify-center items-center`}
      >
        {isAccepted ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                <path d="M1 4.23077L4.5 8L11 1" stroke={isDashboard ? '#82FA00' : '#82FA00'} strokeWidth="1.88498" />
            </svg>
        ) : (
            ''
        )}
      </button>
  );
}

export default CheckboxActive;
