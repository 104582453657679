import React, { useEffect, useState } from "react";
import DropDownIcon from "../../assets/images/icons/DropDownIcon.svg";
import Checkbox from "../../common/buttons/Checkbox";
import ButtonNavigate from "../buttons/ButtonNavigate";
function Dropdown({ onChange = () => {}, onSelect = () => {}, ...props }) {
  // const { onChange } = props;
  // const { onSelect } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { selectedData } = props;
  const [data, setData] = useState();

  useEffect(() => {
    let abc = [];
    props.data.value.map((item) => {
      const itemKey = Object.keys(item)[0];
      let obj = {};
      obj[itemKey] = item[itemKey].map((i) => {
        if (i === "closest to me") {
          if (props.isLocation) {
            return { text: i, checked: true };
          } else return { text: i, checked: false };
        }
        return { text: i, checked: false };
      });
      abc.push(obj);
    });
    setData(abc);
  }, []);

  const reselect = (e, i, idx, key) => {
    if (i.text !== "closest to me") {
      let abc = [];
      let obj = {};
      data.map((item) => {
        const itemKey = Object.keys(item)[0];
        obj[itemKey] = item[itemKey].map((e) => {
          return e.text == i.text
            ? { ...e, checked: true }
            : e.text == "closest to me"
            ? { ...e }
            : { ...e, checked: false };
        });
        abc.push(obj);
      });

      setData(abc);
    }
  };
  const reselect1 = (e, i, idx, key) => {
    let abc = [];
    let obj = {};
    data.map((item) => {
      const itemKey = Object.keys(item)[0];
      obj[itemKey] = item[itemKey].map((e) => {
        return e.text == i.text ? { ...e, checked: !e.checked } : { ...e };
      });
      abc.push(obj);
    });
    setData(abc);
  };

  return (
    <div className={props.style + " relative flex"}>
      <div
        className={"flex w-full justify-between items-center bg-transparent"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div
          className={`bg-transparent ${
            props.data.label === "sort by"
              ? (selectedData?.text != "" && selectedData?.length != 0) ||
                data?.[0]?.["sort by"]?.[2]?.checked == true
                ? "text-brand-clime"
                : "text-white"
              : selectedData.length > 0
              ? "text-brand-clime"
              : "text-white"
          }`}
        >
          {props.data.label === "sort by"
            ? selectedData?.text != "" && selectedData?.length != 0
              ? `${
                  data?.[0]?.["sort by"]?.[2]?.checked == true
                    ? "closest to me, "
                    : ""
                }` + selectedData.text
              : `${
                  data?.[0]?.["sort by"]?.[2]?.checked == true
                    ? "closest to me "
                    : "sort by"
                }`
            : selectedData.length > 0
            ? selectedData?.map((i) => i.text).join(", ")
            : props.data.label}
        </div>
        <div>
          <img className=" bg-transparent" src={DropDownIcon} />
        </div>
      </div>
      <div>
        <div className={isOpen ? "" : "hidden"}>
          <div
            style={{ backgroundColor: `${props.background}` }}
            className="absolute top-[calc(100%-2px)] border-l border-r border-b border-brand-clime left-0 w-full text-white bg-[#292929] px-[25px] pb-[25px] z-10"
          >
            {data?.map((item: any, index: number) => {
              const itemKey = Object.keys(item)[0];
              return (
                <div key={index}>
                  <p className="text-[rgba(255,255,255,0.5)] pb-[6px] text-sm font-normal border-b-[1px] border-b-[rgba(255,255,255,0.5)] graphik-regular">
                    {props.noEachTitle ? "" : itemKey}
                  </p>
                  {item[itemKey]?.map((i: any, idx: number) => (
                    <div className="flex justify-between py-[13px]" key={idx}>
                      <div className=" font-semibold ">{i.text}</div>
                      {props.justOne ? (
                        i.text !== props?.outSideGroup ? (
                          <Checkbox
                            controlled={props.justOne}
                            isChecked={i.checked}
                            onChecked={(e: any) => {
                              if (props.justOne) reselect(e, i, idx, itemKey);
                              onChange(e, i, props.data.label);
                            }}
                          ></Checkbox>
                        ) : (
                          <Checkbox
                            controlled={true}
                            isChecked={i.checked}
                            onChecked={(e: any) => {
                              reselect1(e, i, idx, itemKey);
                              onChange(e, i, props.data.label);
                            }}
                          ></Checkbox>
                        )
                      ) : (
                        <Checkbox
                          controlled={false}
                          isChecked={i.checked}
                          onChecked={(e: any) => {
                            onChange(e, i, props.data.label);
                          }}
                        ></Checkbox>
                      )}
                    </div>
                  ))}
                </div>
              );
            })}

            <div>
              <ButtonNavigate
                onClick={() => {
                  setIsOpen(false);
                  onSelect();
                }}
                text="apply"
                style="text-[#777777] text-[#777777] w-full h-12 border-b "
              ></ButtonNavigate>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
