import React from "react";
import Layout from "../components/layout/Layout";
import GroupFitnessSlider from "../components/group-fitness/GroupFitnessSlider";
import ClassForEveryBody from "../components/group-fitness/ClassForEveryBody";
import ExploreClassType from "../components/group-fitness/ExploreClassType";
import GetYourFreePass from "../components/home/GetYourFreePass";
export default function GroupFitness() {
  return (
    <Layout>
      {/* <div className="text-brand-clime">group fitness</div> */}
      <GroupFitnessSlider />
      <ClassForEveryBody />
      <ExploreClassType />
      <GetYourFreePass/>
    </Layout>
  );
}
