import React, { useEffect } from 'react';
// import TableData from "./TableData";
function TableBase(props) {
  const color = {
    'club lime': '#B3E833',
    'hiit republic': '#FF6900',
    'ground up': '#A4ACD7',
  };
  // get table column
  const column = props?.headerCells;

  // get table heading data
  const ThData = () => {
    return column.map((data, idx) => {
      if (idx === 0)
        return (
            <div
                className="text-left w-[24%] py-[15px] border-b sticky top-0  z-10 border-white h-[52px] text-sm font-abcmonument font-normal text-start   uppercase pl-[22px] "
                key={idx}
            >
              {data}
            </div>
        );
      else
        return (
            <div
                className={`text-left py-[15px] border-b sticky top-0 z-10 border-white h-[52px] text-sm font-abcmonument font-normal uppercase w-[14%] ${
                    idx == 1
                        ? 'w-[50%] pr-[15px]'
                        : idx == 2
                            ? 'w-[20%] pl-2'
                            : idx == 3
                                ? 'w-[6%] '
                                : ''
                }`}
                key={idx}
            >
              {Object.keys(data) && data.elm ? data.label : data}
            </div>
        );
    });
  };

  // get table row data
  const tdData = () => {
    return props.bodyCells.map((data, index) => {
      return (
          <div className="text-left  flex items-center relative " key={index}>
            {column.map((v, idx) => {
              if (idx === 0)
                return (
                    <div
                        className="relative flex items-center py-[1px] w-[24%] border-b border-gray text-start h-[52px] font-abcmonument uppercase z-0 pl-[22px] font-normal truncate"
                        key={idx}
                    >
                      {data[v] && Object.keys(data[v]).length > 0 && data[v]?.custom
                          ? data?.[v]?.custom(data)
                          : data[v]}
                    </div>
                );
              else
                return (
                    <div
                        className={`py-[1px] flex  items-center border-b border-gray text-sm text-start h-[52px] font-abcmonument  uppercase z-0  truncate ${
                            idx == 1
                                ? 'w-[50%] pr-[15px]'
                                : idx == 2
                                    ? 'w-[20%] pl-2'
                                    : idx == 3
                                        ? 'w-[6%]'
                                        : ''
                        } ${idx == 2 ? 'font-medium' : 'font-normal'}`}
                        key={idx}
                    >
                      {column[idx] &&
                      Object.keys(column[idx]).length &&
                      column[idx].elm
                          ? column[idx]?.elm(data)
                          : data[v]}
                      {color[data[v]] ? (
                          <div
                              style={{ background: color[data[v]] }}
                              className="w-[5px] h-full absolute left-0 top-0 "
                          ></div>
                      ) : (
                          ''
                      )}
                    </div>
                );
            })}
          </div>
      );
    });
  };
  const responsiveLayout = () => (
      <div className="">
        {props.bodyCells.map((data, idx) => (
            <div
                key={idx}
                className={`relative pt-[13px] pl-[18px] pr-6 pb-[16px] -mx-6 border-white ${
                    idx == 0 ? 'border-y' : 'border-b'
                }`}
            >
              <div className="flex justify-between">
                <p className="font-[500] text-xs tracking-[0.01em] leading-[20px]">
                  {data.date}
                </p>
                <p className="font-[400] text-xs tracking-[0.01em] leading-[20px]">
                  {data.instructor}
                </p>
              </div>
              <div className="font-[500] text-xs tracking-[0.01em] leading-[20px]">
                {data.time}
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="font-[400] tracking-[0.01em] leading-[20px]">
                    {data.class}
                  </p>
                  <p className="font-[400] text-xs tracking-[0.01em] leading-[20px]">
                    {data.brand} {data.place}
                  </p>
                </div>
                <div>
                  {props &&
                  props.headerCells &&
                  props.headerCells[props.headerCells.length - 1] &&
                  props.headerCells[props.headerCells.length - 1].elm
                      ? props?.headerCells[props.headerCells.length - 1]?.elm(data)
                      : ''}
                </div>
              </div>
              <div
                  style={{ background: color[data.brand] }}
                  className="w-[5px] h-full absolute left-0 top-0 "
              ></div>
            </div>
        ))}
      </div>
  );
  return (
      <>
        {props && props.headerCells && props.bodyCells && (
            <>
              {/* if(responsive true then) */}
              <div
                  className={`flex-col w-full md:mx-0  ${
                      props.responsiveChange ? 'flex xl:hidden' : ''
                  }`}
              >
                <div className='flex'>{ThData()}</div>
                {tdData()}
              </div>
              {/* else */}
              {props.responsiveChange && (
                  <div
                      className={`text-white text-lg ${
                          props.responsiveChange ? 'hidden xl:block ' : 'hidden'
                      }`}
                  >
                    {responsiveLayout()}
                  </div>
              )}
            </>
        )}
      </>
  );
}
export default TableBase;
