import React, { useState, useEffect,useContext  } from "react";
import coach1 from "../assets/images/login/coach.png";
import coach2 from "../assets/images/login/coach2.png";
import ButtonActive from "../common/buttons/Active";
import InputBase from "../common/inputs/InputBase";
import { Link } from "gatsby";
import logo from "../assets/images/logo.png";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import { navigate } from "gatsby";
import { Amplify, Auth } from "aws-amplify";
import Password from "./DashBoard/account/Password";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { toastError } from "../common/toasts/toast";
import { AuthContext } from "../apis/auth";

function Login(props) {
  const [status, setStatus] = useState("login");
  // const status = "login";

  const [account, setAccount] = useState({ email: "", password: "" });
  const [isChecked, setChecked] = useState(false);
  const reInit = useContext(AuthContext).Init;

  const handleChangeValue = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: Event) => {
    try {
      setLoading(true);
      if (account.password && account.email) {
        const data = await Auth.signIn(account.email, account.password);
        await reInit();
        window.localStorage.setItem("userInfo", "Deval");
        navigate(`/dashboard`);
      } else {
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = useState(false);

  return (
    <div className="text-white w-full overflow-hidden">
      {isLoading && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className=" animate-spin h-12 w-12  border-r-8 border-8 border-gray border-r-white rounded-full m-auto "></div>
        </div>
      )}
      <div className="flex md:justify-center justify-start md:mt-[31px] mt-[28px] md:ml-0 ml-[25px]">
        <img src={logo} alt="logo" className="md:w-auto w-[150px]" />
      </div>
      {/* Coach */}
      <div>
        <div className="absolute md:block hidden top[130px] mt-[49px] left-0">
          <img className="w-[227px] h-[342px]" src={coach1} />
        </div>
        <div className="absolute bottom-[84px] md:block hidden right-0">
          <img className="w-[211px] h-[314px]" src={coach2} />
        </div>
      </div>
      {/* Form login */}
      {status === "login" && (
        <div className="md:w-[615px] w-[calc(100%-50px)]  md:h-[445px]  h-[478px] bg-[#292929] py-[50px] px-[40px] absolute top-[50%]  left-[50%] transform translate-x-[-50%] border border-brand-clime rounded-[10px] translate-y-[-50%] mx-auto">
          <h1 className="text-[54px] md:block hidden  mb-[32px] uppercase text-brand-clime leading-[90%] bebas font-normal text-center ">
            your login
          </h1>
          <h1 className="text-[45px] md:hidden block  mb-[32px] uppercase text-white  leading-[90%] bebas font-normal text-center ">
            member log in
          </h1>
          <div className="grid grid-rows-2 grid-flow-col gap-y-[18px]">
            <InputBase
              onchange={handleChangeValue}
              nameInput="email"
              holder="Email addres*"
              style="md:w-full"
              isRequired={true}
            />
            <InputBase
              onchange={handleChangeValue}
              nameInput="password"
              holder="Password*"
              type="password"
              style="md:w-full"
              isRequired={true}
            />
          </div>
          <p
            onClick={() => {
              navigate("/forgot-password");
            }}
            className="text-gray graphik-regular hover:text-white cursor-pointer text-[12px] font-normal  mt-2 float-right mb-[24px]"
          >
            Forgot your email/password?
          </p>
          <div className="flex items-center justify-between w-full md:mt-[85px] mt-5">
            <div className="flex items-center">
              <div
                className={`w-[30px]  rounded-[5px] h-[30px] border  flex justify-center items-center ${
                  isChecked ? "border-brand-clime" : "border-white"
                }`}
                onClick={() => setChecked(!isChecked)}
              >
                {isChecked && (
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.69231L5.2 9L13 1"
                      stroke={"#82FA00"}
                      strokeWidth="1.02"
                    />
                  </svg>
                )}
              </div>
              <p className="text-white ml-[15px] font-abcmonument text-[14px] #FFFFFF91 font-normal">
                Remember me
              </p>
            </div>
            <div className="md:block hidden">
              <ButtonNavigate
                onClick={handleSubmit}
                text="LOG IN"
                isArrowRight={true}
              ></ButtonNavigate>
            </div>
            <div className="md:hidden block absolute bottom-[29px] right-[22px]">
              <ButtonNavigate
                onClick={handleSubmit}
                text="LOG IN"
                style="h-[40px] w-[97px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-orange border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                isArrowRight={true}
              ></ButtonNavigate>
            </div>
          </div>
        </div>
      )}
      {/* Form forgot password */}
      {/* Close */}
      <Link to="/">
        <div className="absolute cursor-pointer md:right-[21px] right-[18px] md:top-[33px] top-[18px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="49"
            viewBox="0 0 48 49"
            fill="none"
          >
            <path
              d="M12.4321 36.4923C5.99926 30.0594 5.9701 19.6673 12.3565 13.2809C18.7429 6.89449 29.135 6.92366 35.5679 13.3565C42.0007 19.7894 42.0299 30.1815 35.6435 36.5679C29.2571 42.9543 18.865 42.9251 12.4321 36.4923Z"
              stroke="#82FA00"
              strokeWidth="1.11532"
            />
            <path
              d="M30.0186 30.3115L18.9311 19.2241"
              stroke="#82FA00"
              strokeWidth="1.11532"
            />
            <path
              d="M18.8047 30.4343L29.8225 19.4165"
              stroke="#82FA00"
              strokeWidth="1.11532"
            />
          </svg>
        </div>
      </Link>
      {/* Privacy */}
      <div>
        <h6 className="font-normal graphik-regular md:block hidden text-[14px] text-[#ABABAB] absolute left-[45px] bottom-[30px]">
          Part of Viva Leisure Group
        </h6>
        <div className="font-bold text-[29px] absolute bottom-[24px] flex justify-center items-center left-[50%] transform w-full translate-x-[-50%]">
          <h5 className="text-white font-abcmonument uppercase font-medium md:text-[16px] text-sm ">
            not a member?
          </h5>
          <h5 className="text-[#82FA00] ml-2 md:text-[16px] text-sm font-medium font-abcmonument uppercase">
            <Link to="/register">join now</Link>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default Login;
