import React from "react";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import footer1 from "../../assets/images/ClFooter/footer1.png";
import footer2 from "../../assets/images/ClFooter/footer2.png";
import footer3 from "../../assets/images/ClFooter/footer3.png";
import footer4 from "../../assets/images/ClFooter/footer4.png";
import { navigate, Script } from "gatsby";
function NewsLetterSignUp() {
  // const { loading, data } = useFetch({
  //   params: "home-page",
  //   populate: "tagUs",
  // });
  const { data: footerSocials, loading } = useFetch({
    params: "footer",
    populate: "socials, socials.images",
  });
  // const tagUs = data?.data?.attributes?.tagUs?.data;
  // const [active, setActive] = useState(false);
  // const activeValue = (e) => {
  //   const elm = document.getElementById("email");
  //   return e.target.value === ""
  //     ? ((elm.style.borderColor = ""), (elm.style.color = ""), setActive(false))
  //     : ((elm.style.borderColor = "#FF7900"),
  //       (elm.style.color = "#FF7900"),
  //       setActive(true));
  // };
  return (
    <div className=" w-full border-y border-1 border-brand-clime py-[45px]">
      {loading && <LoadingScreen />}
      {!loading && footerSocials != null && (
        <div className=" text-white gap-8 flex xl:flex-row flex-col overflow-hidden">
          <div className=" flex relative   xl:w-[65%] w-full">
            <Script src="https://apps.elfsight.com/p/platform.js" defer></Script>
            <div className="elfsight-app-4bc1d5b9-d4d6-4250-b668-969b165e4a15"></div>
          </div>
          <div className="flex  mx-[26px]  col-span-3 xl:col-span-1">
          <div className="flex md:flex-col flex-row w-full md:justify-start justify-between">
              <p className="text-brand-clime text-xs lg:text-base gotham font-medium md:w-full w-1/2">
                FOLLOW US
              </p>
              <div className="flex md:justify-start justify-between md:mt-[27px] mt-0 md:justify-items-start md:gap-[31px] items-center md:w-full w-1/2">
                {footerSocials?.data?.attributes?.socials?.map((item, idx) => (
                  <a className="" href={item.url} target="_blank">
                    <img
                      key={idx}
                      className="hover:text-brand-clime"
                      alt={item.title}
                      title={item.title}
                      // src={item?.images?.data[?.attributes?.url}
                      src={item?.images.data[0].attributes.url}
                      onMouseEnter={(e) => {
                        e.target.src = item?.images.data[1].attributes.url;
                      }}
                      onMouseLeave={(e) => {
                        e.target.src = item?.images.data[0].attributes.url;
                      }}
                    />
                  </a>
                ))}
                {/* <a>{facebookIcon()}</a>
                <a>{youtubeIcon()}</a>
                <a>{tiktokIcon()}</a>
                <a>{instagramIcon()}</a> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsLetterSignUp;
