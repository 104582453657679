import React from "react";
import { HeadFC } from "gatsby";
import Layout from "../components/layout/Layout";
import MoreThanHiit from "../components/about/MoreThanHiit";
import AboutSlider from "../components/about/AboutSlider";
import WhatWeOffer from "../components/about/WhatWeOffer";
import MemberStories from "../components/about/MemberStories";
import MembersAppBanner from "../components/about/MembersAppBanner";
import useFetch from "../hooks/useFetch";
import LoadingScreen from "../common/loading/LoadingScreen";
import MoveClasses from "../components/about/MoveClasses";

const About = () => {
  const { data, loading } = useFetch({
    params: "about-page",
    populate:
      "topBanner,topBanner.image,videosCarousels,midBanner,midBanner.images, midBanner.background,belowAccessImage,endBanner, nearlyEndedBanner, nearlyEndedBanner.image1, nearlyEndedBanner.image2, nearlyEndedBanner.image3, nearlyEndedBanner.image4",
  });
  return (
    <Layout>
      {loading && <LoadingScreen />}
      {!loading && data && (
        <>
          <AboutSlider />
          <MoreThanHiit {...data} />

          <WhatWeOffer />
          <MoveClasses />

          <MemberStories data={data} />
          <MembersAppBanner />
        </>
      )}
    </Layout>
  );
};

export default About;

export const Head: HeadFC = () => <title>About</title>;
