import React, { useState, useEffect } from "react";
import coach1 from "../assets/images/login/coach.png";
import coach2 from "../assets/images/login/coach2.png";
import ButtonActive from "../common/buttons/Active";
import InputBase from "../common/inputs/InputBase";
import { Link } from "gatsby";
import logo from "../assets/images/logo.png";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import { navigate } from "gatsby";
import { Amplify, Auth } from "aws-amplify";
import Password from "./DashBoard/account/Password";

function ForgotPassword() {
  const [status, setStatus] = useState("forgot");
  // const status = "login";

  useEffect(() => {
    try {
      // Amplify.configure({
      //   aws_project_region: `${process.env.AWS_REGION}`,
      //   aws_appsync_graphqlEndpoint: `${process.env.AWS_ENDPOINT}`,
      //   aws_appsync_region: `${process.env.AWS_REGION}`,
      //   aws_appsync_authenticationType: `${process.env.AWS_AUTH_TYPE}`,
      //   aws_user_pools_id: `${process.env.AWS_USER_POOLS_ID}`,
      //   aws_user_pools_web_client_id: `${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}`,
      // });

      // const userInfo = JSON.parse(localStorage.getItem("contact"));
      // setCurrentUser(userInfo);
    } catch (e) {}
  }, []);
  const handleForgotPassword = () => {
    setLoading(true);

    Auth.forgotPassword(forgotForm.email)
      .then(() =>
        alert("Your password reset code have been sent to your email.")
      )
      .catch((err) => alert(err.message));
    setLoading(false);
  };
  const [forgotForm, setForgotForm] = useState({
    email: "",
  });
  const [resetForm, setResetForm] = useState({
    email: "",
    code: "",
    password: "",
    retypePassword: "",
  });
  const handleChangeForgot = (e) => {
    setForgotForm({ ...forgotForm, [e.target.name]: e.target.value });
  };
  const handleChangeReset = (e) => {
    setResetForm({ ...resetForm, [e.target.name]: e.target.value });
  };
  const handleResetPassword = () => {
    setLoading(true);
    if (resetForm.password === resetForm.retypePassword)
      Auth.forgotPasswordSubmit(
        resetForm.email,
        resetForm.code,
        resetForm.retypePassword
      )
        .then(() => alert("password reset successful"))
        .catch((err) => alert(err.message));
    else alert("password and retype password does not match");
    setLoading(false);
  };
  const [isLoading, setLoading] = useState(false);

  return (
    <div className="text-white w-full overflow-hidden">
      {isLoading && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className=" animate-spin h-12 w-12  border-r-8 border-8 border-gray border-r-white rounded-full m-auto "></div>
        </div>
      )}
      <div className="flex md:justify-center justify-start md:mt-[31px] mt-[28px] md:ml-0 ml-[25px]">
        <img src={logo} alt="logo" className="md:w-auto w-[150px]" />
      </div>
      {/* Coach */}
      <div>
        <div className="absolute md:block hidden top[130px] mt-[49px] left-0">
          <img className="w-[227px] h-[342px]" src={coach1} />
        </div>
        <div className="absolute bottom-[84px] md:block hidden right-0">
          <img className="w-[211px] h-[314px]" src={coach2} />
        </div>
      </div>
      {/* Form forgot password */}
      {status === "forgot" && (
        <div className="md:w-[615px] w-[calc(100%-50px)]  md:h-[445px]  h-[478px] bg-[#292929] py-[50px] px-[40px] absolute top-[50%]  left-[50%] transform translate-x-[-50%] border border-brand-clime rounded-[10px] translate-y-[-50%] mx-auto">
          <h1 className="text-[50px] text-white font-bold text-center font-abcmonument">
            Password Forgot
          </h1>
          <p className="mt-[31px] text-[14px] font-normal text-center mb-[18px] Bossa">
            To reset your password, you'll need to provide your email. We’ll
            send an email to the address you provided.
          </p>
          <div className=" mt-[18px] gap-y-[18px]">
            <InputBase
              onchange={handleChangeForgot}
              nameInput="email"
              holder="Email address*"
              style="md:w-full"
              isRequired={true}
            />
          </div>
          <p
            onClick={() => {
              setStatus("reset");
            }}
            className="text-gray graphik-regular hover:text-white cursor-pointer text-[12px] font-normal  mt-2 float-right mb-[24px]"
          >
            Already received a code ?
          </p>
          <div className="flex w-full justify-end ">
            <ButtonNavigate
              text="RESET"
              onClick={() => handleForgotPassword()}
            />
          </div>
          <div className="font-bold text-[29px] absolute bottom-[24px] flex justify-center items-center left-[50%] transform w-full translate-x-[-50%]">
            <h5 className="text-white font-abcmonument uppercase font-medium md:text-[16px] text-sm ">
              Remember your password ?
            </h5>
            <h5
              className="text-[#82FA00] ml-2 md:text-[16px] text-sm font-medium font-abcmonument uppercase "
              onClick={() => navigate("/login")}
            >
              Login
            </h5>
          </div>
        </div>
      )}
      {status === "reset" && (
        <div className="md:w-[615px] w-[calc(100%-50px)]   bg-[#292929] py-[50px] px-[40px] absolute top-[50%]  left-[50%] transform translate-x-[-50%] border border-brand-clime rounded-[10px] translate-y-[-50%] mx-auto">
          <h1 className="text-[50px] text-white font-bold text-center font-abcmonument">
            Password Reset
          </h1>
          <p className="mt-[31px] text-[14px] font-normal text-center mb-[18px] Bossa">
            Please type in your code and new password.
          </p>
          <div className="grid grid-rows-4 grid-flow-col mt-[18px] gap-y-[18px]">
            <InputBase
              onchange={handleChangeReset}
              nameInput="email"
              holder="Email*"
              style="md:w-full"
              isRequired={true}
            />
            <InputBase
              onchange={handleChangeReset}
              nameInput="code"
              holder="Code*"
              style="md:w-full"
              isRequired={true}
            />
            <InputBase
              type="password"
              onchange={handleChangeReset}
              nameInput="password"
              holder="Password*"
              style="md:w-full"
              isRequired={true}
            />
            <InputBase
              type="password"
              onchange={handleChangeReset}
              nameInput="retypePassword"
              holder="Retype Password*"
              style="md:w-full"
              isRequired={true}
            />
          </div>
          <div className="flex w-full justify-end my-4">
            <ButtonNavigate
              text="RESET"
              onClick={() => handleResetPassword()}
            />
          </div>
          <div className="font-bold text-[29px] absolute bottom-[24px] flex justify-center items-center left-[50%] transform w-full translate-x-[-50%]">
            <h5 className="text-white font-abcmonument uppercase font-medium md:text-[16px] text-sm ">
              Remember your password ?
            </h5>
            <h5
              className="text-[#82FA00] ml-2 md:text-[16px] text-sm font-medium font-abcmonument uppercase "
              onClick={() => navigate("/login")}
            >
              Login
            </h5>
          </div>
        </div>
      )}
      {/* Close */}
      <Link to="/">
        <div className="absolute cursor-pointer md:right-[21px] right-[18px] md:top-[33px] top-[18px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="49"
            viewBox="0 0 48 49"
            fill="none"
          >
            <path
              d="M12.4321 36.4923C5.99926 30.0594 5.9701 19.6673 12.3565 13.2809C18.7429 6.89449 29.135 6.92366 35.5679 13.3565C42.0007 19.7894 42.0299 30.1815 35.6435 36.5679C29.2571 42.9543 18.865 42.9251 12.4321 36.4923Z"
              stroke="#82FA00"
              strokeWidth="1.11532"
            />
            <path
              d="M30.0186 30.3115L18.9311 19.2241"
              stroke="#82FA00"
              strokeWidth="1.11532"
            />
            <path
              d="M18.8047 30.4343L29.8225 19.4165"
              stroke="#82FA00"
              strokeWidth="1.11532"
            />
          </svg>
        </div>
      </Link>
      {/* Privacy */}
      <div>
        <h6 className="font-normal graphik-regular md:block hidden text-[14px] text-[#ABABAB] absolute left-[45px] bottom-[30px]">
          Part of Viva Leisure Group
        </h6>
        <div className="font-bold text-[29px] absolute bottom-[24px] flex justify-center items-center left-[50%] transform w-full translate-x-[-50%]">
          <h5 className="text-white font-abcmonument uppercase font-medium md:text-[16px] text-sm ">
            not a member?
          </h5>
          <h5 className="text-[#82FA00] ml-2 md:text-[16px] text-sm font-medium font-abcmonument uppercase">
            <Link to="/register">join now</Link>
          </h5>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
