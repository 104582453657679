import DashboardLayout from '../../components/LayoutDashboard';
import React from 'react';
import DashboardGeneral from '../../components/DashBoard/General';

function Dashboard() {
  return (
    <DashboardLayout>
      <DashboardGeneral />
    </DashboardLayout>
  );
}

export default Dashboard;
