
import React from 'react'

function ThreeDot() {
  return (
    <div className="bg-[rgba(0,0,0,0.4)] z-50 absolute h-full flex w-full">
      <div className="row examples m-auto ">
        <div className="  ">
          <div className=" relative " data-title="dot-pulse">
            <div className="stage w-[200px]">
              <div className="dot-pulse"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThreeDot


