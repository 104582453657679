import React, { useRef, useState } from "react";
import useFetch from "../../hooks/useFetch";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { createMarkup } from "../../common/functions/setInnerHtml";
import LifeStyle1 from "../../assets/images/homeClubLime/lifestyle1.png";
import triangleClime from "../../assets/images/icons/triangleClime.svg";

export default function TwoLine() {
  const { data, loading } = useFetch({
    params: "life-style-page",
    populate: "nearlyEndBanner,nearlyEndBanner.images",
  });
  const [play, setPlay] = useState(true);
  const playPause = () => {
    if (play) videoRef.current.play();
    else videoRef.current.pause();
  };
  const videoRef = useRef();
  return (
    <>
      {data != null && !loading && (
        <div className=" mx-[14px] lg:mx-[44px] my-[30px] md:my-[58px] md:flex-row flex-col flex">
          <div className="w-full md:w-1/2 py-[30px] md:py-[50px] md:pr-[110px]">
            <p
              className="Gravity uppercase text-[45px] lg:text-[65px] leading-[90%] text-white"
              dangerouslySetInnerHTML={createMarkup(
                data?.data?.attributes?.nearlyEndBanner?.title
              )}
            ></p>
            <p className="text-base Mono my-[50px] text-white">
              {data?.data?.attributes?.nearlyEndBanner?.description ??
                ` Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit​. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim veniam,.`}
            </p>
          </div>

          <div className="w-full md:w-1/2">
            <div className="text-brand-clime relative w-full h-full">
              {data?.data?.attributes?.nearlyEndBanner?.images.data[0]?.attributes?.url.slice(
                -3
              ) == "mp4" ? (
                <video
                  ref={videoRef}
                  className="h-full w-full object-cover"
                  onClick={() => {
                    setPlay(!play);
                    playPause();
                  }}
                >
                  <source
                    src={
                      data?.data?.attributes?.nearlyEndBanner?.images.data[0]
                        ?.attributes?.url
                    }
                    type="video/mp4"
                  ></source>
                </video>
              ) : (
                <img src={data?.data?.attributes?.nearlyEndBanner?.images.data[0]
                  ?.attributes?.url} alt="logo" className="w-full h-full object-cover" />
              )}

              {data?.data?.attributes?.nearlyEndBanner?.images.data[0]?.attributes?.url.slice(
                -3
              ) == "mp4" &&
                play && (
                  <img
                    onClick={() => {
                      setPlay(!play);
                      playPause();
                    }}
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                    src={triangleClime}
                  />
                )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
