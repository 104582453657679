import React, { useContext, useEffect, useState } from "react";
import { RegisterContext } from "../../context/RegisterContext";
import { Script } from "gatsby";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";

const ChangeLocation = () => {
  const open = useContext(RegisterContext).open;
  const setOpen = useContext(RegisterContext).setOpen;
  const [address, setAddress] = useState("");
  const [clubNearby, setClubNearby] = useState<[]>([]);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [quickSeach, setQuickSearch] = useState<boolean>(false);
  const yourClub = useContext(RegisterContext).yourClub;
  const setYourClub = useContext(RegisterContext).setYourClub;

  const [currentState, setCurrentState] = useState(false);
  const setIsChangeClub = useContext(RegisterContext).setIsChangeClub;
  const handleSelect = async (value: string) => {
    if (value != "") {
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress(value);
      setCoordinates(latLng);
    }
  };
  // Close the ScrollBar
  useEffect(() => {
    open
      ? document.body.classList.add("hide-scroll")
      : document.body.classList.remove("hide-scroll");
  }, [open]);

  // Tracking Type Of Searching
  useEffect(() => {
    address.length > 0 ? setQuickSearch(false) : setQuickSearch(true);
  }, [address]);

  // Search Studio
  useEffect(() => {
    if (coordinates.lat !== null || quickSeach) {
      let searchByState = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${currentState?.lng}&location[]=${currentState?.lng}&distance=12089566&populate=image,access,address.state&pagination`;
      let searchByName = `${process.env.STRAPI_API_URL}/api/studios/inRange?location[]=${coordinates.lng}&location[]=${coordinates.lat}&distance=12089566&populate=image,access,address.state&pagination`;
      axios.get(quickSeach ? searchByState : searchByName).then((res) => {
        setClubNearby(res?.data?.data);
      });
    }
  }, [coordinates, currentState]);

  return (
    <>
      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`}
        // onLoad={() => setLoaded(true)}
      />
      {open && (
        <>
          <div className="absolute right-0 top-0 h-full w-[0px]">
            <div className=" z-[999999] top-[0px] mt-[0px]   sticky  cursor-pointer h-[100vh] w-[361px] transform  translate-x-[-100%] bg-[#1c1c1c]">
              <span
                className="text-gray absolute top-0 left-0 pr-3 underline w-[360px] flex justify-end  border-b border-b-brand-clime h-[40px] leading-[215%] gotham text-sm font-normal cursor-pointer"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                Done
              </span>
              <div className="flex items-center justify-center py-[53px]">
                <p className="text-white text-[65px] bebas mt-[50px] h-[108px] w-[288px] uppercase leading-[90%] font-normal">
                  select your location
                </p>
              </div>
              <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div className="flex flex-col  w-full">
                    <div>
                      <div className="flex border-b border-b-white w-[284px] mx-auto items-center relative justify-between">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 25 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          {" "}
                          <line
                            x1="15.8912"
                            y1="14.8002"
                            x2="24.1415"
                            y2="22.2113"
                            stroke={"white"}
                            strokeWidth="1.57426"
                          />
                          <circle
                            cx="10.0022"
                            cy="10.3639"
                            r="7.15275"
                            transform="rotate(-72.0294 10.0022 10.3639)"
                            stroke={"white"}
                            strokeWidth="1.57426"
                          />
                        </svg>
                        <input
                          className="bg-transparent  py-[15px]  ml-[15px] graphik-regular font-normal w-full text-sm leading-[160%] text-white outline-none"
                          {...getInputProps({ placeholder: "" })}
                        />
                        <div
                          onClick={() => setIsChangeClub(false)}
                          className="cursor-pointer md:block hidden "
                        >
                          <svg
                            onClick={() => {
                              setAddress("");
                              setClubNearby([]);
                            }}
                            width="41"
                            height="41"
                            viewBox="0 0 41 41"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.7275 15.1323L15.3977 24.4622"
                              stroke="#ddd"
                              strokeWidth="1.32496"
                            />
                            <path
                              d="M24.8311 24.5654L15.5598 15.2942"
                              stroke="#ddd"
                              strokeWidth="1.32496"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-[calc(100vh-561px)] overflow-y-auto"
                      id="scrollVert"
                    >
                      {suggestions.map((suggestion) => {
                        return (
                          <div
                            className={`${
                              suggestion.active
                                ? "bg-brand-clime text-black"
                                : ""
                            } border border-brand-clime min-h-[46px] text-white py-[12px] px-[15px] text-sm font-normal leading-[160%] graphik-regular `}
                            {...getSuggestionItemProps(suggestion)}
                          >
                            {suggestion.description}
                          </div>
                        );
                      })}
                      {clubNearby.map((i) => {
                        return (
                          <div
                            onClick={() => {
                              setYourClub(i);
                              localStorage.setItem(
                                "yourClub",
                                JSON.stringify(i)
                              );
                              setOpen(!open);
                              setClubNearby([]);
                              setAddress("");
                            }}
                            className="flex gap-4 mb-2 w-[290px] mx-auto mt-4"
                          >
                            {i?.attributes?.image?.url.slice(-3) == "mp4" ? (
                              <video
                                autoPlay
                                muted
                                src={i?.attributes?.image?.url}
                                className="min-w-[80px] max-w-[80px] h-[80px] object-cover border border-brand-clime"
                              />
                            ) : (
                              <img
                                src={i?.attributes?.image?.url}
                                className="min-w-[80px] max-w-[80px] h-[80px] object-cover border border-brand-clime"
                              />
                            )}
                            <div className="py-2 flex flex-col justify-between">
                              <p className="text-brand-clime gotham uppercase  ">
                                {i?.attributes?.title}
                              </p>
                              <p className="text-white gotham  text-sm">
                                {i?.attributes?.fullAddress}
                              </p>
                              {/* <p className="text-white graphik-regular my-1">{i?.attributes?.email}</p> */}
                              <p className="text-white gotham text-sm ">
                                ${i?.attributes?.price}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <div className="h-[244px] border-t-[1px] absolute bottom-0 w-full border-t-brand-clime px-[43px] py-[41px]">
                <div className="h-full w-full">
                  <p className="mb-[23px] text-white gotham tracking-widest font-medium leading-[140%] text-[12px] uppercase">
                    quick select your state
                  </p>
                  <div className=" grid grid-cols-2 gap-[23px]">
                    <button
                      onClick={() =>
                        setCurrentState({ lat: -31.253218, lng: 146.921099 })
                      }
                      className="w-full focus:border-white focus:text-white h-[49px] focus:border-[2px] border border-white hover:border-brand-clime text-white hover:text-brand-clime rounded-[5px]"
                    >
                      NSW
                    </button>
                    <button
                      onClick={() =>
                        setCurrentState({ lat: -22.575197, lng: 144.084793 })
                      }
                      className="w-full h-[49px] focus:border-white focus:border-[2px] focus:text-white border border-white hover:border-brand-clime text-white hover:text-brand-clime rounded-[5px]"
                    >
                      QLD
                    </button>
                    <button
                      onClick={() =>
                        setCurrentState({ lat: -35.27075, lng: 149.134637 })
                      }
                      className="w-full h-[49px] focus:border-white focus:text-white border border-white  focus:border-[2px] hover:border-brand-clime text-white hover:text-brand-clime rounded-[5px]"
                    >
                      ACT
                    </button>
                    <button
                      onClick={() =>
                        setCurrentState({ lat: -36.984781, lng: 143.390607 })
                      }
                      className="w-full h-[49px] focus:border-white focus:text-white border border-white  focus:border-[2px] hover:border-brand-clime text-white hover:text-brand-clime rounded-[5px]"
                    >
                      VIC
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default ChangeLocation;
