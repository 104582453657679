import React, { useState, useRef } from "react";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import InputBase from "../common/inputs/InputBase";
import apply from "../assets/images/careers/apply.png";
import Layout from "../components/layout/Layout";
import SelectOutline from "../common/dropdowns/SelectOutline";
import useFetch from "../hooks/useFetch";

function index() {
  const ref = useRef(null);
  const [resumes, setResumes] = useState<File[]>([]);
  const { data } = useFetch({
    params: "pt-career-page",
    populate:
      "topCarousels.background,threeColsBanner.background,bestThings,bottomCarousels.images",
  });

  const hanldeUpResumes = async (e: any) => {
    const files = [...e.target.files];
    const newResume: any[] = [];
    files.forEach((file) => {
      return newResume.push(file);
    });
    setResumes([...resumes, ...newResume]);
  };

  const handleDeleteResume = (index: number) => {
    const newArr = [...resumes];
    newArr.splice(index, 1);
    setResumes(newArr);
  };

  function createMarkup(data) {
    return { __html: data };
  }
  return (
    <Layout>
      {/* Banner */}
      <div className="w-full md:h-[692px] h-[581px] relative">
        <img
          src={
            data?.data?.attributes?.topCarousels?.background?.data?.attributes
              ?.url
          }
          className="w-full h-full object-cover"
          alt="hiit intro"
        />
        <div className="w-full absolute top-[50%] transform  translate-y-[-50%]">
          <div
            className="font-bold md:w-full w-[392px] text-[18px] leading-[100%] md:text-center text-white -tracking-[0.03em]"
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.topCarousels?.title
            )}
          ></div>
          <div
            className="font-bold text-[62px] leading-[100%] md:text-center text-white -tracking-[0.03em]"
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.topCarousels?.description
            )}
          ></div>
          <div className="mt-[36px] w-full flex justify-center">
            <div className="flex group">
              <button
                onClick={() =>
                  ref?.current.scrollIntoView({ behavior: "smooth" })
                }
                className={`border-white border-b text-white font-abcmonument text-medium px-[33px]  py-[12px] font-medium flex focus:border-b-[5px  border-t border-l border-r-0 rounded-l  rounded-bl-[5px] rounded-tl-[5px] group-hover:border-brand-clime group-hover:text-brand-clime`}
              >
                APPLY NOW
              </button>{" "}
              <div className="px-3 py-[12px] border-l border-[1px] rounded-br-[5px] rounded-tr-[5px]  flex items-center border-white text-white group-hover:border-brand-clime group-hover:text-brand-clime ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                >
                  <path
                    d="M13 12L7 18L1 12"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path d="M7 17L7 0" stroke="currentColor" strokeWidth="1.5" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Intro */}
      <div className="grid md:grid-cols-3 lg:grid-cols-4 grid-cols-1 border-y border-y-brand-clime">
        <div
          className="max-w-[680px] lg:max-w-[340px] md:col-span-3 lg:col-span-1"
          dangerouslySetInnerHTML={createMarkup(
            data?.data?.attributes?.bestThings?.[0]?.title
          )}
        ></div>
        <div className="md:py-[39px] py-[30px] md:gap-0 gap-[60px] md:pl-[25px] pl-[14px] lg:border-y-0 border-y border-y-brand-clime box-info md:pr-[35px] pr-[14px] lg:border-x md:border-l-0 md:border-r border-x-0 border-x-brand-clime">
          <h5 className="stepEffect">01</h5>
          <div>
            <h6 className="md:mt-[82px] mt-0 font-abcmonument text-white font-medium leading-[140%] tracking-widest">
              {data?.data?.attributes?.bestThings?.[1]?.title}
            </h6>
            <p className="font-abcmonument mt-4 font-normal text-sm leading-[160%] text-white">
              {data?.data?.attributes?.bestThings?.[1]?.description}
            </p>
          </div>
        </div>
        <div className="md:py-[39px] py-[30px] pl-[25px] pr-[35px] md:gap-0 gap-[60px] box-info lg:border-y-0 border-y border-y-brand-clime md:border-r border-r-0 border-brand-clime">
          <h5 className="stepEffect">02</h5>
          <div>
            <h6 className="md:mt-[82px] mt-0 font-abcmonument text-white font-medium leading-[140%] tracking-widest">
              {data?.data?.attributes?.bestThings?.[2]?.title}
            </h6>
            <p className="font-abcmonument mt-4 font-normal text-sm leading-[160%] text-white">
              {data?.data?.attributes?.bestThings?.[2]?.description}
            </p>
          </div>
        </div>
        <div className="md:py-[39px] py-[30px] pl-[25px] pr-[35px] lg:border-y-0 border-y md:gap-0 gap-[60px] border-y-brand-clime box-info">
          <h5 className="stepEffect">03</h5>
          <div>
            <h6 className="md:mt-[82px] mt-0 font-abcmonument text-white font-medium leading-[140%] tracking-widest">
              {data?.data?.attributes?.bestThings?.[3]?.title}
            </h6>
            <p className="font-abcmonument mt-4 font-normal text-sm leading-[160%] text-white">
              {data?.data?.attributes?.bestThings?.[3]?.description}
            </p>
          </div>
        </div>
      </div>
      {/* Form Apply */}
      <div
        ref={ref}
        className="flex bg-[#292929] border-y border-brand-clime lg:flex-row flex-col"
      >
        <div className="text-white relative md:h-[519px] h-[432px] lg:w-[485px] w-full font-normal md:text-[65px] text-[45px] leading-[90%] uppercase border-r border-brand-clime ">
          <p className="absolute bebas md:left-[40px] left-[14px] md:bottom-[61px] lg:max-w-[320px] md:max-w-[518px] max-w-[221px] bottom-[21px] bebas">
            Ready to make a change? <br />
            <span className="bebas font-normal md:mt-0 mt-1 uppercase text-brand-clime">
              Apply now
            </span>
          </p>
          <img src={apply} className="w-full h-full object-cover" />
        </div>
        <div className="md:py-[61px] py-[24px] md:px-[70px] px-[15px] w-full lg:w-2/3">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-y-5 gap-x-[21px] ">
            <InputBase
              holder="First name*"
              style="w-full"
              isRequired={true}
            ></InputBase>
            <InputBase
              holder="Last name*"
              style="w-full"
              isRequired={true}
            ></InputBase>
            <InputBase
              holder="Email address*"
              style="w-full"
              isRequired={true}
            ></InputBase>
            <InputBase
              holder="Phone number*"
              style="w-full"
              isRequired={true}
            ></InputBase>
            <SelectOutline
              titleStyle="text-[12px]"
              style=" h-[54px] w-full graphik-regular text-[14px]"
              placeholder="Club of interest?*"
              placeholderColor="text-slate-400"
              data={["Braddon"]}
            />
            <SelectOutline
              titleStyle="text-[12px]"
              style=" h-[54px] w-full graphik-regular text-[14px]"
              placeholder="How did you hear about us?*"
              placeholderColor="text-slate-400"
              data={["xxx", "xxx", "xxx"]}
            />
          </div>{" "}
          <div className="grid md:grid-cols-2 grid-cols-1 md:justify-items-end justify-start items-center ">
            <div>
              <p className="mt-[40px] max-w-[452px] text-gray font-abcmonument leading-[160%] md:block hidden font-normal text-sm">
                Please note: You only need to submit your application once. Once
                submitted, qualified candidates will be contacted.
              </p>
            </div>
            <div className="md:my-0 my-[40px] ">
              <ButtonNavigate
                text="APPLY NOW"
                style="h-[45px] w-[127px]flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                isArrowRight={true}
              ></ButtonNavigate>
              <p className="mt-[40px] max-w-[452px] md:hidden block text-gray font-abcmonument leading-[160%] font-normal text-sm">
                Please note: You only need to submit your application once. Once
                submitted, qualified candidates will be contacted.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Earning */}
      <div className="md:mt-[110px] mt-[40px] md:mb-0 mb-6 md:px-11 px-[14px] xl:w-[1357px] w-full mx-auto grid md:grid-cols-3 grid-cols-1 md:gap-5 lg:gap-[75px] gap-0">
        <div>
          <div
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.threeColsBanner?.[0]?.title
            )}
          ></div>
          <img
            src={
              data?.data?.attributes?.threeColsBanner?.[0]?.background?.data
                ?.attributes?.url
            }
            className="md:w-[400px] w-full md:h-[346px] h-[300px] object-cover  md:my-[40px] my-[20px]"
          />
          <p className="md:max-w-[380px] w-full font-abcmonument text-white font-normal text-sm">
            {data?.data?.attributes?.threeColsBanner?.[0]?.description}
          </p>
        </div>
        <div className="md:mt-0 mt-5">
          <img
            src={
              data?.data?.attributes?.threeColsBanner?.[1]?.background?.data
                ?.attributes?.url
            }
            className="md:w-[401px] w-full object-cover md:h-[379px] md:mb-0 mb-[23px] h-[282px]"
          />
          <div className="md:my-[40px] my-0">
            <div
              dangerouslySetInnerHTML={createMarkup(
                data?.data?.attributes?.threeColsBanner?.[1]?.title
              )}
            ></div>
            <p className="max-w-[380px] font-abcmonument text-white font-normal md:mt-[40px] mt-[20px] text-sm">
              {data?.data?.attributes?.threeColsBanner?.[1]?.description}
            </p>
          </div>
        </div>
        <div className="md:mt-[152px] mt-[52px]">
          <div
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.threeColsBanner?.[2]?.title
            )}
          ></div>
          <img
            src={
              data?.data?.attributes?.threeColsBanner?.[2]?.background?.data
                ?.attributes?.url
            }
            className="md:w-[400px] w-full md:h-[297px] h-[269px] md:my-[40px] my-[25px]"
          />
          <p className="max-w-[380px] font-abcmonument text-white font-normal text-sm">
            {data?.data?.attributes?.threeColsBanner?.[2]?.description}
          </p>
        </div>
      </div>
      {/* About us */}
      <div className="md:flex overflow-x-auto hidden md:mx-11 px-[14px] xl:w-[1357px] mx-auto my-[70px] h-[400px] lg:h-[500px] items-center">
        <img
          src={
            data?.data?.attributes?.bottomCarousels?.images?.data?.[0]
              ?.attributes?.url
          }
          className="w-[845px] h-full object-cover"
        />
        <img
          src={
            data?.data?.attributes?.bottomCarousels?.images?.data?.[1]
              ?.attributes?.url
          }
          className="flex-1 h-full object-cover"
        />
      </div>
    </Layout>
  );
}

export default index;
