import React from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
interface props {
  data: any;
}
export default function ClubFeatures({ data }: props) {
  const { data: singleStudioTitle } = useFetch({
    params: "single-studio",
    populate: "",
  });

  return (
    <div className="text-brand-clime md:pl-[70px] grid grid-cols-2 md:grid-cols-4 grid-flow-row mx-[14px] md:mx-0 mt-[30px]">
      <div className="flex justify-between flex-col">
        <div className="uppercase text-[98px] Gravity leading-[89px] text-effect-clime text-transparent">
          {singleStudioTitle?.features_title ?? "club features"}
        </div>
        <ButtonNavigate text="TAKE A VIRTUAL TOUR" />
      </div>
      <div></div>
      <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row col-span-2  gap-x-[42px] md:-ml-[160px] ml-0">
        <div className="pb-[20px] col-span-1 md:col-span-2 truncate gotham leading-[9px] pt-[19px] text-brand-clime flex items-center">
          {singleStudioTitle?.facility_title ?? "FACILITIES"}
        </div>
        {data?.access?.data?.map((i) => (
          <div className="pb-[20px] truncate gotham leading-[9px] text-white border-t border-b border-[rgb(255,255,255,0.4)] pt-[19px] uppercase flex items-center">
            {i.attributes.title}
          </div>
        ))}
      </div>
    </div>
  );
}
