import React, { useContext } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import InputBase from "../../common/inputs/InputBase";
import { RegisterContext } from "../../context/RegisterContext";
// import { RegisterContext } from "../../context/RegisterContext";
import { navigate } from "gatsby";
import useFetch from "../../hooks/useFetch";
import { createMarkup } from "../../common/functions/setInnerHtml";

function GetYourFreePass() {
  // const setOpen = useContext(RegisterContext).setOpen;
  // const [form, setForm] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   phoneNumber: "",
  //   message: "",
  // });
  // const [formControlled, setFormControlled] = useState({
  //   firstName: false,
  //   lastName: false,
  //   email: false,
  //   phoneNumber: false,
  // });
  // const [errText, setErrText] = useState({ email: "" });

  // const submit = () => {
  //   let control = { ...formControlled };
  //   Object.keys(form).map((e) => {
  //     if (form[e] == "" && e != "email") control = { ...control, [e]: true };
  //     else if (e === "email") {
  //       if (form[e] === "") {
  //         control = { ...control, [e]: true };
  //         setErrText({ ...errText, email: "" });
  //       } else if (
  //         !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
  //           form[e]
  //         )
  //       ) {
  //         setErrText({ ...errText, email: "invalid email address" });
  //         control = { ...control, [e]: true };
  //       } else control = { ...control, [e]: false };
  //     } else control = { ...control, [e]: false };
  //     setFormControlled(control);
  //   });
  // };
  const { data, loading } = useFetch({
    params: "global",
    populate: "freeVisit",
  });
  const setOpen = useContext(RegisterContext).setOpen;
  const yourClub = useContext(RegisterContext).yourClub;
  return (
    <div className="flex bg-[#292929] border-y border-brand-clime lg:flex-row flex-col">
      <div className=" lg:w-1/3 w-full py-0 px-[4.9%] pt-[4.9%] lg:py-[80px] lg:px-[43px]  flex justify-between flex-col border-r border-brand-clime ">
        <div>
          <p className="text-white Gravity text-[65px] leading-[90%] font-normal">
            <span className="text-brand-clime Gravity"></span>{" "}
            {data?.data?.attributes?.freeVisit?.title}
          </p>
          <p
            className="text-brand-clime font-abcmonument text-sm lg:text-lg my-[30px] lg:my-[11px]"
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.freeVisit?.description
            )}
          ></p>
        </div>
        <p className="text-[rgba(255,255,255,0.57)] font-normal text-sm underline gotham lg:block hidden ">
          *Terms and conditions apply
        </p>
      </div>
      <div className="lg:pr-[44px] lg:pl-[78px] px-[4.9%] pt-3 lg:pt-[74px] lg:pb-[70px] w-full lg:w-2/3">
        <div className="flex justify-between items-end lg:items-start">
          <p className="gotham text-sm lg:text-lg font-medium text-white">
            {data?.data?.attributes?.freeVisit?.content ||
              "REGISTER HERE FOR YOUR FREE VISIT AT"}{" "}
            <br className="lg:hidden block" />
            <span className="text-brand-clime gotham text-lg font-medium uppercase">
              {yourClub?.attributes?.title}, {"ACT"}
            </span>
          </p>
          <span
            className="text-[#A4A4A4] gotham underline text-base "
            onClick={() => setOpen(true)}
          >
            change
          </span>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-flow-row grid-cols-1 grid-flow-row gap-x-[21px] mt-[38px] gap-y-2 ">
          <InputBase
            holder="First name*"
            style="lg:w-[408px] w-full"
            isRequired={true}
          ></InputBase>
          <div className="flex xl:justify-end">
            <InputBase
              holder="Last name*"
              style="lg:w-[408px] w-full"
              isRequired={true}
            ></InputBase>
          </div>

          <InputBase
            holder="Email address*"
            style="lg:w-[408px] w-full"
            isRequired={true}
          ></InputBase>
          <div className="flex xl:justify-end">
            <InputBase
              holder="Phone number*"
              style="lg:w-[408px] w-full"
              isRequired={true}
            ></InputBase>
          </div>
        </div>{" "}
        <div className="flex  lg:justify-end items-start lg:items-center mt-0 lg:mt-[53px] flex-col-reverse lg:flex-row justify-start">
          <ButtonNavigate
            isArrowRight={true}
            text="REGISTER NOW"
            onClick={() => navigate("/register")}
          ></ButtonNavigate>
          <p className="text-gray text-sm gotham lg:m-0 lg:ml-[10px] lg:mb-0 mb-[11px] font-normal">
            Required*
          </p>
        </div>
        <p className="text-[rgba(255,255,255,0.57)] underline gotham lg:my-0 my-[30px]  lg:hidden block">
          *Terms and conditions apply
        </p>
      </div>
    </div>
  );
}

export default GetYourFreePass;
