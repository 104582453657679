import React, { useState } from "react";
import ButtonNavigate from "../common/buttons/ButtonNavigate";
import InputBase from "../common/inputs/InputBase";
import Layout from "../components/layout/Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SelectOutline from "../common/dropdowns/SelectOutline";
import useFetch from "../hooks/useFetch";
import VideoItem from "../components/personal-training/VideoItem";
import { navigate } from "gatsby";
import { StudioMail } from "../../mock/studioEmail.js"
import InputNumber from "../common/inputs/InputNumber";
import ModalBase from "../common/modals/ModalBase";
import axios from "axios";
import { toastError, toastSuccess } from "../common/toasts/toast";
function index() {
  const settings = {
    className: "center-slide",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 3,
    slidesToShow: 1,
    variableWidth: true,
    arrows: false,
    focus: false,
    centerMode: true,
  };

  const { data } = useFetch({
    params: "personal-traning-page",
    populate:
      "topBanner.background,bottomCarousels.background,belowFormBanner.background,videosCarousels.images,customMetadata,bookPT ",
  });

  function createMarkup(data) {
    return { __html: data };
  }
  const [ptPack, setPtPack] = useState({})
  const [payment, setPayment] = useState({})
  const [enquire, setEnquire] = useState({})
  const handleLog = (data: any, isPayment: boolean = false, isEnquire: boolean = false) => {
    if (isEnquire) setEnquire({ ...enquire, [data.target.name]: data.target.value })
    if (isPayment) setPtPack({ ...ptPack, [data.target.name]: data.target.value })
    else setPayment({ ...payment, [data.target.name]: data.target.value })
  }
  const [ptPackModal, setPtPackModal] = useState(false)
  const submitData = () => {
    setStep(0)
    setPtPackModal(true)

    // ptPack
    // "firstName": "string",
    // "lastName": "string",
    // "email": "string",
    // "phoneNumber": "string",
    // "location": "string",
    // "pack": "string",
    // "goal": "string",
    // "trainningTime": "am"

    // payment detail
    // "name": "string",
    // "cardNumber": "string",
    // "cardType": "string",
    // "expiryDate": "2023-06-12",
    // "CVV": "string"
  }

  const submitPayment = async () => {
    try {
      if (!(payment && Object.values(payment).every(i => i != ''))) throw Error()
      await axios.post('https://cms.clublime.com.au/api/book-personal-trainings', { data: ptPack })
      await axios.post('https://cms.clublime.com.au/api/payments', { data: payment })
      toastSuccess('Thank for your booking')
      setPtPackModal(false)

    } catch (e) {
      toastError('Please make sure you fulfill all the inputs')
    }
  }
  const submitEnquire = async () => {
    try {
      if (!(payment && Object.values(payment).every(i => i != ''))) throw Error()
      await axios.post('https://cms.clublime.com.au/api/book-personal-trainings', { data: ptPack })
      await axios.post('https://cms.clublime.com.au/api/payments', { data: payment })
      toastSuccess('Thank for your booking')
      setPtPackModal(false)

    } catch (e) {
      toastError('Please make sure you fulfill all the inputs')
    }
  }
  const submitPt = () => {
    Object.values(ptPack).every(i => i != '') ? setStep(1) : toastError('Please make sure you fulfill all the inputs')
  }
  const [step, setStep] = useState(0)
  return (
    <Layout>
      {ptPackModal && <ModalBase noBaseHeader onClickOutSide={() => setPtPackModal(false)}>
        <div className="md:w-[628px] p-6 ">
          <div className="flex flex-col gap-6">
            <h1 className="text-brand-clime text-left font-normal bebas text-[30px] leading-[90%] uppercase border-b border-b-brand-clime pb-2 w-full truncate tracking-wide">{step == 0 ? "Your personal details" : step == 1 ? 'Your payment details' : 'enquire now'}</h1>
            {/* // ptpack */}
            {step == 0 && <div className="grid grid-cols-2 gap-2">
              <InputBase
                holder="First name*"
                style="md:w-full"
                nameInput="firstName"
                isRequired={true}
                onchange={(e) => handleLog(e, true)}
              ></InputBase>
              <InputBase
                holder="Last name*"
                style="md:w-full"
                nameInput="lastName"

                isRequired={true}
                onchange={(e) => handleLog(e, true)}

              ></InputBase>
              <InputBase
                holder="Email address*"
                style="md:w-full"
                nameInput="email"
                isRequired={true}
                onchange={(e) => handleLog(e, true)}
              ></InputBase>
              <InputNumber
                holder="Phone number*"
                nameInput="phoneNumber"
                style="md:w-full"
                isRequired={true}
                onchange={(e) => handleLog(e, true)}
              ></InputNumber>
              <SelectOutline
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument md:w-full text-[14px]"
                placeholder="Select a location*"
                placeholderColor="text-slate-400"
                data={Object.keys(StudioMail).sort()}
                onSelect={(v) => handleLog({ target: { value: v, name: "location" } }, true)}
              />
              <SelectOutline
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument lg:w-full text-[14px]"
                placeholder="What's your goal?"
                placeholderColor="text-slate-400"
                data={["Weight Loss", "Increase Lean Muscle", "Build Strength", "Improve Mobility/Flexibility", "Improve Cardiovascular Capability", "Improve mind-body connection"]}
                onSelect={(v) => handleLog({ target: { value: v, name: 'goal' } }, true)}

              />
              {/* <SelectOutline
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument md:w-full text-[14px]"
                placeholder="Who is the coach you want to book*"
                placeholderColor="text-slate-400"
                data={["Leah", "Superman", "Henry Cavill", "Ben Affleck"]}
                onSelect={(v) => handleLog({ target: { value: v, name: 'goal' } }, true)}

              /> */}
              <SelectOutline
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument md:w-full text-[14px]"
                placeholder="Training time "
                placeholderColor="text-slate-400"
                data={["am", 'pm']}
                onSelect={(v) => handleLog({ target: { value: v, name: 'trainningTime' } }, true)}

              />
            </div>}
            {/* payment */}
            {step == 1 && <div className="grid grid-cols-2 gap-2">
              <InputBase
                onchange={(e) => handleLog(e, true)}
                nameInput="cardHolderName"
                holder="Name on card*"
                style="lg:w-full"
                isRequired={true}
              />
              <InputBase
                onchange={(e) => handleLog(e, true)}
                nameInput="cardNumber"
                holder="Card number*"
                style="lg:w-full"
                isRequired={true}
              />
              <InputBase
                onchange={(e) => handleLog(e, true)}
                nameInput="cardExpiryDate"
                type=""
                holder="Expiry date*"
                style="lg:w-full"
                isRequired={true}
              />
              <InputBase
                onchange={(e) => handleLog(e, true)}
                nameInput="cardCvv"
                type="number"
                holder="CVV*"
                style="lg:w-full"
                isRequired={true}
              />
              <SelectOutline
                onSelect={(v) =>
                  handleLog({ target: { name: 'cardType', value: v } }, true)
                }
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument lg:w-full text-[14px]"
                placeholder="Card type*"
                placeholderColor="text-slate-400"
                data={["Visa", "Master", "Amex"]}
              />
            </div>}
            {/* enquire */}
            {step == -1 && <div className="grid grid-cols-2 gap-2">
              <InputBase
                holder="First name*"
                style="md:w-full"
                nameInput="firstName"
                isRequired={true}
                onchange={(e) => handleLog(e, false, true)}
              ></InputBase>
              <InputBase
                holder="Last name*"
                style="md:w-full"
                nameInput="lastName"

                isRequired={true}
                onchange={(e) => handleLog(e, false, true)}

              ></InputBase>
              <InputBase
                holder="Email address*"
                style="md:w-full"
                nameInput="email"
                isRequired={true}
                onchange={(e) => handleLog(e, false, true)}
              ></InputBase>
              <InputNumber
                holder="Phone number*"
                nameInput="phoneNumber"
                style="md:w-full"
                isRequired={true}
                onchange={(e) => handleLog(e, false, true)}
              ></InputNumber>
              <SelectOutline
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument md:w-full text-[14px]"
                placeholder="Select a location*"
                placeholderColor="text-slate-400"
                data={Object.keys(StudioMail).sort()}
                onSelect={(v) => handleLog({ target: { value: v, name: "location" } })}
              />
              <SelectOutline
                titleStyle="text-[12px]"
                style=" h-[54px] w-full font-abcmonument lg:w-full text-[14px]"
                placeholder="What's your goal?"
                placeholderColor="text-slate-400"
                data={["Weight Loss", "Increase Lean Muscle", "Build Strength", "Improve Mobility/Flexibility", "Improve Cardiovascular Capability", "Improve mind-body connection"]}
                onSelect={(v) => handleLog({ target: { value: v, name: 'goal' } })}
              />
            </div>}
            <div className="self-end w-max">
              <ButtonNavigate style='self-end' isArrowRight text={step == 0 ? "NEXT" : 'SUBMIT'} onClick={() => { step == 0 && submitPt(); step == 1 && submitPayment(); step == -1 && submitEnquire() }} /></div>
          </div>
        </div>
      </ModalBase>}
      {/* Banner */}
      <div className="w-full md:h-[692px] h-[563px] relative">
        {data?.data?.attributes?.topBanner?.background?.data?.attributes?.url.slice(
          -3
        ) !== "mp4" ? (
          <img
            src={
              data?.data?.attributes?.topBanner?.background?.data?.attributes
                ?.url
            }
            className="w-full md:h-[692px] h-[563px] object-cover"
            alt="hiit intro"
          />
        ) : (
          <video
            autoPlay
            muted
            src={
              data?.data?.attributes?.topBanner?.background?.data?.attributes
                ?.url
            }
            className="w-full md:h-[692px] h-[563px] object-cover"
            alt="hiit intro"
          />
        )}
        <div className="w-full absolute top-[50%] transform  translate-y-[-50%]">
          <h6 className="text-brand-clime text-[20px] gotham tracking-widest uppercase mx-auto font-medium text-center">
            {data?.data?.attributes?.topBanner?.title}
          </h6>
          <p className="uppercase mx-auto max-w-[680px] md:mt-[23px]  text-transparent md:text-[98px] text-[70px] leading-[90%] font-normal text-center text-white ">
            <div
              dangerouslySetInnerHTML={createMarkup(
                data?.data?.attributes?.topBanner?.description
              )}
            ></div>
          </p>
          <div className="md:mt-[36px] mt-[120px] w-full flex justify-center">
            <div className="flex group">
              <button
                className={`border-white uppercase border-b text-white gotham text-medium px-[33px]  py-[12px] font-bold flex  border-t border-l border-r-0 rounded-l  rounded-bl-[5px] rounded-tl-[5px] group-hover:border-brand-clime group-hover:text-brand-clime`}
                onClick={() =>
                  document.getElementById("pt-ca")?.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                    inline: "center",
                  })
                }
              >
                Book now
              </button>
              <div className="px-3 py-[12px] border-l border-[1px] rounded-br-[5px] rounded-tr-[5px]  flex items-center border-white text-white group-hover:border-brand-clime group-hover:text-brand-clime ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                >
                  <path
                    d="M13 12L7 18L1 12"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path d="M7 17L7 0" stroke="currentColor" strokeWidth="1.5" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Form Apply */}
      <div
        id="pt-ca"
        className="flex xl:pl-[70px] pl-[18px]  border-y md:mt-12 lg:mt-[109px] mt-[54px] w-full mx-auto lg:flex-row justify-between flex-col"
      >
        <div className="text-white lg:max-w-[380px] font-normal md:text-[64px] text-[45px] leading-[90%] lg:block md:flex items-start w-full gap-x-[60px] px-4">
          <p
            className="bebas bebas uppercase"
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.bookPT?.title
            )}
          >
          </p>

        </div>
        <div className="xl:px-[70px] px-[18px] w-full lg:w-2/3 flex justify-between flex-col">
          <p className=" Mono font-normal  text-base leading-[160%] text-white max-w-[365px]">
            {data?.data?.attributes?.bookPT?.description}
          </p>
          {/* <div className="grid md:grid-cols-2 gap-y-5 md:grid-flow-row grid-cols-1 grid-flow-row gap-x-[21px] ">
            <InputBase
              holder="First name*"
              style="md:w-full"
              nameInput="firstName"
              isRequired={true}
              onchange={handleLog}
            ></InputBase>
            <InputBase
              holder="Last name*"
              style="md:w-full"
              nameInput="lastName"

              isRequired={true}
              onchange={handleLog}

            ></InputBase>
            <InputBase
              holder="Email address*"
              style="md:w-full"
              nameInput="email"
              isRequired={true}
              onchange={handleLog}
            ></InputBase>
            <InputNumber
              holder="Phone number*"
              nameInput="phoneNumber"
              style="md:w-full"
              isRequired={true}
              onchange={handleLog}
            ></InputNumber>
            <SelectOutline
              titleStyle="text-[12px]"
              style=" h-[54px] w-full gotham md:w-full text-[14px]"
              placeholder="Select a location*"
              placeholderColor="text-slate-400"
              data={Object.keys(StudioMail).sort()}
              onSelect={(v) => handleLog({ target: { value: v, name: "location" } })}
            />
            <SelectOutline
              titleStyle="text-[12px]"
              style=" h-[54px] w-full gotham lg:w-full text-[14px]"
              placeholder="Select a PT Pack*"
              placeholderColor="text-slate-400"
              data={["Weight Loss", "Increase Lean Muscle", "Build Strength", "Improve Mobility/Flexibility", "Improve Cardiovascular Capability", "Improve mind-body connection"]}
              onSelect={(v) => handleLog({ target: { value: v, name: 'pack' } })}

            />
            <SelectOutline
              titleStyle="text-[12px]"
              style=" h-[54px] w-full gotham md:w-full text-[14px]"
              placeholder="Who is the coach you want to book*"
              placeholderColor="text-slate-400"
              data={["Leah", "Superman", "Henry Cavill", "Ben Affleck"]}
              onSelect={(v) => handleLog({ target: { value: v, name: 'goal' } })}

            />
            <SelectOutline
              titleStyle="text-[12px]"
              style=" h-[54px] w-full gotham md:w-full text-[14px]"
              placeholder="Training time "
              placeholderColor="text-slate-400"
              data={["am", 'pm']}
              onSelect={(v) => handleLog({ target: { value: v, name: 'trainningTime' } })}

            />
          </div> */}

          <div className="flex  justify-start items-center gap-3 mt-[36px] ">
            <ButtonNavigate
              text="BUY NOW"
              style="h-[45px] w-[127px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
              isArrowRight={true}
              onClick={() => {  submitData() }}
            ></ButtonNavigate>
            <ButtonNavigate
              text="ENQUIRE NOW"
              style="h-[45px] w-[127px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
              isArrowRight={true}
              onClick={() => { setStep(-1); setPtPackModal(true) }}
            ></ButtonNavigate>
          </div>
        </div>
      </div>
      {/* Earning */}
      <div className="md:mt-[102px] earning mt-[50px] xl:w-[1181px] w-full mx-auto flex md:px-0 px-[18px] items-center md:gap-[87px] gap-[40px] md:flex-row">
        <img
          src={
            data?.data?.attributes?.belowFormBanner?.background?.data
              ?.attributes?.url
          }
          className="md:w-[420px] md:h-[470px] lg:w-[542px] w-full object-cover lg:h-[601px] h-[346px]"
        />
        <div>
          <h6
            className="uppercase Gravity font-normal text-brand-clime md:text-[65px] text-[45px] max-w-[552px] leading-[90%]"
            dangerouslySetInnerHTML={createMarkup(
              data?.data?.attributes?.belowFormBanner?.title
            )}
          >
            {/* {data?.data?.attributes?.belowFormBanner?.title} */}
          </h6>
          {/* <div
            className="lg:mt-[71px] mt-[25px]"
            onClick={() => navigate("/pt-careers")}
          >
            <ButtonNavigate
              text="LEARN MORE"
              style="h-[45px] w-[149px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
              isArrowRight={true}
            ></ButtonNavigate>
          </div> */}
          <p className="text-base mr-6 text-white Mono">
            {data?.data?.attributes?.belowFormBanner?.description}
          </p>
        </div>
      </div>
      {/* Slider */}
      <div className="md:h-[670px] h-[470px] w-full bg-[#212121] md:mt-[187px] mt-[90px] relative">
        <span className="absolute left-[290px] md:left-[70px] h-[838px] bg-[#cccc] hidden md:block w-[1px] -top-[85px]"></span>
        <span className="absolute right-[290px] md:right-[70px] h-[838px] bg-[#cccc] hidden md:block w-[1px] -top-[85px]"></span>
        <h6 className="uppercase bebas font-normal md:text-[65px] text-[47px] transform md:translate-y-[-27px] translate-y-[-20px] leading-[90%] text-white text-center">
          {data?.data?.attributes?.videosCarousels?.[0]?.customMetadata?.title}
        </h6>
        <span className="neue-border hidden md:block  font-normal text-[99px]  -rotate-90 origin-left left-[300px] md:left-[70px] bottom-10 absolute ">
          {
            data?.data?.attributes?.videosCarousels?.[0]?.customMetadata
              ?.textSlide?.[0]
          }
        </span>
        <span className="neue-border hidden md:block  font-normal text-[99px] -rotate-90 origin-right right-[285px] md:right-[74px]   absolute ">
          {
            data?.data?.attributes?.videosCarousels?.[0]?.customMetadata
              ?.textSlide?.[1]
          }
        </span>
        <p className="neue-border text-[70px] absolute top-8 w-full flex md:hidden justify-center">
          {
            data?.data?.attributes?.videosCarousels?.[0]?.customMetadata
              ?.textSlide?.[0]
          }
        </p>
        <p className="neue-border text-[70px] absolute bottom-0 w-full flex md:hidden justify-center">
          {
            data?.data?.attributes?.videosCarousels?.[0]?.customMetadata
              ?.textSlide?.[1]
          }
        </p>
        <Slider {...settings} className="offer-slider md:mt-[30px] mt-[80px]">
          {data?.data?.attributes?.videosCarousels?.map((item: object) => (
            <VideoItem
              key={item.id}
              url={item?.images?.data?.[0]?.attributes?.url}
            />
          ))}
        </Slider>
        <div className="hidden lg:flex w-full justify-center  transform translate-y-[35px]">
          {/* <ButtonNavigate
            text="FIND A PT"
            style="h-[45px] w-[127px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
            isArrowRight={true}
          ></ButtonNavigate> */}
        </div>
      </div>
      {/* About us */}
      <div
        // id="pt-ca"
        className="flex  w-full relative mx-auto p-[45px] md:mt-[80px] mt-0 h-[632px] items-center"
      >
        <img
          src={
            data?.data?.attributes?.bottomCarousels?.background?.data
              ?.attributes?.url
          }
          className="w-full md:h-full h-[507px] object-cover"
        />
        <div className="absolute md:top-[287px] top-[199px] md:left-[70px] left-[23px]">
          <p className="uppercase mx-auto max-w-[680px] mt-[23px] text-transparent md:text-[98px] text-[70px] leading-[90%] font-normal  text-left text-white ">
            <span className="neue-border text-transparent">
              {data?.data?.attributes?.bottomCarousels?.title}
            </span>
            <br className="hidden md:block" />
            <span className="Gravity font-normal text-brand-clime text-left md:ml-0 ml-2 md:text-[98px] text-[70px]">
              {data?.data?.attributes?.bottomCarousels?.description}
            </span>
          </p>
          <div className="mt-[25px]" onClick={() => navigate("/pt-careers")}>
            {/* <ButtonNavigate
                            text="PT CAREERS"
                            // isInSlider={true}
                            // arrowHoverWhite={true}
                            style="h-[45px] w-[149px] flex items-center gap-[23px] justify-center border-[#777777] text-[#777777] border-b  font-bold focus:border-b-[5px] focus:border-b-brand-lime border-t border-l border-r hover:border-white hover:text-white  text-[#777777] border-[#777777] text-[#777777] border-b"
                            isArrowRight={true}
                        ></ButtonNavigate> */}
            <ButtonNavigate
              text="PT CAREERS"
              isArrowRight={true}
              style="border-black text-black group-hover:border-white group-hover:text-white "
            ></ButtonNavigate>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default index;
/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M0 0h24v24H0z" fill="none"></path><path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"></path></svg> */
