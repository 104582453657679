import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import overlay from "../../assets/images/blog/overlay.png";
import { Link } from "gatsby";
import useFetch from "../../hooks/useFetch";
import LoadingScreen from "../../common/loading/LoadingScreen";
import moment from "moment";
import { Location } from "@reach/router";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";

function BlogLayout() {
  const { data, loading } = useFetch({ params: "blogs", populate: "thumbnail,catagory", });
  const { data: blogIntro } = useFetch({ params: "blog-page", populate: "quotes", });

  const [findCategory, setFindCategory] = useState<string>("");
  const [blogsData, setBlogsData] = useState(data?.data);
  const [focus, setFocus] = useState<boolean>(false);

  const [actions, setActions] = useState<object>({ filter: false, search: false, sort: false, });
  const [categories, setCategories] = useState<[]>([]);
  const [sort, setSort] = useState<[]>([]);

  const [currentValues, setCurrentValues] = useState<any>({ search: "", sort: ["Lastest", "Oldest"], });

  // Blog data => Filter Category when the app load
  useEffect(() => {
    setBlogsData(data?.data);
    if (findCategory !== undefined && categories.length) {
      setCategories([findCategory]);
      handleFilter();
    }
  }, [data, findCategory]);

  // Search
  const handleSearch = (e: any) => {
    if (e.key === "Enter") {
      const newFilter = data?.data.filter((value: object) => {
        return value?.attributes?.title
          .toLowerCase()
          .includes(currentValues?.search?.toLowerCase());
      });
      currentValues.search !== ""
        ? setBlogsData(newFilter)
        : setBlogsData(data?.data);
    }
  };

  // Filter
  const handleFilter = () => {
    setActions({ ...actions, filter: false });
    if (categories.length >= 1) {
      const newData = data?.data?.filter((data) => categories.includes(data?.attributes?.catagory?.data?.attributes?.title))
        .map((filteredName) => { return filteredName; });
      setBlogsData(newData);
    } else {
      setBlogsData(data?.data);
    }
  };

  // Sort
  const handleSort = () => {
    setActions({ ...actions, sort: false });
    if (sort.length == 0 || sort.length == 2) return;
    const sorting = blogsData.sort((a, b) => {
      return sort.includes("Lastest")
        ? moment(b.attributes.createdAt).diff(a.attributes.createdAt)
        : moment(a.attributes.createdAt).diff(b.attributes.createdAt);
    });
    setBlogsData(sorting);
  };

  // Check value categories, sort exists
  const handleCheckCategories = (val: string) => {
    setCategories((prev) => {
      const isChecked = categories.includes(val);
      if (isChecked) {
        return categories.filter((item) => item !== val);
      } else {
        return [...prev, val];
      }
    });
  };
  const handleCheckSorting = (val: string) => {
    setSort((prev) => {
      const isChecked = sort.includes(val);
      if (isChecked) {
        return sort.filter((item) => item !== val);
      } else {
        return [...prev, val];
      }
    });
  };

  // Unique Categories
  const uniqueCategory = Array.from(new Set(data?.data?.map((item: object) => item?.attributes?.catagory?.data?.attributes?.title)));

  return (
    <Layout>
      <Location>
        {({ location }) => {
          setFindCategory(location?.state?.category);
          return null;
        }}
      </Location>
      {loading && <LoadingScreen />}
      {!loading && blogsData && (
        <div>
          <div className=" max-w-[1280px] mx-auto flex justify-center flex-col items-center">
            <div className="mt-[87px] text-center">
              <h6 className="text-brand-orange font-bold text-[24px] leading-[100%]">
                on the blog
              </h6>
              <h2 className="mt-[28px] outline-text-md lg:text-[80px] text-[50px] uppercase leading-[90%] tracking-wider font-bold text-center lg:max-w-[585px] max-w-[350px]">
                {blogIntro?.data?.attributes?.title}
              </h2>
            </div>
            {/* Filter */}
            <div className="mt-[72px] w-full grid grid-cols-1 lg:grid-cols-3 px-2 lg:px-0 gap-[14px]">
              {/* Search */}
              <div className="py-[16px] pl-[25px] gap-[19px] bg-[#292929] flex items-center justify-between">
                <svg
                  width="25"
                  height="23"
                  viewBox="0 0 25 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="15.8912"
                    y1="14.8002"
                    x2="24.1415"
                    y2="22.2113"
                    stroke={focus ? "#FF6900" : "gray"}
                    strokeWidth="1.57426"
                  />
                  <circle
                    cx="10.0022"
                    cy="10.3639"
                    r="7.15275"
                    transform="rotate(-72.0294 10.0022 10.3639)"
                    stroke={focus ? "#FF6900" : "gray"}
                    strokeWidth="1.57426"
                  />
                </svg>
                <input
                  onBlur={() => setFocus(false)}
                  onFocus={() => setFocus(true)}
                  onKeyDown={handleSearch}
                  type="text"
                  value={currentValues.search}
                  onChange={(e) =>
                    setCurrentValues({
                      ...currentValues,
                      search: e.target.value,
                    })
                  }
                  placeholder="Search the blog"
                  className={`w-full border-0 outline-none ${currentValues.search.length > 0
                    ? "text-brand-orange"
                    : "text-gray"
                    }  bg-transparent graphik-regular font-normal text-[16px] leading-[160%]`}
                />
              </div>
              {/* Filter */}
              <div className="py-[16px] h-[59px] hidden lg:flex pl-[25px] bg-[#292929] relative  cursor-pointer pr-[22px] flex-col">
                <div className="flex items-center justify-between">
                  <h2 className="font-bold text-[18px] text-white">filter</h2>
                  <div onClick={() => setActions({ ...actions, filter: !actions.filter })} className="w-[50px] h-[30px] flex items-center justify-end">
                    <svg
                      width="17"
                      height="10"
                      viewBox="0 0 17 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L8.25114 8.25114L15.5023 1"
                        stroke={actions.filter == true ? "#ff6900" : "gray"}
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                </div>
                {actions.filter === true && (
                  <ul className="bg-[#292929] px-[25px]  pb-2 text-white z-10  absolute top-[59px]  left-0 w-full">
                    {uniqueCategory
                      ?.filter((item) => item !== undefined)
                      ?.map((item: any, index: number) => (
                        <li
                          key={index}
                          className="h-[55px] cursor-pointer relative flex justify-between items-center"
                        >
                          {item}
                          <label className="flex opacity-0  absolute right-0">
                            <input
                              type="checkbox"
                              className="h-[30px] w-[30px] cursor-pointer transform  relative -z-10"
                              value={item}
                              onChange={() => handleCheckCategories(item)}
                            />
                          </label>
                          <button
                            style={
                              categories.includes(item)
                                ? { borderBottom: "2px solid #FF6900" }
                                : { borderBottom: "1px solid white" }
                            }
                            className={`text-white font-bold h-[24px] w-[24px] border-t border-l border-r border-white flex justify-center items-center`}
                          >
                            {categories.includes ? (
                              <svg
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                fill="transparent"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 4.69231L5.2 9L13 1"
                                  stroke={
                                    categories.includes(item)
                                      ? "#FFF"
                                      : "hidden"
                                  }
                                  strokeWidth="1.02"
                                />
                              </svg>
                            ) : (
                              ""
                            )}
                          </button>
                        </li>
                      ))}
                    <ButtonNavigate
                      onClick={() => handleFilter()}
                      text="apply"
                      style="text-[#777777] border-[#777777] my-3 text-[3] w-full h-12  border-b"
                    ></ButtonNavigate>
                  </ul>
                )}
              </div>
              {/* Sort */}
              <div className="py-[16px] pl-[25px] bg-[#292929] relative hidden lg:flex pr-[22px] cursor-pointer items-center justify-between" >
                <h2 className="font-bold text-[18px] text-white">sort by</h2>
                <div onClick={() => setActions({ ...actions, sort: !actions.sort })}
                  className="w-[50px] h-[30px] flex items-center justify-end">
                  <svg
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L8.25114 8.25114L15.5023 1"
                      stroke={actions.sort == true ? "#ff6900" : "gray"}
                      strokeWidth="1.5"
                    />
                  </svg>
                </div>
                {actions.sort === true && (
                  <ul className="bg-[#292929] px-[25px]  pb-2 text-white z-10  absolute top-[59px]  left-0 w-full">
                    {currentValues.sort.map((item: string, index: number) => (
                      <li
                        key={index}
                        className="h-[55px] cursor-pointer relative flex justify-between items-center"
                      >
                        {item}
                        <label className="flex opacity-0  absolute right-0">
                          <input
                            type="checkbox"
                            className="h-[30px] w-[30px] cursor-pointer transform  relative -z-10"
                            value={item}
                            onChange={() => handleCheckSorting(item)}
                          />
                        </label>
                        <button
                          style={
                            sort.includes(item)
                              ? { borderBottom: "2px solid #FF6900" }
                              : { borderBottom: "1px solid white" }
                          }
                          className={`text-white font-bold h-[24px] w-[24px] border-t border-l border-r border-white flex justify-center items-center`}
                        >
                          {sort.includes ? (
                            <svg
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              fill="transparent"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 4.69231L5.2 9L13 1"
                                stroke={
                                  sort.includes(item) ? "#FF6900" : "hidden"
                                }
                                strokeWidth="1.02"
                              />
                            </svg>
                          ) : (
                            ""
                          )}
                        </button>
                      </li>
                    ))}
                    <ButtonNavigate
                      onClick={() => handleSort()}
                      text=" apply"
                      style="text-[#777777] border-[#777777] my-3 text-[3] w-full h-12  border-b"
                    ></ButtonNavigate>
                  </ul>
                )}
              </div>
            </div>
            {/* Blogs */}
            <div className="lg:mt-[72px] mt-[17px] w-full px-4 lg:px-0">
              {blogsData?.slice(0, 1)?.map((item: object, index: number) => (
                <Link key={index}
                  state={{ id: item?.id }}
                  to={`/blog/${item?.attributes?.slug}`}
                >
                  <div
                    style={{
                      backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 47.25%, #000000 100%),  url(${item?.attributes?.thumbnail?.data?.attributes?.url})`,
                    }}
                    className="relative w-full cursor-pointer bg lg:h-[500px] h-[351px] bg-cover "
                  >
                    <div>
                      <img
                        src={overlay}
                        className="absolute top-0 left-0 h-full w-full"
                      />
                      <h2 className="absolute  max-w-[462px] bottom-[111px] left-[24px] font-bold text-white text-[40px] tracking-tight lowercase">
                        {item?.attributes?.title}
                      </h2>
                      <p className="graphik-regular absolute left-[24px] text-white font-normal text-sm max-w-[330px] leading-[160%] bottom-[37px]">
                        {item?.attributes?.description}
                      </p>
                      <label className="text-black absolute right-0 top-0 py-2 px-4 text-[16px] graphik-regular font-normal bg-brand-orange">
                        {item?.attributes?.catagory?.data?.attributes?.title}
                      </label>
                    </div>
                  </div>
                </Link>
              ))}

              <div className="lg:mt-[39px] mt-[27px] w-full grid lg:grid-cols-2 grid-cols-1 lg:gap-[71px] gap-[27px]">
                {blogsData
                  ?.slice(1, blogsData?.length)
                  ?.map((item: object, index: number) => (
                    <Link
                      state={{ id: item?.id }}
                      to={`/blog/${item?.attributes?.slug}`}
                    >
                      <div
                        key={index}
                        style={{
                          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 47.25%, #000000 100%),  url(${item?.attributes?.thumbnail?.data?.attributes?.url})`,
                        }}
                        className={`lg:h-[500px] h-[351px] hover:border-brand-orange border transition-colors cursor-pointer relative`}
                      >
                        <h2
                          className={`absolute left-[28px] max-w-[462px] leading-[110%] bottom-[111px] tracking-tight lowercase  font-bold text-[40px] ${index === 0 ? "text-brand-orange" : "text-white"
                            }`}
                        >
                          {item?.attributes?.title}
                        </h2>
                        <p className="graphik-regular absolute bottom-[26px] max-w-[330px] left-[28px] text-white text-sm font-normal leading-[160%]">
                          {item?.attributes?.description}
                        </p>
                        <label className="text-black absolute right-0 top-0 py-2 px-4 text-[16px] graphik-regular font-normal bg-brand-orange">
                          {item?.attributes?.catagory?.data?.attributes?.title}
                        </label>
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default BlogLayout;
