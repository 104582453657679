import React, { useState } from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import { navigate } from "gatsby"
export default function PersonalTrainer({ data }) {
  const [classActive, setClassActive] = useState();

  return (
    <div className="text-white gotham">
      <p className="uppercase xl:pl-[70px] pl-[25px] xl:pt-[36px] pt-[24px] xl:pb-[31px] pb-[20px]  bebas text-[45px] xl:text-[65px] leading-[40.5px] xl:leading-[58px] border-y border-[rgba(255,255,255,0.4)]">
        Meet our Personal Trainer
      </p>
      <div></div>
      {/* <div className="h-[607px]">
        {personalTrainer.map((item, inperdex) => (
          <div className="w-[607px] rotate-90 bg-red-500">{item.title}</div>
        ))}
      </div> */}
      <ul className="xl:flex block  ">
        <li
          className={` border-[1px] xl:border-x-[1px] border-x-[0px] relative border-[rgba(255,255,255,0.4)] transition-all duration-300 xl:block hidden h-[607px] w-[70px]`}
        ></li>
        {data?.coachers?.map(
          (i, idx) =>
            idx > -1 && (
              <li
                key={idx}
                className={` border-[1px] xl:border-x-[1px] border-x-[0px] relative border-[rgba(255,255,255,0.4)] transition-all duration-300 xl:h-[607px] ${i?.title === classActive
                    ? "xl:w-[858px] w-full"
                    : "xl:w-[85px] w-full "
                  }`}
                onClick={() => setClassActive(i?.title)}
              >
                <p
                  className={` font-bold   absolute gotham  w-max bottom-1/2 ml-[26px] xl:ml-0 left-[50%] h-[57px]  justify-center items-center origin-center xl:translate-y-1/2 -translate-x-1/2 xl:-rotate-90 uppercase ${i?.title === classActive ? "hidden" : "xl:flex hidden"
                    }`}
                >
                  {i?.title}
                </p>
                <p
                  className={`font-bold   gotham   ml-[26px]  h-[57px] flex xl:hidden justify-center items-center origin-center  ${i?.title === classActive ? "hidden" : ""
                    }`}
                >
                  {i?.title}
                </p>
                <div
                  className={`relative overflow-hidden coacher-content transition-all duration-300 md:pt-[70px] md:px-[70px] py-[45px] md:py-[25px]   ${
                    i?.title === classActive
                      ? " md:h-full w-full"
                      : "w-0 hidden"
                    }`}
                >
                  <div className="md:flex-row flex-col flex  relative gap-6 md:gap-[40px]">
                    <p className=" text-effect text-transparent text-[60px] leading-[54px] md:text-[85px] bebas md:leading-[76px] absolute top-[163px] left-1/2 -translate-x-1/2  md:left-[50%] md:-translate-x-1/2 md:top-20 whitespace-nowrap">
                      {i?.title}
                    </p>

                    <img
                      className="md:w-full w-1/2 max-h-[477px] max-w-[364px] min-h-[477px] min-w-[364px] md:h-auto object-cover"
                      src={i?.background?.data?.attributes?.url}
                    />
                    <div className="flex flex-col justify-end relative gap-4 md:px-0 px-4">
                      <p className="gotham text-brand-clime text-[16px] md:text-[18px] uppercase">
                        level {i?.customMetadata?.level ?? " 3 "} pt
                      </p>
                      <div className="gotham text-[14px]">
                        {i?.description ??
                          `Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat.`}
                      </div>
                      <ButtonNavigate text="GET IN TOUCH" isArrowRight={true} onClick={() => navigate('/personal-training')} />
                    </div>
                  </div>
                </div>
              </li>
            )
        )}
      </ul>
    </div>
  );
}
