import React from "react";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useFetch from "../../hooks/useFetch";
import { navigate } from "gatsby";
import { createMarkup } from "../../common/functions/setInnerHtml";
export default function GroupFitnessSlider() {
  // const [slider, setSlider] = useState<any>();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots: any) => (
      <div
        className="cursor-pointer"
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          margin: "0 0 42px 74px",
        }}
      >
        <ul
          className="cursor-pointer"
          style={{
            width: "100%",
            margin: "0 9px 0 0",
            display: "flex",
            gap: "9px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div
        key={i}
        className="h-full bg-transparent  justify-center items-center cursor-pointer md:flex hidden"
      >
        <div
          className="bg-brand-clime"
          style={{
            width: "100%",
            height: "2px",
          }}
        ></div>
      </div>
    ),
  };
  const { data: clubLime } = useFetch({
    params: "group-fitness-page",
    populate: "topCarousels,topCarousels.background,topCarousels.mobileImage",
  });

  return (
    <div className="homeCarousel lg:h-full max-h-[680px] h-[555px] overflow-x-hidden overflow-y-hidden">
      {clubLime != null && (
        <Slider
          //  ref={(c: any) => setSlider(c)}
          {...settings}
          className="h-full max-h-[680px]"
        >
          {clubLime.data.attributes.topCarousels.map((item, index: number) => (
            <div key={index} className="w-full relative h-full max-h-[680px] ">
              {item?.background?.data?.attributes?.url?.slice(-3) == "mp4" ? (
                <video
                  autoPlay
                  muted
                  loop
                  className="w-[1440px] !p-0 md:h-full max-h-[680px] h-[555px] opacity-90 object-cover md:block hidden "
                  src={item?.background?.data?.attributes?.url}
                ></video>
              ) : (
                <img
                  className="w-[1440px] md:h-full max-h-[680px] h-[555px] opacity-90 object-cover object-top md:block hidden"
                  src={item?.background?.data?.attributes?.url}

                // src={item?.background?.data?.attributes?.url}
                />
              )}
              {item?.mobileImage?.data?.attributes?.url?.slice(-3) == "mp4" ? (
                <video
                  autoPlay
                  muted
                  loop
                  className="w-[1440px] md:h-full max-h-[680px] h-[555px] opacity-90 object-cover md:hidden"
                  src={item?.mobileImage?.data?.attributes?.url}
                ></video>
              ) : (
                <img
                  className="w-[1440px] md:h-full max-h-[680px] h-[555px] opacity-90 object-cover object-top md:hidden"
                  src={item?.mobileImage?.data?.attributes?.url}

                // src={item?.background?.data?.attributes?.url}
                />
              )}
              <div className="absolute  flex flex-col justify-between left-1/2 -translate-y-1/2 -translate-x-1/2 text-black top-1/2 ">
                <div dangerouslySetInnerHTML={createMarkup(item.title)}></div>

                <p
                  className="bebas leading-[90%] mb-[40px] md:mb-[71px]  text-effect text-transparent w-full bebas text-[70px] md:text-[98px] text-center"
                  dangerouslySetInnerHTML={createMarkup(item.description)}
                >
                  {/* Challenge
                  <br /> your limits */}
                  {/* {item.description} */}
                </p>
                <div className="w-full flex justify-center">
                  <ButtonNavigate
                    onClick={() => navigate("/book-a-class")}
                    text={"VIEW CLASS TIMETABLE"}
                    isArrowRight={true}
                    isInSlider={true}
                    style="text-white border-white group-hover:text-brand-clime group-hover:border-brand-clime group-focus:border-brand-clime"
                  ></ButtonNavigate>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
}
