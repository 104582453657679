import React from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LoadingScreen from "../../common/loading/LoadingScreen";
import homeSlide from "../../assets/images/homeClubLime/homeSlide.png";
import ButtonNavigate from "../../common/buttons/ButtonNavigate";
import useFetch from "../../hooks/useFetch";
import { navigate } from "gatsby";

function HomeSlider() {
  // const [slider, setSlider] = useState<any>();
  const { loading, data } = useFetch({
    params: "home-page",
    populate: "topCarousels, topCarousels.background, customMetadata,topCarousels.mobileImage",
  });

  const sliderData = data?.data?.attributes?.topCarousels;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
    appendDots: (dots: any) => (
      <div
        className="cursor-pointer"
        style={{
          position: "absolute",
          bottom: "0",
          display: "flex",
          margin: "0 0 42px 74px",
        }}
      >
        <ul
          className="cursor-pointer"
          style={{
            width: "100%",
            margin: "0 9px 0 0",
            display: "flex",
            gap: "9px",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: any) => (
      <div
        key={i}
        className="h-full bg-transparent flex justify-center items-center cursor-pointer"
      >
        <div
          className="bg-lime-primary"
          style={{
            width: "100%",
            height: "2px",
          }}
        ></div>
      </div>
    ),
  };
  return (
    <>
      {loading && <LoadingScreen />}
      {!loading && sliderData && (
        <div className="homeCarousel md:h-full  overflow-x-hidden overflow-y-hidden">
          <Slider
            // ref={(c: any) => setSlider(c)}
            {...settings}
            className="h-full"
          >
            {sliderData?.map((item: object, index: number) => (
              <div key={index} className="w-full relative h-full">
                <div className="sm:!hidden !block">
                  {item.mobileImage?.data?.attributes.url.slice(-3) == "mp4" ? (<>
                    <video
                      autoPlay muted
                      className="w-[1440px] sm:block hidden h-full max-h-[555px] !opacity-100 object-cover !p-0"
                      src={item.mobileImage?.data?.attributes.url ?? homeSlide}
                    // src={item?.background?.data?.attributes?.url}
                    /></>
                  ) : (
                    <>
                      <img
                        className="w-[1440px]  sm:!hidden !block h-full max-h-[555px] opacity-90 object-cover"
                        src={item.mobileImage?.data?.attributes.url ?? homeSlide}
                      // src={item?.background?.data?.attributes?.url}
                      />
                    </>
                  )}
                </div>
                <div className="sm:block hidden">
                  {item.background?.data?.attributes.url.slice(-3) == "mp4" ? (<>
                    <video
                      autoPlay muted
                      className="w-[1440px] sm:block hidden h-full max-h-[555px] !opacity-100 object-cover !p-0"
                      src={item.background?.data?.attributes.url ?? homeSlide}
                    // src={item?.background?.data?.attributes?.url}
                    /></>
                  ) : (
                    <>
                      <img
                        className="w-[1440px] sm:!block !hidden h-full max-h-[555px] opacity-90 object-cover"
                        src={item.background?.data?.attributes.url ?? homeSlide}
                      // src={item?.background?.data?.attributes?.url}
                      />

                    </>
                  )}
                </div>

                <div className="absolute h-[70%] flex flex-col justify-between left-[50px] text-black top-1/2 -translate-y-1/2">
                  <div className="bebas md:text-[159px] text-[100px] leading-[90%] w-full md:w-[502px]">
                    {item?.title?.split("<br/>")[0] ?? ""}
                    <br />
                    <p className="w-full text-left md:text-right bebas text-transparent  text-effect-black">
                      {item?.title?.split("<br/>")[1] ?? ""}
                    </p>
                  </div>

                  {item?.customMetadata?.button && (
                    <ButtonNavigate
                      onClick={() => navigate(item?.customMetadata?.link)}
                      arrowHoverWhite={true}
                      text={item?.customMetadata?.label}
                      isArrowRight={true}
                      style="border-black text-black group-hover:border-white group-hover:text-white uppercase"
                      isInSlider={true}
                    ></ButtonNavigate>
                  )}
                </div>
                {/* <img
                  className="sm:w-[422px] w-[239px] sm:h-[530px] object-cover h-[301px] absolute top-1/2 left-1/2 -translate-y-2/4 -translate-x-2/4"
                  src={item?.images?.data[0]?.attributes?.url}
                />
                <p className="outline-text font-bold  sm:text-[80px] text-[50px] leading-[95%] w-full text-center  absolute top-1/2 -translate-y-2/4 -tracking-tighter">
                  {item?.title}
                </p> */}
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
}

export default HomeSlider;
