export const footer = {
  explore: {
    title: "EXPLORE",
    categories: [
      {
        name: "Clubssd",
        link: "studio",
      },
      {
        name: "Memberships",
        link: "memberships",
      },
      {
        name: "Group Fitness",
        link: "",
      },
      {
        name: "Personal Training",
        link: "",
      },
      {
        name: "Lifestyle",
        link: "life-style",
      },
      {
        name: "Timetable",
        link: "book-a-class",
      },
      {
        name: "Blog",
        link: "blog",
      },
    ],
  },
  support: {
    title: "SUPPORT",
    categories: [
      {
        name: "Talk With Us",
        link: "contact",
      },
      {
        name: "Help centre",
        link: "",
      },
      {
        name: "FAQ",
        link: "",
      },
      {
        name: "Privacy",
        link: "",
      },
      {
        name: "Terms Of Service",
        link: "",
      },
    ],
  },
  joinUs: {
    title: "JOIN US",
    categories: [
      {
        name: "Join Now",
        link: "",
      },
      {
        name: "Work with us",
        link: "work-with-us",
      },
      {
        name: "PT Careers",
        link: "pt-careers",
      },
    ],
  },
  members: {
    title: "MEMBERS",
    categories: [
      {
        name: "Login",
        link: "login",
      },
    ],
  },
  outBrands: {
    title: "OUR BRANDS",
    categories: [
      {
        name: "Club Lime",
        link: "",
      },
      {
        name: "Hiit Republic",
        link: "",
      },
      {
        name: "Ground Up",
        link: "",
      },
    ],
  },
  membersIdApp: {
    title: "CLUB LIME MEMBER APP",
  },
  newsLetter: {
    title: "Newsletter Sign Up",
    description:
      "Qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas.",
  },
  copyrightText: "Viva Leisure Pty Ltd Copyright © 2022",
  exploreText:
    "We are part of the Viva Leisure Group. Viva operates over 100 health clubs across Australia, New Zealand and India.",
};
